import { Component } from '@angular/core';
import { EvaluationsService } from '../../services/evaluations.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from '../../../../../environnements/environment';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-completed-assessment',
  templateUrl: './completed-assessment.component.html',
  styleUrls: ['./completed-assessment.component.css'],
})
export class CompletedAssessmentComponent {
  currentUser: any;
  id: any;
  completedAssessment: any;
  totalCompletedAssessment: any;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  pagination : boolean =false;

  url: string = environment.baseUrl + '/api';

  type = [
    { id: 1, name: 'Annuelle' },
    { id: 2, name: 'Suivi' },
  ];

  searchForm: FormGroup = this.createSearchForm();
  private unsubscribeAll: Subject<void> = new Subject();

  isCollapsed = true; // Initial state
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  constructor(
    private evaluationsService: EvaluationsService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.getAllCompletedAssessment();

    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
  }

  getAllCompletedAssessment(data?: any) {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    let dataPayload = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      id: this.id,
    };

    console.log('idddddddddddddddddddddddddd', this.id);
    this.spinner.show();
    this.evaluationsService
      .getAllListCompletedAssessment(dataPayload)
      .subscribe(
        (res: any) => {
          this.completedAssessment = res?.data?.data;
          this.totalCompletedAssessment = res?.data?.total;
          this.spinner.hide();
          console.log('data completedAssessment ', this.completedAssessment);
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching completedAssessment:', error);
        }
      );
  }
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchListCompletedAssessment();
      }  else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true

        this.getAllCompletedAssessment();
      }
    }
  }
  visualiserEvaluation(id: any) {
    this.router.navigate([
      '/wegestu/evaluations/details-evaluation',
      { idEvaluation: id },
    ]);
  }

  profilCompanyNavigation(id: any) {
    this.router.navigate(['/wegestu/entreprises/details', { id: id }]);
  }

  createSearchForm(data?: any) {
    return this.formBuilder.group({
      company_name: [data ? data.company_name : ''],
      evaluation_type: [data ? data?.evaluation_type : null],
      start_date: [data ? data.start_date : ''],
      end_date: [data ? data.end_date : ''],
      state: [data ? data.end_date : ''],
    });
  }
  reset() {
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    this.searchListCompletedAssessment();
  }
  searchListCompletedAssessment() {
    let formattedEndDate = this.datePipe.transform(this.searchForm.value.end_date, 'yyyy-MM-dd');
    let formattedStartDate = this.datePipe.transform(this.searchForm.value.start_date, 'yyyy-MM-dd');
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      company_name: this.searchForm.value.company_name,
      evaluation_type: this.searchForm.value.evaluation_type,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      state: this.searchForm.value.state,
      id: this.id,
    };
    this.spinner.show();
    this.evaluationsService
      .searchCompletedAssessment(this.id, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('response format:', res);
          this.completedAssessment = res.data.data.slice(0, this.endIndex);
          this.spinner.hide();
        },
        error: () => {
          console.error('Error during evaluation search');
          this.spinner.hide();
        },
      });
  }
}
