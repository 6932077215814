import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class EvaluationsService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  getAllListEvaluation(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
      id?: any;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base + `/EvaluationSend/getEvaluationsByCandidate?id=${params.id}&
 per_page=${params?.per_page}&page=${params?.page}`
    );
  }

  getEvaluationsByCandidateDeatils(id?: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/EvaluationSend/getEvaluationsByCandidateDeatils?id=${id}`
    );
  }

  addEvaluationResponses(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseEvaluation/addResponse`,
      data
    );
  }
  sendEvaluationResponses(id: any,esn_id: any, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/EvaluationSend/sendEvalToESN?id=${id}&esn_id=${esn_id}`,
      data
    );
  }

  deleteEvaluation(id: number[]) {
    let ids = '';
    for (const iterator of id) {
      ids += `ids[]=${iterator}&`;
    }
    ids = ids.slice(0, -1);
    return this._http.delete<ApiResponse<any>>(
      this._base + `/ResponseEvaluation/deleteResponse?${ids}`
    );
  }
  updateEvaluationResponses(id: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseEvaluation/update/${id}`,
      data
    );
  }
  searchReceivedEvaluations(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/EvaluationSend/search`,
      data
    );
  }

  getAllListCompletedAssessment(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
      id?: any;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/EvaluationSend/completedEvaluationByCandidate?id=${params.id}&
 per_page=${params?.per_page}&page=${params?.page}`
    );
  }

  searchCompletedAssessment(id: any, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/EvaluationSend/SearchcompletedEvaluationByCandidate?id=${id}`,
      data
    );
  }
  getAllListPvs(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
      id?: any;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base + `/Pv/getAllPvsByUser?${sort}&id=${params?.id}`
    );
  }
  searchListPv(id: any, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Pv/searchPvsByUser?id=${id}`,
      data
    );
  }

  addSignature(id: any,company_name:any, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Pv/signedByCandidate?id=${id}&company_id=${company_name}`,
      data
    );
  }
  getAllDetailsOfPv(idPv: any) {
    return this._http.get<ApiResponse<any>>(this._base + `/Pv/getById/${idPv}`);
  }
}
