import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthentificationService } from '../../authentification/services/authentification.service';
import { NetworkService } from '../../main/network-status/services/NetworkService';

export namespace mainGuard {
  export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    const authService = inject(AuthentificationService);
    const router = inject(Router);
    const networkService = inject(NetworkService);

    // Check network connectivity before proceeding
    if (!networkService.getOnlineStatus()) {
      router.navigate(['/reconnect']);
      return false;
    }

    if (authService.isAuthenticated()) {
      return true;
    } else {
      router.navigate(['/auth/login']);
      return false;
    }
  };

  export const canActivateChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => canActivate(route, state);
}
