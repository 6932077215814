<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_534_1023)">
                            <path d="M8.73438 5.38853H19.3343V3.25956H15.8403V0H12.1576V3.25956H8.73438V5.38853Z"
                                fill="#265D91" />
                            <path
                                d="M23.0071 5.26135H21.1792V6.30811C21.1792 6.81596 20.7664 7.2277 20.2573 7.2277H7.81339C7.30421 7.2277 6.8914 6.81596 6.8914 6.30811V5.26135H4.99288C4.4445 5.26135 4 5.70476 4 6.25165V28.0122C4 28.5592 4.44456 29.0025 4.99288 29.0025H23.0071C23.5555 29.0025 24 28.5591 24 28.0122V6.25165C23.9999 5.70469 23.5554 5.26135 23.0071 5.26135ZM9.66407 24.6577C9.31129 25.0116 8.72134 25.0152 8.36426 24.6654L6.74707 23.0816L8.03906 21.7693L9.00248 22.7128L11.514 20.1933L12.8217 21.49L9.66407 24.6577ZM9.66407 14.8943C9.31129 15.2482 8.7214 15.2518 8.36426 14.9021L6.74707 13.3183L8.03906 12.0059L9.00248 12.9495L11.514 10.4299L12.8217 11.7267L9.66407 14.8943ZM21.4019 26.2713H14.9673V24.4321H21.4019V26.2713ZM21.4019 21.6653H14.9673V19.8261H21.4019V21.6653ZM21.4019 16.3509H14.9673V14.5117H21.4019V16.3509ZM21.4019 11.745H14.9673V9.90579H21.4019V11.745Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_534_1023" x="0" y="0" width="28" height="37.0026"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_534_1023" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_534_1023"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>


                    Evaluation
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Evaluation</li>
                <!--end::Item-->
                 <!--begin::Item-->
                 <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Listes des PVs</li>

                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->

            <div class="card-body tools">
                <div class="title mb-3">
                    <h4>Recherche</h4>
                </div>
                <div  [formGroup]="searchForm">
                    <div class="row mb-3">
                        <div class="col-lg-3">
                            <div class="input-shadow  mb-3">
                            <ng-select [items]="signed" bindLabel="name" bindValue="id"  id="type"
                            placeholder="État de Signature" formControlName="signed" [notFoundText]="'Aucun élément trouvé'">
                        </ng-select>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <input type="text" name="esn" id="esn" class="form-control form-control-solid mb-3"
                                placeholder="Entreprise" formControlName="company_name">
                        </div>
                        <div class="col-lg-3  mb-3">
                            <ng-select [items]="type" bindLabel="name" bindValue="id"  id="type"
                            placeholder="Type evaluation" [notFoundText]="'Aucun élément trouvé'" formControlName="evaluation_type">
                        </ng-select>
                        </div>
                        <div class="col-lg-3">
                                <div class="position-relative d-flex align-items-center calenderDate">
                                   
                                    <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy" 
                                    placeholder="Date de début" inputId="yearpicker"
                                    formControlName="start_date" class="form-control"
                                   ></p-calendar>
                                  </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="position-relative d-flex align-items-center calenderDate">
                               
                                <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy"  placeholder="Date de fin" 
                                inputId="yearpicker"
                                formControlName="end_date" class="form-control" [minDate]="searchForm.get('start_date')?.value"
                               ></p-calendar>
                              </div>
                    </div>
                        
                    </div>
                    <div class="row mb-3 rechercher">
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-3">

                            <div class="mb-3 search-btn d-flex justify-content-end">
                                <div class="btn_search">
                                    <a type="submit" (click)="searchPv()" class="search"> <svg width="20" height="20"
                                            viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                                fill="white" />
                                        </svg>
                                        Rechercher
                                    </a>
                                </div> &nbsp;&nbsp;
                       
                                <div class="resett-btn">
                   
                                    <div class="reset-icon cursor-pointer"  >
                                     <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                                     </div>
                                  </div>
                              
                              </div>

                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">

            <!--begin::Card header-->
            <div class="d-flex flex-wrap my-1 header-grid align-items-center mb-5">
                <div class="section_title">
                    <h1 class="section-heading my-0">Listes des PVs</h1>
                </div>
                <!--begin::Wrapper-->

                <!--end::Wrapper-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <div class="tab-content pt-5 pb-5">
                    <!--begin::Tab pane-->
                    <div id="project_targets_table_pane" class="tab-pane fade  active show">
                        <div class="content">
                            <div class="card-body pt-3">
                                <!--begin::Table-->
                                <div class="table-responsive">
                                    <table class="table align-middle fs-6 gy-5"
                                        id="kt_customers_table">
                                        <thead>
                                            <tr class="text-start fw-bold fs-7 text-uppercase gs-0">
                                                <th class="min-w-125px"><span>Logo</span>
                                                </th>
                                                <th class="min-w-125px"><span>Entreprise
                                                    <i (click)="sortData('name')" class="fa-solid fa-sort"></i></span>
                                                </th>
                                                <th class="min-w-125px"><span>PV NUM
                                                    <i (click)="sortData('name')" class="fa-solid fa-sort"></i></span>
                                                </th>
                                                <th class="min-w-125px"><span>TYPE
                                                    <i (click)="sortData('name')" class="fa-solid fa-sort"></i></span>
                                                </th>
                                                <th class="min-w-125px"><span>État de Signature</span></th>
                                               
                                                <th class="min-w-125px"><span>DATE
                                                    <i (click)="sortData('name')" class="fa-solid fa-sort"></i></span>
                                                </th>
                                                
                                                <th class="min-w-125px"><span>Compte rendu</span></th>
                                                
                                                <th class="min-w-70px"><span>Actions</span></th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-semibold">
                                            <tr *ngFor="let pv of listPv">
                                                <td>
                                                    <div class="symbol symbol-65px symbol-circle mb-5">

                                                    <img  src="/assets/media/img/modules/avatar.jpg" alt="image" />
                                                </div>
                                                   
                                                </td>
                                            
                                                <td>
                                                    <span class="esn">{{pv?.company_name?pv?.company_name:'---'}}</span>
                                                </td>
                                                <td>
                                                    <a class="num" 
                                                    [routerLink]="['/wegestu/evaluations/details-pv',{idPv:pv?.id}]">
                                                    {{pv?.pv_num?pv?.pv_num:'---'}}</a>
                                                </td>
                                                <td>
                                                    <span class="badge hybrid">{{pv?.evaluation_type=='1'?'Annuelle':
                                                        pv?.evaluation_type=='2'?'Suivi ':'---' }}</span>
                                                </td>
                                              <td>
                                                <span class="esn">{{pv?.signed_by_esn=='2'?'Signé par l`ESN':
                                                    pv?.signed_by_candidate=='2'?'Signé par vous ':'Non Signé' }} </span>
                                               </td>

                                                <!-- <td>
                                                    <div *ngIf="pv?.signed_by_esn === '2'" class="form-check form-check-custom form-check-solid checkbtn">
                                                        <input class="form-check-input" type="checkbox" value="1"
                                                            id="flexCheckChecked" checked="checked" disabled/>

                                                    </div>
                                                    <div *ngIf="pv?.signed_by_esn === '1'" class="form-check form-check-custom form-check-solid checkbtn">
                                                        <input class="form-check-input" type="checkbox" value="1"
                                                            id="flexCheckChecked"  disabled />

                                                    </div>
                                                </td>
                                                <td>
                                                    <div *ngIf="pv?.signed_by_candidate === '1'" class="form-check form-check-custom form-check-solid checkbtn">
                                                        <input class="form-check-input" type="checkbox" value="1"
                                                            id="flexCheckChecked"  disabled />

                                                    </div>
                                                    <div *ngIf="pv?.signed_by_candidate === '2'" class="form-check form-check-custom form-check-solid checkbtn">
                                                        <input class="form-check-input" checked="checked" type="checkbox" value="1"
                                                            id="flexCheckChecked"  disabled />

                                                    </div>
                                                </td> -->
                                                <td>
                                                    <span *ngIf="pv?.created_at">{{pv?.created_at | date:'dd/MM/yyyy'}}</span>
                                                    <span *ngIf="!pv?.created_at">---</span>

                                                </td>
                                                <td>
                                                    <a  (click)="openModalCompteRendu(compte_rendu,pv?.id)" >
                                                        <svg width="53" height="53" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <g filter="url(#filter0_d_3631_1281)">
                                                            <rect x="8" y="9" width="32.0513" height="32.0513" rx="7" fill="white"/>
                                                            </g>
                                                            <path d="M25.9122 17.8911H21.4336V14.9945H25.9122V17.8911Z" stroke="#265D91"/>
                                                            <path d="M27.1954 19.6704H27.6954V19.1704V16.5531H29.152C29.9568 16.5531 30.6087 17.2053 30.6087 18.0014V33.5879C30.6087 34.384 29.9568 35.0362 29.152 35.0362H18.1949C17.3902 35.0362 16.7383 34.384 16.7383 33.5879V18.0014C16.7383 17.2053 17.3902 16.5531 18.1949 16.5531H19.6515V19.1704V19.6704H20.1515H27.1954ZM21.3255 32.1396H26.0214C26.512 32.1396 26.9128 31.7436 26.9128 31.2499C26.9128 30.7563 26.512 30.3603 26.0214 30.3603H21.3255C20.8357 30.3603 20.4342 30.756 20.4342 31.2499C20.4342 31.7439 20.8357 32.1396 21.3255 32.1396ZM19.7602 29.8016H27.5867C28.0773 29.8016 28.4781 29.4056 28.4781 28.912C28.4781 28.4183 28.0773 28.0223 27.5867 28.0223H19.7602C19.2704 28.0223 18.8689 28.418 18.8689 28.912C18.8689 29.4059 19.2704 29.8016 19.7602 29.8016ZM19.7602 27.4636H27.5867C28.0773 27.4636 28.4781 27.0676 28.4781 26.574C28.4781 26.0804 28.0773 25.6843 27.5867 25.6843H19.7602C19.2704 25.6843 18.8689 26.08 18.8689 26.574C18.8689 27.0679 19.2704 27.4636 19.7602 27.4636ZM19.7602 25.1257H27.5867C28.0776 25.1257 28.4781 24.7289 28.4781 24.236C28.4781 23.7431 28.0776 23.3464 27.5867 23.3464H19.7602C19.2701 23.3464 18.8689 23.7428 18.8689 24.236C18.8689 24.7293 19.2701 25.1257 19.7602 25.1257ZM19.7602 22.7877H27.5867C28.0776 22.7877 28.4781 22.391 28.4781 21.8981C28.4781 21.4051 28.0776 21.0084 27.5867 21.0084H19.7602C19.2701 21.0084 18.8689 21.4048 18.8689 21.8981C18.8689 22.3913 19.2701 22.7877 19.7602 22.7877Z" stroke="#265D91"/>
                                                            <defs>
                                                            <filter id="filter0_d_3631_1281" x="0" y="0" width="52.0508" height="52.0513" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                            <feOffset dx="2" dy="1"/>
                                                            <feGaussianBlur stdDeviation="5"/>
                                                            <feComposite in2="hardAlpha" operator="out"/>
                                                            <feColorMatrix type="matrix" values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.24 0"/>
                                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3631_1281"/>
                                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3631_1281" result="shape"/>
                                                            </filter>
                                                            </defs>
                                                            </svg>
                                                            
                                                    </a>
                                                    <ng-template #compte_rendu let-modal class="modal-dialog-centered ">
                                                        <!--begin::Modal dialog-->
                                                        <div class="modal-dialog-centered">
                                                          <!--begin::Modal content-->
                                                          <div class="modal-content pt-5 pb-15">
                                                            <!--begin::Modal header-->
                                                            <div class="modal-header">
                                                              <!--begin::Modal title-->
                                                              <h2 class="fw-bold">Compte rendu  </h2>
                                                              <!--end::Modal title-->
                                                              <!--begin::Close-->
                                                              <div  (click)="closeModal()" id="kt_customers_export_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                                                                <i class="ki-duotone ki-cross fs-1" data-bs-dismiss="modal">
                                                                  <span class="path1"></span>
                                                                  <span class="path2"></span>
                                                                </i>
                                                              </div>
                                                              <!--end::Close-->
                                                            </div>
                                                           
                                                            <!--end::Modal header-->
                                                            <!--begin::Modal body-->
                                                            <div class="modal-body rendu-compte mx-5  my-7">
                                                                <h4 class="mb-5">Votre compte rendu</h4>
                                                              <div class="rendu-warapper ">
                                                                <span>
                                                                    {{pv?.report}}
                                                                   </span>
                                                              </div>
                                                            </div>
                                                           
                                                            <!--end::Modal body-->
                                                          </div>
                                                          <!--end::Modal content-->
                                                        </div>
                                                        <!--end::Modal dialog-->
                                                    </ng-template>
                                                </td>
                                              
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item"  (click)="visualiserPv(pv?.id)">Voir Détails</a>
                                                                <!-- <a class="dropdown-item" (click)="addSigned(pv?.id)" >Signer</a> -->
                                                                <a *ngIf="pv?.signed_by_candidate!=='2'"class="dropdown-item cursor-pointer" 
                                                            
                                                                (click)="pv?.signed_by_candidate !=='2' && addSigned(pv?.id)">
                                                                Signer
                                                             </a>
                                                             <a *ngIf="pv?.signed_by_candidate==='2'"class="dropdown-item cursor-pointer" style="cursor: not-allowed; pointer-events: none; opacity: 0.5;" disabled>
                                                             Signer
                                                          </a>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                           
                                        </tbody>
                                    </table>
                                </div>
                                <!--end::Table-->
                                <!-- [currentPageList]="currentPage"  [endexPageList]="endIndex" -->
                                <div class="pagination" *ngIf="listPv && listPv.length">
                                    <!-- <app-pagination [data]="listPv" [lengthData]="totalPvs"  (setPages)="getItems($event)">
                                    </app-pagination> -->
                                    <app-pagination
                                    [data]="listPv"
                                    [lengthData]="totalPvs"
                                    [currentPageList]="currentPage"
                                    [endexPageList]="endIndex"
                                    (setPages)="getItems($event)"
                                  >
                                  </app-pagination>
                                </div>
                               

                                <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listPv && !listPv.length">
                                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                    <span class="empty-list">
                                     La liste est vide
                                 </span>
                                 </div> 


                            </div>
                        </div>
                    </div>
                    <!--end::Tab pane-->
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>




