import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';


@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  key = "123";

  constructor() { }

  public saveData(key: string, value: string | number) {
    const stringValue = typeof value === 'number' ? String(value) : value?.toString();
    // console.log('key:', key, 'value:', stringValue);
    localStorage.setItem(key, this.encrypt(stringValue));
  }

  
  public getData(key: any) {
    if (key === null) {
      return null;
    }
  
    let data = localStorage.getItem(key) || "";
    return this.decrypt(data);
  }


  public removeData(key: string) {
    localStorage.removeItem(key);
  }

  public clearData() {
    localStorage.clear();
  }

  private encrypt(txt: string): string {
    return CryptoJS.AES.encrypt(txt, this.key).toString();
  }

  private decrypt(txtToDecrypt: string) {
    return CryptoJS.AES.decrypt(txtToDecrypt, this.key).toString(CryptoJS.enc.Utf8);
  }
}