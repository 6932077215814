<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_504_1495)">
                            <path d="M8.73438 5.38853H19.3343V3.25956H15.8403V0H12.1576V3.25956H8.73438V5.38853Z"
                                fill="#265D91" />
                            <path
                                d="M23.0071 5.26135H21.1792V6.30811C21.1792 6.81596 20.7664 7.2277 20.2573 7.2277H7.81339C7.30421 7.2277 6.8914 6.81596 6.8914 6.30811V5.26135H4.99288C4.4445 5.26135 4 5.70476 4 6.25165V28.0122C4 28.5592 4.44456 29.0025 4.99288 29.0025H23.0071C23.5555 29.0025 24 28.5591 24 28.0122V6.25165C23.9999 5.70469 23.5554 5.26135 23.0071 5.26135ZM9.66407 24.6577C9.31129 25.0116 8.72134 25.0152 8.36426 24.6654L6.74707 23.0816L8.03906 21.7693L9.00248 22.7128L11.514 20.1933L12.8217 21.49L9.66407 24.6577ZM9.66407 14.8943C9.31129 15.2482 8.7214 15.2518 8.36426 14.9021L6.74707 13.3183L8.03906 12.0059L9.00248 12.9495L11.514 10.4299L12.8217 11.7267L9.66407 14.8943ZM21.4019 26.2713H14.9673V24.4321H21.4019V26.2713ZM21.4019 21.6653H14.9673V19.8261H21.4019V21.6653ZM21.4019 16.3509H14.9673V14.5117H21.4019V16.3509ZM21.4019 11.745H14.9673V9.90579H21.4019V11.745Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_504_1495" x="0" y="0" width="28" height="37.0026"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_504_1495" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_504_1495"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    Evaluation
                </h1>
                <!--begin::Menu-->
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Evaluation Annuelle</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->
        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
            <div class="card-body tools">
                <div class="title mb-15">
                    <h4>Evaluation Annuelle</h4>
                </div>
                <div class="reviews">
                    <div class="review mb-15">
                        <div class="head mb-10">
                            <div class="number">
                               <span>1</span>

                            </div>
                            <div class="question">
                                <span>
                                    Lequel Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitation ullamco laboris nisi deserunt mollit anim id est laborum ?
                                </span>
                            </div>
                        </div>
                        <div class="reply">
                            <div class="reply-input mb-10">
                                <!--begin::Header-->
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body d-flex align-items-center pt-10 pb-10">
                                    <!--begin::Input-->
                                    <textarea class="form-control bg-transparent border-0 px-0" id="kt_social_feeds_post_input" name="message" data-kt-autosize="true" rows="1" placeholder="Ecrire votre réponse ..."></textarea>
                                    <!--end::Input-->
                                    <a href="/" class="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                        <!--begin::Indicator label-->
                                        <span class="indicator-label">Envoyer</span>
                                        <!--end::Indicator label-->
                                        <!--begin::Indicator progress-->
                                        <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        <!--end::Indicator progress-->
                                    </a>
                                </div>
                                <!--end::Body-->
                                <!--begin::Footer-->
                                <!--end::Footer-->
                            </div>
                        </div>
                    </div>
                    <div class="review mb-15">
                        <div class="head mb-10">
                            <div class="number">
                               <span>1</span>
                            </div>
                            <div class="question">
                                <span>
                                    Lequel Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitation ullamco laboris nisi deserunt mollit anim id est laborum ?
                                </span>
                            </div>
                        </div>
                        <div class="reply">
                            <div class="reply-input mb-10">
                                <!--begin::Header-->
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body d-flex align-items-center pt-10 pb-10">
                                    <!--begin::Input-->
                                    <textarea class="form-control bg-transparent border-0 px-0" id="kt_social_feeds_post_input" name="message" data-kt-autosize="true" rows="1" placeholder="Ecrire votre réponse ..."></textarea>
                                    <!--end::Input-->
                                    <a href="/" class="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                        <!--begin::Indicator label-->
                                        <span class="indicator-label">Envoyer</span>
                                        <!--end::Indicator label-->
                                        <!--begin::Indicator progress-->
                                        <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        <!--end::Indicator progress-->
                                    </a>
                                </div>
                                <!--end::Body-->
                                <!--begin::Footer-->
                                <!--end::Footer-->
                            </div>
                        </div>
                    </div>
                    <div class="review mb-15">
                        <div class="head mb-10">
                            <div class="number">
                               <span>1</span>
                            </div>
                            <div class="question">
                                <span>
                                    Lequel Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitation ullamco laboris nisi deserunt mollit anim id est laborum ?
                                </span>
                            </div>
                        </div>
                        <div class="reply">
                            <div class="reply-input mb-10">
                                <!--begin::Header-->
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body d-flex align-items-center pt-10 pb-10">
                                    <!--begin::Input-->
                                    <textarea class="form-control bg-transparent border-0 px-0" id="kt_social_feeds_post_input" name="message" data-kt-autosize="true" rows="1" placeholder="Ecrire votre réponse ..."></textarea>
                                    <!--end::Input-->
                                    <a href="/" class="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                        <!--begin::Indicator label-->
                                        <span class="indicator-label">Envoyer</span>
                                        <!--end::Indicator label-->
                                        <!--begin::Indicator progress-->
                                        <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        <!--end::Indicator progress-->
                                    </a>
                                </div>
                                <!--end::Body-->
                                <!--begin::Footer-->
                                <!--end::Footer-->
                            </div>
                        </div>
                    </div>
                    <div class="review mb-15">
                        <div class="head mb-10">
                            <div class="number">
                               <span>1</span>

                            </div>
                            <div class="question">
                                <span>
                                    Lequel Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                                    tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                    nostrud exercitation ullamco laboris nisi deserunt mollit anim id est laborum ?
                                </span>
                            </div>
                        </div>
                        <div class="reply">
                            <div class="reply-input mb-10">
                                <!--begin::Header-->
                                <!--end::Header-->
                                <!--begin::Body-->
                                <div class="card-body d-flex align-items-center pt-10 pb-10">
                                    <!--begin::Input-->
                                    <textarea class="form-control bg-transparent border-0 px-0" id="kt_social_feeds_post_input" name="message" data-kt-autosize="true" rows="1" placeholder="Ecrire votre réponse ..."></textarea>
                                    <!--end::Input-->
                                    <a href="/" class="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                        <!--begin::Indicator label-->
                                        <span class="indicator-label">Envoyer</span>
                                        <!--end::Indicator label-->
                                        <!--begin::Indicator progress-->
                                        <span class="indicator-progress">Please wait...
                                        <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                        <!--end::Indicator progress-->
                                    </a>
                                </div>
                                <!--end::Body-->
                                <!--begin::Footer-->
                              
                                <!--end::Footer-->
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </div>
    <!--end::Content container-->
</div>