import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../../../main/entreprise/services/company.service';

@Component({
  selector: 'app-details-my-reviews',
  templateUrl: './details-my-reviews.component.html',
  styleUrls: ['./details-my-reviews.component.css'],
})
export class DetailsMyReviewsComponent {
  /* number */
  totalItems!: number;
  itemsPerPage: number = 20;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  note: number = 4;
  stars!: number;
  id: any;
  listDefaultReview: any;
  companyDetails: any;
  pagination : boolean =false;

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.getId();
    this.getAllDefaultReview(this.itemsPerPage, this.currentPage);
  }

  /* get id esn from route */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.id = params.params['id'];
        this.getCompanyById();
      },
    });
  }

  /* get company  details */
  getCompanyById() {
    this.spinner.show();
    this.companyService
      .getCompanybyId(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.companyDetails = res.data;
          console.log(
            '%cdetails-my-reviews.component.ts line:59 companyDetails',
            'color: #007acc;',
            this.companyDetails
          );
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
       if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getAllDefaultReview(this.itemsPerPage, this.currentPage);
        }
    }
  }

  getAllDefaultReview(per_page: number, page: number) {
    this.companyService
      .getAllDefaultReview(per_page)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listDefaultReview = res.data.items.slice(0, this.endIndex);
          this.totalItems = res.data.items.total;
          this.listDefaultReview = res.data.items;
        },
        error: (err: any) => {
          console.log('error', err);
        },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
