import { Component } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environnements/environment';
import { ReviewCompanyService } from '../../services/review-company.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '../../../../../app/shared/shared-services/local-storage.service';
import { CompanyService } from '../../../../main/entreprise/services/company.service';

@Component({
  selector: 'app-reviews-candidat',
  templateUrl: './reviews-candidat.component.html',
  styleUrls: ['./reviews-candidat.component.css'],
})
export class ReviewsCandidatComponent {
  /* number */
  totalItems!: number;
  itemsPerPage: number = 20;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  note: number = 4;
  stars!: number;
  id: any;
  listDefaultReview: any;
  companyDetails: any;
  currentUser: any;
  idCandidate: number = 0;
  listReviews: any;
  pagination : boolean =false;

  currentSortDirection: number = 1;
  url: string = environment.baseUrl + '/api';
  dataCompany: any = [];
  idCompany: any;

  constructor(
    private reviewCompanyService: ReviewCompanyService,
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.getIdCandidate();
    this.getIdCompany();
    this.getAllReviewReviewCompany();
  }

  getIdCandidate() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idCandidate = JSON.parse(this.currentUser).id;
  }

  /* get id getIdCompany*/
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['idCompany'];
        console.log(
          '%creview.component.ts line:46 idCompany',
          'color: #007acc;',
          this.idCompany
        );
        this.getCompanyById();
      },
    });
  }

  /* get company  details */
  getCompanyById() {
    this.spinner.show();
    this.companyService
      .getCompanybyId(this.idCompany)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.companyDetails = res.data;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  /* sort data */
  sortData() {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.getAllReviewReviewCompany();
  }

  /*get list Companies reviews by candidate */
  getAllReviewReviewCompany() {
    this.spinner.show();
    this.reviewCompanyService
      .getAllReviewReviewCompany(
        this.idCompany,
        this.itemsPerPage,
        this.currentPage,
        this.currentSortDirection
      )
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listReviews = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
          this.spinner.hide();
          console.log('listReviews', this.listReviews);
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
       if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getAllReviewReviewCompany();
        }
    }
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
