import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { environment } from '../../../../environnements/environment';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';

@Injectable({
  providedIn: 'root',
})
export class FaqService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}`);
  }

  /* get all FAQ */
  getAllFaq(perpage: number, page: number): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base +
        '/api/FAQ/getListFAQwithResponse?type=1' +
        '&per_page=' +
        perpage +
        '&page=' +
        page
    );
  }
  /* search list FAQ*/
  searchListFAQ(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/FAQ/filterinListFAQwithResponse`,
      data
    );
  }
}
