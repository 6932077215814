import { Component, LOCALE_ID } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject, finalize, forkJoin, takeUntil } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import {
  serverError,
  successTimeSheet,
} from '../../../../shared/shared-models/message';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { TimeSheetService } from '../../services/time-sheet.service';
import { CandidateService } from '../../../../main/dossier/services/candidate.service';
import Swal from 'sweetalert2';

registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-detail-time-sheet',
  templateUrl: './detail-time-sheet.component.html',
  styleUrls: ['./detail-time-sheet.component.css'],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],

})
export class DetailTimeSheetComponent {
  selectedValues: any[] = [];
  allSelectedValues: any[] = [];
  listOfTimeSheet: any;
  idTimeSheet: any;
  listDetailsCraForMonths: any;
  totalTypeOfCra: any;
  rowIndex!: number;
  numberOfDays!: number;
  addTimeSheetData: any;
  totalClientOfCra: any;
  listProjectsOfCra: any;
  allProject:any[]=[];
  durationCra: any;
  typeIds: any = [];
  totalDurations: any[] = [];
  totalDurationByfamilyByType: any;
  totalDurationForAllFamilies: any;
  type_id!: number;
  unknownFamily: boolean = false;
  unknownType: boolean = false;
  currentUser: any;
  id: any;
  idUser: any;
  company_id: any;
  selectedCandidate: any;
  res = [
    { id: 1, name: '0.25' },
    { id: 2, name: '0.5' },
    { id: 3, name: '0.75' },
    { id: 4, name: '1' },
  ];
  sums: any[] = [];

  testArray: any = [1, 2, 3];
  modelSlect = '1';
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private serviceTimeSheet: TimeSheetService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private candidatService: CandidateService
  ) {}

  ngOnInit(): void {
    this.getlistProjectsOfCra()
    forkJoin([this.getId()]).subscribe(() => {
    this.getCandidateById();
    });
  }
  client_name:any
  getCandidateById() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idUser = JSON.parse(this.currentUser).id;
    this.spinner.show();
    this.candidatService
      .getCandidate(this.idUser)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];
          this.company_id = this.selectedCandidate?.client_id;
          this.client_name=this.selectedCandidate.client_name
          console.log('selectedCandidate ', this.client_name);
          this.spinner.hide();
          this.getListTypeOfTimeSheet();
          this.getDetailsTimeSheet();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  getId(): Observable<void> {
    return new Observable((observer) => {
      this.activatedRoute.paramMap.subscribe({
        next: (params: any) => {
          this.idTimeSheet = params.params['idTimeSheet'];
          observer.next();
          observer.complete();
        },
        error: (err) => observer.error(err),
      });
    });
  }

  getlistProjectsOfCra() {
    this.spinner.show();
    this.serviceTimeSheet
      .getProjetByCandidate(this.idUser)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listProjectsOfCra = res.data;
          console.log('listProjectsOfCra', this.listProjectsOfCra);

          this.allProject.push({
            type:"cra",
            items:this.listProjectsOfCra
         })
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek',
    },
  };

  getListTypeOfTimeSheet() {
    this.spinner.show();
    this.serviceTimeSheet
      .getListOfTimeSheet(this.company_id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listOfTimeSheet = res.data.items;

          console.log("listOfTimeSheet",this.listOfTimeSheet);

          this.allProject.push({
             type:"timesheet",
             items:this.listOfTimeSheet
          })
          // this.listOfTimeSheet.sort((a:any, b:any) => {
          //   const order = ['Production', 'Interne', 'Absence'];
          //   return order.indexOf(a.family) - order.indexOf(b.family);
          // });
          this.typeIds = [];
          for (const type of this.listOfTimeSheet) {
            this.typeIds.push(type.id);
          }

          console.log('typeIds_typeIds', this.typeIds);
          this.totalTypeOfCra = res.data.count;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  searchElement: string = '';

  // applyFilter() {
  //   const searchTerm = this.searchElement.trim().toLowerCase()
  //   this.spinner.show();
  //   this.serviceTimeSheet
  //     .searchCra(this.idTimeSheet, searchTerm, this.company_id, this.idUser)
  //     .pipe(takeUntil(this.unsubscribeAll))
  //     .subscribe({
  //       next: (res) => {
  //         this.listOfTimeSheet = res?.data.items;
  //         this.typeIds = [];
  //         for (const type of this.listOfTimeSheet) {
  //           this.typeIds.push(type.id);
  //         }
  //         console.log('typeIds_typeIds', this.typeIds);
  //         this.totalTypeOfCra = res.data.count;
  //         this.spinner.hide();
  //       },
  //       error: () => {
  //         this.spinner.hide();
  //       },
  //     });
  // }
  applyFilter() {
    const searchTerm = this.searchElement.trim().toLowerCase();   
    this.spinner.show();
    this.serviceTimeSheet
      .searchCra(this.idTimeSheet, searchTerm, this.company_id, this.idUser)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.listDetailsCraForMonths = res?.data;
          console.log("listDetailsCraForMonths:", this.listDetailsCraForMonths);
            if (this.listDetailsCraForMonths?.desiredCRA?.ligne_cra) {
            this.listOfTimeSheet = this.listDetailsCraForMonths?.desiredCRA?.ligne_cra;
            this.typeIds = this.listOfTimeSheet.map((cra: any) => cra.type_id);
            console.log("listOfTimeSheet:", this.listOfTimeSheet);
            console.log('typeIds:', this.typeIds);
            this.listProjectsOfCra = []
          }
          //   if (res?.data?.clients) {
          //   this.listClientOfCra = res?.data?.clients.map((client: any) => {
          //     client['checked'] = false;
          //     return client;
          //   });
          //   console.log("listClientOfCra:", this.listClientOfCra);
          // }
  
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          console.error("Error during applyFilter");
        },
      });
  }
  
  totalDurationByDay: { day: number, total_duration: number }[] = [];
  isAnnulerButtonDisabled: boolean = false;

  getDetailsTimeSheet() {
    this.spinner.show();
    this.serviceTimeSheet
      .getDetailsTimeSheet(this.idTimeSheet, this.company_id, this.idUser)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listDetailsCraForMonths = res?.data;
          this.totalDurationByDay = this.listDetailsCraForMonths.desiredTimeSheet.total_duration_by_day;
          this.checkIfButtonShouldBeDisabled();
          console.log(
            'data listDetailsCraForMonths ',
            this.listDetailsCraForMonths
          );
          const numberOfRows = Number(this.numberOfRows);
          const numberOfDays = Number(
            this.listDetailsCraForMonths?.numberOfDays
          );
          this.selectedValues = Array.from({ length: numberOfRows }, () =>
            Array.from({ length: numberOfDays }, () => null)
          );
          for (const cra of this.listDetailsCraForMonths?.desiredTimeSheet
            ?.ligne_time_sheet) {
            const type_id = cra.type_id;
            for (const day of cra.days) {
              const dayIndex = day.day - 1;
              const duration = day.duration ? parseInt(day.duration) : null;
              if (!this.selectedValues[type_id]) {
                this.selectedValues[type_id] = [];
              }
              if (!this.selectedValues[type_id][dayIndex]) {
                this.selectedValues[type_id][dayIndex] = { duration: null };
              }
              this.selectedValues[type_id][dayIndex].duration = duration;
            }
          }

          console.log('selectedValues', this.selectedValues);
          for (const totalDuration of this.listDetailsCraForMonths
            ?.desiredTimeSheet?.total_duration_by_day) {
            const dayIndex = totalDuration.day - 1;
            this.totalDurations[dayIndex] = totalDuration.total_duration;
          }
          this.sums.length=0;
          for (let i = 0; i < this.totalDurations?.length; i++) {
            this.sums.push(this.totalDurations[i]);
          }
          console.log('totalDurations', this.totalDurations);
          this.totalDurationByfamilyByType =
            this.listDetailsCraForMonths?.desiredTimeSheet?.totalDurationByfamilyByType;
          this.totalDurationForAllFamilies =
            this.listDetailsCraForMonths?.desiredTimeSheet?.totalDurationForAllFamilies;
          console.log(
            'totalDurationByfamilyByType',
            this.totalDurationByfamilyByType
          );
          console.log(
            'totalDurationForAllFamilies',
            this.totalDurationForAllFamilies
          );

          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching listDetailsCraForMonths:', error);
        }
      );
  }
  checkIfButtonShouldBeDisabled() {
    this.isButtonDisabled = this.totalDurationByDay.every(day => day.total_duration === 1);
    this.isAnnulerButtonDisabled = !this.isButtonDisabled;
  }
  getTypeName(typeId: number): string | undefined {
    return (
      this.totalDurationByfamilyByType &&
      this.totalDurationByfamilyByType[typeId]?.[0]?.type_name
    );
  }

  getTotalDurationByType(typeId: number): any[] {
    return (
      this.totalDurationByfamilyByType &&
      this.totalDurationByfamilyByType[typeId]
    );
  }
  getTotalDurationByTypeProduction(): any[] {
    return (
      this.totalDurationByfamilyByType &&
      this.totalDurationByfamilyByType.Production
    );
  }
  getTotalDurationByTypeAbsence(): any[] {
    return (
      this.totalDurationByfamilyByType &&
      this.totalDurationByfamilyByType.Absence
    );
  }
  getTotalDurationByTypeInterne(): any[] {
    return (
      this.totalDurationByfamilyByType &&
      this.totalDurationByfamilyByType.Interne
    );
  }
  generateDaysArray(): number[] {
    return Array.from(
      { length: this.listDetailsCraForMonths?.numberOfDays },
      (_, index) => index + 1
    );
  }

  get numberOfRows(): number[] {
    return Array.from({ length: this.totalTypeOfCra }, (_, index) => index + 1);
  }

  isWeekend(day: number): boolean {
    if (
      !this.listDetailsCraForMonths?.weekends ||
      this.listDetailsCraForMonths.weekends.length === 0
    ) {
      return false;
    }

    const firstWeekendDate = this.listDetailsCraForMonths.weekends[0];
    const yearMonth = firstWeekendDate.substring(0, 7);

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const formattedDate = `${yearMonth}-${dayString}`;

    return this.listDetailsCraForMonths.weekends.includes(formattedDate);
  }
  isHoliday(day: number): boolean {
    if (
      !this.listDetailsCraForMonths?.holidays ||
      this.listDetailsCraForMonths.holidays.length === 0
    ) {
      return false;
    }

    const firstWeekendDate = this.listDetailsCraForMonths.holidays[0];
    const yearMonth = firstWeekendDate.substring(0, 7);

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const formattedDate = `${yearMonth}-${dayString}`;

    return this.listDetailsCraForMonths.holidays.includes(formattedDate);
  }
  onNgModelChange(
    value: string,
    type_id: number,
    type: any,
    client_id: number,
    day: number,
    items: any[],
    i: number
  ) {
    console.log("Valeur sélectionnée :", value);
    console.log("Type d'ID:", type_id, "Client ID:", client_id, "Jour:", day);
  
    // Vérifiez si selectedValues est défini pour le type_id et le jour
    if (!this.selectedValues[type_id]) {
      this.selectedValues[type_id] = Array.from(
        { length: this.listDetailsCraForMonths.numberOfDays },
        () => ({ duration: null })
      );
    }
  
    // Obtenez la durée existante pour le jour
    const existingDurationValue = this.selectedValues[type_id][day].duration;
  
    // Obtenez la nouvelle durée à partir de la valeur sélectionnée
    const newDurationValue = this.getDurationValue(value);
  
    // Si une durée existe déjà, soustrayez-la de la somme avant d'ajouter la nouvelle
    if (existingDurationValue !== null) {
      const existingValueAsNumber = this.getDurationValue(existingDurationValue.toString());
      if (existingValueAsNumber !== null) {
        this.sums[day] -= existingValueAsNumber; // Soustraire l'ancienne durée
      }
    }
  
    // Ne pas ajouter la nouvelle durée si cela rend la somme totale > 1
    if (newDurationValue !== null && (this.sums[day] + newDurationValue > 1)) {
      console.warn(`Total pour le jour ${day} ne peut pas dépasser 1. Aucune mise à jour effectuée.`);
      // Restauration de la sélection précédente
      this.selectedValues[type_id][day].duration = existingDurationValue;
      return; // Ne rien faire si la nouvelle durée dépasse 1
    }
  
    // Mettre à jour la sélection avec la nouvelle valeur
    this.selectedValues[type_id][day].duration = newDurationValue;
  
    // Ajouter la nouvelle durée à la somme
    if (newDurationValue !== null) {
      this.sums[day] += newDurationValue; // Ajouter la nouvelle durée
    }
  
    console.log(`Somme calculée pour le jour ${day}: `, this.sums[day]);
    this.selectedValues[type_id][day].duration = value;
       if (type === 'client')
         this.updateSelectedValue(value, client_id, type_id, day);
       else this.updateSelectedValue(value, type_id, client_id, day);
  }
  getDurationValue(value: any): number | null {
    switch (value) {
      case 1:
        return 0.25;
      case 2:
        return 0.5;
      case 3:
        return 0.75;
      case 4:
        return 1;
      default:
        return null;
    }
  }
  isButtonDisabled: boolean = false;
  checkIfRowIsFullyFilled(typeId: number): boolean {
    const numberOfDays = this.listDetailsCraForMonths?.numberOfDays;
    if (!this.selectedValues[typeId]) return false;

    return this.selectedValues[typeId].every((day:any) => day.duration === '4');
  }
  updateSelectedValue(value: any, type_id: any, project_id: any, day: number) {
    console.log(`Selected value for type_id ${type_id}, day ${day}:`, value);
    this.allSelectedValues.push({
      project_id:project_id,
      type_id: type_id,
      duration: value,
      day: day + 1, 
    });
    console.log('All selected values:', this.allSelectedValues);

    let data = {
      date: this.listDetailsCraForMonths?.desiredTimeSheet?.date,
      consultant_client_id:this.listDetailsCraForMonths?.desiredTimeSheet?.consultant_client_id,
      ligneTimeSheet: this.allSelectedValues,
    };
    console.log('Data to be added:', data);
    this.addTimeSheetData = data;
  }
  // updateSelectedValue(value: any, type_id: number, day: number) {
  //   if (!this.selectedValues[type_id]) {
  //     this.selectedValues[type_id] = Array.from(
  //       { length: this.listDetailsCraForMonths.numberOfDays },
  //       () => null
  //     );
  //   }
  //   this.selectedValues[type_id][day] = value;
  //   console.log(`Selected value for type_id ${type_id}, day ${day}:`, value);

  //   this.allSelectedValues.push({
  //     type_id: type_id,
  //     duration: value,
  //     day: day + 1,
  //     project_id: 1,
  //   });
  //   console.log('All selected values:', this.allSelectedValues);

  //   let data = {
  //     date: this.listDetailsCraForMonths?.desiredTimeSheet?.date,
  //     project_candidate_id: 1,
  //     ligneTimeSheet: this.allSelectedValues,
  //   };
  //   console.log('Data to be added:', data);
  //   this.addTimeSheetData = data;
  // }

  addTimeSheet() {
    // Swal.fire({
    //   title: 'Voulez-vous vraiment enregistrer votre TimeSheet ?',
    //   icon: 'info',
    //   showCancelButton: true,
    //   confirmButtonText: 'Confirmer',
    //   cancelButtonText: 'Annuler',
    // }).then((result) => {
    //   if (result.isConfirmed) {
        
        console.log("addTimeSheetData ",this.addTimeSheetData);

        if(this.addTimeSheetData==undefined){
    
          this.toastr.success('Vous n\'avez rien à changer dans le TimeSheet.');
    
        }
        else {
    this.spinner.show();
    this.serviceTimeSheet
      .addListTimeSheet(this.addTimeSheetData)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastr.success(successTimeSheet);
            this.allSelectedValues=[]
            this.getDetailsTimeSheet();
          }
          this.spinner.hide();
        },
        error: (err: any) => {
          this.spinner.hide();
          if (err.error) {
            this.toastr.error(serverError);
          }
        },
      });
  }
// }
// });
 }
  selectedTypeId!: number;


  removeLigne(typeId: number,index:any,type:any){
  
    if(type==="project") {
      this.listProjectsOfCra[index].checked=false
      this.selectedTypeId = typeId;
      if (this.selectedTypeId === undefined) {
        console.error('No type_id selected', this.selectedTypeId);
        return;
      }
  
      const numberOfDays = this.listDetailsCraForMonths?.numberOfDays;
      if (!this.selectedValues[this.selectedTypeId]) {
        this.selectedValues[this.selectedTypeId] = Array.from(
          { length: numberOfDays },
          () => ({ duration: null })
        );
      }
  
      for (let day = 0; day < numberOfDays; day++) {
        if(this.selectedValues[this.selectedTypeId][day].duration=='4')
        this.selectedValues[this.selectedTypeId][day].duration = '';
        this.updateSelectedValue('','',this.selectedTypeId, day);
        console.log("updateSelectedValue done")
      }
  
      console.log(
        `Filled row for type_id ${this.selectedTypeId} with duration '4':`,
        this.selectedValues[this.selectedTypeId]
      ); 
  
    }
    if(type==="timesheet") {
      this.listOfTimeSheet[index].checked=false 
      this.selectedTypeId = typeId;
      if (this.selectedTypeId === undefined) {
        console.error('No type_id selected', this.selectedTypeId);
        return;
      }
  
      const numberOfDays = this.listDetailsCraForMonths?.numberOfDays;
      if (!this.selectedValues[this.selectedTypeId]) {
        this.selectedValues[this.selectedTypeId] = Array.from(
          { length: numberOfDays },
          () => ({ duration: null })
        );
      }
  
      for (let day = 0; day < numberOfDays; day++) {

        console.log("this day : ",this.selectedValues[this.selectedTypeId][day])
        if(this.selectedValues[this.selectedTypeId][day].duration=='4')
        this.selectedValues[this.selectedTypeId][day].duration = '';
        this.updateSelectedValue('', this.selectedTypeId, '', day);
        console.log("updateSelectedValue done")
      }
  
      console.log(
        `Filled row for type_id ${this.selectedTypeId} with duration '4':`,
        this.selectedValues[this.selectedTypeId]
      ); 
  
    }

   
  }

  remplirLigne(typeId: number,index:any,type:any) {

      console.log("project typeId ",typeId);

     if(type==="project") {
      this.listProjectsOfCra[index].checked=true
      this.selectedTypeId = typeId;
      if (this.selectedTypeId === undefined) {
        console.error('No type_id selected', this.selectedTypeId);
        return;
      }
  
      const numberOfDays = this.listDetailsCraForMonths?.numberOfDays;
      if (!this.selectedValues[this.selectedTypeId]) {
        this.selectedValues[this.selectedTypeId] = Array.from(
          { length: numberOfDays },
          () => ({ duration: null })
        );
      }
  
      for (let day = 0; day < numberOfDays; day++) {


        console.log("this duration",this.selectedValues[this.selectedTypeId][day].duration)
        if(this.selectedValues[this.selectedTypeId][day].duration==null || this.selectedValues[this.selectedTypeId][day].duration=='')
        this.selectedValues[this.selectedTypeId][day].duration = '4';
        this.updateSelectedValue('4', "",this.selectedTypeId, day);
      }
  
      console.log(
        `Filled row for type_id ${this.selectedTypeId} with duration '4':`,
        this.selectedValues[this.selectedTypeId]
      );
     }
     if(type==="timesheet"){
      this.listOfTimeSheet[index].checked=true
      this.selectedTypeId = typeId;
      if (this.selectedTypeId === undefined) {
        console.error('No type_id selected', this.selectedTypeId);
        return;
      }
  
      const numberOfDays = this.listDetailsCraForMonths?.numberOfDays;
      if (!this.selectedValues[this.selectedTypeId]) {
        this.selectedValues[this.selectedTypeId] = Array.from(
          { length: numberOfDays },
          () => ({ duration: null })
        );
      }
  
      for (let day = 0; day < numberOfDays; day++) {

        console.log("duration of ",  this.selectedValues[this.selectedTypeId][day].duration)
 
        if(this.selectedValues[this.selectedTypeId][day].duration==null || this.selectedValues[this.selectedTypeId][day].duration=='')
        this.selectedValues[this.selectedTypeId][day].duration = '4';
        this.updateSelectedValue('4', this.selectedTypeId, '', day);
        console.log("updateSelectedValue done")
      }
  
      console.log(
        `Filled row for type_id ${this.selectedTypeId} with duration '4':`,
        this.selectedValues[this.selectedTypeId]
      );

     }


   
  }
  isGeneratingPdf = false;
  exportToPDF() {
    this.spinner.show();
    this.isGeneratingPdf = true;
    setTimeout(() => {
      const element = document.getElementById('downloadCra');
      if (element) {
        html2canvas(element).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');
          const pdf = new jsPDF('p', 'mm', 'a4');
          const imgProps = pdf.getImageProperties(imgData);
          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

          pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
          pdf.save('download.pdf');
          this.spinner.hide();
          this.isGeneratingPdf = false;
        }).catch((error) => {
          console.error('Error capturing screen:', error);
          this.spinner.hide();
          this.isGeneratingPdf = false;
        });
      } else {
        this.spinner.hide();
        this.isGeneratingPdf = false;
      }
    }, 100); 
  }
  getBackgroundColor(family: string): string {
    switch (family) {
      case 'Production':
        return 'rgb(252 228 236 / 41%)'; 
      case 'Interne':
        return 'rgb(232 245 233 / 66%)';
      case 'Absence':
        return 'rgb(224 247 250 / 48%)'; 
      default:
        return '#ffffff'; 
    }
  }
  getClassForDuration(duration: number): string {
    switch (duration) {
      case 1:
        return 'duration-1';
      case 4:
        return 'duration-4';
      case 2:
        return 'duration-2';
      default:
        return 'default-duration';
    }
  }
  validationTimeSheetState: any;
  sendCra: boolean = false;
  cancelCra: boolean = false;

  rejectCra() {
    Swal.fire({
      title: "Voulez-vous vraiment être sûr d'annuler votre TimeSheet ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
    let data = {
      time_sheet_id: this.idTimeSheet,
      state_validation: '3',
    };
    this.serviceTimeSheet.cancelAndValidateTimeSheet(data).subscribe({
      next: (res: any) => {
        console.log('res', res);
        this.cancelCra = true;
        this.toastr.success('Cra a été annuler avec succès.');
        this.getDetailsTimeSheet();
      },
      error: (error) => {
        this.toastr.error(serverError);
      },
    });
    }
   });
  }
  validateCra() {
    Swal.fire({
      title: "Voulez-vous vraiment être sûr d'envoyer votre TimeSheet ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.addTimeSheet()
    let data = {
      time_sheet_id: this.idTimeSheet,
      state_validation: '2',
    };
    this.serviceTimeSheet.cancelAndValidateTimeSheet(data).subscribe({
      next: (res: any) => {
        this.validationTimeSheetState = res.state_validation;
        console.log('res', res);
        this.sendCra = true;
        this.toastr.success('Cra a été envoyé avec succès.');
        this.getDetailsTimeSheet();
      },
      error: (error) => {
        this.toastr.error(serverError);
      },
    });
     }
});
  }
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
