import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../../app/shared/shared-models/responseApi';
import { CrudService } from '../../../../app/shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class ContactService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}`);
  }

  /*contac tWegestU */
  contactWegestU(body: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + '/api/ContactWegestu/create',
      body
    );
  }

  /*contact ESN */
  contactEsn(body: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + '/api/ContactESN/create',
      body
    );
  }
  getContactNamesByCandidat(company_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/api/Mailing/getContactNamesByCandidat?company_id=${company_id}`
    );
  }
  getNamesForContactWegestu() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/MailingAdmin/getNamesForContactWegestu`
    );
  }

  getAllListOfMonthsOfYear(params: { year?: number; user_id?: number } = {}) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Cra/getListOfMonthsForYear?year=${params.year}&user_id=${params.user_id}`
    );
  }
  contactResponsable(body: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + '/api/JobOffer/contactManagerJobOffer',
      body
    );
  }
}
