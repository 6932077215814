import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { ResetMailComponent } from './components/reset-mail/reset-mail.component';
import { CodeResetComponent } from './components/code-reset/code-reset.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { RegisterComponent } from './components/register/register.component';
import { CodeVerficationComponent } from './components/code-verfication/code-verfication.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'reset-mail',
    component: ResetMailComponent
  },
  {
    path: 'code-reset',
    component: CodeResetComponent
  },
  {
    path: 'reset-password',   
    component: ResetPasswordComponent
  },
  {
    path: 'code-verification',
    component: CodeVerficationComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthentificationRoutingModule { }
