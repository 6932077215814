import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../shared-models/responseApi';
import { environment } from 'src/environnements/environment';
import { CrudService } from '../shared-services/crud-service/crud.service';


@Injectable({
  providedIn: 'root'
})
export class ModuleService extends CrudService<ApiResponse<any>, number> {

  constructor(_http: HttpClient) {super(_http, `${environment.baseUrl}/api`) }

  /* get list of categories */
  getListCategories(data?:{per_page?:number,page?:number,sort?:number}){    
    let sort =''
    if(data?.sort) sort=`&sort=${data.sort}&sortAttribute=name_FR`
    return this._http.get<ApiResponse<any>>(this._base+`/Category/getListCategorywithDetails?per_page=${data?.per_page}&page=${data?.page}${sort}`);
  }
  /* get list of categories */
  getListCategoriesCnadidat(data?:{per_page?:number,page?:number,sort?:number}){    
    return this._http.get<ApiResponse<any>>(this._base+`/Category/getListCategorywithDetails`);
  }
   /*add Category  */
   addCategory(data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Category/addCategory`,data);
  }
  /*get Category  by id */
  getCatId(id:number,sort?:number){
    let sortData
    if(sort) sortData=`?sort=${sort}`
    return this._http.get<ApiResponse<any>>(this._base+`/Category/getCategoryDetails/${id}${sortData}`);
  }
  
  /*get subcat by Category id */
  getSubCatId(id:number,data:{per_page?:number,page?:number,sort?:number}){
    let sort =''
    if(data?.sort) sort=`&sort=${data.sort}&sortAttribute=name_FR`
    return this._http.get<ApiResponse<any>>(this._base+`/SubCategory/getByCategoryId/${id}?per_page=${data?.per_page}&page=${data?.page}${sort}`);
  }
  /*update Category  */
  updateCategory(id:number,data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Category/updateCategory/${id}`,data);
  }
   /*delete Category  */
   deleteCategory(id:number){
    return this._http.delete<ApiResponse<any>>(this._base+`/Category/delete/${id}`);
  }
   /*search Category  */
   searchCategory(data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Category/search`,data);
  }
  /*add subCategory  data:{name_FR :string,category_id :number,icon:any}*/
  addSubCategory(data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/SubCategory/addSubCategory`,data);
  }
  /*delete Category  */
  deleteSubCategory(id:number){
    return this._http.delete<ApiResponse<any>>(this._base+`/SubCategory/delete/${id}`);
  }
  /*update subCategory  */
  updateSubCategory(id:number,data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/SubCategory/updateSubCategory/${id}`,data);
  }
   /*search subCategory  */
   searchSubCategory(id:number ,data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/SubCategory/search/${id}`,data);
  }
   /*search subCategory  */
   searchQuestionSubCategory(id:number ,data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Question/search/${id}`,data);
  }
/* get list question by cubcategory */
  getQuestionList(id:number,data?:{per_page?:number,page?:number}){
    return this._http.get<ApiResponse<any>>(this._base+`/Question/getBySubCategoryId/${id}?per_page=${data?.per_page}&page=${data?.page}`);
  }
  /*add question ChatGPT */
  addQuestionChatGpt(data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Question/addMultipleQuestionOpenai`,data);
  }
   /*add question  */
  addQuestion(data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Question/create`,data);
  }

  /*update question  */
  updateQuestion(id:number|undefined,data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/Question/updateQuestionWithResponse/${id}`,data);
  }
  /*update state response  */
  updateStateResponse(id:number,data:any){
    return this._http.post<ApiResponse<any>>(this._base+`/ResponseQuestion/updateTypeResponse/${id}`,data);
  }
  
  /*delete response  */
  deleteResponse(id:number[]){
    let ids=''
    for (const iterator of id) {
      ids+= `ids[]=${iterator}&`
    }
    ids = ids.slice(0, -1);
    return this._http.delete<ApiResponse<any>>(this._base+`/ResponseQuestion/deleteResponse?${ids}`);
  }
    /*delete question category  */
    deleteQesion(id:number,){
      return this._http.delete<ApiResponse<any>>(this._base+`/Question/delete/${id}`);
    }
}
