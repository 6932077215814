import { Component } from '@angular/core';

@Component({
  selector: 'app-annual-evaluation',
  templateUrl: './annual-evaluation.component.html',
  styleUrls: ['./annual-evaluation.component.css']
})
export class AnnualEvaluationComponent {

}
