export class Review {
    id: string | undefined;
    question: string | undefined;
    stars: string | undefined;
    selectedRating: number = 0;
    comment: string | undefined;
  
    constructor(model?: Review) {
      this.id = model && model.id || "";
      this.stars = model && model.stars || "";
      this.question = model && model.question || "";
      this.comment = model && model.comment || "";
    }
  }
  interface StarRating {
    id: string; // ou number selon votre cas
    stars: number;
  }