<head>
    <title>Reset-password-interface Wegestu</title>
    <meta name="description" content="Reset-password-interface Wegestu">
</head>

<div class="login-page">
    <div class="d-flex flex-column flex-root" id="kt_app_root">
        <div class="d-flex flex-column flex-center flex-column-fluid">
            <!--begin::Content-->
            <div class="d-flex flex-column flex-center p-10">
                <div class="card-warp w-md-650px py-5">
                    <div class="content py-15 py-lg-20">
                        <!--begin::Logo-->
                        <div class="mb-15 text-center">
                            <a href="/" class="">
                                <img alt="Logo" src="/assets/media/logos/logo-wegestu-color.png" class="h-40px" />
                            </a>
                        </div>
                        <!--end::Logo-->

                        <!--begin::Illustration-->
                        <div class="mb-10" [formGroup]="generateResePasswordForm">
                            <!--begin::Input group-->
                            <div class="fv-row mb-8 input-login">
                                <!--begin::Password-->
                                <label for="password">Nouveau Mot de passe</label>
                                <div class="input-group mb-5">
                                    <span class="input-group-text" id="basic-addon1">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12.0001 5.14286H4.00014C3.84859 5.14286 3.70324 5.08265 3.59608 4.97549C3.48891 4.86833 3.42871 4.72298 3.42871 4.57143V2.28571C3.42871 1.6795 3.66953 1.09812 4.09818 0.66947C4.52684 0.240816 5.10822 0 5.71443 0H10.2859C10.8921 0 11.4734 0.240816 11.9021 0.66947C12.3308 1.09812 12.5716 1.6795 12.5716 2.28571V4.57143C12.5716 4.72298 12.5114 4.86833 12.4042 4.97549C12.297 5.08265 12.1517 5.14286 12.0001 5.14286ZM4.57157 4H11.4287V2.28571C11.4287 1.98261 11.3083 1.69192 11.094 1.47759C10.8796 1.26326 10.589 1.14286 10.2859 1.14286H5.71443C5.41132 1.14286 5.12063 1.26326 4.9063 1.47759C4.69198 1.69192 4.57157 1.98261 4.57157 2.28571V4Z"
                                                fill="#9AB4CC" />
                                            <path
                                                d="M13.1429 4H2.85714C2.09938 4 1.37266 4.30102 0.836838 4.83684C0.30102 5.37266 0 6.09938 0 6.85714V13.1429C0 13.9006 0.30102 14.6273 0.836838 15.1632C1.37266 15.699 2.09938 16 2.85714 16H13.1429C13.9006 16 14.6273 15.699 15.1632 15.1632C15.699 14.6273 16 13.9006 16 13.1429V6.85714C16 6.09938 15.699 5.37266 15.1632 4.83684C14.6273 4.30102 13.9006 4 13.1429 4ZM8.57143 10.1257V12C8.57143 12.1516 8.51123 12.2969 8.40406 12.4041C8.2969 12.5112 8.15155 12.5714 8 12.5714C7.84845 12.5714 7.7031 12.5112 7.59594 12.4041C7.48878 12.2969 7.42857 12.1516 7.42857 12V10.1257C7.2107 9.99992 7.04041 9.80576 6.94414 9.57332C6.84786 9.34089 6.83097 9.08319 6.89609 8.84018C6.9612 8.59717 7.10468 8.38244 7.30427 8.22928C7.50387 8.07613 7.74842 7.99311 8 7.99311C8.25158 7.99311 8.49613 8.07613 8.69573 8.22928C8.89532 8.38244 9.0388 8.59717 9.10392 8.84018C9.16903 9.08319 9.15214 9.34089 9.05586 9.57332C8.95959 9.80576 8.78931 9.99992 8.57143 10.1257Z"
                                                fill="#9AB4CC" />
                                        </svg>
                                    </span>
                                        <input  [type]="showOldPassword ? 'text' : 'password'" formControlName="oldPassword" placeholder="Mot de passe" class="form-control bg-transparent" id="password" name="password"/>
                                      <i *ngIf="!showOldPassword" (click)="togglePasswordVisibility('oldPassword')"  class="fa-regular fa-eye-slash" ></i>
                                      <i *ngIf="showOldPassword" (click)="togglePasswordVisibility('oldPassword')"  class="fa-regular fa-eye"  ></i>
                                </div>
                                <!--end::Password-->
                                <app-feild-error [control]="generateResePasswordForm.get('oldPassword')"  [controlName]="'oldPassword'"></app-feild-error>

                                <div class="fv-row mb-8 input-login">
                                    <!--begin::Password-->
                                    <label for="password">Confirmer mot de passe</label>
                                    <div class="input-group mb-5">
                                        <span class="input-group-text" id="basic-addon1">
                                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.0001 5.14286H4.00014C3.84859 5.14286 3.70324 5.08265 3.59608 4.97549C3.48891 4.86833 3.42871 4.72298 3.42871 4.57143V2.28571C3.42871 1.6795 3.66953 1.09812 4.09818 0.66947C4.52684 0.240816 5.10822 0 5.71443 0H10.2859C10.8921 0 11.4734 0.240816 11.9021 0.66947C12.3308 1.09812 12.5716 1.6795 12.5716 2.28571V4.57143C12.5716 4.72298 12.5114 4.86833 12.4042 4.97549C12.297 5.08265 12.1517 5.14286 12.0001 5.14286ZM4.57157 4H11.4287V2.28571C11.4287 1.98261 11.3083 1.69192 11.094 1.47759C10.8796 1.26326 10.589 1.14286 10.2859 1.14286H5.71443C5.41132 1.14286 5.12063 1.26326 4.9063 1.47759C4.69198 1.69192 4.57157 1.98261 4.57157 2.28571V4Z"
                                                    fill="#9AB4CC" />
                                                <path
                                                    d="M13.1429 4H2.85714C2.09938 4 1.37266 4.30102 0.836838 4.83684C0.30102 5.37266 0 6.09938 0 6.85714V13.1429C0 13.9006 0.30102 14.6273 0.836838 15.1632C1.37266 15.699 2.09938 16 2.85714 16H13.1429C13.9006 16 14.6273 15.699 15.1632 15.1632C15.699 14.6273 16 13.9006 16 13.1429V6.85714C16 6.09938 15.699 5.37266 15.1632 4.83684C14.6273 4.30102 13.9006 4 13.1429 4ZM8.57143 10.1257V12C8.57143 12.1516 8.51123 12.2969 8.40406 12.4041C8.2969 12.5112 8.15155 12.5714 8 12.5714C7.84845 12.5714 7.7031 12.5112 7.59594 12.4041C7.48878 12.2969 7.42857 12.1516 7.42857 12V10.1257C7.2107 9.99992 7.04041 9.80576 6.94414 9.57332C6.84786 9.34089 6.83097 9.08319 6.89609 8.84018C6.9612 8.59717 7.10468 8.38244 7.30427 8.22928C7.50387 8.07613 7.74842 7.99311 8 7.99311C8.25158 7.99311 8.49613 8.07613 8.69573 8.22928C8.89532 8.38244 9.0388 8.59717 9.10392 8.84018C9.16903 9.08319 9.15214 9.34089 9.05586 9.57332C8.95959 9.80576 8.78931 9.99992 8.57143 10.1257Z"
                                                    fill="#9AB4CC" />
                                            </svg>

                                        </span>

                                            <input  [type]="showNewPassword ? 'text' : 'password'" formControlName="newPassword" placeholder="Mot de passe" class="form-control bg-transparent" id="newPassword" name="newPassword"/>
                                            <i *ngIf="!showNewPassword" (click)="togglePasswordVisibility('newPassword')"  class="fa-regular fa-eye-slash" ></i>
                                            <i *ngIf="showNewPassword" (click)="togglePasswordVisibility('newPassword')"  class="fa-regular fa-eye"  ></i>

                                    </div>
                                    <!--end::Password-->
                                    <app-feild-error [control]="generateResePasswordForm.get('newPassword')"  [controlName]="'newPassword'"></app-feild-error>

                                    <!--end::Input group-->
                                </div>
                                <!--end::Section-->
                                <!--begin::Submit-->
                                <div class="d-flex flex-center">
                                    <button [disabled]="!this.generateResePasswordForm.valid" (click)="resetPassword()"
                                        type="button" id="kt_sing_in_two_factor_submit"
                                        class="btn btn-lg w-100 pt-5 pb-5">
                                        <span class="indicator-label">Confirmer</span>
                                        <span class="indicator-progress">Please wait...
                                            <span
                                                class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                    </button>
                                </div>

                                <!--end::Submit =-->



                                <!--begin::Wrapper-->
                                <!--end::Illustration-->
                                <!--begin::Link-->

                                <!--end::Link-->
                            </div>
                        </div>
                        <!--end::Illustration-->
                    </div>
                </div>
            </div>
            <!--end::Content-->
        </div>
    </div>
</div>

<script src="./assets/js/custom/authentication/sign-in/two-factor.js"></script>