<ngx-spinner class="custom-spinner" bdColor="rgba(255,255,255,1)" size="medium" color="#fff" type="ball-atom"
  [fullScreen]="true">
  <span class="loader">
    <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
  </span>
</ngx-spinner>

<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
  <!--begin::Toolbar container-->
  <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
    <!--begin::Page title-->
    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
      <!--begin::Title-->
      <div class="d-flex align-items-center">
        <h1 class="page-heading">
          <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_525_174)">
              <path
                d="M32.8463 0H6.15368C4.97895 0 4 0.986779 4 2.17091V22.5315C4 23.7156 4.97895 24.7024 6.15368 24.7024H32.8463C34.0211 24.7024 35 23.7156 35 22.5315V2.17091C35 0.953886 34.0211 0 32.8463 0ZM13.2021 2.2367C13.7242 2.2367 14.1158 2.6643 14.1158 3.15769C14.1158 3.68397 13.6916 4.07869 13.2021 4.07869C12.68 4.07869 12.2884 3.65108 12.2884 3.15769C12.2558 2.63141 12.68 2.2367 13.2021 2.2367ZM10.3958 2.2367C10.9179 2.2367 11.3095 2.6643 11.3095 3.15769C11.3095 3.68397 10.8853 4.07869 10.3958 4.07869C9.87368 4.07869 9.48211 3.65108 9.48211 3.15769C9.44947 2.63141 9.87368 2.2367 10.3958 2.2367ZM7.55684 2.2367C8.07895 2.2367 8.47053 2.6643 8.47053 3.15769C8.47053 3.68397 8.04632 4.07869 7.55684 4.07869C7.06737 4.07869 6.64316 3.65108 6.64316 3.15769C6.64316 2.63141 7.06737 2.2367 7.55684 2.2367ZM32.2263 21.7091C32.2263 21.8078 32.1611 21.8736 32.0632 21.8736H6.93684C6.83895 21.8736 6.77368 21.8078 6.77368 21.7091V6.34828H32.2263V21.7091Z"
                fill="#265D91" />
              <path
                d="M11.5045 15.4267H14.8329C15.2897 15.4267 15.714 15.1964 15.9424 14.7688L17.1497 12.6966L20.7066 18.7159C20.935 19.1107 21.3592 19.3738 21.8161 19.3738C22.2729 19.3738 22.6971 19.1435 22.9255 18.7159L24.8834 15.3938H27.4613C28.1792 15.3938 28.7666 14.8017 28.7666 14.0781C28.7666 13.3544 28.1792 12.7624 27.4613 12.7624H24.1655C23.7087 12.7624 23.2845 12.9926 23.0561 13.4202L21.8487 15.4925L18.2919 9.47311C17.835 8.68369 16.5297 8.68369 16.0403 9.47311L14.0824 12.7953H11.5045C10.7866 12.7953 10.1992 13.3873 10.1992 14.111C10.1992 14.8346 10.7866 15.4267 11.5045 15.4267Z"
                fill="#265D91" />
            </g>
            <defs>
              <filter id="filter0_d_525_174" x="0" y="0" width="39" height="32.7024" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_525_174" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_525_174" result="shape" />
              </filter>
            </defs>
          </svg>

          CRAs
        </h1>
        <!--begin::Menu-->
      </div>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted"
            routerLinkActive="router-link-active">Accueil</a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted"><a class="text-muted" routerLink="/wegestu/pointage/cra">Liste CRA</a>
        </li>
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">Activités </li>
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
    <!--begin::Actions-->

    <!--end::Actions-->
  </div>
  <!--end::Toolbar container-->
</div>

<div class="row cards mb-15">
  <div class="title-page mb-10">
    <h4>Activités </h4>
  </div>
  <div class="d-flex">
    <div class="col-6 seach-free">
      <!-- <div class="input-group mb-3">
        <input type="text" data-kt-customer-table-filter="search" [(ngModel)]="searchFilter"
           class="form-control form-control-solid ps-15" placeholder="Rechercher" />
        <div class="input-group-append">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
              fill="#7E7C7C" />
          </svg>

          <button class="input-group-text cursor-pointer" (click)="applyFilter()"
            id="basic-addon2"><span>Rechercher</span>
          </button>
        </div>
      </div> -->
    </div>
    <div class="col-6 actions">
      <a (click)="exportCra()" class="download"><svg width="13" height="14" viewBox="0 0 13 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M2.38333 13.095H10.6167C10.9297 13.095 11.2396 13.0316 11.5287 12.9084C11.8179 12.7852 12.0806 12.6047 12.3019 12.377C12.5233 12.1494 12.6988 11.8792 12.8186 11.5817C12.9384 11.2843 13 10.9655 13 10.6436V7.30076C13 7.06434 12.9087 6.8376 12.7462 6.67043C12.5836 6.50325 12.3632 6.40933 12.1333 6.40933C11.9035 6.40933 11.683 6.50325 11.5205 6.67043C11.358 6.8376 11.2667 7.06434 11.2667 7.30076V10.6436C11.2667 10.8209 11.1982 10.991 11.0763 11.1164C10.9544 11.2418 10.7891 11.3122 10.6167 11.3122H2.38333C2.21094 11.3122 2.04561 11.2418 1.92371 11.1164C1.80182 10.991 1.73333 10.8209 1.73333 10.6436V7.30076C1.73333 7.06434 1.64202 6.8376 1.47949 6.67043C1.31696 6.50325 1.09652 6.40933 0.866667 6.40933C0.636812 6.40933 0.416372 6.50325 0.253841 6.67043C0.0913094 6.8376 0 7.06434 0 7.30076V10.6436C0 11.2938 0.2511 11.9173 0.698062 12.377C1.14502 12.8368 1.75123 13.095 2.38333 13.095Z"
            fill="#265D91" />
          <path
            d="M6.49962 0C6.26976 0 6.04932 0.0939179 5.88679 0.261093C5.72426 0.428268 5.63295 0.655007 5.63295 0.891429V6.5052L4.31562 5.27949C4.14524 5.12079 3.92055 5.03821 3.69097 5.04991C3.46139 5.06161 3.24574 5.16664 3.09145 5.34189C2.93716 5.51713 2.85687 5.74825 2.86825 5.98438C2.87963 6.22052 2.98174 6.44233 3.15212 6.60103L5.91895 9.17503C5.93628 9.19063 5.95362 9.20177 5.97095 9.21514C5.98828 9.22851 6.01212 9.24857 6.03378 9.26417C6.05972 9.28049 6.08648 9.29536 6.11395 9.30874L6.17895 9.34217C6.21011 9.35448 6.24194 9.3649 6.27428 9.37337L6.33495 9.3912C6.44362 9.41389 6.55561 9.41389 6.66428 9.3912L6.72495 9.37337C6.75729 9.36487 6.78912 9.35445 6.82028 9.34217L6.88528 9.30874C6.91276 9.29537 6.93952 9.28049 6.96545 9.26417C6.98712 9.24857 7.00662 9.23297 7.02828 9.21514C7.04995 9.19732 7.06295 9.19063 7.08028 9.17503L9.84712 6.60103C9.93148 6.52245 9.99997 6.42755 10.0487 6.32174C10.0974 6.21594 10.1254 6.1013 10.131 5.98438C10.1366 5.86746 10.1198 5.75054 10.0815 5.6403C10.0432 5.53006 9.98418 5.42866 9.90779 5.34189C9.83139 5.25511 9.73912 5.18467 9.63626 5.13457C9.53339 5.08447 9.42194 5.0557 9.30827 5.04991C9.19459 5.04411 9.08092 5.06141 8.97374 5.1008C8.86657 5.14019 8.76798 5.20091 8.68362 5.27949L7.36628 6.5052V0.891429C7.36628 0.655007 7.27497 0.428268 7.11244 0.261093C6.94991 0.0939179 6.72947 0 6.49962 0Z"
            fill="#265D91" />
        </svg>
        Télécharger</a>
      <button [disabled]="allSelectedValues.length==0"
        *ngIf="listDetailsCraForMonths?.desiredCRA.state_validation == '3' || listDetailsCraForMonths?.desiredCRA.state_validation == '1'"
        class="validate"  [disabled]="isEnvoyerDisabled"  (click)="validateCra()"><svg width="19" height="14" viewBox="0 0 19 14" fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <path
            d="M5.9375 12.9919C6.16489 13.2181 6.49335 13.3688 6.82181 13.3688C7.15027 13.3688 7.47872 13.2181 7.73138 12.9919L18.621 2.16113C19.1263 1.65854 19.1263 0.879528 18.621 0.376941C18.1157 -0.125647 17.3324 -0.125647 16.8271 0.376941L6.82181 10.3282L2.17287 5.67924C1.66755 5.17665 0.884309 5.17665 0.378989 5.67924C-0.12633 6.18183 -0.12633 6.96084 0.378989 7.46343L5.9375 12.9919Z"
            fill="#265D91" />
        </svg>
        Envoyer</button>
      <button class="annuler" (click)="rejectCra()"
        *ngIf="listDetailsCraForMonths?.desiredCRA.state_validation == '2' &&  listDetailsCraForMonths?.desiredCRA?.state_first_validation == '1'">
        <i class="fa-solid fa-xmark iconeXmark"></i> Annuler</button>

      <button (click)="addCra()" class="saved"
        [disabled]="(listDetailsCraForMonths?.desiredCRA.state_validation == '2') || allSelectedValues.length==0"
        *ngIf="listDetailsCraForMonths?.desiredCRA.state_validation != '4' && (listDetailsCraForMonths?.desiredCRA?.state_first_validation == '1' || !listDetailsCraForMonths?.desiredCRA?.state_first_validation)"
        [disabled]="(listDetailsCraForMonths?.desiredCRA.state_validation == '2')"><svg width="14" height="15"
          viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.6832 0.224657C10.6178 0.153285 10.5377 0.0963657 10.4484 0.0576468C10.359 0.0189279 10.2623 -0.000712155 10.1647 1.97277e-05H2.07407C1.52399 1.97277e-05 0.996445 0.215175 0.607481 0.598155C0.218518 0.981135 0 1.50057 0 2.04218V12.253C0 12.7946 0.218518 13.314 0.607481 13.697C0.996445 14.08 1.52399 14.2952 2.07407 14.2952H11.9259C12.476 14.2952 13.0035 14.08 13.3925 13.697C13.7815 13.314 14 12.7946 14 12.253V4.11327C14.0014 3.94547 13.9399 3.78304 13.8271 3.65719L10.6832 0.224657ZM3.71604 1.36146H8.55554V3.31853H3.71604V1.36146ZM12.6173 12.253C12.6173 12.4335 12.5444 12.6067 12.4148 12.7343C12.2851 12.862 12.1093 12.9337 11.9259 12.9337H2.07407C1.89071 12.9337 1.71486 12.862 1.58521 12.7343C1.45555 12.6067 1.38271 12.4335 1.38271 12.253V2.04218C1.38271 1.86164 1.45555 1.6885 1.58521 1.56084C1.71486 1.43318 1.89071 1.36146 2.07407 1.36146H2.33333V3.31853C2.33333 3.67961 2.47901 4.0259 2.73832 4.28122C2.99763 4.53654 3.34932 4.67997 3.71604 4.67997H8.55554C8.92226 4.67997 9.27396 4.53654 9.53327 4.28122C9.79257 4.0259 9.93825 3.67961 9.93825 3.31853V1.44655L12.6173 4.37365V12.253Z"
            fill="#265D91" />
          <path
            d="M6.99924 5.6636C6.38898 5.6636 5.79242 5.84181 5.28503 6.17567C4.77764 6.50953 4.3822 6.98406 4.14874 7.53923C3.91528 8.0944 3.85429 8.70527 3.97347 9.29458C4.09265 9.88388 4.38666 10.4251 4.8183 10.8499C5.24995 11.2747 5.79983 11.5639 6.39842 11.6809C6.99699 11.7979 7.61738 11.7375 8.18109 11.5073C8.7448 11.2771 9.22652 10.8875 9.56532 10.3877C9.90411 9.88797 10.0848 9.30049 10.0844 8.69962C10.0831 7.89454 9.75751 7.12284 9.17918 6.55372C8.60084 5.9846 7.8169 5.6645 6.99924 5.6636ZM6.99924 10.381C6.66253 10.381 6.33337 10.2827 6.0534 10.0985C5.77343 9.9143 5.55523 9.65251 5.42637 9.34621C5.29751 9.03991 5.2638 8.70286 5.32949 8.3777C5.39518 8.05253 5.55732 7.75385 5.79542 7.51942C6.03351 7.28499 6.33686 7.12534 6.66711 7.06066C6.99735 6.99598 7.33966 7.02918 7.65075 7.15605C7.96183 7.28292 8.22772 7.49777 8.41479 7.77344C8.60186 8.0491 8.70171 8.37319 8.70171 8.70472C8.70125 9.14916 8.52174 9.57527 8.20256 9.88953C7.88339 10.2038 7.45063 10.3805 6.99924 10.381Z"
            fill="#265D91" />
        </svg>
        Enregistrer</button>
    </div>
  </div>
  <div id="downloadCra">
    <div class="d-flex">
      <div class="col-lg-3 padding-zero">
        <div class="left">
          <div class="title text-center">
            <h4>
              Client / Type d’activité
            </h4>
          </div>
          <div class="item" *ngFor="let item1 of sortedListClientOfCra ; let i1 = index">
            <div class="item-name">
              <h4> {{item1?.client_final_e_s_n?.name}}</h4>
            </div>
            <div class="item-btn">
              <button class="remplir" *ngIf="!item1?.checked" [disabled]="isButtonDisabled"
                (click)="remplirLigne(item1?.client_final_e_s_n?.id,i1,'project')">Remplir</button>
              <button class="remplir" *ngIf="item1?.checked"
                (click)="removeLigne(item1?.client_final_e_s_n?.id,i1,'project')">Annuler</button>
            </div>
          </div>
          <div class="item" *ngFor="let item2 of listOfCra ; let i2 = index"
            [style.background-color]="getBackgroundColor(item2.family)">
            <div class="item-name">
              <h4>{{ item2?.name }}</h4>
            </div>
            <div class="item-btn">
              <button class="remplir" *ngIf="!item2?.checked" [disabled]="isButtonDisabled"
                (click)="remplirLigne(item2?.id,i2,'timesheet')">Remplir</button>
              <button class="remplir" *ngIf="item2?.checked"
                (click)="removeLigne(item2?.id,i2,'timesheet')">Annuler</button>

            </div>
          </div>

          <div class="item-total">
            <div class="item-name">
              <h4> Total journalier:</h4>
            </div>
          </div>

          <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listOfCra&&!listOfCra.length">
            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
            <span class="empty-list">
              La liste est vide
            </span>
          </div>

        </div>
      </div>
      <div class="col-lg-9 padding-zero">
        <h4 class="title-month">{{listDetailsCraForMonths?.desiredCRA?.date | date: 'MMMM y'}}</h4>
        <div class="table-responsive">

          <table class="table table-cra">
            <thead>
              <th *ngFor="let day of generateDayNumbersArray()" [class.weekend]="isWeekend(day)"
                [class.weekend]="isHoliday(day)">
                {{ day }}
              </th>
            </thead>

            <tbody>
              <tr *ngFor="let cra of listDetailsCraForMonths?.desiredCRA?.ligne_cra; let i = index; trackBy: trackByCra ">
                <ng-container *ngFor="let day of cra.days; let j = index; trackBy: trackByDay">
                  <td
                    [ngStyle]="{ 'background-color': isWeekend(day.day) ? '#FDEFEE' : isHoliday(day.day) ? '#f4587a' : '' }">
                    <ng-container *ngIf="selectedValues[cra.type_id] && selectedValues[cra.type_id][j]">

                    <ng-select
                    #selectRef
                      [ngStyle]="{'cursor': listDetailsCraForMonths?.desiredCRA.state_validation == '4' ? 'no-drop' : 'auto'}"
                      class="ng-select-container" [items]="day?.values" bindLabel="name" bindValue="id"
                      id="dispo-{{cra?.type_id}}-{{j}}"
                      [(ngModel)]="selectedValues[cra.type_id][j].duration"
                      (focus)="storePreviousValue(cra?.type_id, j, selectedValues[cra?.type_id][j].duration)"
                      [style.visibility]="(!isWeekend(day.day) && !isHoliday(day.day)) ? 'visible' : 'hidden'"
                      [ngClass]="{
                        'custom-ng-select': true,
                        'option-value0': selectedValues[cra?.type_id]?.[j]?.duration == '0',
                        'option-value1': selectedValues[cra?.type_id]?.[j]?.duration == '1',
                        'option-value2': selectedValues[cra?.type_id]?.[j]?.duration == '2',
                        'option-value3': selectedValues[cra?.type_id]?.[j]?.duration == '3',
                        'option-value4': selectedValues[cra?.type_id]?.[j]?.duration == '4'
                      }"
                      [disabled]="listDetailsCraForMonths?.desiredCRA.state_validation == '4'">
                      <ng-template ng-option-tmp let-item="item" let-index="index">
                        <div (click)="onDurationChange(item, cra?.type_id, cra?.type, j, day, selectRef)">
                          {{ item.name }}
                        </div>
                      </ng-template>
                    </ng-select>
                    </ng-container>

                  </td>
                </ng-container>
              </tr>
            </tbody>
            <tfoot class="table-cra-total">
              <tr>
                <td *ngFor="let total of sums; let dayIndex = index"
                [ngStyle]="{
        'background-color': isWeekend(dayIndex + 1) ? '#FDEFEE' : isHoliday(dayIndex + 1) ? '#f4587a' : '',
        'color': getTotalColor(total),
        'visibility': (!isWeekend(dayIndex + 1) && !isHoliday(dayIndex + 1)) ? 'visible' : 'hidden'
      }"
                >
                  {{ total }}
                </td>
              </tr>
            </tfoot>
          </table>

        </div>

      </div>
    </div>
  </div>

</div>

<div class="card">
  <div class="title mb-5">
    <h4>Totaux</h4>
  </div>

  <div class="row">
    <div class="col-lg-3">
      <h4 class="prod" style="text-align: center;">Production</h4>
      <div class="table-responsive">
        <table class="table" id="totaux">
          <tbody>
            <tr>
              <td><span class="color-blue">Total</span></td>
              <td>{{ totalDurationForAllFamilies?.Production ? totalDurationForAllFamilies.Production : '---' }} jours
              </td>
            </tr>
            <tr *ngFor="let item of getTotalDurationByTypeProduction()">
              <td><span class="color-blue">{{ item.type_name }}</span></td>
              <td>{{ item.total_duration }} jours</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-3">
      <h4 class="interne" style="text-align: center;">Interne</h4>
      <div class="table-responsive">
        <table class="table" id="totaux">
          <tbody>
            <tr>
              <td><span class="color-blue">Total</span></td>
              <td>{{ totalDurationForAllFamilies?.Interne ? totalDurationForAllFamilies.Interne : '---' }} jours</td>
            </tr>
            <tr *ngFor="let item of getTotalDurationByTypeInterne()">
              <td><span class="color-blue">{{ item.type_name }}</span></td>
              <td>{{ item.total_duration }} jours</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-3">
      <h4 class="absence" style="text-align: center;">Absence</h4>
      <div class="table-responsive">
        <table class="table" id="totaux">
          <tbody>
            <tr>
              <td><span class="color-blue">Total</span></td>
              <td>{{ totalDurationForAllFamilies?.Absence ? totalDurationForAllFamilies.Absence : '---' }} jours</td>
            </tr>
            <tr *ngFor="let item of getTotalDurationByTypeAbsence()">
              <td><span class="color-blue">{{ item.type_name }}</span></td>
              <td>{{ item.total_duration }} jours</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-lg-3">
      <h4 class="total" style="text-align: center;">Total</h4>
      <div class="table-responsive">
        <table class="table" id="totaux">
          <tbody>
            <tr>
              <td><span class="color-blue">Nombre de jours</span></td>
              <td><span class="color-blue">{{ listDetailsCraForMonths?.desiredCRA?.totalDuration ?
                  listDetailsCraForMonths.desiredCRA.totalDuration : '---' }} jours</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
