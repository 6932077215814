<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_1223_3646)">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.75 3.2834C9.86824 4.28202 7 7.80437 7 11.9996V17.9991C7 18.5519 6.55087 19 6.00104 19C4.8959 19 4 19.8977 4 21C4 22.1046 4.89703 23 6.00495 23H25.9951C27.1024 23 28 22.1023 28 21C28 19.8954 27.0999 19 25.999 19C25.4472 19 25 18.5554 25 17.9991V11.9996C25 7.80603 22.1324 4.28236 18.25 3.28343V2.25043C18.25 1.00462 17.2426 0 16 0C14.7599 0 13.75 1.00755 13.75 2.25043V3.2834ZM12.5 24H19.5C19.5 25.933 17.933 27.5 16 27.5C14.067 27.5 12.5 25.933 12.5 24Z" fill="#265D91"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_1223_3646" x="0" y="0" width="32" height="35.5" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1223_3646"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1223_3646" result="shape"/>
                        </filter>
                        </defs>
                        </svg>
                        


                    Notifications
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Notifications({{notifications?.length}})</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="body">

            <!--begin::Card body-->
            <div class="wrapper" >
                <div class="container" *ngFor="let notification of notifications; let i = index">
                    <div class="notifs"  (click)="notificationRedirection(notification,i)">
                        <div class="notif bg mb-3" [ngStyle]="{'background-color': notification.read ? 'white' : 'rgba(99, 195, 232, 0.13)'}">
                            <div class="symbol symbol-45px symbol-circle img">
                              <img *ngIf="notification?.sender?.company?.logo" 
                                   [src]="base_url_img_company + notification?.sender?.company?.logo" 
                                   alt="user" width="75px" style="border-radius: 50%;" />
                              <img *ngIf="notification?.sender?.image_url" 
                                   [src]="base_url_img + notification?.sender?.image_url" 
                                   alt="user" width="75px" style="border-radius: 50%;" />
                              <img *ngIf="!notification?.sender?.image_url && !notification?.sender?.company?.logo" 
                                   src="../../../assets/media/logos/logo-avatar.jpg" 
                                   alt="user" width="75px" />
                            </div>
                            <div class="text">
                              <div class="notif-username fw-bold" *ngIf="!notification?.sender?.company?.name && notification.state != '17'">
                                {{notification?.sender?.last_name}} {{notification?.sender?.first_name}}
                              </div>
                              <div class="notif-username fw-bold" *ngIf="notification?.sender?.company?.name">
                                {{notification?.sender?.company?.name}}
                              </div>
                              <div class="notif-username fw-bold" *ngIf="notification.state == '17'">
                                Demande acceptée
                              </div>
                              <span class="msg">{{notification.message}}</span>
                              <div class="text-gray-400 notif-date">
                                <i class="fa-regular fa-clock"></i> {{notification?.created_at | date : 'dd/MM/yyyy HH:mm'}}
                              </div>
                            </div>
                          </div>
                          
                    </div>
                </div>
                <div class="pagination" *ngIf="notifications && notifications?.length">
                    <app-pagination [data]="notifications" [lengthData]="totalItems" [currentPageList]="currentPage"  (setPages)="getItems($event)">
                    </app-pagination>
                </div>
                <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="!notifications?.length ">
                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                    <span class="empty-list">
                     La liste est vide
                 </span>
                 </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>