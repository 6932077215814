import { Component } from '@angular/core';
import { ServiceCraService } from '../../services/service-cra.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { FormBuilder } from '@angular/forms';
import { Observable, Subject, finalize, forkJoin, of, takeUntil } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-list-cra-company',
  templateUrl: './list-cra-company.component.html',
  styleUrls: ['./list-cra-company.component.css'],
})
export class ListCraCompanyComponent {
  currentYear!: number;
  currentUser: any;
  id: any;
  month: any;
  idMonth: any;
  idYear: any;
  listCraForMonths: any;
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private serviceCra: ServiceCraService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    forkJoin([this.getCurrentYear(), this.getIdUser(), this.getId()]).subscribe(
      () => {
        this.getListOfCraForMonth();
      }
    );
  }
  getCurrentYear(): Observable<void> {
    const now = new Date();
    this.currentYear = now.getFullYear();
    return of(undefined);
  }

  getIdUser(): Observable<void> {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    return of(undefined);
  }

  getId(): Observable<void> {
    return new Observable((observer) => {
      this.activatedRoute.paramMap.subscribe({
        next: (params: any) => {
          this.idMonth = params.params['month'];
          this.idYear = params.params['year'];
          observer.next();
          observer.complete();
        },
        error: (err) => observer.error(err),
      });
    });
  }
  getListOfCraForMonth() {
    let dataPayload = {
      year: this.idYear,
      user_id: this.id,
      month: this.idMonth,
    };

    console.log('year', this.currentYear);
    console.log('id', this.id);

    this.spinner.show();

    this.serviceCra
      .getAllListOfCraForMonth(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listCraForMonths = res?.data[0]?.Cras || [];
          console.log('data listCraForMonths ', this.listCraForMonths);
        },
        (error) => {
          console.error('Error fetching listCraForMonths:', error);
        }
      );
  }
  voirListDetailsCra(idCra: any) {
    this.router.navigate(['/wegestu/pointage/details-cra', { idCra: idCra }]);
  }
  searchListCraForMonth(event?: Event) {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    const filterValue = (event?.target as HTMLInputElement).value;
    let data = {
      year: this.idYear,
      user_id: this.id,
      month: this.idMonth,
      search: filterValue.trim().toLowerCase(),
    };
    this.spinner.show();
    this.serviceCra
      .searchCraForMonth(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('response format:', res);
          this.listCraForMonths = res?.data[0]?.Cras || [];
          this.spinner.hide();
        },
        error: () => {
          console.error('Error during completedAssessment search');
          this.spinner.hide();
        },
      });
  }
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
