import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../../../../../main/entreprise/services/company.service';
import { ReviewCompanyService } from '../../../../../../main/evaluation/services/review-company.service';
import {
  serverError,
  successEvaluation,
  successEvaluationupdate,
} from '../../../../../../shared/shared-models/message';
import { LocalStorageService } from '../../../../../../shared/shared-services/local-storage.service';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.css'],
})
export class ReviewComponent {
  /* number */
  totalItems!: number;
  itemsPerPage: number = 20;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  note: number = 4;
  stars!: number;
  id: any;
  listDefaultReview: any[] = [];
  companyDetails: any;
  idCompany: any;
  dataComment: any;
  showStar: boolean = false;
  redirection:any

  starRatings: number[] = [1, 2, 3, 4, 5];
  selectedRating: number = 0;
  commentLengthError: boolean = false;

  /* modal */
  @ViewChild('modal') modal: any;
  /* modal */
  modalRef?: BsModalRef;
  responsecandidat: any;
  comment: string = '';
  show_submit: boolean = true;
  userResponses: number[] = [];
  currentUser: any;
  idCandidate: any;
  evaluationData: any;
  responseData: any[] = [];
  finalData: any;
  /*comment */
  id_comment: any;
  showBtnEdit: boolean = false;
  validationUpdate: boolean = false;
  addComment: boolean = false;
  starsDisabled: boolean = false;
  showcomment: boolean = false;
  commentToEdit: any;
  loadSpinner: boolean = false;

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private reviewCompanyServise: ReviewCompanyService,
    private router:Router
  ) {}

  ngOnInit() {
    this.getIdCompany();
    this.getIdCandidate();
    this.getCompanyById();
    this.getCandidateReviewByIdCandidate();
  }

  getIdCandidate() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idCandidate = JSON.parse(this.currentUser).id;
  }

  /* get id getIdCompany*/
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['idCompany']
        this.redirection= params.params['redirection']
        console.log('%creview.component.ts line:46 listDefaultReview', 'color: #007acc;', this.idCompany);
      }
    })
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
    }
  }

  /* get company  details */
  getCompanyById() {
    this.spinner.show();
    this.companyService
      .getCompanybyId(this.idCompany)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.companyDetails = res.data[0];
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  getCandidateReviewByIdCandidate() {
    this.companyService
      .getCandidateReviewForOneCompany(this.idCompany, this.idCandidate)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          if (res.status == 200) {
            this.responsecandidat = res.data;

            this.dataComment = res.comment;
            console.log(
              '+++++responsecandidashowcommentshowcommentshowcommentshowcommentt',
              this.dataComment
            );

            if (
              this.dataComment?.comment !== null &&
              this.dataComment?.comment !== undefined
            ) {
              this.showBtnEdit = true;
              this.id_comment = this.dataComment?.id;
              this.comment = this.dataComment?.comment;
              this.commentToEdit = this.comment;
              this.showcomment = true;
            } else {
              this.showcomment = false;
              console.log(
                '%crate-wegest-u.component.ts line:150 not exist',
                'color: #007acc;'
              );
              this.addComment = true;
            }

            // Filter the data array to include only items with a null comment
            const responsesWithoutComment = this.responsecandidat.filter(
              (response: any) =>
                response.response_specific_review[0]?.comment === null
            );

            // Map the filtered array to the userResponses array
            this.userResponses = new Array(responsesWithoutComment.length).fill(
              0
            );

            // Map the responses to the userResponses array based on the question IDs
            responsesWithoutComment.forEach((response: any, index: number) => {
              const questionId =
                response.response_specific_review[0].specific_review_id;
              const questionIndex = this.responsecandidat.findIndex(
                (question: any) => question.id === questionId
              );

              if (questionIndex !== -1) {
                this.userResponses[questionIndex] =
                  parseInt(response.response_specific_review[0].stars, 10) || 0;
              }
            });

            console.log('userResponses+++++', this.userResponses);
          }
        },
        error: (err: any) => {
          if (err.error) {
            this.toastrService.error(serverError);
          }
        },
      });
  }
  checkCommentLength() {
    if (this.comment.length > 400 || this.commentToEdit.length > 400) {
      this.commentLengthError = true;
    } else {
      this.commentLengthError = false;
    }
  }
  validationUpdateComment() {
    this.validationUpdate = true;
  }
  ratings: any = [];
  setRating(questionId: any, index: number, stars: number) {
    this.validationUpdate = true;
    this.userResponses[index] = stars;
    this.ratings.push({
      specific_review_id: questionId.id,
      stars: stars.toString(),
    });
    let data1 = {
      responses: this.ratings,
    };
    this.evaluationData = data1;
    console.log('evaluationData', this.evaluationData);
  }

  /* edite response */
  editResponse() {
    const company_id = this.idCompany
    const comment = this.commentToEdit
    let data = { ...this.evaluationData, comment, company_id }
    this.spinner.show()
    this.loadSpinner = true
    this.reviewCompanyServise.updateEvaluationCompany(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.toastrService.success(successEvaluationupdate)
          this.getCandidateReviewByIdCandidate()
          this.spinner.hide()
          this.loadSpinner = false
          this.navigateToCompanyDetails()
          
        }
      }
      , error: (err: any) => {
        this.spinner.hide()
        this.loadSpinner = false
        if (err.error) {
          this.toastrService.error(serverError)
        }
      }
    })
  }

  /* edite Response */
  submitFormEdit() {
    this.editResponse();
  }

  addEvaluationCompany() {
    const data = {
      comment: this.comment,
      company_id: this.idCompany,
    };
    this.addEvaluationCompany_1(this.comment, this.idCompany);
  }

  addEvaluationCompany_1(comment: any, company_id:any) {


    let data = { ...this.evaluationData, comment, company_id }
    this.reviewCompanyServise.addEvaluationCompany(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        if (res.status == 200) {
          this.toastrService.success(successEvaluation)
          this.getCandidateReviewByIdCandidate()
          this.addComment = false
          this.showcomment = true
          this.showStar = true
          this.router.navigateByUrl('wegestu/evaluations/evaluation-societe')
        }
      }
      , error: (err: any) => {
        if (err.error) {
          this.toastrService.error(serverError)
        }
      }
    })
  }
  navigateToCompanyDetails(){
    this.router.navigate(['/wegestu/entreprises/details', { id: this.idCompany}])

  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
