<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_553_210)">
                            <path
                                d="M13.5225 18.2418C13.3312 18.2418 13.1762 18.0872 13.1762 17.8964V12.0494C13.1762 11.9007 13.0556 11.7802 12.906 11.7802C12.7569 11.7802 12.6364 11.9007 12.6364 12.0494V17.1185C12.6364 17.6811 12.224 18.1832 11.6626 18.2367C11.0185 18.2981 10.4766 17.7944 10.4766 17.1649V11.7802C10.4766 10.2932 11.6854 9.08771 13.1765 9.08771H18.0358C19.5269 9.08771 20.7357 10.2932 20.7357 11.7802H20.7339V17.1191C20.7339 17.6814 20.3216 18.1838 19.7602 18.237C19.116 18.2983 18.5741 17.795 18.5741 17.1652V12.0494C18.5741 11.9007 18.4536 11.7802 18.3039 11.7802C18.1552 11.7802 18.0346 11.9007 18.0346 12.0494V17.8931C18.0346 18.0857 17.8781 18.2418 17.685 18.2418H13.5225Z"
                                fill="#265D91" />
                            <path
                                d="M15.6069 8.54909C16.7252 8.54909 17.6317 7.64502 17.6317 6.5298C17.6317 5.41457 16.7252 4.5105 15.6069 4.5105C14.4886 4.5105 13.582 5.41457 13.582 6.5298C13.582 7.64502 14.4886 8.54909 15.6069 8.54909Z"
                                fill="#265D91" />
                            <path d="M6.66194 8.37708H5.4375V16.4106H6.66194V8.37708Z" fill="#265D91" />
                            <path
                                d="M4.81629 7.35937H7.06244C7.51257 7.35937 7.87875 6.99421 7.87875 6.54532C7.87875 6.09642 7.51257 5.73126 7.06244 5.73126H4.81629C4.36616 5.73126 4 6.09642 4 6.54532C4 6.99421 4.36616 7.35937 4.81629 7.35937Z"
                                fill="#265D91" />
                            <path
                                d="M7.06244 17.4277H4.81629C4.36616 17.4277 4 17.7929 4 18.2418C4 18.6907 4.36616 19.0558 4.81629 19.0558H7.06244C7.51257 19.0558 7.87873 18.6907 7.87873 18.2418C7.87873 17.7929 7.51257 17.4277 7.06244 17.4277Z"
                                fill="#265D91" />
                            <path
                                d="M27.2969 0H25.1917H24.6222H24.3754H6.59446C5.9581 0 5.4375 0.51917 5.4375 1.15379V4.71447H6.66194V1.22107H24.5547V23.4372H6.66194V20.0736H5.4375V23.5045C5.4375 24.1391 5.9581 24.6583 6.59446 24.6583H24.6222C25.2586 24.6583 25.7792 24.1391 25.7792 23.5045V4.36426H27.2969C27.6151 4.36426 27.8754 4.10468 27.8754 3.78737V0.576885C27.8754 0.259277 27.6151 0 27.2969 0Z"
                                fill="#265D91" />
                            <path
                                d="M27.2909 4.79913H26.2383V5.40951H27.2573V8.5527H26.2383V9.1631H27.2909C27.6091 9.1631 27.8694 8.9035 27.8694 8.58619V5.37573C27.8694 5.05842 27.6091 4.79913 27.2909 4.79913Z"
                                fill="#265D91" />
                            <path
                                d="M27.2909 9.54443H26.2383V10.1548H27.2573V13.298H26.2383V13.9084H27.2909C27.6091 13.9084 27.8694 13.6488 27.8694 13.3315V10.121C27.8694 9.80402 27.6091 9.54443 27.2909 9.54443Z"
                                fill="#265D91" />
                            <path
                                d="M20.4295 19.6663H13.7477H12.5233H11.0886C10.7504 19.6663 10.4766 19.9396 10.4766 20.2767C10.4766 20.6137 10.7507 20.8871 11.0886 20.8871H12.5233H13.7477H20.4295C20.7678 20.8871 21.0416 20.6137 21.0416 20.2767C21.0416 19.9396 20.7678 19.6663 20.4295 19.6663Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_553_210" x="0" y="0" width="31.875" height="32.6583"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_553_210" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_553_210"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    Contacter <span *ngIf="type==='Responsable'">Responsable</span><span *ngIf="type==='Entreprise'">Entreprise</span>
                </h1>
                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted" *ngIf="type==='Responsable'">Responsable</li>
                <li class="breadcrumb-item text-muted" *ngIf="type==='Entreprise'">Entreprise</li>



                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card" [formGroup]="contactEsnForm">
            <div class="card-body mt-15">
                <div class="container">
                    <div class="row mb-10">
                        <div class="col-lg-12">
                            <!-- <label for="sujet">Raison</label>
                            <select class="form-select mb-3" name="type" id="type" formControlName="type"
                                placeholder="Type" [ngClass]="{ 'is-invalid': submitted && f['type'].errors }">
                                <option [value]="item.id" *ngFor="let item of types">
                                    {{ item.value }}
                                </option>
                            </select> -->
                            <ng-select class="form-select mb-3" [items]="listContactNames" bindLabel="contactName" bindValue="id" id="listContactNames"
                            placeholder="Type" formControlName="mailing_id" [notFoundText]="'Aucun élément trouvé'" *ngIf="type === 'Entreprise'">
                 </ng-select>

                 <ng-select class="form-select mb-3" [items]="listContactNames" bindLabel="contactName" bindValue="id" id="listContactNames"
                            placeholder="Type" formControlName="type" [notFoundText]="'Aucun élément trouvé'" *ngIf="type === 'Responsable'">
                 </ng-select>

                            <div *ngIf="submitted &&  f['type'].errors" class="invalid-feedback">
                                <div *ngIf="f['type'].errors['required']">Champ obligatoire</div>
                            </div>
                        </div>
                    </div>
                    <div class="mail mb-10">
                        <label for="objet">Objet</label>
                        <input type="text" name="objet" id="objet" placeholder="Objet" class="form-control mb-3"
                            formControlName="object" [ngClass]="{ 'is-invalid': submitted && f['object'].errors }">

                        <div *ngIf="submitted &&  f['object'].errors" class="invalid-feedback">
                            <div *ngIf="f['object'].errors['required']">Champ obligatoire</div>
                            <div *ngIf="f['object'].errors['maxlength']">l'objet ne doit pas dépasser 100 caractères</div>
                        </div>
                    </div>
                    <div class="sujet mb-10">
                        <label for="sujet">Sujet</label>
                        <quill-editor formControlName="subject" [modules]="{ toolbar: toolbarOptions }"  style="width: 100%; height: 300px;" 
                        [ngClass]="{ 'is-invalid': submitted && f['object'].errors }" placeholder="Sujet"></quill-editor>
                        <!--
                        <textarea name="sujet" id="sujet" style="height: 100px;" class="form-control"
                            formControlName="subject" [ngClass]="{ 'is-invalid': submitted && f['subject'].errors }">
                    </textarea>
                        -->
                        

                        <div *ngIf="submitted &&  f['subject'].errors" class="invalid-feedback">
                            <div *ngIf="f['subject'].errors['required']">Champ obligatoire</div>
                        </div>
                    </div>

                    <div class="row mb-10">
                        <div class="col-lg-12">
                          <label for="file">Pièce jointe</label>
                          <button *ngIf="fileName" class="more-label" (click)="resetFile()">
                            <img src="/assets/media/icons/icon-delete.png" alt="" width="25" class="cursor-pointer">
                          </button>
                          <input 
                            id="file" 
                            type="file" 
                            class="form-control input-file" 
                            accept=".pdf, .docx, .psd, image/*" 
                            (change)="onFileChange($event)" 
                          />
                        </div>
                      </div>

                      
                    <div class="row">
                        <div class="col-lg-4">
                        </div>
                        <div class="col-lg-4 text-center">
                            <a class="send" (click)="contact()">Envoyer</a>
                        </div>
                        <div class="col-lg-4"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>