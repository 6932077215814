<!--begin::Footer-->
<div id="kt_app_footer" class="app-footer">
  <!--begin::Footer container-->
  <div class="app-container container-fluid d-flex flex-column flex-md-row flex-center flex-md-stack py-3">
    <!--begin::Copyright-->
    <div class="text-dark order-2 order-md-1">
     

      <ul class="menu ">
        <li class="menu-item">
        <a routerLink="/acceuil/faq">FAQ</a>
        </li>
        <li class="menu-item">
          <a routerLink="/acceuil/tuto">Tutos</a>
          </li>
          <li class="menu-item">
            <a href="https://wegestu.com/contact/">Aide et contact</a>
            </li>
            <li class="menu-item">
              <a href="#">Accès entreprise</a>
              </li>
              <li class="menu-item">
                <a  href="https://wegestu.com">À propos</a>
                </li>
              
      </ul>
    </div>
    <!--end::Copyright-->
    <div class="copyright">
      <span class="year me-1">2024</span>
      <a  class="copy">Copyright© wegestu All rights reserved.</a>
    </div>
    <!--begin::Menu-->
    <div class="right order-1">
 <ul class="menu ">
      <li class="menu-item">
      <a href="#">Informations légales</a>
      </li>
      <li class="menu-item">
        <a href="#">CGU</a>
        </li>
        <li class="menu-item">
          <a href="#">Politique de confidentialité</a>
          </li>
          <li class="menu-item">
            <a href="#">Gérer mes cookies</a>
            </li>
            <li class="menu-item">
              <a href="#">Aide et contact</a>
              </li>
              <li class="menu-item">
                <a href="https://www.linkedin.com/company/we-gest-u/?originalSubdomain=fr" target="_blank"><i
                    class="fa-brands fa-linkedin"></i></a>
              </li>
              <li class="menu-item">
                <a href="https://www.facebook.com/wegestu/" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
              </li>
              <li class="menu-item">
                <a href="https://www.instagram.com/wegestu/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
              </li>
        
    </ul>
    </div>
   
    <!--end::Menu-->
  </div>
  <!--end::Footer container-->
</div>