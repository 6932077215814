import { Component } from '@angular/core';
import { CandidateService } from '../../services/candidate.service';
import { listContracts } from '../../models/candidate';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../environnements/environment';
import { ToastrService } from 'ngx-toastr';
import { serverError } from '../../../../shared/shared-models/message';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import * as saveAs from 'file-saver';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { CvType } from 'src/app/core/enums/cv-type.enum';

@Component({
  selector: 'app-dossier-compt',
  templateUrl: './dossier-compt.component.html',
  styleUrls: ['./dossier-compt.component.css'],
})
export class DossierComptComponent {
  emailImage = 'assets/image_cv/email-svg.svg';
  linkedinImage = 'assets/image_cv/linkedin.svg';
  locationImage = 'assets/image_cv/location.svg';
  telephoneImage = 'assets/image_cv/telephone.svg';
  aboutImage = 'assets/image_cv/about-icon.svg';
  skillsImage = 'assets/image_cv/skills-image.png';
  softSkillsImage = 'assets/image_cv/softSkills.png';
  starImage='assets/image_cv/start.svg';
  starinactiveImage='assets/image_cv/star-inactive.svg';
  imageLogo='assets/image_cv/wegstu-pdf.png'; 
  experienceImage='assets/image_cv/experience-image.svg';
  graduationImage='assets/image_cv/graduation-svg.svg';
  certificateImage='assets/image_cv/certificate.svg';
  languageImage='assets/image_cv/language.svg';
 
  Candidate_info: any
  baseUrl_Company = environment.baseUrlGaleryCompany
  base_url_img = environment.baseUrlGalery
  base_url_cv = environment.baseUrlGaleryCv
  base_url_pdf = environment.baseUrlStoragePdf
  first_name: string = '';
  last_name: string = '';
  image_url!: string
  cv_file: any
  skillsCandidate: any
  skillsCandidateCv:any
  userId: any
  currentUser: any
  contratRequest = listContracts
  availabilityOptions = [
    { id: 'availability_1', label: 'Immédiate', value: '1' },
    { id: 'availability_2', label: '1 mois', value: '2' },
    { id: 'availability_3', label: '2 mois', value: '3' },
    { id: 'availability_4', label: '3 mois', value: '4' },
    { id: 'availability_5', label: '4 mois', value: '5' },
    { id: 'availability_6', label: '5 mois', value: '6' },
    { id: 'availability_7', label: '---', value: '7' },
  ];
  listSkillsCandidate: any;
  linkedinPrefix = 'https://www.linkedin.com/in/';
  language = [
    { id: '1', label: 'Moyen' },
    { id: '2', label: 'Bon' },
    { id: '3', label: 'Excelent' },
    { id: '4', label: '---' },
  ];
  show_cv: boolean = false;
  show_test: boolean = false;
  id: any;
  elapsedTime: any;
  /* colors skills */
  colors = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];
  cv: string | null = null;

  /* pagination */
  totalItems: number = 10;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = this.itemsPerPage;
  pagination : boolean =false;

  /* spinner */
  loadSpinner: boolean = false;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  /* string */
  url: string = environment.baseUrl;
  linkedinUrl: boolean = false;

  test_file: any;

  readonly ESN_CONSULTANT_ROLE  = "4"
  cvTypeEnum  = CvType
  
  constructor(
    private candidateService: CandidateService,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    protected http: HttpClient,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    if (!this.currentUser) {
      return;
    }
    try {
      const userData = JSON.parse(this.currentUser);      
      this.id = userData.id;
    } catch (error) {
    }
    this.getProfileCandidate(this.id)
    this.getSkillsCandidate()
    window.scrollTo(0,0)

  }
  removeHtmlTags(html: string | undefined): string {
    if (!html) {
      return '';
    }
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html; 
    return tempDiv.textContent || ''; 
  }
  formatDate(date: Date): string {
    const year = date?.getFullYear();
    const month = this.padZero(date.getMonth() + 1);
    const day = this.padZero(date.getDate());
    const hours = this.padZero(date.getHours());
    const minutes = this.padZero(date.getMinutes());
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  createdAt:any
  lastConnection:any
  profileCompletionPopupShown = false;
  profileCompletionPopupKey = 'profileCompletionPopupShown';
  showProfileCompletionPopup() {
    const popupShown = localStorage.getItem(this.profileCompletionPopupKey);
    if (!popupShown) {
      Swal.fire({
        title: 'Finalisez votre profil',
        text: 'Vous n\'avez pas terminé votre profil. Veuillez le compléter.',
        icon: 'info',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          this.router.navigateByUrl('/wegestu/profil-candidat/modifier');
        }
        localStorage.setItem(this.profileCompletionPopupKey, 'true');
      });
    }

  }

/**
 * Verifies if the candidate's company review has been completed and exports the appropriate CV based on the provided type and anonymity flag.
 * If the candidate has already been evaluated, it exports either the WEGESTU or ESN CV, depending on the provided CV type.
 * If the candidate has not been evaluated, it triggers a popup for the review.
 *
 * @param {string} cvType - The type of CV to export (e.g., WEGESTU or ESN).
 * @param {number} isAnonymous - Whether to export an anonymous version of the CV (1 : not anonymous, 2 : anonymous) .
 * @returns {void}
 */
  verifyReviewCompany(cvType : string, isAnonymous: number){   
    if(this.Candidate_info.Evaluated=='true'){
      if(cvType === this.cvTypeEnum.WEGESTU){
        this.exportCVCandidate(isAnonymous, null)
      }else{
        this.exportCVCandidate(isAnonymous, this.Candidate_info?.ESN_id)
      }
    }else
      this.verifyReviewCompanyPopup()
  }

  verifyReviewCompanyPopup() {
    Swal.fire({
      title: 'Avis requis',
      text: 'Vous ne pouvez télécharger votre dossier de compétence que si vous avez mis un avis pour une entreprise. Veuillez mettre un avis pour pouvoir télécharger votre dossier.',
      icon: 'info',
      confirmButtonText: 'Donner un avis',
      showCancelButton: true,
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigateByUrl('/wegestu/evaluations/evaluation-societe');
      }
    });
  }
  getTotalPages(): number {
    return Math.ceil(this.totalItems / this.itemsPerPage);
  }

  isLastPage(): boolean {
    return this.currentPage === this.getTotalPages();
  }

  // @TOFIX: duplicated
/**
 * Exports the CV of a candidate based on the specified type and company ID.
 *
 * @param {number} type - The type of CV to export (e.g., 1 for standard, 2 for anonymous)
 * @param {number | null} idCompany - The ID of the company for which the CV is being exported.
 *                          Use `null` if no specific company is associated.
 */
exportCVCandidate(type: number, idCompany : number | null) { 
  const extension = ".pdf"
  this.spinner.show()
  let exportSuccess = false;
  this.candidateService.exportCVCandidate(this.id, type, idCompany).subscribe({
    next: (res: any) => {
      this.spinner.hide();
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      let name = `${this.last_name}-${this.first_name}`
      if(idCompany){
        name = name.concat('-CvESN')
      }else{
        name = name.concat('-CvWegestu')
      }
      if(type === 2){
        name = name.concat('-Anonyme')
      }
      name = name.concat(extension)

      saveAs(blob, name);
      exportSuccess = true;
      if (exportSuccess) {
        this.toastrService.success('CV téléchargé avec succès');
      }
    },
    error: () => {
      this.spinner.hide();
      this.toastrService.error(serverError);
    },
  });
}

formatPeriod(period: string): string {
  if (!period) return '';

  const matches = period.match(/(\d+) ans, (\d+) mois, (\d+) jours/);
  if (!matches) return period;

  const years = parseInt(matches[1], 10);
  const months = parseInt(matches[2], 10);

  if (years === 0 && months === 0) {
    return `moins d'un mois`;
  } else {
    let result = '';

    if (years > 0) {
      result += `${years} An${years > 1 ? 's' : ''}`;
    }
    if (months > 0) {
      if (result.length > 0) result += ', ';
      result += `${months} Mois`;
    }

    return result;
  }
}




  getProfileCandidate(id: any) {
    this.spinner.show()
    this.loadSpinner = true
    this.candidateService.getCandidate(id).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        if (res.data && res.data.length > 0) {
          this.Candidate_info = res.data[0];          
          this.Candidate_info.softSkills= this.Candidate_info.softSkills.filter((skill:any) => skill !== null);
          this.first_name = this.Candidate_info.first_name || '';
          this.last_name = this.Candidate_info.last_name || '';
          this.createdAt = this.Candidate_info.first_connection;
          if (this.createdAt == '1') {
            this.showProfileCompletionPopup()
          }
        }
        this.Candidate_info.desired_contract = this.Candidate_info.desired_contract || [];
        if (Array.isArray(this.Candidate_info.desired_contract)) {
          this.Candidate_info.desired_contract = this.Candidate_info.desired_contract.map((contractId: any) => {
            return this.contratRequest[contractId - 1]?.label || '';
          });
        }
        // this.Candidate_info.desired_contract = this.Candidate_info.desired_contract?.map((contractId:any) => {
        //   return this.contratRequest[contractId - 1]?.label || '';
        // });
        this.elapsedTime = this.formatElapsedTime(this.Candidate_info.last_connection);
        this.image_url = this.base_url_img + this.Candidate_info!.image_url
        this.cv_file = this.base_url_cv + this.Candidate_info.cv
        this.test_file = this.base_url_pdf + this.Candidate_info?.file_test?.split("/")[1]

          if (this.Candidate_info.linkedin_link !== null) {
            this.linkedinUrl = true;
          }
          this.cv = this.Candidate_info.cv;
          if (this.Candidate_info.cv !== 'null') {
            this.show_cv = true;
          } else {
            this.show_cv = false;
          }
          if (this.Candidate_info.score !== null) {
            this.show_test = true;

            const originalValue = this.Candidate_info.score;

            const hasDecimalValues = originalValue % 1 !== 0;

            this.Candidate_info.score = hasDecimalValues
              ? originalValue.toFixed(2)
              : originalValue.toString();
          } else {
            this.show_test = false;
          }

        this.Candidate_info.current_country = !this.Candidate_info.current_country || this.Candidate_info.current_country == 'null' ? '' : this.Candidate_info.current_country
        this.Candidate_info.linkedin_link = !this.Candidate_info.linkedin_link || this.Candidate_info.current_country == 'null' ? '' : this.Candidate_info.linkedin_link
        this.Candidate_info.date_first_experience = !this.Candidate_info.date_first_experience || this.Candidate_info.date_first_experience == 'null' ? '' : this.Candidate_info.date_first_experience
        this.Candidate_info.desired_salary = !this.Candidate_info.desired_salary || this.Candidate_info.desired_salary == 'null' ? '' : this.Candidate_info.desired_salary
        this.Candidate_info.destination_country = !this.Candidate_info.destination_country || this.Candidate_info.destination_country == 'null' ? '' : this.Candidate_info.destination_country
        

        this.parseData('certificates')
        this.parseData('desired_contract')
        this.parseData('diplomas')
        this.parseData('mobility')

        this.spinner.hide()
        this.loadSpinner = false
      },
      error: (err) => {
        this.spinner.hide()
        this.loadSpinner = false
      
      }
    })
  }

  cleanDate(dateString: string): string {
    if (!dateString) {
      return '';
    }
    return dateString.replace('admin', '').trim();
  }
  getFormattedDate(): string {
    if (!this.Candidate_info?.last_connection && this.Candidate_info?.created_at) {
      return this.cleanDate(this.Candidate_info?.created_at);
    }
    return this.cleanDate(this.Candidate_info?.last_connection);
  }

  formatElapsedTime(created_at: string): string {
    const currentTime = moment();
    const messageTime = moment(created_at);
    const duration = moment.duration(currentTime.diff(messageTime));

    if (duration.asMinutes() <= 59) {
      const minutes = Math.floor(duration.asMinutes());
      if (minutes < 1) {
        return "à l'instant";
      } else if (minutes === 1) {
        return '1 minute';
      } else {
        return `${minutes} minutes`;
      }
    } else if (duration.asHours() <= 23) {
      const hours = Math.floor(duration.asHours());
      if (hours === 1) {
        return '1 heure';
      } else {
        return `${hours} heures`;
      }
    } else if (duration.asDays() <= 6) {
      const days = Math.floor(duration.asDays());
      if (days === 1) {
        return '1 jour';
      } else {
        return `${days} jours`;
      }
    } else {
      const weeks = Math.floor(duration.asDays() / 7);
      if (weeks === 1) {
        return '1 semaine';
      } else {
        return `${weeks} semaines`;
      }
    }
  }
  isLastPagee: boolean = false;
  hasPagination: boolean = false;
  getSkillsCandidate() {
    this.candidateService.getSkillsbyIdCandidate(this.id).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.skillsCandidate = res.data.categoriesWithSubCategoriesAndSkills.slice(this.startIndex, this.endIndex);
        this.skillsCandidateCv = res.data.categoriesWithSubCategoriesAndSkills
            .map((category:any) => ({
              ...category,
              sub_category: category.sub_category
                .map((subCategory:any) => ({
                  ...subCategory,
                  skills: subCategory.skills.filter((skill:any) => skill.checked === '1')
                }))
                .filter((subCategory:any) => subCategory.skills.length > 0)
            }))
            .filter((category:any) => category.sub_category.length > 0);

        const skillsTextLength = this.Candidate_info?.skills_text?.length ? 1 : 0;
        this.totalItems = res.data.count + skillsTextLength;
        this.hasPagination = this.totalItems > this.itemsPerPage;
        this.isLastPagee = (this.endIndex >= this.totalItems);
      },
      error: (err) => {
      }
    });
  }

  capitalizeFirstLetter(string: any) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  openPDF(): void {
    this.candidateService.downloadPDF(this.cv_file).subscribe((blob: any) => {
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    });
  }

  downloadCV() {
    const link = document.createElement('a');
    link.href = this.cv_file;
    link.download = 'downloaded_cv.pdf';
    link.click();
    this.toastrService.success('CV téléchargé avec succès');
  }
  shouldDisplayCountry(destination_country: any[]): boolean {
    return destination_country && destination_country.some((c) => c !== null);
  }
  shouldDisplayMobility(mobility: any[]): boolean {
    return mobility && mobility.some((m) => m !== null);
  }

  /* get pagination items */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      this.itemsPerPage = event.itemsPerPage;
      this.currentPage = event.currentPage;
      if (this.endIndex > this.totalItems) {
        this.endIndex = this.totalItems;
      }
      else  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
      this.getSkillsCandidate();
      }
    }
  }
  openResultTest(): void {
    this.candidateService
      .downloadPDFTest(this.test_file)
      .subscribe((blob: any) => {
        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');
      });
  }

  downloadResultTest() {
    const link = document.createElement('a');
    link.href = this.test_file;
    // link.target = '_blank';
    link.download = 'downloaded_test.pdf';
    link.click();
  }

  parseData(name: string) {
    if (typeof this.Candidate_info[name] === 'string') {
      const jsonString = this.Candidate_info[name].trim();
      if (jsonString === 'null') {
        this.Candidate_info[name] = [];
      } else if (jsonString.length > 0) {
        try {
          this.Candidate_info[name] = JSON.parse(jsonString);
        } catch (error) {
        }
      }
    }
  }
  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  /**
 * Checks if a candidate is an ESN consultant and belongs to the current user's ESN
 * 
 * @param roles - candidate's roles
 * @returns boolean - Returns true if the candidate is an ESN consultant and belongs to the current user's ESN
 */
  checksEsnConsultant(roles: string[]): boolean { 
    return roles?.includes(this.ESN_CONSULTANT_ROLE);
}
}
