import { Component, ElementRef,HostListener  , OnInit, TemplateRef } from '@angular/core';
import { JobsService } from '../../services/jobs.service';
import { Router } from '@angular/router';
import { environment } from '../../../../../environnements/environment';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  applySuccess,
  archiveJobSuccess,
  blockSuccess,
  serverError,
} from '../../../../shared/shared-models/message';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';
import Swal from 'sweetalert2';
import { ICountry, State } from 'country-state-city';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as moment from 'moment';
import { PaysService } from '../../../../shared/services/pays.service';
import { headerColumns1 } from '../../models/jobOffer';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-list-jobs',
  templateUrl: './list-jobs.component.html',
  styleUrls: ['./list-jobs.component.css'],
})
export class ListJobsComponent implements OnInit {
  loadSpinner: boolean = false;
  isCollapsed = true;
  jobs: any;
  totalJobs!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  url: string = environment.baseUrl + '/api';
  user: any;
  mobilities: any[] = [];
  countries: any[] = [];
  id: any;
  currentUser: any;
  currentSortDirection: number = 2;
  jobOffer: any;
  skillsList: { name_FR: string }[] = [];
  idJobs: any;
  state: any;
  postuled: any;
  hasPassedTestEsn : boolean = false
  pagination : boolean =false;

  translations: { [key: string]: string } = {
    Afghanistan: 'Afghanistan',
    'South Africa': 'Afrique du Sud',
    Albania: 'Albanie',
    Algeria: 'Algérie',
    Germany: 'Allemagne',
    Andorra: 'Andorre',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    'Saudi Arabia': 'Arabie saoudite',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    Belarus: 'Biélorussie',
    Bolivia: 'Bolivie',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    Brazil: 'Brésil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    Chile: 'Chili',
    China: 'Chine',
    Cyprus: 'Chypre',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    'Congo - Brazzaville': 'Congo-Brazzaville',
    'Congo - Kinshasa': 'Congo-Kinshasa',
    'North Korea': 'Corée du Nord',
    'South Korea': 'Corée du Sud',
    'Costa Rica': 'Costa Rica',
    'Ivory Coast': 'Côte d’Ivoire',
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Denmark: 'Danemark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'United Arab Emirates': 'Émirats arabes unis',
    Eritrea: 'Érythrée',
    Spain: 'Espagne',
    Eswatini: 'Eswatini',
    Estonia: 'Estonie',
    'United States': 'États-Unis',
    Ethiopia: 'Éthiopie',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Grenada: 'Grenade',
    Greenland: 'Groenland',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    'Equatorial Guinea': 'Guinée équatoriale',
    Guyana: 'Guyana',
    'French Guiana': 'Guyane française',
    Haiti: 'Haïti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    Iraq: 'Irak',
    Iran: 'Iran',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Israel: 'Israël',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    Laos: 'Laos',
    Latvia: 'Lettonie',
    Lebanon: 'Liban',
    Lesotho: 'Lesotho',
    Liberia: 'Libéria',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'North Macedonia': 'Macédoine du Nord',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    Micronesia: 'Micronésie',
    Moldova: 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibie',
    Nauru: 'Nauru',
    Nepal: 'Népal',
    Netherlands: 'Pays-Bas',
    'New Caledonia': 'Nouvelle-Calédonie',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    Palestine: 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Romania: 'Roumanie',
    Russia: 'Russie',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint-Barthélemy',
    'Saint Helena': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
    'Saint Lucia': 'Sainte-Lucie',
    'Saint Martin': 'Saint-Martin',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten': 'Saint-Martin',
    Slovakia: 'Slovaquie',
    Slovenia: 'Slovénie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Georgia and the South Sandwich Islands':
      'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Swaziland: 'Eswatini',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    Syria: 'Syrie',
    Taiwan: 'Taïwan',
    Tajikistan: 'Tadjikistan',
    Tanzania: 'Tanzanie',
    Thailand: 'Thaïlande',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Kingdom': 'Royaume-Uni',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Viêt Nam',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Zimbabwe: 'Zimbabwe',
  };
  paysList: ICountry[] = [];
  stateList: any[] = [];
  listNames: string[] = [];
  dataHeader: any = [];
  /* modal */
  modalRef?: BsModalRef;
  mode: string = '';
  currentSortColumn: string = 'esn_name';

  /* formGroup */
  applyForm: FormGroup = this.createForm();
  searchForm: FormGroup = this.createSearchForm();
  stateTest: any;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  isLoadSpinner: boolean = true;
  
  constructor(
    private jobService: JobsService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private jobOffreService: JobsService,
    private paysServices: PaysService,
    private sortDataService: JobsService,
    private elRef: ElementRef,
    private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    this.paysList = this.paysServices.getAllCountries();
    this.getAllDefaultJobs();
    this.getUser();
    this.getAllSkills();
    this.getListElementHeader();
    this.searchForm.get('city')?.disable();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));

    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
    
  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');
    
    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }

  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  type = [
    { id: 1, name: 'Alternance'},
    { id: 2, name: 'Autres type de contrat'},
    { id: 3, name: 'CDD'},
    { id: 4, name: 'CDI'},
    { id: 5, name: 'Freelance'},
    { id: 6, name: 'Portage salarial'},
    { id: 7, name: 'Stage'},
  ];

  poste = [
    { id: 1, name: 'à distance' },
    { id: 2, name: 'Hybrid' },
    { id: 3, name: 'sur site' },
  ];

  pays = [
    { id: 1, name: 'France' },
    { id: 2, name: 'Tunisie' },
    { id: 3, name: 'Turquie' },
    { id: 4, name: 'Allemagne' },
  ];

  entreprise = [
    { id: 1, name: 'Entreprise' },
    { id: 2, name: 'ESN' },
    { id: 3, name: 'Client Final' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ];

  villes = [
    { id: 1, name: 'Paris' },
    { id: 2, name: 'lyon' },
    { id: 3, name: 'Marseille' },
    { id: 4, name: 'Nice' },
    { id: 5, name: 'Strasbourg' },
  ];
  date = [
    { id: 1, name: 'Hier' },
    { id: 2, name: 'Dernier 7 jours' },
    { id: 3, name: 'Dernier mois' },
    { id: 4, name: 'Dernier 2 mois' },
  ];
  states = [
    { id: 0, name: 'Actif' },
    { id: 1, name: 'Inactif' },
  ];
  profile_abroad = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  passeport_talent = [
    { id: 0, name: 'Non' },
    { id: 1, name: 'Oui' },
  ];
  typeEntreprise = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de recrutement' },
    // { id: 3, name: 'Client final' },
  ];
  contratTypeOptions: { [key: string]: string } = {
    '1': 'CDD',
    '2': 'CDI',
    '3': 'Portage salarial',
    '4': 'Freelance',
    '5': 'Stage',
    '6': 'Alternance',
    '7': 'Autres type de contrat',
  };
  salary: number = 50000;
  incrementAmount: number = 100;
  decrementAmount: number = 100;
  incrementSalary() {
    this.salary += 1000;
  }

  decrementSalary() {
    this.salary -= 1000;
  }

  /* change country select and delete the string Governorate from state name*/
  onCountryChange(mode: string): void {
    this.searchForm.get('city')?.enable();
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.searchForm.get('city')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.searchForm.get('city')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.searchForm.get('city')?.disable();
        this.searchForm.get('city')?.setValue(null);
      }
    }
  }

  oncityChange(mode: string) {
    this.mobilities = this.searchForm.get('city')?.value;
    let paysLists: any[] = [];
    this.countries = this.searchForm.get('country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    console.log(
      'stateList in change mobilities mobilities : ',
      this.mobilities
    );
    console.log(
      'stateList in change mobilities stateListAfter  : ',
      stateListAfter
    );
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.searchForm.get('city')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }

  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }
    return duplicates;
  }

  isDropdownVisible = false;
 
  // @HostListener('document:click', ['$event'])
  // handleDocumentClick(event: Event) {
  //   if (!(event.target as HTMLElement).matches('.dropbtn')) {
  //     const dropdowns = document.getElementsByClassName("dropdown-content");
  //     for (let i = 0; i < dropdowns.length; i++) {
  //       const openDropdown = dropdowns[i];
  //       if (openDropdown.classList.contains('show')) {
  //         openDropdown.classList.remove('show');
  //       }
  //     }
  //   }
  // }
  onButtonClick(event: MouseEvent): void {
    this.isDropdownVisible = !this.isDropdownVisible;
    console.log('Le bouton a été cliqué');
  }
  translateCountryName(name: string): string {
    return this.translations[name] || name;
  }
  /* get pagination items */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchJob();
      } 
      else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getAllDefaultJobs();
      }
    }
  }
  headerColumns: { checked: boolean; name: string; code: string }[] =
    headerColumns1;
  getSortName(columnName: string): string {
    switch (columnName) {
      case 'ID':
        return 'ID_jobOffer';
      case 'Nom entreprise':
        return 'esn_name';
      case 'Type entreprise':
        return 'type';
      case 'Poste':
        return 'name';
      case 'Date de publication':
        return 'start_date';
      case 'Années d’expériences':
        return 'years_of_experience';
      case 'Type de contrat':
        return 'contract_type';
      case 'Type de poste':
        return 'post_type';
      case 'Disponibilité':
        return 'availability';
      case 'Profil étranger':
          return 'profile_abroad';  
      case 'Passeport talent':
        return 'passeport_talent';  
      case 'scoreJobOffer':
        return 'Score';
      case 'TJM':
        return 'tjm';
      case 'Salaire':
        return 'salary';
      case 'Compétence':
        return 'skills';
      case 'Pays':
        return 'country';
      case 'Ville':
        return 'city';
      case 'Statut':
        return 'state';
      default:
        return columnName;
    }
  }
  updateInputType(type: 'text' | 'date', nameControl: string) {
    const inputElement =
      nameControl == 'end_date'
        ? (document.getElementById('dateInputEnd') as HTMLInputElement)
        : (document.getElementById('dateInput') as HTMLInputElement);
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchForm.value[nameControl] &&
        this.searchForm.value[nameControl] != ''
      )
        this.searchForm
          .get(nameControl)
          ?.setValue(
            moment(this.searchForm.value[nameControl]).format('DD/MM/yyyy')
          );
    }
  }
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;
    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) this.listNames.push(iterator.name);
    }
    this.changeSelectionHeadrElements(data);
  }
  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.jobService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => {},
        error: () => {},
      });
  }
  getListElementHeader() {
    this.jobService
      .getHeaderElements(9)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isLoadSpinner = false;
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
            // console.log('listNamesyyyyyy', this.listNames);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => {},
      });
  }

  navigateToTest() {
    this.router.navigate(['/wegestu/qcm']);
    this.toastr.info('Vous devez passer le test avant de postuler');
  } 

  getAllDefaultJobs(sort?: any) {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    this.stateTest = JSON.parse(this.currentUser).stateTest;

    // let dataPayload = !sort ? { per_page: this.itemsPerPage, page: this.currentPage, candidate_id: this.id } : { ...{ per_page: this.itemsPerPage, page: this.currentPage, candidate_id: this.id }, ...sort }
    let dataPyload =
      this.sortAttr == ''
        ? {
            per_page: this.itemsPerPage,
            page: this.currentPage,
            candidate_id: this.id,
          }
        : {
            ...{
              per_page: this.itemsPerPage,
              page: this.currentPage,
              candidate_id: this.id,
            },
            ...{
              sort: this.currentSortDirection,
              sortAttribute: this.sortAttr,
            },
          };
    this.spinner.show();

    this.jobService.getAllJobs(dataPyload).subscribe(
      (res: any) => {
        this.jobs = res.data.data
        // .slice(0, this.endIndex);
        this.totalJobs = res.data.total;
        if (this.currentPage > 1 && !res.data.data.length) {
          this.currentPage = 1;
          this.getAllDefaultJobs();
        }
        this.spinner.hide();
        console.log('data jobs', this.jobs);
      },
      (error) => {
        console.error('Error fetching jobs:', error);
      }
    );
  }
  contactResponsableNavigation(user : any,name : string) {
    this.router.navigate([
      '/wegestu/chat',
      { user: user,name:name},
    ]);
  }
  getSkillsArray(candidate: any): string[] {
    if (candidate && candidate.skills) {
      return candidate.skills.split(',').map((s: string) => s.trim());
    } else {
      return [];
    }
  }

  profilCompanyNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }
  /* Open and close modal */
  openModal(
    template: TemplateRef<any>,
    selectedJobOffer: any
  ) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.idJobs = selectedJobOffer.id;
    this.state = selectedJobOffer.state;
    this.postuled = selectedJobOffer.postuled;
    this.hasPassedTestEsn = selectedJobOffer.passed_test_esn
    if (this.mode == 'apply') {
      this.applyCandidateJob(this.idJobs, this.state, this.postuled, this.mode);
    }
  }
  closeModal() {
    this.modalRef!.hide();
    this.applyForm.reset();
  }
  /* create apply form */
  createForm(data?: any) {
    return this.formBuilder.group({
      letter: [data ? data.letter : ''],
      salary: [data ? data.salary : ''],
      tjm: [data ? data.tjm : ''],
    });
  }
  showTestConfirmation(idJobs: any) {
    Swal.fire({
      title: 'Pourriez-vous passer un test pour cette offre d’emploi?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(['/test/welcome-test', { idOffre: idJobs }]);
      }
      const index =this.jobs?.findIndex((j : any) => j.id === idJobs)
      if(index !== -1){
          this.jobs[index].postuled = true
      }
    });
  }
  createTest(idJobs: any) {
    const data = { job_offer_id: idJobs };
    this.jobOffreService.createTestEsn(data).subscribe({
      next: (res: any) => {

        console.log("res of createTest ",res)
        this.spinner.hide();
        if(!this.hasPassedTestEsn){
          this.showTestConfirmation(idJobs);
        }
      },
      error: () => {
        this.spinner.hide();
      },
    });
  }

  applyCandidateJob(idJobs: any, state: any, postuled: any, mode: string) {
    const data = { job_offer_id: idJobs, user_id: this.id };
    if (state === 0) {
      if (postuled === 0) {
        this.jobService
          .applyCandidateJobOffer({ ...data, ...this.applyForm.value })
          .subscribe({
            next: (res: any) => {

            console.log("res of applyCandidateJobOffer ",res);
            const index =this.jobs?.findIndex((j : any) => j.id === idJobs)
            if(index !== -1){
              this.jobs[index].postuled = true
            }
              
              this.spinner.hide();
              this.toastr.success(applySuccess);
              this.closeModal();
              setTimeout(() => {
                this.createTest(idJobs);
              }, 3000);
            },
            error: () => {
              this.spinner.hide();
              this.toastr.error(serverError);
            },
          });
      } else {
        this.toastr.error("Vous avez déjà postulé à cette offre d'emploi.");
      }
    } else {
      this.toastr.error("Cette offre d'emploi est actuellement désactivée.");
    }
  }

  showarchivedConfirmation(idJobs: any, type: any) {
    Swal.fire({
      title: 'Êtes-vous sûr d’archiver cette offre ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.archivedCandidateJob(idJobs, type);
      }
    });
  }
  archivedCandidateJob(idJobs: any, type: any) {
    console.log('infooo', idJobs, type, this.id);
    if (type === '2') {
      const data = { jobOffer_id: idJobs };

      console.log('archivedjobOffer', type);
      this.jobService.archivedJobOffer(data).subscribe({
        next: (res: any) => {

          console.log('res of archivées', res);

          this.spinner.hide();
          this.toastr.success(archiveJobSuccess);
          this.searchJob();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    } else {
      const data = { job_request_id: idJobs, user_id: this.id };

      console.log('archivedjobRequest', type);
      this.jobService.archivedJobRequest(data).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.toastr.success(applySuccess);
          this.searchJob();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    }
  }

  blockedCandidateJob(idJobs: any, type: any) {
    console.log('infooo', idJobs, type, this.id);
    if (type === '2') {
      const data = { jobOffer_id: idJobs };

      console.log('BlockedjobOffer', type);
      this.jobService.blockedJobOffer(data).subscribe({
        next: (res: any) => {
          this.toastr.success(blockSuccess);
          this.getAllDefaultJobs();
        },
        error: () => {
          this.toastr.error(serverError);
        },
      });
    } else {
      const data = { job_request_id: idJobs };

      console.log('BlockedjobRequest', type);
      this.jobService.blockedJobRequest(data).subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.toastr.success(applySuccess);
          this.getAllDefaultJobs();
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
    }
  }

  getUser() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
  }
  onSalaryRangeInput(event: any) {
    this.preventNegativeInput(event)
    const value = event.target.value;
    if (value.toLowerCase().endsWith('k')) {
      this.searchForm.controls['salary_range'].setValue(
        this.convertKToNumber(value)
      );
    }
  }

  convertKToNumber(value: any) {
    if (typeof value === 'string' && value.toLowerCase().endsWith('k')) {
      return parseFloat(value.slice(0, -1)) * 1000;
    } else if (!isNaN(value)) {
      return parseFloat(value) * 1000;
    }
    return parseFloat(value);
  }
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_jobOffer: [data ? data.ID_jobOffer : ''],
      company_type: [data ? data.ens.type : null],
      name: [data ? data?.name : ''],
      skillsAnd: [data ? data.skillsAnd : ''],
      skillsOr: [data ? data.skillsOr : ''],
      years_of_experience: [data ? data.years_of_experience : ''],
      post_type: [data ? data.post_type : ''],
      company_name: [data ? data?.company_name : ''],
      contract_type: [data ? data?.contract_type : null],
      country: [data ? data?.country : ''],
      city: [data ? data?.city : ''],
      salary: [data ? data?.salary : ''],
      salary_range: [data ? data?.salary_range : ''],
      tjm: [data ? data?.tjm : ''],
      tjm_range: [data ? data?.tjm_range : ''],
      date_range: data ? data?.date_range : '',
      availability: [data ? data?.availability : ''],
      typeEntreprise: [data ? data?.typeEntreprise : ''],
      start_date: [data ? data.start_date : ''],
      end_date: [data ? data.end_date : ''],
      profile_abroad: [data ? data.profile_abroad : ''],
      passeport_talent: [data ? data.passeport_talent : ''],
      state: [data ? data.state : null],
      search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
    });
  }
  transformSearch(search: string): string {
    search = search.replace(/\|\|/g, 'OR');
    search = search.replace(/&&/g, 'AND');
    search = search.replace(/!/g, 'NOT');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        // If the term is inside double quotes, keep it as is
        return `"${p1}"`;
      } else {
        // If the term is not inside double quotes, add double quotes around it
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `"${escapedTerm}"`;
      }
    });

    return search;
  }

  isSimpleWord(value: string): boolean {
    // Utilisez une expression régulière pour vérifier si la valeur est un mot simple ou deux mots avec espace
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchJob() {
    let formattedEndDate = this.datePipe.transform(this.searchForm.value.end_date, 'yyyy-MM-dd');
    let formattedStartDate = this.datePipe.transform(this.searchForm.value.start_date, 'yyyy-MM-dd');
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      candidate_id: this.id,
      ID_jobOffer: this.searchForm.value.ID_jobOffer,
      name: this.searchForm.value.name,
      typeEntreprise: this.searchForm.value.typeEntreprise,
      years_of_experience: this.searchForm.value.years_of_experience,
      experience_range: this.searchForm.value.experience_range,
      company_name: this.searchForm.value.company_name,
      skillsAnd: this.searchForm.value.skillsAnd,
      skillsOr: this.searchForm.value.skillsOr,
      availability: this.searchForm.value.availability,
      contract_type: this.searchForm.value.contract_type,
      country: this.searchForm.value.country,
      city: this.searchForm.value.city,
      post_type: this.searchForm.value.post_type,
      tjm: this.searchForm.value.tjm,
      tjm_range: this.searchForm.value.tjm_range,
      salary: this.searchForm.value.salary,
      salary_range: this.convertKToNumber(
        this.searchForm.value.salary_range?.toString()
      ),
      date_range: this.searchForm.value.date_range,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
      profile_abroad: this.searchForm.value.profile_abroad,
      passeport_talent: this.searchForm.value.passeport_talent,
      state: this.searchForm.value.state,
      search: this.transformSearch(this.searchForm.value?.search),
    };
    this.spinner.show();

    console.log('data of job search : ', data);
    this.jobService
      .searchJobs(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (resSearch) => {
          this.isRecherche = true;
          console.log('resSearch of : ', resSearch);
          if (resSearch.status == 200) {
            if (!resSearch.data.data.length && this.currentPage > 1) {
              this.currentPage = 1;
              this.searchJob();
            } else {
              this.spinner.hide();
              this.loadSpinner = false;
              this.jobs = resSearch.data.data.slice(0, this.endIndex);
              this.totalJobs = resSearch.data.total;
            }
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.loadSpinner = false;
        },
      });
  }
  isRecherche: boolean = false;
  showStartDate:boolean=false;
  showEndDate:boolean=false;

  onDateInputStartDateChange(e:any){
    this.showStartDate=true;
  }
  onDateInputEndDateChange(e:any){
    this.showEndDate=true;
  }
  onDateInputBlur(): void {
    this.showStartDate = false;
  }
  onEndDateInputBlur(): void {
    this.showEndDate = false;
  }
  onDateInputEndDateChangee(): void {
    this.showEndDate = true;
  }
  reset() {
    this.showStartDate=false;
    this.showEndDate=false;
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getAllDefaultJobsreset();
    }
    return (this.isRecherche = false);
  }
  
  getAllDefaultJobsreset(sort?: any) {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    let dataPayload = !sort
      ? {
          per_page: this.itemsPerPage,
          page: this.currentPage,
          candidate_id: this.id,
        }
      : {
          ...{
            per_page: this.itemsPerPage,
            page: this.currentPage,
            candidate_id: this.id,
          },
          ...sort,
        };
        this.spinner.show();
    this.jobService.getAllJobs(dataPayload).subscribe(
      (res: any) => {
        this.jobs = res.data.data.slice(0, this.endIndex);
        this.totalJobs = res.data.total;
        if (this.currentPage > 1 && !res.data.data.length) {
          this.currentPage = 1;
          this.getAllDefaultJobs();
        }
        this.spinner.hide();
        console.log('data jobs', this.jobs);
      },
      (error) => {
        console.error('Error fetching jobs:', error);
      }
    );
  }
  detailsJobOfferNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-offer', { id: id }]);
  }
  detailsJobRequestNavigation(id: any) {
    this.router.navigate(['/wegestu/jobs/details-job-request', { id: id }]);
  }
  // sortData(columnName: string) {
  //   let sort = { sort: this.currentSortDirection, sortAttribute: this.currentSortColumn }

  //   if (this.currentSortColumn === columnName) {
  //     this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1; // Toggle sort direction
  //   } else {
  //     this.currentSortColumn = columnName; // Change the sort column
  //     this.currentSortDirection = 1; // Reset sort direction to ascending
  //   }

  //   this.getAllDefaultJobs(sort)
  // }
  sortAttr: string = '';
  sortData(name?: string) {
    for (const iterator of this.headerColumns) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    if (this.searchForm.dirty)
      this.jobOffer = this.sortDataService.sortArray(
        this.jobOffer,
        name,
        this.currentSortDirection
      );
    else this.getAllDefaultJobs();
  }

  getAllSkills() {
    this.jobService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
          console.error('Error');
        },
      });
  }

  ngOnDestroy() {
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));

  }

// @TOFIX: duplicated  
/**
 * Prevents negative numbers or invalid characters in input fields.
 *
 * @param event - The input event triggered by the user.
 */
preventNegativeInput(event: Event): void {
  const inputElement = event.target as HTMLInputElement;
  
  if (inputElement.type === 'number') {
    const value = inputElement.value;
      inputElement.value = value.replace(/-/g, '');
  }

  if (inputElement.type === 'text') {
    console.log('text ',inputElement.value);
    
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '');
  }
}

}
