import { Component } from '@angular/core';
import { TutoService } from '../../services/tuto.service';
import { ToastrService } from 'ngx-toastr';
import { serverError } from '../../../../shared/shared-models/message';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from '../../../../../environnements/environment';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tuto',
  templateUrl: './tuto.component.html',
  styleUrls: ['./tuto.component.css'],
})
export class TutoComponent {
  listTuto: any[] = [];
  loadSpinner: boolean = false;
  loadSpinnerTable: boolean = false;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  /* pagination */
  totalItems: number = 10;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 10;

  showFullDescription = false;
  showFullText: any;
  maxLength = 40;
  safeUrl!: SafeResourceUrl;
  displayURL: any;

  /*videoLinks*/
  videoLinks: string[] = [];
  intervalId: any;
  newListTuto: any;
  currentVideoIndex = 0;
  isVideoPlaybackInProgress = false;
  getTutoState = false;
  howFullText: boolean[] = [];
  currentPlayingVideo: number | null = null;
  pagination : boolean =false;

  url: string = environment.baseUrl + '/api/Tuto/file/tutosFiles/';
  searchFormGroup: FormGroup = this.dataSearch();

  constructor(
    private tutoService: TutoService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.spinner.show();
    this.loadSpinnerTable = true;
    if (!this.getTutoState) {
      this.getListTuto(this.itemsPerPage, this.currentPage);
    }
  }

  getListTuto(per_page: number, page: number) {
    this.spinner.show();
    this.tutoService
      .getAllTuto(per_page, page)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('resss', res);
          if (res.status == 200) {
            this.getTutoState = true;

            this.totalItems = res.data.total;
            this.listTuto = res.data.data.slice(0, this.endIndex);

            this.showFullText = new Array(this.listTuto.length).fill(false);

            if (this.listTuto.length) {
              this.listTuto.forEach((element: any) => {
                this.videoLinks.push(element.link);
              });
            }

            this.listTuto = this.listTuto.map((item) => ({
              ...item,
              show_video: false,
            }));
          }
          this.spinner.hide();
          this.loadSpinnerTable = false;
        },
        error: (err: Error) => {
          this.toastrService.error(serverError);
          this.spinner.hide();
          this.loadSpinnerTable = false;
        },
      });
  }
  dataSearch(data?: any) {
    return this.formBuilder.group({
      search: [data ? data?.search : ""],
      type: [1],
    })
  }

  searchTuto() {
    this.spinner.show();
    let data = {
      type: this.searchFormGroup.value.type,
      page: this.currentPage,
      per_page: this.itemsPerPage,
      search: this.searchFormGroup.value?.search
    }

    this.tutoService.searchListTuto(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.isRecherche = true;
        if (!res.data.items.data.length && this.currentPage > 1) {
          this.currentPage = 1
          this.searchTuto()
        } else {
          console.log('response format:', res);
          this.listTuto = res.data.items.data.slice(0, this.endIndex)
          this.totalItems = res.data.items.data.total
          this.spinner.hide()
        }
      },
      error: () => {
        console.error('Error during tuto search');
        this.spinner.hide()
      }
    })
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.dataSearch();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListTuto(this.itemsPerPage, this.currentPage);
    }
    return (this.isRecherche = false);
  }

  showGlobalSpinner() {
    this.loadSpinner = true;
    this.spinner.show();
  }

  hideGlobalSpinner() {
    this.loadSpinner = false;
    this.spinner.hide();
  }

  /* get pagination items */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchFormGroup.dirty) {
        this.searchTuto();
      } 
      else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
        this.getListTuto(this.itemsPerPage, this.currentPage);
      }
    }
  }

  displayVideo(link: string, index: number) {
    const videoId = this.getVideoIdFromURL(link);
    // Create the embedded YouTube URL
    const displayURL = `https://www.youtube.com/embed/${videoId}`;
    this.listTuto[index].displayURL =
      this.sanitizer.bypassSecurityTrustResourceUrl(displayURL);
    this.isVideoPlaybackInProgress = false;
  }

  private getVideoIdFromURL(url: string): string {
    const match = url.match(/v=([a-zA-Z0-9_-]+)/);
    if (match && match[1]) {
      return match[1];
    }
    return '';
  }

  showVideo(link: any, index: any) {
    if (this.currentPlayingVideo !== null) {
      // Check if there's a currently playing video
      this.stopVideo(this.currentPlayingVideo);
    }

    const videoId = this.getVideoIdFromURL(link);
    const displayURL = `https://www.youtube.com/embed/${videoId}`;
    this.listTuto[index].displayURL =
      this.sanitizer.bypassSecurityTrustResourceUrl(displayURL);
    this.listTuto[index].show_video = true;

    this.currentPlayingVideo = index;
  }

  stopVideo(index: number) {
    // Check if the video at the specified index is playing and stop it
    if (this.listTuto[index].show_video) {
      this.listTuto[index].show_video = false;
      this.currentPlayingVideo = null;
    }
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
