import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject, finalize, forkJoin, of, takeUntil } from 'rxjs';
import { EvaluationsService } from '../../../../../../main/evaluation/services/evaluations.service';
import {
  serverError,
  successSignature,
} from '../../../../../../shared/shared-models/message';
import { LocalStorageService } from '../../../../../../shared/shared-services/local-storage.service';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pvs-evaluation-annual',
  templateUrl: './pvs-evaluation-annual.component.html',
  styleUrls: ['./pvs-evaluation-annual.component.css'],
})
export class PvsEvaluationAnnualComponent implements OnInit {
  // @ViewChild('signature')
  // public signaturePad!: SignaturePadComponent;
  @ViewChild('signature', { static: true })
  signaturePad!: SignaturePadComponent;

  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 1,
    canvasWidth: 300,
    canvasHeight: 250,
    backgroundColor: '#ffffff',
  };

  signed = [
    { id: 1, name: 'Non Signé' },
    { id: 3, name: 'Signé par l’ESN' },
    { id: 2, name: 'Signé par vous' },
  ];
  type = [
    { id: 1, name: 'Annuelle' },
    { id: 2, name: 'Suivi' },
  ];
  modalRef?: BsModalRef;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentUser: any;
  currentSortDirection: number = 2;
  id: any;
  listPv: any;
  totalPvs: any;
  currentSortColumn: string = 'name';
  searchForm: FormGroup = this.createSearchForm();
  private signatureDataUrl: string | null = null;

  private unsubscribeAll: Subject<void> = new Subject();
  signatureFile: any;
  formData = new FormData();
  company_name:any
  detailsPv: any;
  evaluation: any;
  evaluationDetais:any
  pagination : boolean =false;

  constructor(
    private localStorageService: LocalStorageService,
    private evaluationsService: EvaluationsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private datePipe: DatePipe,
    private primengConfig: PrimeNGConfig
  ) {}

  ngOnInit(): void {
    forkJoin([this.getIdUser(), this.getItems()]).subscribe(() => {
      this.getListPvs();
      this.getDetailsPv();
    });
    this.primengConfig.setTranslation({
      dayNames: ["Dimanche", "Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi"],
      dayNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
      dayNamesMin: ["D", "L", "M", "M", "J", "V", "S"],
      monthNames: ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"],
      monthNamesShort: ["Jan", "Fév", "Mar", "Avr", "Mai", "Juin", "Juil", "Aoû", "Sep", "Oct", "Nov", "Déc"],
      today: 'Aujourd\'hui',
      clear: 'Effacer'
    });
  }

  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.clear();
  }
  getDetailsPv() {
    
    let dataPayload = this.id;
    this.spinner.show();
    this.evaluationsService
      .getAllDetailsOfPv(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.detailsPv = res?.data;
          console.log("detailspv",this.detailsPv)
          // this.evaluation = res?.data?.evaluation_send?.response_evaluation;
          this.evaluationDetais =  res?.data?.evaluation_send?.evaluation.company.id;
          console.log("detailspv",this.evaluationDetais)
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching detailsPv:', error);
        }
      );
  }
  getIdUser(): Observable<void> {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    return of(undefined);
  }

  getItems(event?: any): Observable<void> {
    if (
      (event && event?.startIndex !== this.startIndex) ||
      this.endIndex !== event?.endIndex ||
      this.itemsPerPage !== event?.itemsPerPage ||
      this.currentPage !== event?.currentPage
    ) {
      this.startIndex = event?.startIndex;
      this.endIndex = event?.endIndex;
      this.itemsPerPage = event?.itemsPerPage;
      this.currentPage = event?.currentPage;
      if (this.searchForm.dirty) {
        this.searchPv();
      }      
       else if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true

        this.getListPvs();
      }
      return of(undefined);
    }
    return of(undefined);
  }
  getListPvs(sort?: any) {
    let dataPayload = !sort
      ? { per_page: this.itemsPerPage, page: this.currentPage, id: this.id }
      : {
          ...{
            per_page: this.itemsPerPage,
            page: this.currentPage,
            candidate_id: this.id,
          },
          ...sort,
        };

    this.spinner.show();

    this.evaluationsService.getAllListPvs(dataPayload).subscribe(
      (res: any) => {
        this.listPv = res.data.data;
        this.totalPvs = res.data.total;
        if (this.currentPage > 1 && !res.data.data.length) {
          this.currentPage = 1;
          this.getListPvs();
        }
        this.spinner.hide();
        console.log('data Pvs', this.listPv);
      },
      (error) => {
        console.error('Error fetching Pvs:', error);
      }
    );
  }

  createSearchForm(data?: any) {
    return this.formBuilder.group({
      signed: [data ? data.signed : null],
      evaluation_type: [data ? data?.evaluation_type : null],
      company_name: [data ? data.company_name : ''],
      start_date: [data ? data.start_date : ''],
      end_date: [data ? data.end_date : ''],
    });
  }

  searchPv() {
    let formattedEndDate = this.datePipe.transform(this.searchForm.value.end_date, 'yyyy-MM-dd');
    let formattedStartDate = this.datePipe.transform(this.searchForm.value.start_date, 'yyyy-MM-dd');
    let data = {
      per_page: this.itemsPerPage,
      page: this.currentPage,
      signed: this.searchForm.value.signed,
      evaluation_type: this.searchForm.value.evaluation_type,
      company_name: this.searchForm.value.company_name,
      start_date: formattedStartDate,
      end_date: formattedEndDate,
    };
    this.spinner.show();
    this.evaluationsService
      .searchListPv(this.id, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          console.log('response format:', res);
          this.listPv = res.data.data;
          this.spinner.hide();
        },
        error: () => {
          console.error('Error during pv search');
        },
      });
  }
  isRecherche: boolean = false;

  reset() {
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListPvs();
    }
    return (this.isRecherche = false);
  }
  sortData(columnName: string) {
    let sort = {
      sort: this.currentSortDirection,
      sortAttribute: this.currentSortColumn,
    };

    if (this.currentSortColumn === columnName) {
      this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
      this.currentPage = 1;
      this.endIndex = 5;
    } else {
      this.currentSortColumn = columnName;
      this.currentSortDirection = 1;
    }

    this.getListPvs(sort);
  }
  drawComplete() {
    console.log('Completed drawing', event);
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    console.log('Signature Data URL:', this.signatureDataUrl);
    const blob = this.dataURLToBlob(this.signatureDataUrl);
    this.signatureFile = new File([blob], 'signature.png');
    console.log('this.signatureFilethis.signatureFile', this.signatureFile);
  }

  downloadSignature() {
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    this.convertBase64ToImage(this.signatureDataUrl);
  }

  dataURLToBlob(dataURL: string): Blob {
    const arr = dataURL?.match(/:(.*?);/);
    if (arr && arr[1]) {
      const mime = arr[1];
      const bstr = atob(dataURL?.split(',')[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr?.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    } else {
      console.error('Failed to determine MIME type from data URL:', dataURL);
      return new Blob();
    }
  }
  convertBase64ToImage(base64Data: string) {
    const image = new Image();
    image.src = base64Data;

    const downloadLink = document.createElement('a');
    downloadLink.href = base64Data;
    downloadLink.download = 'signature.png';

    const container = document.getElementById('imageContainer');
    if (container) {
      container.innerHTML = '';
      container.appendChild(image);
      container.appendChild(downloadLink);
      downloadLink.click();
    }
  }
  drawStart(event: MouseEvent | Touch) {
    console.log('Start drawing', event);
  }
  clearSignature() {
    console.log('signaturePadsignaturePadsignaturePad', this.signaturePad);
    this.signaturePad?.clear();
  }

  sendSignature(pvId: any) {
    this.spinner.show();
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    console.log('Signature Data URL:', this.signatureDataUrl);
    const blob = this.dataURLToBlob(this.signatureDataUrl);
    this.signatureFile = new File([blob], 'signature.png');
    console.log('this.signatureFilethis.signatureFile', this.signatureFile);

    if (!this.signatureFile) {
      console.error('No signature file selected.');
      this.spinner.hide();
      return;
    }

    this.formData.append('signature_file_candidate', this.signatureFile);
    this.evaluationsService.addSignature(pvId,this.evaluationDetais,this.formData).subscribe(
      (response) => {
        console.log('Signature sent to the backend successfully', response);
        this.toastr.success(successSignature);
        this.spinner.hide();
        this.closeModal();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(serverError);
        console.error('Error sending signature to the backend', error);
      }
    );
  }

  sendSignatureTest() {
    this.spinner.show();
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    console.log('Signature Data URL:', this.signatureDataUrl);
    const blob = this.dataURLToBlob(this.signatureDataUrl);
    this.signatureFile = new File([blob], 'signature.png');
    console.log('this.signatureFilethis.signatureFile', this.signatureFile);

    if (!this.signatureFile) {
      console.error('No signature file selected.');
      this.spinner.hide();
      return;
    }

    this.formData.append('signature_file_candidate', this.signatureFile);
    let id = 2;
    this.evaluationsService.addSignature(id,this.company_name,this.formData).subscribe(
      (response) => {
        console.log('Signature sent to the backend successfully', response);
        this.toastr.success(successSignature);
        this.spinner.hide();
        this.closeModal();
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(serverError);
        console.error('Error sending signature to the backend', error);
      }
    );
  }

  openModalSigned(template: TemplateRef<any>, idPv: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    console.log('idPv', idPv);
    this.drawComplete();
    this.clearSignature();
  }
  openModalCompteRendu(template: TemplateRef<any>, idPv: any) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    console.log('idPv', idPv);
  }
  closeModal() {
    this.modalRef?.hide();
  }
  visualiserPv(id: any) {
    this.router.navigate(['/wegestu/evaluations/details-pv', { idPv: id }]);
  }

  addSigned(id: any) {
    this.router.navigate(['/wegestu/evaluations/signature-PV', { idPv: id }]);
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
