import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RateWegestuRoutingModule } from './rate-wegestu-routing.module';
import { RateWegestUComponent } from './components/rate-wegest-u/rate-wegest-u.component';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RatingModule } from 'ngx-bootstrap/rating';

@NgModule({
  declarations: [RateWegestUComponent],
  imports: [
    CommonModule,
    RateWegestuRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RatingModule.forRoot(),
    PaginationModule,
    NgxSpinnerModule,
  ],
})
export class RateWegestuModule {}
