import { ChangeDetectorRef, Component, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { ChatService } from '../../services/chat.service';
import { PusherService } from '../../services/pusher.service';
import * as moment from 'moment';
import 'moment-duration-format';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import {
  Subject,
  Subscription,
  catchError,
  debounceTime,
  distinctUntilChanged,
  switchMap,
} from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from '../../../../../environnements/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.css'],
})
export class ChatComponent {
  @ViewChildren('userLink') userLinks!: QueryList<ElementRef>;
  messages: any;
  messagesReciveds: any;
  newMessage: string = '';
  totalUsers: any;
  profil_image: any;
  last_name: any;
  first_name: any;
  dataEmployeeWithCompany: any;
  ListMessages: any;
  userIds: any;
  currentConversationUserId: number | null = null;
  currentUser: any;
  url: string = environment.baseUrl;
  profil_id: any;
  messagesSubscription!: Subscription;
  selectedUser: any;
  headerClass: any;
  user_id:any;
  name:any;
  searchInputSubject = new Subject<string>();
  searchInputSubjectUsers = new Subject<string>();
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private pusherService: PusherService,
    private chatService: ChatService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private changeDetectorRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.changeDetectorRef.detectChanges(); 
    this.getHeaderClass();
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {

        this.user_id = params.params['user'];
        this.name = params.params['name'];
        this.serachCandidat(this.name);
        this.goToUsersTab()
        
      },
    });
    this.getId()

    this.messagesSubscription = this.pusherService.messages$.subscribe(
      (message) => {
        console.log('message', message);
        this.messages = this.messages.concat(message);
        console.log('message2', this.messages);
        this.changeDetectorRef.detectChanges();
      }
    );

    this.chatService.getMessageSentObservable().subscribe(() => {
      this.loadMessagesEmitter(this.currentConversationUserId);
    });

    this.loadMessagesEmitter(this.currentConversationUserId);
    this.getUsers();
    this.getUser();
    this.searchInputSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchName) => {
          this.spinner.show();
          const data = {
            search: searchName.trim().toLowerCase(),
          };
          console.log(
            '%centreprises.component.ts line:203 data search',
            'color: #007acc;',
            data
          );
          return this.chatService.searchListMessage(data).pipe(
            catchError(() => {
              this.spinner.hide();
              return [];
            })
          );
        })
      )
      .subscribe((res: any) => {
        this.ListMessages = res.data;
        this.spinner.hide();
      });
    this.searchInputSubjectUsers
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((searchName) => {
          const data = {
            search: searchName.trim().toLowerCase(),
          };

          this.spinner.show();
          console.log(
            '%centreprises.component.ts line:203 data search',
            'color: #007acc;',
            data
          );
          return this.chatService.searchListUser(data).pipe(
            catchError(() => {
              this.spinner.hide();
              return [];
            })
          );
        })
      )
      .subscribe((res: any) => {
        this.dataEmployeeWithCompany = res.employeeWithCompany;
        this.spinner.hide();
      });
  }
  goToUsersTab() {
    const usersTab = document.querySelector('a[href="#Utilisateurs"]');
    if (usersTab) {
      (usersTab as HTMLElement).click();
    }
  }
  serachCandidat(name:any) {
    console.log('serachhhh user for ens',name);
    const data = {
      search: name?.trim().toLowerCase(),
    };
    this.chatService.searchListUser(data).pipe(
      
    ).subscribe((res: any) => {
  // this.users = res.candidat.concat(res.employeeWithCompany);
  // this.dataAdmin = res.admin;
  this.dataEmployeeWithCompany = res.employeeWithCompany;

  this.dataEmployeeWithCompany = res.candidat.filter((emp: any) => emp.id == this.user_id

  );

  this.getFirstLetter(this.dataEmployeeWithCompany[0])
  this.userSelected(this.dataEmployeeWithCompany[0])
  this.startConversation(this.dataEmployeeWithCompany[0]?.id)

  this.spinner.hide();
});

  }
  clickUserLink(userId: string) {
    const userLink = this.userLinks.find(link => link.nativeElement.getAttribute('href') === `#userLink_${userId}`);
    if (userLink) {
      userLink.nativeElement.click();
    }
  }
  getUser() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.first_name = JSON.parse(this.currentUser).first_name;
    this.last_name = JSON.parse(this.currentUser).last_name;
    this.profil_image = JSON.parse(this.currentUser).image_url;
    this.profil_id = JSON.parse(this.currentUser).id;
  }

  getFirstLetter(user: any): string {
    if (user?.first_name && user?.first_name?.length > 0) {
      return user?.first_name[0];
    } else {
      return '';
    }
  }
  loadMessagesEmitter(userId?: any) {
    this.spinner.show();
    this.chatService.getMessagesEmitter(userId).subscribe((messages) => {
      this.messages = messages?.data.reverse().map((message: any) => {
        message.elapsedTime = this.formatElapsedTime(message.created_at);
        return message;
      });
      this.spinner.hide();
    });
  }
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        const userString = params.params['user'];
      },
    });
  }
  sendMessage() {
    if (this.newMessage.trim() !== '') {
      this.chatService
        .sendMessage(this.currentConversationUserId, this.newMessage)
        .subscribe(() => {
          this.newMessage = '';
        });
    }
    console.log('chanel', this.pusherService.channel);
  }
  formatElapsedTime(created_at: string): string {
    const currentTime = moment();
    const messageTime = moment(created_at);
    const duration = moment.duration(currentTime.diff(messageTime));

    if (duration.asMinutes() <= 59) {
      const minutes = Math.floor(duration.asMinutes());
      if (minutes < 1) {
        return "à l'instant";
      } else if (minutes === 1) {
        return '1 minute';
      } else {
        return `${minutes} minutes`;
      }
    } else if (duration.asHours() <= 23) {
      const hours = Math.floor(duration.asHours());
      if (hours === 1) {
        return '1 heure';
      } else {
        return `${hours} heures`;
      }
    } else if (duration.asDays() <= 6) {
      const days = Math.floor(duration.asDays());
      if (days === 1) {
        return '1 jour';
      } else {
        return `${days} jours`;
      }
    } else {
      const weeks = Math.floor(duration.asDays() / 7);
      if (weeks === 1) {
        return '1 semaine';
      } else {
        return `${weeks} semaines`;
      }
    }
  }

  getUsers() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.profil_id = JSON.parse(this.currentUser).id;
    this.chatService.getListMessage().subscribe({
      next: (res) => {
        this.ListMessages = res.data;
        console.log('user messages ********** ', this.ListMessages);
      },
      error: (e) => {
        console.log('get messages error ===== ', e);
      },
    });
  }

  getHeaderClass(): void {
    this.headerClass = this.chatService.getHeaderClass();
    console.log('classeHeader', this.headerClass);
    this.currentConversationUserId = this.headerClass.id;
    this.startConversation(this.currentConversationUserId);
    this.loadMessagesEmitter(this.currentConversationUserId);
    this.getUser();
    this.userSelected(this.headerClass);
  }

  userSelected(user: any) {
    this.selectedUser = user;
    console.log('selectedUser', this.selectedUser.length);
    this.startConversation(user?.id);
    console.log('userIdsuserIdsuserIds22222', user?.id);
  }
  newUser: any;
  startConversation(user: any) {
    this.userIds = this.ListMessages?.map((user: any) => user.id);
    console.log('userIdsuserIdsuserIds', this.userIds);
    this.currentConversationUserId = user;
    this.loadMessagesEmitter(user);
  }

  getCurrentUser() {
    if (!this.ListMessages[0] || this.ListMessages[0].length === 0) {
      return null;
    }
    console.log('this.ListMessages', this.ListMessages[0]?.user);
    console.log('aaaaaaaaaaaaaaaaa', this.currentConversationUserId);
    const firstUserData = this.ListMessages[0];
    if (Array.isArray(firstUserData.user)) {
      const currentUser = firstUserData.user.find(
        (user: any) => user.id === this.currentConversationUserId
      );
      console.log('currentUser', currentUser);
      return currentUser;
    }
  }
  applyFilterUsers(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubjectUsers.next(filterValue);
  }
  // applyFilterUsers(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   let data = {
  //     search: filterValue.trim().toLowerCase(),

  //   }

  //   this.spinner.show()

  //   console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);

  //   this.chatService.searchListUser(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (res) => {

  //       this.dataEmployeeWithCompany = res.employeeWithCompany

  //       this.spinner.hide()
  //     },
  //     error: () => {
  //       this.spinner.hide()
  //     }
  //   })
  // }
  applyFilterMessage(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.searchInputSubject.next(filterValue);
  }
  // applyFilterMessage(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   let data = {
  //     search: filterValue.trim().toLowerCase(),

  //   }

  //   this.spinner.show()

  //   console.log('%centreprises.component.ts line:203 data search', 'color: #007acc;', data);

  //   this.chatService.searchListMessage(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (res) => {

  //       this.ListMessages = res.data;
  //       this.spinner.hide()
  //     },
  //     error: () => {
  //       this.spinner.hide()
  //     }
  //   })
  // }
  deleteConversation(receiverId: any): void {
    const confirmDelete = window.confirm(
      'Êtes-vous sûr de vouloir supprimer cette Conversation ?'
    );
    if (confirmDelete) {
      this.chatService.deleteConversation(receiverId).subscribe(
        (response) => {
          console.log('Conversation deleted successfully', response);
          this.messages = this.messages.filter(
            (message: any) => message.id === receiverId
          );
        },
        (error) => {
          console.error('Error deleting Conversation', error);
        }
      );
    }
  }
  onRightClick(event: MouseEvent) {
    event.preventDefault(); // Empêche le menu contextuel par défaut
  }
  ngOnDestroy() {
    this.pusherService.disconnect();
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    this.messagesSubscription.unsubscribe();
  }
}
