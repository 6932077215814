<div id="kt_app_header" class="app-header" data-kt-sticky="true" data-kt-sticky-activate="{default: true, lg: true}"
  data-kt-sticky-name="app-header-minimize" data-kt-sticky-offset="{default: '200px', lg: '0'}"
  data-kt-sticky-animation="false">
  <!--begin::Header container-->
  <div class="app-container container-fluid d-flex align-items-stretch justify-content-between"
    id="kt_app_header_container">
    <!--begin::Sidebar mobile toggle-->
    <div class="d-flex align-items-center d-lg-none ms-n3 me-1 me-md-2" title="Show sidebar menu">
      <div class="btn btn-icon btn-active-color-primary w-35px h-35px" id="kt_app_sidebar_mobile_toggle">
        <i class="ki-duotone ki-abstract-14 fs-2 fs-md-1">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </div>
    </div>
    <!--end::Sidebar mobile toggle-->
    <!--begin::Mobile logo-->
    <div class="logo d-flex align-items-center flex-grow-1 flex-lg-grow-0">
      <a href="/" class="d-none">
        <img alt="Logo" src="/assets/media/logos/logo-wegestu-white.png" class="h-30px" />
      </a>
    </div>
    <!--end::Mobile logo-->
    <!--begin::Header wrapper-->
    <div class="d-flex align-items-stretch justify-content-between flex-lg-grow-1" id="kt_app_header_wrapper">
      <!--begin::Menu wrapper-->
      <div class="app-header-menu app-header-mobile-drawer align-items-stretch" data-kt-drawer="true"
      data-kt-drawer-name="app-header-menu" data-kt-drawer-activate="{default: true, lg: false}"
      data-kt-drawer-overlay="true" data-kt-drawer-width="250px" data-kt-drawer-direction="end"
      data-kt-drawer-toggle="#kt_app_header_menu_toggle" data-kt-swapper="true"
      data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
      data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}">
      <!--begin::Menu-->
      <div
        class="left-menu menu menu-rounded menu-column menu-lg-row my-5 my-lg-0 align-items-stretch fw-semibold px-2 px-lg-0"
        id="kt_app_header_menu" data-kt-menu="true">
        <a id="kt_app_sidebar_toggle" href="#" class="menu-link app-sidebar-toggle mr-2" data-kt-toggle="true"
          data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-minimize">
          <svg width="21" height="16" viewBox="0 0 21 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0 14.7368C0 15.4358 0.58625 16 1.3125 16H15.3125C16.0387 16 16.625 15.4358 16.625 14.7368C16.625 14.0379 16.0387 13.4737 15.3125 13.4737H1.3125C0.58625 13.4737 0 14.0379 0 14.7368ZM15.3125 2.52632H1.3125C0.58625 2.52632 0 1.9621 0 1.26316C0 0.564211 0.58625 0 1.3125 0H15.3125C16.0387 0 16.625 0.564211 16.625 1.26316C16.625 1.9621 16.0387 2.52632 15.3125 2.52632ZM19.6875 9.26316H5.6875C4.96125 9.26316 4.375 8.69895 4.375 8C4.375 7.30105 4.96125 6.73684 5.6875 6.73684H19.6875C20.4137 6.73684 21 7.30105 21 8C21 8.69895 20.4137 9.26316 19.6875 9.26316Z"
              fill="white" />
          </svg>

        </a>
      </div>
      <!--end::Menu-->
    </div>
      <!--end::Menu wrapper-->
      <!--begin::Navbar-->
      <div class="app-navbar flex-shrink-0">
        <!--begin::Search-->

        <!--end::Search-->
        <!--begin::Activities-->
        <div class="app-navbar-item ms-1 ms-md-4">
          <!--begin::Menu- wrapper-->
          <div class="w-35px h-35px" data-kt-menu-trigger="{default: 'click', lg: 'click'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end" id="kt_menu_item_wow" style="cursor: pointer;">
            <svg width="20" height="28" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg" (click)="toggleDropdownmessage()">
              <path d="M10.4324 8.92397C9.98762 9.21962 9.47101 9.37589 8.93837 9.37589C8.40577 9.37589 7.88916 9.21962 7.44441 8.92397L0.119032 4.0544C0.078388 4.02737 0.0387855 3.99921 0 3.97025V11.9496C0 12.8644 0.744573 13.5905 1.64567 13.5905H16.231C17.1485 13.5905 17.8767 12.8481 17.8767 11.9496V3.97021C17.8378 3.99924 17.7982 4.02748 17.7574 4.05453L10.4324 8.92397Z" fill="white"/>
              <path d="M0.700057 3.18537L8.02543 8.05498C8.30273 8.23933 8.62053 8.33148 8.93834 8.33148C9.25617 8.33148 9.574 8.23928 9.8513 8.05498L17.1767 3.18537C17.615 2.89414 17.8767 2.40675 17.8767 1.88069C17.8767 0.976184 17.1387 0.240356 16.2316 0.240356H1.64512C0.738014 0.240392 0 0.97622 0 1.88157C-5.64528e-08 2.40675 0.261731 2.89414 0.700057 3.18537Z" fill="white"/>
              </svg>
              
          </div>

          <div *ngIf="unreadMessageCount > 0" class="message-icon">
            {{ unreadMessageCount }}
          </div>


          <!--begin::Menu-->
          <div class="menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px" [ngClass]="{'show': showDropdownmessage}" data-kt-menu="true" id="kt_menu_notifications"
          style="z-index: 107; position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate(-30px, 55px);">
            <!--begin::Heading-->
            <div class="msg-header d-flex flex-column bgi-no-repeat rounded-top">
              <!--begin::Title-->
              <h3 class="msg-title px-9 mt-10 mb-6">Messages
            </h3>
              <!--end::Title-->
              <!--begin::Tabs-->
              <!--end::Tabs-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->
            <div class="tab-content">
              <!--begin::Tab panel-->
              <div class="notif" id="kt_topbar_notifications_1" role="tabpanel" >
                <!--begin::Items-->
                <div *ngFor="let message  of messages?.slice(0,5)" >
                <div class="scroll-y mh-325px px-8 message" (click)="readMessage(message?.chats[0]?.id)">
                  <!--begin::Item-->
                  <div class="d-flex flex-stack py-3" (click)="setHeaderClass(message?.user)">
                    <!--begin::Section-->
                    <div class="d-flex align-items-center" >
                      <!--begin::Symbol-->
                      <div class="symbol symbol-35px me-4" >
                        <img *ngIf="!message?.user?.image_url" src="../../../assets/media/logos/logo-avatar.jpg" class="user-img" alt="user" />
                        <img *ngIf="message?.user?.image_url" src="{{base_url_img + message?.user?.image_url}}" class="user-img" alt="user" />
                      </div>
                      <!--end::Symbol-->
                      <!--begin::Title-->
                      <div class="mb-0 me-2">
                        <a  class="msg-user" >{{message?.user?.full_name}}</a>
                        <div class="text-gray-400">{{message?.chats[0]?.message}}</div>
                      </div>
                      <!--end::Title-->
                    </div>
                    <!--end::Section-->
                    <!--begin::Label-->
                    <span class="badge badge-light fs-8">1hr</span>
                    <!--end::Label-->
                  </div>
                  <!--end::Item-->
                </div>
               </div>
                <!--end::Items-->
                <!--begin::View more-->
                <div class="py-3 text-center border-top">
                  <a routerLink="/wegestu/chat" class="msg-see ">Voir tout
                 </a>
                </div>
                <!--end::View more-->
              </div>
              <!--end::Tab panel-->
              <!--begin::Tab panel-->
              
              <!--end::Tab panel-->
              <!--begin::Tab panel-->
            
              <!--end::Tab panel-->
            </div>
            <!--end::Tab content-->
          </div>
          <!--end::Menu-->
          <!--end::Menu wrapper-->
        </div>
        
        <!--end::Activities-->
       
        <!--begin::Notifications-->
        <div class="app-navbar-item ms-1 ms-md-4">
          <!--begin::Menu- wrapper-->
          <div class="w-35px h-35px"
          data-kt-menu-trigger="{default: 'click', lg: 'click'}" data-kt-menu-attach="parent"
          data-kt-menu-placement="bottom-end" id="kt_menu_item_wow" (click)="toggleDropdown()">

            
            <svg width="20" height="28" viewBox="0 0 24 28" fill="none" xmlns="http://www.w3.org/2000/svg" style="cursor: pointer;">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.75 3.2834C5.86824 4.28202 3 7.80437 3 11.9996V17.9991C3 18.5519 2.55087 19 2.00104 19C0.895898 19 0 19.8977 0 21C0 22.1046 0.897026 23 2.00495 23H21.9951C23.1024 23 24 22.1023 24 21C24 19.8954 23.0999 19 21.999 19C21.4472 19 21 18.5554 21 17.9991V11.9996C21 7.80603 18.1324 4.28236 14.25 3.28343V2.25043C14.25 1.00462 13.2426 0 12 0C10.7599 0 9.75 1.00755 9.75 2.25043V3.2834ZM8.5 24H15.5C15.5 25.933 13.933 27.5 12 27.5C10.067 27.5 8.5 25.933 8.5 24Z" fill="#fff"/>
              </svg>
          </div>
          <div *ngIf="unreadNotificationCount > 0" class="notification-icon">
            {{ unreadNotificationCount }}
          </div>
          <!--begin::Menu-->
          <div [ngClass]="{'menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px notif': true, 'show': showDropdown, 'hide': !showDropdown}"
          data-kt-menu="true"
          id="kt_menu_notifications" style="z-index: 107; position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate(-30px, 55px);" #notificationDropdown>
            <!--begin::Heading-->
            <div class="d-flex flex-column bgi-no-repeat rounded-top ">
              <!--begin::Title-->
              <div class="notif-header d-flex flex-column bgi-no-repeat rounded-top">
                <!--begin::Title-->
                <h3 class="px-9 mt-10 mb-6">Notifications({{unreadNotificationCount}})</h3>
                <!--end::Title-->
                <!--begin::Tabs-->
                <!--end::Tabs-->
              </div>
              <!--end::Title-->
              <!--begin::Tabs-->
             
              <ul class="">
                <div class="">
                  
                  <!--begin::Tab panel-->
                  <div class="notif">
                    <!--begin::Items-->
                    
                    <div class="scroll-y mh-325px">
                    <div class="one-notif"  *ngFor="let notification  of notifications?.slice(0,5); let i = index" [ngStyle]="{'background-color': !notification.read ? 'rgba(232, 246, 252, 0.53)' : ''}">
                      <div class="d-flex flex-stack py-3" >
                        <!--begin::Section-->
                        <div class="d-flex align-items-center blockNotification" (click)="notificationRedirection(notification,i)">
                          <!--begin::Symbol-->
                          <div class="notif-img symbol me-4">
                            <img *ngIf="notification?.sender?.image_url" src="{{base_url_img + notification?.sender?.image_url}}" class="user-img" alt="user" />
                            <img *ngIf="notification?.sender?.company?.logo" src="{{base_url_img_company + notification?.sender?.company?.logo}}" class="user-img" alt="user" />
                            <img  *ngIf="!notification?.sender?.image_url && !notification?.sender?.company?.logo" src="/assets/media/logos/logo-avatar.jpg" class="user-img" alt="user" />
                          </div>
                          <!--end::Symbol-->
                          <!--begin::Title-->
                          <div class="mb-0 me-2">
                            <div class="notif">
                              <div class="notif-username fw-bold" *ngIf="!notification?.sender?.company?.name && notification.state!='17'">{{notification?.sender?.first_name}}  {{notification?.sender?.last_name}} 
                              </div>
                              <div class="notif-username fw-bold" *ngIf="notification?.sender?.company?.name">{{notification?.sender?.company?.name}}
                              </div>
                              <div class="notif-username fw-bold" *ngIf="notification.state=='17'">Demande accepter ({{!notification.read ? "Non Lu":'Lu'}})
                              </div>
                              
                              <div class="notif-body"> {{notification?.message}} </div>
                              <div class="tools">
                                <td class="actions">
                                  <div class="tools">
                                  </div>
                                </td>
                              </div>
                            </div>
                            <div class="text-gray-400 notif-date"><i class="fa-regular fa-clock"></i> {{notification?.created_at |date : 'dd/MM/yyyy  HH:mm'}}</div>
                          </div>
                          <!--end::Title-->
                        </div>
                        <!--end::Section-->

                      </div>
                    </div>
                    <div  *ngIf="!notifications?.length" class="py-3 text-center border-top">
                      <a routerLink="/wegestu/notifications" class="btn ">liste de notifications est actuellement vide
                      </a>
                    </div>
                    </div>
                    <div *ngIf="notifications?.length" class="py-3 text-center border-top" (click)="viewAllNotifications()">
                      <a  class="notif-see"data-bs-dismiss="modal" aria-label="Close" >Voir Tout
                      </a>
                    </div>
                    <!--end::View more-->
                  </div>
                </div>
              </ul>
              <!--end::Tabs-->
            </div>
            <!--end::Heading-->
            <!--begin::Tab content-->

          </div>
          <!--end::Menu-->
          <!--end::Menu wrapper-->
        </div>
        <div class="d-column info-user">
          <div class="name">
            <span> {{first_name | titlecase}} {{last_name | titlecase}}</span>
          </div>
          <!-- <div class="role"  *ngIf="isConsultant()">
            <span>Consultant</span>
          </div>
          <div class="role"  *ngIf="isExternalConsultant()">
            <span>Consultant externe</span>
          </div>
          <div class="role" *ngIf="isCandidat()">
            <span>Candidat</span>
          </div> -->

        </div>
        <!--end::Notifications-->

        <!--begin::User menu-->
        <div class="app-navbar-item ms-1 ms-md-4" id="kt_header_user_menu_toggle">
          <!--begin::Menu wrapper-->
          <div class="symbol symbol-35px  " data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
            data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
            <img *ngIf="!user?.image_url?.includes('avatar')" src={{image_profile}} class="img-profile" alt="avatar" />
            <img *ngIf="user?.image_url && user?.image_url?.includes('avatar')" src="/assets/media/logos/logo-avatar.jpg" class="img-profile" alt="avatar" />
          </div>
          <!--end::Menu wrapper-->
        </div>
        <!--end::User menu-->
        <div class="dropdown-container app-navbar-item ms-1 ms-md-4">
          <div class="cursor-pointer symbol symbol-35px m-25" data-kt-menu-trigger="click" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
            <button class="dropdown-toggle-button" (click)="toggleDropdownProfil()">
              <i class="fa fa-chevron-down" [ngClass]="{'fa-rotate-180': isDropdownOpen}"></i>
            </button>
          </div>

          <!--begin::User account menu-->
          <div
          class="menu menu-sub menu-sub-dropdown menu-column menu-rounded  menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px drp-update"
          data-kt-menu="true" [ngClass]="{'show': showDropdownProfil}" style="z-index: 107; position: fixed; inset: 0px 0px auto auto; margin: 0px; transform: translate(-30px, 55px);">

          <div class="menu-item px-5 d-flex profile-drop-menu">
            <div class="menu-item d-flex profile-drop-menu">
              <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M7.49467 5.25H4.5C4.08578 5.25 3.75 5.58577 3.75 6C3.75 6.41423 4.08578 6.75 4.5 6.75H7.49467V11.25C7.49467 11.6642 7.1589 12 6.74467 12H0.75C0.335782 12 0 11.6642 0 11.25V0.75C0 0.33579 0.335782 0 0.75 0H6.74467C7.1589 0 7.49467 0.33579 7.49467 0.75V5.25ZM7.5 5.25H12.1307L10.1924 3.31028C9.89985 3.01754 9.89985 2.54312 10.1924 2.25038C10.4852 1.95734 10.9602 1.95734 11.2531 2.25038L15 6L11.2531 9.74963C10.9602 10.0427 10.4852 10.0427 10.1924 9.74963C9.89985 9.4569 9.89985 8.98245 10.1924 8.68972L12.1307 6.75H7.5V5.25Z"
                  fill="#BAB8B8" />
              </svg>
              </div>
              <div>
                <a routerLink="/wegestu/profil-candidat/modifier" class="profile-drop-menu px-5">Modifier mon dossier</a>
              </div>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-5 d-flex profile-drop-menu">
              <div class="mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                  <path
                    d="M11.6815 8.43177V5.87578C11.6815 4.8069 10.8008 3.92392 9.73462 3.92392H8.76116V3.04094C8.80752 1.55381 7.69499 0.252578 6.21162 0.0202146C4.63553 -0.165676 3.15216 0.949669 2.96674 2.57621C2.96674 2.71563 2.96674 2.80858 2.96674 2.94799V3.92392H1.94692C0.880752 3.92392 0 4.8069 0 5.87578V10.7554C0 11.8243 0.880752 12.7073 1.94692 12.7073H7.46321C7.69499 10.4301 9.45649 8.66414 11.6815 8.43177ZM8.25125 7.78116C8.52938 7.78116 8.71481 8.01352 8.66845 8.24588C8.6221 8.47825 8.43667 8.71061 8.2049 8.66414C7.97312 8.66414 7.7877 8.43177 7.7877 8.19941C7.74134 8.01352 7.97312 7.78116 8.25125 7.78116ZM3.38394 8.75708C3.10581 8.75708 2.87403 8.52472 2.87403 8.29236C2.87403 8.05999 3.10581 7.78116 3.33759 7.78116C3.56936 7.78116 3.84749 8.01352 3.84749 8.24588C3.84749 8.52472 3.66207 8.71061 3.4303 8.71061C3.4303 8.75708 3.38394 8.75708 3.38394 8.75708ZM3.89385 2.90152C3.89385 1.83265 4.7746 0.949669 5.84077 0.949669C5.97984 0.949669 6.11891 0.949668 6.25797 0.996141C7.18508 1.2285 7.83405 2.01854 7.7877 2.99447V3.92392H3.89385V2.90152ZM5.79442 8.75708C5.51629 8.75708 5.28451 8.52472 5.28451 8.29236C5.28451 8.05999 5.51629 7.78116 5.74806 7.78116C5.97984 7.78116 6.25797 8.01352 6.25797 8.24588C6.25797 8.52472 6.07255 8.71061 5.84077 8.75708C5.84077 8.75708 5.84077 8.75708 5.79442 8.75708Z"
                    fill="#BAB8B8" />
                  <path
                    d="M16.1779 10.9877L15.4362 11.2665C14.3701 9.45408 12.0523 8.84993 10.2444 9.91881C8.43658 10.9877 7.83396 13.3113 8.90014 15.1237C9.96631 16.9362 12.2841 17.5403 14.0919 16.4715C14.6482 16.1461 15.1118 15.6814 15.4362 15.1237C15.529 14.8914 15.3899 14.6125 15.1118 14.5196C14.9263 14.4731 14.6946 14.5196 14.5555 14.659C13.7674 16.0067 12.0059 16.4715 10.6616 15.6814C9.31734 14.8914 8.85378 13.1254 9.64182 11.7777C10.4299 10.43 12.145 9.96528 13.4893 10.7553C13.8602 10.9877 14.1846 11.2665 14.4164 11.6383L13.7211 11.8707C13.5357 11.9636 13.443 12.1495 13.4893 12.3354C13.5357 12.4283 13.582 12.5213 13.6747 12.5677L15.2972 13.3578C15.4826 13.4507 15.7144 13.3578 15.8071 13.1254L16.5488 11.4989C16.6415 11.313 16.5951 11.0806 16.4097 10.9877C16.4097 10.9412 16.2706 10.9412 16.1779 10.9877Z"
                    fill="#BAB8B8" />
                </svg>
              </div>
              <div>
                <a routerLink="/wegestu/profil-candidat/change-password" class="profile-drop-menu px-5">Changer mot de passe</a>
              </div>
            </div>
            <!--end::Menu item-->
            <!--begin::Menu item-->
            <div class="menu-item px-5 d-flex profile-drop-menu">
              <div class="mt-2">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M7.49467 5.25H4.5C4.08578 5.25 3.75 5.58577 3.75 6C3.75 6.41423 4.08578 6.75 4.5 6.75H7.49467V11.25C7.49467 11.6642 7.1589 12 6.74467 12H0.75C0.335782 12 0 11.6642 0 11.25V0.75C0 0.33579 0.335782 0 0.75 0H6.74467C7.1589 0 7.49467 0.33579 7.49467 0.75V5.25ZM7.5 5.25H12.1307L10.1924 3.31028C9.89985 3.01754 9.89985 2.54312 10.1924 2.25038C10.4852 1.95734 10.9602 1.95734 11.2531 2.25038L15 6L11.2531 9.74963C10.9602 10.0427 10.4852 10.0427 10.1924 9.74963C9.89985 9.4569 9.89985 8.98245 10.1924 8.68972L12.1307 6.75H7.5V5.25Z"
                    fill="#BAB8B8" />
                </svg>
              </div>
              <div>
                <a class="profile-drop-menu px-5 logout" (click)="showLogoutConfirmation()">Se Déconnecter</a>
              </div>
            </div>
            <!--end::Menu item-->
          </div>
          <!--end::User account menu-->
        </div>
        <!--begin::Header menu toggle-->
       
        <!--end::Header menu toggle-->
        <!--begin::Aside toggle-->
        <!--end::Header menu toggle-->
      </div>
      <!--end::Navbar-->
    </div>
    <!--end::Header wrapper-->
  </div>
  <!--end::Header container-->
</div>