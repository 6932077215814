import { Component, ViewChild } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CRegister } from '../../../shared/shared-models/login';
import { AuthentificationService } from '../../services/authentification.service';
import {
  emailExist,
  existPhoneNumber,
  serverError,
  successRegister,
} from '../../../shared/shared-models/message';
import { Router } from '@angular/router';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-telephone-input';
import {
  patternEmail,
  patternPassword,
  patternText,
} from '../../../shared/shared-models/pattern';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
})
export class RegisterComponent {
  /* formGroup */
  generateRegisterForm: FormGroup = this.registerForm(new CRegister({}));
  phoneInputTouched: boolean = false;
  private unsubscribeAll: Subject<void> = new Subject();
  // input phone
  separateDialCode: boolean = false;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  selectedCountryCode: string = 'FR';

  @ViewChild('phoneInput') phoneInput: any;
  countryCode: any;
  phoneNumber!: string;
  showPassword: string = 'password';
  showNewPassword: boolean = false;
  showOldPassword: boolean = false;
  phoneNumberIsValid: boolean = true;
  validPhoneNumber: boolean = false;
  submitted:boolean=false;
  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthentificationService,
    private toastrService: ToastrService,
    private router: Router
  ) {}
  onPhoneInputTouch(event: any) {
    this.phoneInputTouched = true;
  }
  validateNumberInput(event: KeyboardEvent) {
    const allowedKeys = [
      'Backspace',
      'ArrowLeft',
      'ArrowRight',
      'Delete',
      'Tab',
    ];
    if (
      allowedKeys.includes(event.key) ||
      (event.key >= '0' && event.key <= '9')
    ) {
      return;
    } else {
      event.preventDefault();
    }
  }
  ngOnInit() {
    this.selectedCountryCode = 'US';
    // get code country from navigator to use it in phone number input
    const that = this;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        const geocodeUrl = `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}`;

        fetch(geocodeUrl)
          .then((response) => response.json())
          .then((data) => {
            const countryCode = data.countryCode;
            that.countryCode = countryCode;
            console.log(`The country code is ${countryCode}`);
          })
          .catch((error) => console.error(error));
      });
    } else {
      console.error('Geolocation is not supported by this browser.');
    }
    //end  code country
  }

  term:boolean=false;

  terms(){
     
    let termCondition=this.generateRegisterForm.get("terme")?.value;

    console.log("okk term ",termCondition);
    this.term=termCondition;
   
  }
  /* create register form */
  registerForm(data: CRegister) {
    return this.formBuilder.group({
      first_name: [data.first_name, [Validators.required/* , Validators.minLength(3), Validators.pattern(patternText) */]],
      last_name: [data.last_name, [Validators.required/* , Validators.minLength(3), Validators.pattern(patternText) */]],
      phone_number: [data.phone_number || '' || null],
      email: [data.email, [Validators.required, Validators.email, Validators.pattern(patternEmail)]],
      password: [data.password, [Validators.required,Validators.pattern(patternPassword)]],
      confirmedPassword:[data.confirmedPassword, [Validators.required]],
      role_user_id:[["3"]],
      source: [data ? data.source: "Autre"],
      terme: [data.terme, [Validators.required]],
      validPhoneNumber: [this.validPhoneNumber]
    }, { validators: this.passwordsMatchValidator('password', 'confirmedPassword') })
  }
  passwordsMatchValidator(
    password: string,
    confirmedPassword: string
  ): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formGroup = control as FormGroup;
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmedPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (
        confirmPasswordControl.errors &&
        !confirmPasswordControl.errors['passwordMismatch']
      ) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }

      return null;
    };
  }

  togglePasswordVisibility(field: string) {
    if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'oldPassword') {
      this.showOldPassword = !this.showOldPassword;
    }
  }

  /* signUp candidat */
  signUp() {
    console.log('generateRegisterForm', this.generateRegisterForm.value);
    this.submitted=true;
    if (this.generateRegisterForm.invalid) {

      return ;
    }


    if (this.generateRegisterForm.valid) {
      this.authService.register(this.generateRegisterForm.value).pipe().subscribe({
        next: (res) => {
          console.log(res.status == 200, 'signUp candidat');
          if (res.status == 200) {
            console.log('%cregister.component.ts line:108 User regitred', 'color: #007acc;', res);
            this.toastrService.success(successRegister)
            this.router.navigate(['/auth/code-verification', { email: this.generateRegisterForm.value.email }]);
          }
        },
        error: (err) => {
          console.log("error",err)
          if (err.error?.message['email']){
            this.toastrService.error("l'adresse email est déjà associée à un autre utilisateur.");
          } else if (err.error?.message['phone_number']) {
            this.toastrService.error(existPhoneNumber);
          }
           else {
            this.toastrService.error(serverError);
          }
        }
      })
    }
  }

  resendCodeVerification(data: any) {
    this.router.navigateByUrl('/auth/code-verification');
  }

  changePreferredCountries() {
    this.preferredCountries = [CountryISO.India, CountryISO.Canada];
  }

  onInputChange(data: any) {
    const phoneNumber = data.phoneNumber;
    this.validPhoneNumber = data.isNumberValid;
    this.generateRegisterForm.get('phone_number')?.setValue(phoneNumber);
  }

  /* unsubscibe */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
