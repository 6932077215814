import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, Subscription, takeUntil } from 'rxjs';
import { NotificationService } from '../../main/notifications/services/notification/notification.service';
import { environment } from '../../../environnements/environment';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css'],
})
export class NotificationsComponent {

  notifications:any[]=[]
  unreadNotificationCount: number = 0;
  base_url_img = environment.baseUrlGalery
  base_url_img_company = environment.baseUrlGaleryCompany
  itemsPerPage: number = 5
  currentPage: number = 1
  startIndex: number = 0
  endIndex: number = 5
  totalItems:any
  notificationReaded: boolean = false;
  pagination : boolean =false;

  notificationsSubscription!:Subscription
  private unsubscribeAll: Subject<void> = new Subject();

  
  constructor(private notificationService: NotificationService,
              private changeDetectorRef: ChangeDetectorRef,
              private spinner: NgxSpinnerService,
              private router:Router){}

  ngOnInit() {
    this.notificationsSubscription =
      this.notificationService.messages$.subscribe((notification) => {
        console.log('notification', notification);
        this.notifications = this.notifications.concat(notification);
        console.log('notifications2', this.notifications);
        this.changeDetectorRef.detectChanges();
      });
    this.getNotification();
  }
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex
      this.endIndex = event.endIndex
      this.itemsPerPage = event.itemsPerPage,
        this.currentPage = event.currentPage
       if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
        this.getNotification()
        }
    }
  }
  readNotification(id:any, index:number){
    this.spinner.show();
    let data = {"id":id}
    this.notificationService.readNotification(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.getNotification()
        this.notifications[index].read = true;
      },
      error: (err: any) => {
        this.spinner.hide();
        this.notificationReaded = false
      }
    });
  }

  notificationRedirection(notification:any,index:number){

    console.log("notification ",notification);

    
    this.readNotification(notification.id,index)
    if(notification.state=='4'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }else if (notification.state=='5'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
    else if (notification.state=='6'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
    else if (notification.state=='7'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
    else if (notification.state=='8'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
    else if (notification.state=='9'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
    else if (notification.state=='11'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
    else if (notification.state=='12'){
      this.router.navigate(['/wegestu/pointage/cra']);
    }
    else if (notification.state=='14'){
      this.router.navigate(['/wegestu/evaluations/list-evaluation']);
    }
    else if (notification.state=='15'){
      this.router.navigate(['/wegestu/evaluations/pvs-evaluation']);
    }
    else if (notification.state=='17'){
      this.router.navigate(['/wegestu/qcm']);
    }
    else if (notification.state=='18'){
      this.router.navigate(['/wegestu/pointage/timeSheet']);
    }
    else if (notification.state=='19'){
      this.router.navigate(['/wegestu/pointage/cra']);
    }
    else if (notification.state=='25'){
      this.router.navigate(['/wegestu/profil-candidat']);
    }
  }
  
  getNotification() {
    this.spinner.show();
    this.notificationService.getListOfNotification(this.itemsPerPage, this.currentPage).subscribe({
      next: (res) => {
        this.spinner.hide();
        console.log('API response:', res);

        // Check if res.data.data is an array
        if (res.data && Array.isArray(res.data.data.data)) {
          this.notifications = res.data.data.data;
        this.totalItems = res.data.data.total;

        } else {
          console.error('Expected array but got:', res.data.data.data);
          this.notifications = [];
        }

        console.log('User notifications:', this.notifications);

        if (Array.isArray(this.notifications)) {
          this.unreadNotificationCount = this.notifications.filter(
            (notification: any) => notification.read === 0
          ).length;
        } else {
          console.error(
            'this.notifications is not an array',
            this.notifications
          );
          this.unreadNotificationCount = 0;
        }
      },
      error: (e) => {
        this.spinner.hide();
        console.log('Get notification error:', e);
      },
    });
  }
}
