import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class RateService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get all evaluations */
  getListEvaluations() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/EvaluateWegestu/getBytype/1`
    );
  }

  /* add evaluation wegestu */
  addEvaluationWegestu(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseEvaluateWegestu/updateByCandidat`,
      data
    );
  }
  updateEvaluation(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseEvaluateWegestu/updateByCandidat`,
      data
    );
  }

  /* get evaluation by id candidat */
  getEvaluationWegestuByIdCandidate(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseEvaluateWegestu/getResponsesEvaluate?id=${id}&type=1`
    );
  }

  /* get reponse  by id  */
  getResponseById(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/ResponseEvaluateWegestu/getById/` + id
    );
  }

  /* get list candidat rating  wegestu*/
  getListCandidatReview(
    idCompany: number,
    data?: { per_page?: number; page?: number }
  ) {
    console.log(
      'url',
      this._base +
        `/ResponseEvaluateWegestu/getUsersAvisWegestu?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`
    );
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseEvaluateWegestu/getUsersAvisWegestu?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`
    );
  }

  /* get list of question wegestu*/
  getListQuestion(
    idCompany: number,
    data?: { per_page?: number; page?: number }
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsers?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`
    );
  }


  getListQuestioncompany(idCompany:number,data?:{per_page?:number,page?:number}){
    return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsersByCompany?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
  }
  
  getListQuestioncandidat(idCompany:number,data?:{per_page?:number,page?:number}){
    return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getResponsesEvaluateWithStarsWithUsersByCandidat?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
  }


  /* get candidat rating  wegestu by id*/
  getCandidatReviewById(
    idCompany: number,
    idUser: number,
    data?: { per_page?: number; page?: number }
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseEvaluateWegestu/getUsersAvisWegestuWithResponses?type=${idCompany}&user_id=${idUser}&per_page=${data?.per_page}&page=${data?.page}`
    );
  }

  getListusersReviewCandidat(idCompany:number,data?:{per_page?:number,page?:number}){
    return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getUsersAvisWegestuByCandidate?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
  }
  
  getListusersReviewCompany(idCompany:number,data?:{per_page?:number,page?:number}){
    return this._http.get<ApiResponse<any>>(this._base+`/ResponseEvaluateWegestu/getUsersAvisWegestuByCompany?type=${idCompany}&per_page=${data?.per_page}&page=${data?.page}`);
  }
  

}
