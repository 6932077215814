<head>
    <title>Reset-mail-interface Wegestu</title>
    <meta name="description" content="Reset-mail-interface Wegestu">
</head>
<div class="d-flex flex-column flex-root" id="kt_app_root">
    <!--begin::Authentication - Reset password -->
    <div class="reset-Password">
        <div class="d-flex flex-column flex-center flex-column-fluid">
            <!--begin::Content-->
            <div class="d-flex flex-column flex-center p-10">
                <!--begin::Wrapper-->
                <div class="card-warp w-md-650px py-5">
                    <div class="content py-15 py-lg-20">
                        <!--begin::Logo-->
                        <div class="mb-15 text-center">
                            <a href="/" class="">
                                <img alt="Logo" src="/assets/media/logos/logo-wegestu-color.png" class="h-40px" />
                            </a>
                        </div>
                        <!--end::Logo-->

                        <!--begin::Illustration-->
                        <div class="mb-10">
                            <!--begin::Label-->
                            <div class="text-center code-title fs-6 mb-10 ms-1">
                                Saisir votre email de réinitialisation
                            </div>
                            <!--end::Label-->
                            <!--begin::Input group-->
                            <div class="fv-row mb-8" (keyup.enter)="sendMail()">
                                <!--begin::Password-->
                                <label for="mail" class="label-mail mb-5">E-mail</label>
                                <div class="input-group mb-5">
                                    <span class="input-group-text" id="basic-addon1">
                                        <svg width="22" height="15" viewBox="0 0 22 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M2.22768 0H19.773C20.3839 0 20.941 0.249676 21.3459 0.653107C21.7501 1.05654 22 1.6109 22 2.21958V11.9788C22 12.5896 21.7494 13.1447 21.3459 13.5467L21.3247 13.5664C20.9219 13.9572 20.374 14.1984 19.7723 14.1984H2.22697C1.61537 14.1984 1.05827 13.9487 0.654783 13.546C0.250587 13.1447 0 12.5896 0 11.9788V2.21958C0 1.6109 0.250587 1.05583 0.655491 0.652402C1.06039 0.249676 1.61678 0.00070545 2.22768 0.00070545V0ZM2.67718 1.87751L2.29847 2.47207L11.0004 7.98681L19.7015 2.47207L19.3228 1.87751L10.9996 7.15174L2.67647 1.87751H2.67718Z"
                                                fill="#9AB4CC" />
                                        </svg>
                                    </span>
                                    <input type="text" [formControl]="emailControl" class="form-control"
                                        placeholder="E-mail" aria-label="mail"
                                        aria-describedby="basic-addon1" />
                                </div>

                                <app-feild-error [control]="emailControl" [controlName]="'email'"></app-feild-error>
                                <!--end::Password-->
                            </div>
                            <!--begin::Input group-->
                        </div>
                        <!--end::Section-->
                        <!--begin::Submit-->
                        <div class="d-flex flex-center">
                            <button [disabled]="!this.emailControl.valid" (click)="sendMail()" type="button"
                                id="send_email" class="btn btn-lg w-100 pt-5 pb-5">
                                <span class="indicator-label">Envoyer</span>
                                <span class="indicator-progress">Please wait...
                                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                            </button>
                        </div>
                        <!--end::Submit-->
                        <!--start::Link-->
                        <div class="redirect-login mt-5 d-flex justify-content-center">
                            <label class="form-check-label" for="flexCheckDefault">
                                Se souvenir du mot de passe?
                            </label>
                            <a href="auth/login" class="link-primary">&nbsp; Se connecter </a>
                        </div>
                        <!--end::Link-->
                    </div>
                </div>
                <!--end::Wrapper-->
            </div>
            <!--end::Content-->
        </div>
    </div>
    <!--end::Authentication -Reset password-->
</div>
<script src="./assets/js/custom/authentication/sign-in/two-factor.js"></script>