import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { serverError } from '../../../../shared/shared-models/message';
import { FaqService } from '../../services/faq.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

enum PaginationConstants {
  TotalItems = 10,
  ItemsPerPage = 5,
}

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
})
export class FaqComponent implements OnInit, OnDestroy {
  totalItems: number = PaginationConstants.TotalItems;
  itemsPerPage: number = PaginationConstants.ItemsPerPage;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = PaginationConstants.TotalItems;
  private unsubscribeAll: Subject<void> = new Subject();
  listFaq: any;
  loadSpinner: boolean = false;
  pagination : boolean =false;
  loadSpinnerTable: boolean = false;
  searchFormGroup: FormGroup = this.dataSearch();
  offset: number = 0;

  constructor(
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private faqService: FaqService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.getListFaq(this.itemsPerPage, this.currentPage);
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  getListFaq(itemsPerPage: any, currentPage: any) {
    this.spinner.show();
    this.loadSpinnerTable = true;

    this.faqService.getAllFaq(itemsPerPage, currentPage).subscribe({
      next: (res) => {
        console.log('resss', res);
        if (res.status === 200) {
          this.totalItems = res.data.total;
          this.listFaq = res.data.data;
          this.offset = (this.currentPage - 1) * this.itemsPerPage;

          if (this.currentPage > 1 && !res.data.data.length) {
            this.currentPage = 1;
            this.getListFaq(itemsPerPage, currentPage);
          }
        }
        this.spinner.hide();
        this.loadSpinnerTable = false;
      },
      error: (err: Error) => {
        this.toastrService.error(serverError);
        this.spinner.hide();
        this.loadSpinnerTable = false;
      },
    });
  }

  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      this.itemsPerPage = event.itemsPerPage;
      this.currentPage = event.currentPage;
      console.log(
        '%ctuto.component.ts line:79 getItems++++',
        'color: #007acc;',
        this.startIndex,
        this.endIndex,
        this.itemsPerPage,
        this.currentPage
      );
     if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
      this.getListFaq(this.itemsPerPage, this.currentPage);
      }
    }
  }

  dataSearch(data?: any): FormGroup {
    return this.formBuilder.group({
      // question: [data ? data?.question : ''],
      // response: [data ? data?.response : ''],
      search: [data ? data?.search : ''],
    });
  }

  searchFAQ() {
    let data = {
      page: this.currentPage,
      per_page: this.itemsPerPage,
      type: 1,
      // question: this.searchFormGroup.value.question,
      // response: this.searchFormGroup.value.response,
      search: this.searchFormGroup.value.search,
    };
    this.spinner.show();
    this.faqService
      .searchListFAQ(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          if (!res.data.items?.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.searchFAQ();
            this.spinner.hide();
          } else {
            console.log('response format:', res);
            this.listFaq = res.data?.data.slice(0, this.endIndex);
            this.totalItems = res.data?.total;
            this.spinner.hide();
          }
        },
        error: () => {
          console.error('Error during FAQ search');
          this.spinner.hide();
        },
      });
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchFormGroup.reset();
    this.searchFormGroup = this.dataSearch();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListFaq(this.itemsPerPage, this.currentPage);
    }
    return (this.isRecherche = false);
  }
}
