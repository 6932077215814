import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CCandidate } from '../../../../authentification/models/user';
import { ContactService } from '../../services/contact.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import {
  emailSended,
  serverError,
} from '../../../../shared/shared-models/message';
import { Router } from '@angular/router';
import { Subject, finalize, takeUntil } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { CandidateService } from '../../../../main/dossier/services/candidate.service';

@Component({
  selector: 'app-contact-esn',
  templateUrl: './contact-esn.component.html',
  styleUrls: ['./contact-esn.component.css'],
})
export class ContactEsnComponent {
  contactEsnForm!: FormGroup;
  currentUser: any;
  id: any;
  filtered: any;
  submitted = false;

  types = [
    { value: 'Technique', id: '1' },
    { value: 'Service client', id: '2' },
    { value: 'Réclamation', id: '3' },
  ];

  selected: any = this.types[0];
  file: any;
  idResponsable: any;
  company_id: any;
  esn_id: any;
  listContactNames: any;
  idUser: any;
  selectedCandidate: any;
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private contactService: ContactService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private candidatService: CandidateService
  ) {
    this.currentUser = new CCandidate();
  }
  toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }], // outdent/indent // outdent/indent
    ['clean'], // remove formatting button
  ];
  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    // this.company_id = JSON.parse(this.currentUser).company_id

    if (this.company_id != null) {
      this.esn_id = this.company_id;
    } else {
      this.esn_id = 86;
    }
    // this.getId()
    this.contactEsnFormGroup();
    this.getCandidateById();
  }

  getCandidateById() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idUser = JSON.parse(this.currentUser).id;
    this.spinner.show();
    this.candidatService
      .getCandidate(this.idUser)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];
          this.company_id = this.selectedCandidate?.ESN_id;
          this.getContactNames(this.company_id);
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  getContactNames(id_company: any) {
    this.spinner.show();
    this.contactService
      .getContactNamesByCandidat(id_company)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listContactNames = res?.data;
        },
        (error) => {
        }
      );
  }
  contactEsnFormGroup(data?: any) {
    this.contactEsnForm = this.formBuilder.group({
      mailing_id: [data ? data.mailing_id : null, Validators.required],
      object: ['', [Validators.required, Validators.maxLength(100)]],
      subject: ['', Validators.required],
      user_id: [''],
      esn_id: this.esn_id,
      file: [''],
    });
  }

  onFileChange(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      this.file = file;
      this.fileName = this.file.name;
      this.contactEsnForm.get('file')?.setValue(this.file);
    }
  }
  fileName: string = '';
  resetFile() {
    const inputFile = document.getElementById('cv_file') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.file = null;
    this.fileName = '';
  }
  contactEsn() {
    this.submitted = true;
    if (this.contactEsnForm.invalid) {
      return;
    } else {
      this.contactEsnForm.get('user_id')?.setValue(this.id);

      const formData = new FormData();
      formData.append(
        'mailing_id',
        this.contactEsnForm.get('mailing_id')?.value
      );
      formData.append('object', this.contactEsnForm.get('object')?.value);
      formData.append('subject', this.contactEsnForm.get('subject')?.value);
      formData.append('user_id', this.id);
      formData.append('esn_id', this.contactEsnForm.get('esn_id')?.value);
      formData.append('file', this.contactEsnForm.get('file')?.value);

      const formDataObject: { [key: string]: any } = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      this.contactService
        .contactEsn(formData)
        .pipe()
        .subscribe({
          next: (res) => {
            this.toastrService.success(emailSended);
            this.router.navigateByUrl('/wegestu/profil-candidat');
          },
          error: (err) => {
            if (err?.error) {
              this.toastrService.error(serverError);
            }
          },
        });
    }
  }

  get f() {
    return this.contactEsnForm.controls;
  }

  onSelectChange(event: any) {
    console.log('Selected:', event);
  }

  onReset() {
    this.submitted = false;
    this.contactEsnForm.reset({
      esn_id: this.esn_id,
      user_id: this.id,
    });
    this.contactEsnForm.get('file')?.setValue('');
  }
}
