import { Component } from '@angular/core';
import { ConsultantService } from '../../services/consultant.service';
import { Subject, takeUntil } from 'rxjs';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../../../environnements/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import { listContracts } from '../../../../main/dossier/models/candidate';

@Component({
  selector: 'app-details-consultant',
  templateUrl: './details-consultant.component.html',
  styleUrls: ['./details-consultant.component.css'],
})
export class DetailsConsultantComponent {

  id: any
  consultant_info:any=[]
  currentUser: any
  url: string = environment.baseUrl
  linkedinUrl: boolean = false
  show_cv: boolean = false
  show_test: boolean = false
  pagination : boolean =false;

  image_url!: string
  cv_file:any
  skillsCandidate: any
  cv: string | null = null;
  contratRequest = listContracts;

  /* pagination */
  totalItems: number = 10; // until i get it from back-end
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  base_url_img = environment.baseUrlGalery;
  base_url_cv = environment.baseUrlGaleryCv;

  /* colors skills */
  colors = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];

  /* spinner */
  loadSpinner: boolean = false;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  availabilityOptions = [
    { id: 'availability_1', label: 'ASAP', value: '1' },
    { id: 'availability_2', label: 'Sous 1 mois', value: '2' },
    { id: 'availability_3', label: '2 mois', value: '3' },
    { id: 'availability_4', label: '3 mois', value: '4' },
    { id: 'availability_5', label: '4 mois', value: '5' },
  ];
  language = [
    { id: '1', label: 'Moyen' },
    { id: '2', label: 'Bon' },
    { id: '3', label: 'Excelent' },
  ];
  idcandidat:any
  constructor(private localStorageService: LocalStorageService,
              private consultantService: ConsultantService,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService){}

  ngOnInit() {
    this.route.params.subscribe(params => {
      let candidateId:any = +params['id']; 
      this.getConsultantById(candidateId);
      this.getSkillsCandidate(candidateId)
      this.idcandidat=candidateId
    });
   
   window.scrollTo(0,0)

  }
  

  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      this.itemsPerPage = event.itemsPerPage;
      this.currentPage = event.currentPage;
      if (this.endIndex > this.totalItems) {
        this.endIndex = this.totalItems;
      }
     if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
        this.pagination = true
      this.getSkillsCandidate(this.idcandidat);
      }
    }
  }
    /* get Consultant  details */
    getConsultantById(candidateId: number) {
      this.consultantService.getCandidate(candidateId).pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (res) => {
          this.consultant_info = res.data[0]
          console.log('Consultant_info', 'color: #007acc;',this. consultant_info);
  
          this.image_url = this.base_url_img + this.consultant_info.image_url
          this.cv_file = this.base_url_cv + this.consultant_info.cv
          console.log('linkedin_link', 'color: #007acc;', this.consultant_info.linkedin_link);
          this.consultant_info.desired_contract = this.consultant_info.desired_contract || [];
          if (Array.isArray(this.consultant_info.desired_contract)) {
            this.consultant_info.desired_contract =
              this.consultant_info.desired_contract.map((contractId: any) => {
                return this.contratRequest[contractId - 1]?.label || '';
              });
          } else {
            // Handle the case where this.consultant_info.desired_contract is not an array
            console.error(
              'this.consultant_info.desired_contract is not an array'
            );
          }
          if (this.consultant_info.linkedin_link !== null) {
            this.linkedinUrl = true;
          }

          console.log('linkedin_link', 'color: #007acc;', this.linkedinUrl);

          // this.cv = this.Candidate_info.cv
          if (this.consultant_info.cv !== null) {
            this.show_cv = true;
          } else {
            this.show_cv = false;
          }

          if (this.consultant_info.score !== null) {
            this.show_test = true;

            /* format value score*/
            const originalValue = this.consultant_info.score;

            const hasDecimalValues = originalValue % 1 !== 0;

            this.consultant_info.score = hasDecimalValues
              ? originalValue.toFixed(2)
              : originalValue.toString();

            console.log(this.consultant_info.score);
          } else {
            this.show_test = false;
          }

          /* availability */
          // if (this.consultant_info.availability != null) {
          //   const availability = this.availabilityOptions.find(option => option.value === this.consultant_info.availability);
          //   this.consultant_info.availability = availability?.label
          // }
  
          /* anglais */
          let anglais: any = this.language.find(
            (option) => option.id === this.consultant_info.english_level
          );
          this.consultant_info.english_level = anglais?.label;
          /* francais */
          let francais: any = this.language.find(
            (option) => option.id === this.consultant_info.frensh_level
          );
          this.consultant_info.frensh_level = francais?.label;

          this.consultant_info.current_country =
            !this.consultant_info.current_country ||
            this.consultant_info.current_country == 'null'
              ? ''
              : this.consultant_info.current_country;
          this.consultant_info.linkedin_link =
            !this.consultant_info.linkedin_link ||
            this.consultant_info.current_country == 'null'
              ? ''
              : this.consultant_info.linkedin_link;

          this.consultant_info.date_first_experience =
            !this.consultant_info.date_first_experience ||
            this.consultant_info.date_first_experience == 'null'
              ? ''
              : this.consultant_info.date_first_experience;

          this.consultant_info.desired_salary =
            !this.consultant_info.desired_salary ||
            this.consultant_info.desired_salary == 'null'
              ? ''
              : this.consultant_info.desired_salary;

          this.parseData('certificates');
          this.parseData('desired_contract');
          this.parseData('diplomas');
          this.parseData('mobility');

          this.spinner.hide();
          this.loadSpinner = false;
        },
        error: (err: any) => {
          console.log('error', err);
         
        }
      })
    }
    capitalizeFirstLetter(string:any) {
      if (!string) return string;
      return string.charAt(0).toUpperCase() + string.slice(1);
    } 
    isLastPagee: boolean = false;
    hasPagination: boolean = false;
    getSkillsCandidate(idCandidat:any) {
      this.consultantService.getSkillsbyIdCandidate(idCandidat).pipe(takeUntil(this.unsubscribeAll)).subscribe({
        next: (res) => {
          this.skillsCandidate = res.data.categoriesWithSubCategoriesAndSkills.slice(this.startIndex, this.endIndex);
          const skillsTextLength = this.consultant_info?.skills_text?.length ? 1 : 0;
          this.totalItems = res.data.count + skillsTextLength;
          this.hasPagination = this.totalItems > this.itemsPerPage;
          this.isLastPagee = (this.endIndex >= this.totalItems);
        },
        error: (err) => {
          console.log('error', err);
        }
      });
    }
    parseData(name: string) {
      console.log('name', name)
      if (typeof this.consultant_info[name] == "string" && this.consultant_info[name] == 'null') {
        this.consultant_info[name] = []
      } else {
        if ((this.consultant_info[name]) || (this.consultant_info[name].length)) {
          this.consultant_info[name] = JSON.parse(this.consultant_info[name])
        }
      }
    }
    // parseData(name: string) {
  //   console.log('name', name);

  //   if (
  //     typeof this.consultant_info[name] === "string" &&
  //     (this.consultant_info[name] === 'null' || this.consultant_info[name].trim() === '')
  //   ) {
  //     this.consultant_info[name] = [];
  //   } else {
  //     try {
  //       if (this.consultant_info[name]) {
  //         this.consultant_info[name] = JSON.parse(this.consultant_info[name]);
  //       }
  //     } catch (error) {
  //       console.error('Error parsing JSON:', error);
  //       // Handle the error or log it appropriately
  //     }
  //   }
  // }

  openPDF(): void {
    this.consultantService.downloadPDF(this.cv_file).subscribe((blob: any) => {
      const fileURL = URL.createObjectURL(blob);
      window.open(fileURL, '_blank');
    });
  }

  downloadCV() {
    const link = document.createElement('a');
    link.href = this.cv_file;
    // link.target = '_blank';
    link.download = 'downloaded_cv.pdf';
    link.click();
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
