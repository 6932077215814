import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EvaluationRoutingModule } from './evaluation-routing.module';
import { SemesterEvaluationComponent } from './components/semester-evaluation/semester-evaluation.component';
import { SocietyEvaluationComponent } from './components/society-evaluation/society-evaluation.component';
import { ListEvaluationAnnualComponent } from './components/annual-evaluation/components/list-evaluation-annual/list-evaluation-annual.component';
import { PvsEvaluationAnnualComponent } from './components/annual-evaluation/components/pvs-evaluation-annual/pvs-evaluation-annual.component';
import { AnnualEvaluationComponent } from './components/annual-evaluation/annual-evaluation.component';
import { ListSemesterEvaluationComponent } from './components/semester-evaluation/components/list-semester-evaluation/list-semester-evaluation.component';
import { PvsSemesterEvaluationComponent } from './components/semester-evaluation/components/pvs-semester-evaluation/pvs-semester-evaluation.component';
import { ReviewComponent } from './components/society-evaluation/components/review/review.component';
import { CompletedAssessmentComponent } from './components/completed-assessment/completed-assessment.component';
import { ReviewsCandidatComponent } from './components/reviews-candidat/reviews-candidat.component';
import { AssessmentDetailsComponent } from './components/assessment-details/assessment-details.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyReviewsComponent } from './components/my-reviews/my-reviews.component';
import { DetailsMyReviewsComponent } from './components/details-my-reviews/details-my-reviews.component';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { RatingModule } from 'ngx-bootstrap/rating';
import { DetailsPvComponent } from './components/annual-evaluation/components/details-pv/details-pv.component';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { PvSignedComponent } from './components/annual-evaluation/components/pv-signed/pv-signed.component';
import { CalendarModule } from 'primeng/calendar';

@NgModule({
  declarations: [
    AnnualEvaluationComponent,
    SemesterEvaluationComponent,
    SocietyEvaluationComponent,
    ListEvaluationAnnualComponent,
    PvsEvaluationAnnualComponent,
    ListSemesterEvaluationComponent,
    PvsSemesterEvaluationComponent,
    ReviewComponent,
    CompletedAssessmentComponent,
    ReviewsCandidatComponent,
    AssessmentDetailsComponent,
    MyReviewsComponent,
    DetailsMyReviewsComponent,
    DetailsPvComponent,
    PvSignedComponent,
  ],
  imports: [
    CommonModule,
    EvaluationRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PaginationModule,
    NgxSpinnerModule,
    RatingModule.forRoot(),
    AngularSignaturePadModule,
    CalendarModule
  ],
  providers: [{ provide: LOCALE_ID, useValue: 'fr-FR' }]



})
export class EvaluationModule {}
