<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

 
<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_444_1768)">
                            <path d="M16.0442 6.79683H23.9584V8.36302H25.1979V5.71521H14.8047V8.36302H16.0442V6.79683Z"
                                fill="#265D91" />
                            <path
                                d="M31.989 16.1651C32.4197 15.9199 32.7979 15.6546 33.1092 15.3748V10.3503C33.1092 9.72439 32.5971 9.21387 31.9628 9.21387H8.03994C7.40565 9.21387 6.89355 9.72439 6.89355 10.3503V15.3748C7.20488 15.6546 7.58313 15.9199 8.01376 16.1651C10.2804 17.4428 14.0599 18.1697 18.0519 18.3485V16.9323C18.0519 15.9084 18.8899 15.0806 19.9199 15.0806H20.08C21.1129 15.0806 21.9479 15.9113 21.9479 16.9323V18.3485C25.9428 18.1697 29.7224 17.4428 31.989 16.1651Z"
                                fill="#265D91" />
                            <path
                                d="M19.9198 15.9286C19.364 15.9286 18.9072 16.3814 18.9072 16.9323V19.8426C18.9072 20.3935 19.364 20.8464 19.9198 20.8464H20.0798C20.6355 20.8464 21.0924 20.3935 21.0924 19.8426V16.9323C21.0924 16.3814 20.6355 15.9286 20.0798 15.9286H19.9198Z"
                                fill="#265D91" />
                            <path
                                d="M30.6681 7.05065L33.8221 3.92405L32.0472 2.16461L28.8932 5.29121L27.7119 4.12306V8.22168H31.8465L30.6681 7.05065Z"
                                fill="#265D91" />
                            <path
                                d="M29.4575 3.65587C29.5303 3.65587 29.603 3.62703 29.6583 3.57223L32.7745 0.483123C32.8851 0.373519 32.8851 0.191807 32.7745 0.0822031C32.6639 -0.027401 32.4806 -0.027401 32.37 0.0822031L29.2568 3.17419C29.1462 3.2838 29.1462 3.46551 29.2568 3.57511C29.312 3.62991 29.3848 3.65587 29.4575 3.65587Z"
                                fill="#265D91" />
                            <path
                                d="M33.6098 2.78188C33.6826 2.78188 33.7553 2.75303 33.8106 2.69823L35.3672 1.15512C35.4778 1.04552 35.4778 0.863804 35.3672 0.7542C35.2567 0.644596 35.0734 0.644596 34.9628 0.7542L33.4062 2.29731C33.2956 2.40691 33.2956 2.58863 33.4062 2.69823C33.4644 2.75592 33.5371 2.78188 33.6098 2.78188Z"
                                fill="#265D91" />
                            <path
                                d="M32.3993 6.69015C32.4546 6.74495 32.5274 6.77379 32.6001 6.77379C32.6728 6.77379 32.7456 6.74495 32.8009 6.69015L35.9171 3.60104C36.0276 3.49144 36.0276 3.30973 35.9171 3.20012C35.8065 3.09052 35.6232 3.09052 35.5126 3.20012L32.3993 6.28923C32.2888 6.40172 32.2888 6.58054 32.3993 6.69015Z"
                                fill="#265D91" />
                            <path
                                d="M9.33567 24.6797L6.18164 27.8063L7.95651 29.5657L11.1105 26.4391L12.2918 27.6073V23.5087H8.15727L9.33567 24.6797Z"
                                fill="#265D91" />
                            <path
                                d="M10.3407 28.1553L7.22746 31.2444C7.11689 31.354 7.11689 31.5357 7.22746 31.6453C7.28274 31.7001 7.35548 31.7289 7.42822 31.7289C7.50096 31.7289 7.5737 31.7001 7.62898 31.6453L10.7452 28.5562C10.8558 28.4466 10.8558 28.2649 10.7452 28.1553C10.6346 28.0457 10.4542 28.0457 10.3407 28.1553Z"
                                fill="#265D91" />
                            <path
                                d="M6.19229 29.0292L4.63273 30.5752C4.52216 30.6848 4.52216 30.8665 4.63273 30.9761C4.68801 31.0309 4.76075 31.0597 4.83349 31.0597C4.90623 31.0597 4.97897 31.0309 5.03426 30.9761L6.5909 29.433C6.70147 29.3234 6.70147 29.1417 6.5909 29.0321C6.48325 28.9196 6.30285 28.9196 6.19229 29.0292Z"
                                fill="#265D91" />
                            <path
                                d="M7.60356 25.0402C7.493 24.9306 7.30969 24.9306 7.19913 25.0402L4.08292 28.1293C3.97236 28.2389 3.97236 28.4206 4.08292 28.5302C4.13821 28.585 4.21095 28.6139 4.28369 28.6139C4.35643 28.6139 4.42917 28.585 4.48445 28.5302L7.60356 25.4411C7.71413 25.3286 7.71413 25.1498 7.60356 25.0402Z"
                                fill="#265D91" />
                            <path
                                d="M6.18164 3.92405L9.33567 7.05065L8.15727 8.22168H12.2918V4.12306L11.1105 5.29121L7.95651 2.16461L6.18164 3.92405Z"
                                fill="#265D91" />
                            <path
                                d="M7.19913 6.69013C7.25441 6.74494 7.32715 6.77378 7.39989 6.77378C7.47263 6.77378 7.54537 6.74494 7.60065 6.69013C7.71122 6.58053 7.71122 6.39882 7.60065 6.28921L4.48736 3.20299C4.3768 3.09339 4.19349 3.09339 4.08292 3.20299C3.97236 3.3126 3.97236 3.49431 4.08292 3.60391L7.19913 6.69013Z"
                                fill="#265D91" />
                            <path
                                d="M6.31826 2.57709C6.37354 2.63189 6.44629 2.66074 6.51903 2.66074C6.59177 2.66074 6.66451 2.63189 6.71979 2.57709C6.83036 2.46749 6.83036 2.28578 6.71979 2.17617L5.16314 0.630177C5.05258 0.520573 4.86927 0.520573 4.75871 0.630177C4.64814 0.739781 4.64814 0.921493 4.75871 1.0311L6.31826 2.57709Z"
                                fill="#265D91" />
                            <path
                                d="M10.3407 3.57516C10.396 3.62996 10.4688 3.6588 10.5415 3.6588C10.6142 3.6588 10.687 3.62996 10.7423 3.57516C10.8528 3.46555 10.8528 3.28384 10.7423 3.17424L7.62896 0.0851328C7.5184 -0.0244713 7.33509 -0.0244713 7.22453 0.0851328C7.11396 0.194737 7.11396 0.376449 7.22453 0.486053L10.3407 3.57516Z"
                                fill="#265D91" />
                            <path
                                d="M33.8221 27.8063L30.6681 24.6797L31.8465 23.5087H27.7119V27.6073L28.8932 26.4391L32.0472 29.5657L33.8221 27.8063Z"
                                fill="#265D91" />
                            <path
                                d="M32.8038 25.0402C32.6932 24.9306 32.5099 24.9306 32.3993 25.0402C32.2888 25.1498 32.2888 25.3315 32.3993 25.4411L35.5155 28.5302C35.5708 28.585 35.6436 28.6139 35.7163 28.6139C35.789 28.6139 35.8618 28.585 35.9171 28.5302C36.0276 28.4206 36.0276 28.2389 35.9171 28.1293L32.8038 25.0402Z"
                                fill="#265D91" />
                            <path
                                d="M33.6846 29.1532C33.5741 29.0436 33.3908 29.0436 33.2802 29.1532C33.1696 29.2628 33.1696 29.4445 33.2802 29.5541L34.8368 31.0972C34.8921 31.152 34.9649 31.1809 35.0376 31.1809C35.1103 31.1809 35.1831 31.152 35.2384 31.0972C35.3489 30.9876 35.3489 30.8059 35.2384 30.6963L33.6846 29.1532Z"
                                fill="#265D91" />
                            <path
                                d="M29.6612 28.1553C29.5506 28.0457 29.3673 28.0457 29.2568 28.1553C29.1462 28.2649 29.1462 28.4466 29.2568 28.5562L32.373 31.6453C32.4282 31.7001 32.501 31.7289 32.5737 31.7289C32.6465 31.7289 32.7192 31.7001 32.7745 31.6453C32.8851 31.5357 32.8851 31.354 32.7745 31.2444L29.6612 28.1553Z"
                                fill="#265D91" />
                            <path
                                d="M31.9899 17.1256C29.6884 18.2765 26.066 19.0206 21.9517 19.1994V19.8426C21.9517 20.8666 21.1138 21.6973 20.0838 21.6973H19.9237C18.8908 21.6973 18.0558 20.8637 18.0558 19.8426V19.1994C13.9387 19.0206 10.3162 18.2765 8.01758 17.1256V22.6606H13.1501V26.0151H26.8574V22.6606H31.9899V17.1256Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_444_1768" x="0" y="0" width="40" height="39.7289"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_444_1768" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_444_1768"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    Offres d'emploi
                </h1>
                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Liste des offres d’emploi</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-15">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card mb-10" >
            <div   class="card-body tools"  (keyup.enter)="applyFilter()">
                <div class="title mb-3">
                  <h4>Recherche</h4>
                </div>
                <div class="row mb-3" [formGroup]="searchFormGroup" >
                  <div class="col-lg-3">
                    <input type="text" name="id" id="id" class="form-control form-control-solid mb-3"
                      formControlName="name" placeholder="Poste">
                  </div>
                  <div class="col-lg-3">
                    <input type="text" id="dateInput" placeholder="Date" formControlName="start_date"
                    class="form-control form-control-solid mb-3" 
                     (focus)="updateInputType('date')"
                    >
                  </div>
                  <div class="col-lg-3">
                    <input type="text" name="nom" id="nom" formControlName="state"
                      class="form-control form-control-solid mb-3" placeholder="Statut">
                  </div>
                  <div class="col-lg-3">
                    <div class="mb-3 search-btn d-flex justify-content-end">
                    <div class="align-items-center recherche">
                      <button type="submit" class="me-5" (click)="applyFilter()">
                        <svg width="15" height="15" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                            fill="white" />
                        </svg>
                        Rechercher</button>
                    </div>
                    <div class="reset-icon cursor-pointer"  >
                        <a > <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                        </div>
      
                  </div>
                  </div>
                </div>
                
              </div>
            
             <!--end::Card header-->
            <!--begin::Card body-->
           
            <!--end::Card body-->
        </div>
    </div>
    <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid for-web">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
            <div class="header-grid ">
                <div class="section_title">
                    <h1 class="section-heading my-5">Listes des Offres d'emploi</h1>
                </div>
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3"
                            data-bs-toggle="tab" href="#kt_project_targets_card_pane">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active"
                            data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body">
                <div class="tab-content">
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_card_pane" class="tab-pane fade ">
                        <!--begin::Row-->
                        <div class="row g-6 g-xl-9 justify-content-center">
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4" *ngFor="let job of listJobOffer">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                            
                                     <div class="type-esn">
                                        <!-- <a class="text-gray-800 text-hover-primary mb-1" [ngClass]="job.esn?.type=='2'?
                                        'esn-type':job.esn?.type=='3'?'client-type':'centre-type'">
                                            {{job.esn?.type=='1'?'Entreprise':job.esn?.type=='2'?
                                            'ESN':job.esn?.type=='3'?'Client Final':'Cabinet de Recrutement'}}</a> -->
                                     </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <!-- <img *ngIf="job && job?.esn && job.esn?.logo"
                                                [src]="url + '/Company/file/gallery_company/' + job.esn?.logo"
                                                alt="logo">
                                            <img *ngIf="!job || !job?.esn || !job.esn?.logo"
                                                src="/assets/media/img/modules/avatar.jpg" alt="image" /> -->


                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-45 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="job-title"> {{job?.esn?.name?job?.esn?.name:'---'}} </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <div class="poste">
                                            <span> {{job?.name?job?.name:'---'}} </span>
                                        </div>
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap mt-5 mb-5">
                                            <!--begin::Stats-->
                                            <div class="skills">
                                                <!-- <div class="skill"
                                                    *ngFor="let skill of getSkillsArray(job)?.slice(0,3); let i = index">
                                                    <span [ngClass]="'badge style' + (i % 3 + 1)">{{ skill }}</span>
                                                </div>
                                                <span *ngIf="getSkillsArray(job) && getSkillsArray(job).length > 3"> +
                                                </span> -->
                                            </div>
                                            <!--end::Stats-->
                                        </div>
                                        <div class="info">
                                            <div class="type mb-3">
                                                <span>Salaire :</span>
                                                <!-- {{job.salary?job.salary:'---'}} -->
                                            </div>
                                            <div class="type mb-3">
                                                <span>TJM :</span>
                                                {{job.tjm?job.tjm:'---'}}
                                            </div>
                                           
                                        </div>
                                        <div class="experience">
                                            <div class="exper mb-3">
                                                <span>Expérience :</span>
                                                {{job?.years_of_experience?job?.years_of_experience:'---'}} ans
                                            </div>
                                        </div>
                                        <!--end::Info-->
                                    </div>
                                    <div class="sep"></div>
                                    <div class="foot p-5">
                                       
                                        <!-- <div class="plus">
                                            <a *ngIf="job.esn?.type==='2' "
                                                (click)="detailsJobOfferNavigation(job.id)">Voir plus</a>

                                            <a *ngIf="job.esn?.type==='3' "
                                                (click)="detailsJobRequestNavigation(job.id)">Voir plus</a>

                                        </div> -->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->

                        </div>
                        <!--end::Row-->
                    </div>
                    <!--end::Tab pane-->
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_table_pane" class="tab-pane fade  active show">
                        <div class="content">
                            <div class="card-body pt-3">
                                <!--begin::Table-->
                                <div class="table-responsive"  id="kt_customers_table">
                                    <table class="table align-middle  fs-6 gy-5"
                                       >
                                        <thead>
                                            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                <th class="min-w-125px"><span>ID <i (click)="sortData('ID_jobOffer')" class="fa-solid fa-sort cursor-pointer"></i></span></th>

                                                <th class="min-w-125px"><span>Poste <i (click)="sortData('name')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                    
                                                <th class="min-w-125px"><span>date <i (click)="sortData('created_at')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                    
                                                <th class="min-w-125px"><span>STATUT <i (click)="sortData('state')" class="fa-solid fa-sort cursor-pointer"></i></span></th>
                                               
                                            
                                            </tr>
                                        </thead>
                                        <tbody class="fw-semibold text-gray-600" *ngFor="let job of listJobOffer ">

                                           
                                           <td>
                                <a class="first-td">{{job.ID_jobOffer?job?.ID_jobOffer:'---'}}</a> </td>
                           
                           
                             <td>
                              <span>{{job.name?job.name:'---'}}</span> 
                            </td> 
                            
                            <td>
                               <span>{{!job.created_at?'---':job.created_at | formatDate}}</span>
                            </td>
                            <td>
                              <span><div [ngClass]="job.state?'badge-red':'badge-green'" class="badge ">{{job.state?'Désactivé':'Activé'}}</div></span>
                            </td>

                                        </tbody>
                                    </table>
                                </div>
                                <!--end::Table-->
                               

                                 

                            </div>
                        </div>
                    </div>
                    <div class="pagination" *ngIf="listJobOffer && listJobOffer.length">
                        <app-pagination [data]="listJobOffer" [lengthData]="totalItems" [currentPageList]="currentPage"
                            (setPages)="getItems($event)">
                        </app-pagination>
                    </div>
                

                    <div class="alert alert-warning text-center mt-5" role="alert"  *ngIf="listJobOffer&&!listJobOffer.length">
                        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                        <span class="empty-list">
                         La liste est vide
                     </span>
                     </div> 
                    <!--end::Tab pane-->
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
    </div>
    <div id="kt_app_content_container" class="app-container container-fluid for-mobile">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
            <div class="header-grid">
                <div class="section_title">
                    <h1 class="section-heading my-5">Listes des Offres d'emploi</h1>
                </div>
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active  me-3"
                            data-bs-toggle="tab" href="#kt_project_targets_card_pane">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary "
                            data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
       
            <!--end::Card body-->
        </div>
    </div>
    <!--end::Content container-->
</div>


