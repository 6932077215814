import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthentificationService } from '../../services/authentification.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import {
  codeSended,
  noAccountCandidateMail,
  noAccountMail,
  serverError,
} from '../../../shared/shared-models/message';
import { patternEmail } from '../../../shared/shared-models/pattern';

@Component({
  selector: 'app-reset-mail',
  templateUrl: './reset-mail.component.html',
  styleUrls: ['./reset-mail.component.css'],
})
export class ResetMailComponent {
  /* generate email control */
  emailControl = new FormControl();
  userInfo: any;

  constructor(
    private authService: AuthentificationService,
    private toastrService: ToastrService,
    private router: Router
  ) {}
  /* init */
  ngOnInit(): void {
    this.emailControl.addValidators([
      Validators.required,
      Validators.email,
      Validators.pattern(patternEmail),
    ]);
  }
  /* sending mail to get reset link */
  sendMail() {
    if (this.emailControl.valid) {
      let data = {
        email: this.emailControl.value,
        role_id: [3],
      };
      this.authService
        .resetMail(data)
        .pipe()
        .subscribe({
          next: (res: { status: number; data: any }) => {
            if (res.status == 200) {
              this.handleSuccessResponse(res.data.User);
            }
          },
          error: (err) => {
            if (err?.error) {
              this.handleError(err.error.status);
            }
          },
        });
    }
  }

  private handleSuccessResponse(userData: any) {
    this.userInfo = userData;
    this.toastrService.success(codeSended);
    this.router.navigate(['auth/code-reset', { email: this.userInfo.email }]);
  }

  private handleError(status: number) {
    let errorMessage: string;

    switch (status) {
      case 404:
        errorMessage = noAccountMail;
        break;
      case 409:
        errorMessage = noAccountCandidateMail;
        break;
      default:
        errorMessage = serverError;
    }

    this.toastrService.error(errorMessage);
  }
}
