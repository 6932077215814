import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListJobsComponent } from './components/list-jobs/list-jobs.component';
import { CandidaturesComponent } from './components/candidatures/candidatures.component';
import { SuggestionsComponent } from '../suggestions/components/suggestions/suggestions.component';
import { JobsArchivedComponent } from './components/jobs-archived/jobs-archived.component';
import { DetailsJobRequestComponent } from './components/details-job-request/details-job-request.component';
import { DetailsJobOfferComponent } from './components/details-job-offer/details-job-offer.component';
import { JobEntrepriseComponent } from './components/job-entreprise/job-entreprise/job-entreprise.component';

const routes: Routes = [
  {
    path: '',
    component: ListJobsComponent
  },
  {
    path: 'list-jobs',
    component: ListJobsComponent
  },
  {
    path: 'list-jobs-entreprise',
    component: JobEntrepriseComponent
  },
  {
    path: 'candidatures',
    component: CandidaturesComponent
  },
  {
    path: 'suggestions',
    component: SuggestionsComponent
  },
  {
    path:'job-archived',
    component:JobsArchivedComponent
  },
  {
    path: 'details-job-offer',
    component: DetailsJobOfferComponent
  },
  {
    path: 'details-job-request',
    component: DetailsJobRequestComponent
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class JobsRoutingModule { }
