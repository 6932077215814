<div class="register-page">
  <!--begin::Authentication - Signup Welcome Message -->
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <!--begin::Content-->
    <div class="d-flex flex-column flex-center">
      <!--begin::Wrapper-->
      <div class="card-warp w-md-650px py-5" [formGroup]="generateRegisterForm">
        <div class="content py-15 py-lg-20">
          <!--begin::Logo-->
          <div class="mb-7 text-center">
            <a class="">
              <img
                alt="Logo"
                src="/assets/media/logos/logo-wegestu-color.png"
                class="h-40px"
              />
            </a>
          </div>
          <!--end::Logo-->

          <div class="fv-row mb-8">
            <!--begin::prenom-->
            <label for="prenom" class="required">Prénom</label>
            <div class="input-group mb-5">
              <span class="input-group-text" id="basic-addon1">
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.82499 8.1952C9.11399 8.1952 10.962 6.3648 10.962 4.0976C10.962 1.8512 9.11399 0 6.82499 0C4.53599 0 2.68799 1.8304 2.68799 4.0976C2.68799 6.3648 4.53599 8.1952 6.82499 8.1952Z"
                    fill="#9AB4CC"
                  />
                  <path
                    d="M13.125 16.2656C13.419 16.2656 13.65 16.0368 13.65 15.7456C13.65 11.7312 10.647 8.56958 6.825 8.56958C3.003 8.56958 0 11.7104 0 15.7456C0 16.0368 0.231 16.2656 0.525 16.2656"
                    fill="#9AB4CC"
                  />
                </svg>
              </span>
              <input
                type="text"
                id="first_name"
                formControlName="first_name"
                class="form-control"
                placeholder="Prénom"
                aria-label="prenom"
                aria-describedby="basic-addon1"
              />
            </div>
            <span
              style="color: red"
              *ngIf="
                generateRegisterForm.get('first_name')?.dirty ||
                generateRegisterForm.get('first_name')?.touched ||
                submitted
              "
            >
              <small
                *ngIf="
                  generateRegisterForm.get('first_name')?.hasError('required')
                "
                >Ce champ est obligatoire.</small
              >
            </span>
           
            <div
              class="error-message"
              *ngIf="
                generateRegisterForm.get('first_name')?.hasError('pattern')
              "
            >
              Ce champ ne doit contenir que des lettres
            </div>
            <!--end::prenom-->
          </div>
          <div class="fv-row mb-8">
            <!--begin::nom-->
            <label for="nom" class="required">Nom</label>
            <div class="input-group mb-5">
              <span class="input-group-text" id="basic-addon1">
                <svg
                  width="14"
                  height="17"
                  viewBox="0 0 14 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.82499 8.1952C9.11399 8.1952 10.962 6.3648 10.962 4.0976C10.962 1.8512 9.11399 0 6.82499 0C4.53599 0 2.68799 1.8304 2.68799 4.0976C2.68799 6.3648 4.53599 8.1952 6.82499 8.1952Z"
                    fill="#9AB4CC"
                  />
                  <path
                    d="M13.125 16.2656C13.419 16.2656 13.65 16.0368 13.65 15.7456C13.65 11.7312 10.647 8.56958 6.825 8.56958C3.003 8.56958 0 11.7104 0 15.7456C0 16.0368 0.231 16.2656 0.525 16.2656"
                    fill="#9AB4CC"
                  />
                </svg>
              </span>
              <input
                type="text"
                id="last_name"
                formControlName="last_name"
                class="form-control"
                placeholder="Nom"
                aria-label="Nom"
                aria-describedby="basic-addon1"
              />
            </div>
            <span
            style="color: red"
            *ngIf="
              generateRegisterForm.get('last_name')?.dirty ||
              generateRegisterForm.get('last_name')?.touched ||
              submitted
            "
          >
            <small
              *ngIf="
                generateRegisterForm.get('last_name')?.hasError('required')
              "
              >Ce champ est obligatoire.</small
            >
          </span>
            <div
              class="error-message"
              *ngIf="generateRegisterForm.get('last_name')?.hasError('pattern')"
            >
              Ce champ ne doit contenir que des lettres
            </div>
            <!--end::nom-->
          </div>

          <div class="fv-row mb-8">
            <!--begin::tel-->
            <label for="tel">Tél</label>
            <div class="input-group mb-5">
              <intl-telephone-input
                #phoneInput
                [preferredCountries]="[CountryISO.France]"
                [enablePlaceholder]="true"
                [searchCountryFlag]="true"
                [separateDialCode]="false"
                [enableAutoCountrySelect]="true"
                [searchCountryField]="SearchCountryField.All"
                [phoneValidation]="true"
                [numberFormat]="PhoneNumberFormat.International"
                [inputId]="'phone'"
                (onChange)="onInputChange($event)"
                (input)="onPhoneInputTouch($event)"
                (keydown)="validateNumberInput($event)"
              >
              </intl-telephone-input>
            </div>
            <!--end::tel-->

            <!-- <div *ngIf="phoneInputTouched && generateRegisterForm.get('phone_number')?.errors?.['required']"
        class="error-message">
        Champ obligatoire.
    </div> -->
            <!-- Afficher l'erreur de numéro de téléphone invalide uniquement si l'input a été touché -->
            <div
              *ngIf="phoneInputTouched && !validPhoneNumber && !generateRegisterForm.get('phone_number')?.errors?.['required']"
              class="error-message"
            >
              Numéro de téléphone invalide.
            </div>
          </div>

          <div class="fv-row mb-8">
            <!--begin::mail-->
            <label for="mail" class="required">E-mail</label>
            <div class="input-group mb-5">
              <span class="input-group-text" id="basic-addon1">
                <svg
                  width="22"
                  height="15"
                  viewBox="0 0 22 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.22768 0H19.773C20.3839 0 20.941 0.249676 21.3459 0.653107C21.7501 1.05654 22 1.6109 22 2.21958V11.9788C22 12.5896 21.7494 13.1447 21.3459 13.5467L21.3247 13.5664C20.9219 13.9572 20.374 14.1984 19.7723 14.1984H2.22697C1.61537 14.1984 1.05827 13.9487 0.654783 13.546C0.250587 13.1447 0 12.5896 0 11.9788V2.21958C0 1.6109 0.250587 1.05583 0.655491 0.652402C1.06039 0.249676 1.61678 0.00070545 2.22768 0.00070545V0ZM2.67718 1.87751L2.29847 2.47207L11.0004 7.98681L19.7015 2.47207L19.3228 1.87751L10.9996 7.15174L2.67647 1.87751H2.67718Z"
                    fill="#9AB4CC"
                  />
                </svg>
              </span>
              <input
                type="email"
                formControlName="email"
                class="form-control"
                id="email"
                placeholder="mail@wegestu.com"
                aria-label="mail"
                aria-describedby="basic-addon1"
              />
            </div>

            <span
            style="color: red"
            *ngIf="
              generateRegisterForm.get('email')?.dirty ||
              generateRegisterForm.get('email')?.touched ||
              submitted
            "
          >
            <small
              *ngIf="
                generateRegisterForm.get('email')?.hasError('required')
              "
              >Ce champ est obligatoire.</small
            >

            <small
            *ngIf="
              generateRegisterForm.get('email')?.hasError('pattern')
            "
            >Format de l'email invalide.</small
          > 

          </span> 
          

            <!--end::mail-->
          </div>
          <div class="fv-row mb-8">
            <!--begin::Password-->
            <label for="password" class="required">Mot de passe</label>
            <div class="input-group mb-5 input-login">
              <span class="input-group-text" id="basic-addon1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0001 5.14286H4.00014C3.84859 5.14286 3.70324 5.08265 3.59608 4.97549C3.48891 4.86833 3.42871 4.72298 3.42871 4.57143V2.28571C3.42871 1.6795 3.66953 1.09812 4.09818 0.66947C4.52684 0.240816 5.10822 0 5.71443 0H10.2859C10.8921 0 11.4734 0.240816 11.9021 0.66947C12.3308 1.09812 12.5716 1.6795 12.5716 2.28571V4.57143C12.5716 4.72298 12.5114 4.86833 12.4042 4.97549C12.297 5.08265 12.1517 5.14286 12.0001 5.14286ZM4.57157 4H11.4287V2.28571C11.4287 1.98261 11.3083 1.69192 11.094 1.47759C10.8796 1.26326 10.589 1.14286 10.2859 1.14286H5.71443C5.41132 1.14286 5.12063 1.26326 4.9063 1.47759C4.69198 1.69192 4.57157 1.98261 4.57157 2.28571V4Z"
                    fill="#9AB4CC"
                  />
                  <path
                    d="M13.1429 4H2.85714C2.09938 4 1.37266 4.30102 0.836838 4.83684C0.30102 5.37266 0 6.09938 0 6.85714V13.1429C0 13.9006 0.30102 14.6273 0.836838 15.1632C1.37266 15.699 2.09938 16 2.85714 16H13.1429C13.9006 16 14.6273 15.699 15.1632 15.1632C15.699 14.6273 16 13.9006 16 13.1429V6.85714C16 6.09938 15.699 5.37266 15.1632 4.83684C14.6273 4.30102 13.9006 4 13.1429 4ZM8.57143 10.1257V12C8.57143 12.1516 8.51123 12.2969 8.40406 12.4041C8.2969 12.5112 8.15155 12.5714 8 12.5714C7.84845 12.5714 7.7031 12.5112 7.59594 12.4041C7.48878 12.2969 7.42857 12.1516 7.42857 12V10.1257C7.2107 9.99992 7.04041 9.80576 6.94414 9.57332C6.84786 9.34089 6.83097 9.08319 6.89609 8.84018C6.9612 8.59717 7.10468 8.38244 7.30427 8.22928C7.50387 8.07613 7.74842 7.99311 8 7.99311C8.25158 7.99311 8.49613 8.07613 8.69573 8.22928C8.89532 8.38244 9.0388 8.59717 9.10392 8.84018C9.16903 9.08319 9.15214 9.34089 9.05586 9.57332C8.95959 9.80576 8.78931 9.99992 8.57143 10.1257Z"
                    fill="#9AB4CC"
                  />
                </svg>
              </span>
              <input
                [type]="showOldPassword ? 'text' : 'password'"
                formControlName="password"
                placeholder="Mot de passe"
                class="form-control bg-transparent"
                id="password"
                name="password"
              />
              <i
                *ngIf="!showOldPassword"
                (click)="togglePasswordVisibility('oldPassword')"
                class="fa-regular fa-eye-slash"
              ></i>
              <i
                *ngIf="showOldPassword"
                (click)="togglePasswordVisibility('oldPassword')"
                class="fa-regular fa-eye"
              ></i>
            </div>

            <span
            style="color: red"
            *ngIf="
              generateRegisterForm.get('password')?.dirty ||
              generateRegisterForm.get('password')?.touched ||
              submitted
            "
          >
            <small
              *ngIf="
                generateRegisterForm.get('password')?.hasError('required')
              "
              >Ce champ est obligatoire.</small
            >

            <small
            *ngIf="
              generateRegisterForm.get('password')?.hasError('pattern')
            "
            >Le mot de passe doit contenir au moins 8 caractères, incluant une lettre majuscule, une lettre minuscule, un chiffre et un caractère spécial (@$!%*?&).</small
          >  


          </span>  
            <!--end::Password-->
          </div>
          <div class="fv-row mb-8">
            <!--begin::Password-->
            <label for="password" class="required"
              >Confirmer mot de passe</label
            >
            <div class="input-group mb-5 input-login">
              <span class="input-group-text" id="basic-addon1">
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.0001 5.14286H4.00014C3.84859 5.14286 3.70324 5.08265 3.59608 4.97549C3.48891 4.86833 3.42871 4.72298 3.42871 4.57143V2.28571C3.42871 1.6795 3.66953 1.09812 4.09818 0.66947C4.52684 0.240816 5.10822 0 5.71443 0H10.2859C10.8921 0 11.4734 0.240816 11.9021 0.66947C12.3308 1.09812 12.5716 1.6795 12.5716 2.28571V4.57143C12.5716 4.72298 12.5114 4.86833 12.4042 4.97549C12.297 5.08265 12.1517 5.14286 12.0001 5.14286ZM4.57157 4H11.4287V2.28571C11.4287 1.98261 11.3083 1.69192 11.094 1.47759C10.8796 1.26326 10.589 1.14286 10.2859 1.14286H5.71443C5.41132 1.14286 5.12063 1.26326 4.9063 1.47759C4.69198 1.69192 4.57157 1.98261 4.57157 2.28571V4Z"
                    fill="#9AB4CC"
                  />
                  <path
                    d="M13.1429 4H2.85714C2.09938 4 1.37266 4.30102 0.836838 4.83684C0.30102 5.37266 0 6.09938 0 6.85714V13.1429C0 13.9006 0.30102 14.6273 0.836838 15.1632C1.37266 15.699 2.09938 16 2.85714 16H13.1429C13.9006 16 14.6273 15.699 15.1632 15.1632C15.699 14.6273 16 13.9006 16 13.1429V6.85714C16 6.09938 15.699 5.37266 15.1632 4.83684C14.6273 4.30102 13.9006 4 13.1429 4ZM8.57143 10.1257V12C8.57143 12.1516 8.51123 12.2969 8.40406 12.4041C8.2969 12.5112 8.15155 12.5714 8 12.5714C7.84845 12.5714 7.7031 12.5112 7.59594 12.4041C7.48878 12.2969 7.42857 12.1516 7.42857 12V10.1257C7.2107 9.99992 7.04041 9.80576 6.94414 9.57332C6.84786 9.34089 6.83097 9.08319 6.89609 8.84018C6.9612 8.59717 7.10468 8.38244 7.30427 8.22928C7.50387 8.07613 7.74842 7.99311 8 7.99311C8.25158 7.99311 8.49613 8.07613 8.69573 8.22928C8.89532 8.38244 9.0388 8.59717 9.10392 8.84018C9.16903 9.08319 9.15214 9.34089 9.05586 9.57332C8.95959 9.80576 8.78931 9.99992 8.57143 10.1257Z"
                    fill="#9AB4CC"
                  />
                </svg>
              </span>
              <input
                [type]="showNewPassword ? 'text' : 'password'"
                formControlName="confirmedPassword"
                placeholder="Mot de passe"
                class="form-control bg-transparent"
                id="newPassword"
                name="newPassword"
              />
              <i
                *ngIf="!showNewPassword"
                (click)="togglePasswordVisibility('newPassword')"
                class="fa-regular fa-eye-slash"
              ></i>
              <i
                *ngIf="showNewPassword"
                (click)="togglePasswordVisibility('newPassword')"
                class="fa-regular fa-eye"
              ></i>

            
            </div>

            <!-- <app-feild-error    [control]="generateRegisterForm.get('confirmedPassword')"  [controlName]="'oldPassword'"></app-feild-error> -->
            <div
              *ngIf="
                generateRegisterForm
                  .get('confirmedPassword')
                  ?.hasError('passwordMismatch')
              "
              class="text-danger"
            >
              Les mots de passe ne correspondent pas.
            </div>
            <span
            style="color: red"
            *ngIf="
              generateRegisterForm.get('confirmedPassword')?.dirty ||
              generateRegisterForm.get('confirmedPassword')?.touched ||
              submitted
            "
          >
            <small
              *ngIf="
                generateRegisterForm.get('confirmedPassword')?.hasError('required')
              "
              >Ce champ est obligatoire.</small
            >
          </span>   


            <!--end::Password-->
          </div>

          <div class="fv-row mb-8">
            <!--begin::Password-->
            <label for="Source" class="Sourcee"
              >D'où avez-vous entendu parler de WeGestU ?</label
            >
            <select
              name="source"
              formControlName="source"
              id="source"
              class="form-control source"
            >
              <option value="" disabled selected>
                Veuillez sélectionner une option
              </option>
              <option value="Linkedin">Linkedin</option>
              <option value="facebook">Facebook</option>
              <option value="instagram">Instagram</option>
              <option value="tiktok">Tiktok</option>
              <option value="youtube">Youtube</option>
              <option value="google search">Google search</option>
              <option value="emailing">Emailing</option>
              <option value="autres">Autres</option>
            </select>

            <span
            style="color: red"
            *ngIf="
              generateRegisterForm.get('source')?.dirty ||
              generateRegisterForm.get('source')?.touched ||
              submitted
            "
          >
            <small
              *ngIf="
                generateRegisterForm.get('source')?.hasError('required')
              "
              >Ce champ est obligatoire.</small
            >
          </span>   
          </div>
          <div class="terms mt-10 mb-5">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                formControlName="terme"
                (change)="terms()"
                id="flexCheckDefault"
              />
              <label
                class="form-check-label"
                for="flexCheckDefault"
                class="required"
              >
                J'ai lu et j'accepte
                <a
                  class="terms"
                  href="/assets/media/docs/CGU.pdf"
                  target="_blank"
                  >les termes et conditions de service</a
                >.
              </label>
            </div>
            <span
            style="color: red"
            *ngIf="
              !generateRegisterForm.get('terme')?.value &&
             
              submitted
            "
          >
            <small
              
              >Ce champ est obligatoire.</small
            >
          </span> 

          </div>

          <!--begin::Link-->
          <div class="mb-8">
            <button
              id="btn_signup"
              [disabled]="!term"
              (click)="signUp()"
              class="btn btn-sm btn-primary signup-btn pt-5 pb-5 w-100"
            >
              S’inscrire
            </button>
          </div>
          <div class="mb-8 text-center">
            <a routerLink="/auth/login" class="signin">Déjà inscrit(e) ?</a>
          </div>
          <!--end::Link-->
        </div>
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Authentication - Signup Welcome Message-->
</div>
