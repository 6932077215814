export interface Rate {
    current_page: number
    data: any[]
    first_page_url: string
    from: any
    last_page: number
    last_page_url: string
    links: Link[]
    next_page_url: any
    path: string
    per_page: number
    prev_page_url: any
    to: any
    total: number
  }
  
  export interface Link {
    url?: string
    label: string
    active: boolean
  }
  
  export class CResponseReview{
    id: string | undefined;
    question: string | undefined;
    stars: string | undefined;
    constructor(model?: CResponseReview) {
        this.id = model && model.id || "";
        this.stars = model && model.stars || "";
        this.question = model && model.question || "";
    }
  }
  export class CResponseReviewByCompany{
    id: string | undefined;
    question: string | undefined;
    stars: string | undefined;
    constructor(model?: CResponseReview) {
        this.id = model && model.id || "";
        this.stars = model && model.stars || "";
        this.question = model && model.question || "";
    }
  }