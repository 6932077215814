export const welcomeMessage = 'Bienvenue sur votre espace Wegestu'
export const noThingChanged = 'Vous n\'avez effectué aucun changement.'
export const serverError = 'Une erreur est survenue.'
export const succesdisassociateConsultant = 'Consultant dissocier avec succés.'
export const noAccountMail = 'Pas de compte associé à cette adresse e-mail.'
export const invalidMailPassword = 'Adresse e-mail ou mot de passe invalide.'
export const emailSended = 'Email envoyé avec succès.'
export const sessionExpired = 'Session expirée.'
export const emailExist = 'L\'adresse e-mail a déjà été utilisée.'
export const phoneNumberExist = 'Le numéro de téléphone a déjà été utilisé.'
export const linkdinExist = 'Le profil LinkedIn a déjà été utilisé.'
export const successRegister = 'Inscription réussie.'
export const successCodeVerficiation = 'E-mail vérifié avec succès.'
export const invalidVerificationCode = 'Code erroné.'
export const codeexpire = 'Le code que vous avez entré est expiré.'
export const changePasswordSuccess = 'Le mot de passe a été changé avec succès.'
export const notVerifiedEmail = "L'e-mail n'est pas vérifié."
export const checkRequiredInputs = 'Vous devez remplir les champs obligatoires !'
export const invalidSelectedDate = 'La date sélectionnée est dans le futur, veuillez choisir une autre date.'
export const codeSended = 'Code envoyé.'
export const noAccountCandidateMail = 'Pas de compte candidat associé à cette adresse e-mail.'
export const usedmail = 'Adresse e-mail déjà utilisée, essayez une nouvelle.'
export const existPhoneNumber = 'Le numéro de téléphone a déjà été utilisé.'
export const successEditProfile = 'Votre profil a été modifié avec succès.'
export const gotoTest = 'Vous êtes invité(e) à passer un test de compétences. Veuillez vous référer au menu à gauche.'
export const successAddSkills = 'La mise à jour des compétences a été effectuée avec succès.'
export const FormInvalid = 'Champs manquants.'
export const accountNotExist = 'Impossible de trouver un utilisateur avec l’e-mail fourni.'
export const noAccountMailCandidat = 'Pas de compte candidat associé à cette adresse e-mail.'
export const oldPasswordError = 'L\'ancien mot de passe est incorrect.'
export const minCompetence = 'Il faut sélectionner au moins une compétence.'
export const maxCompetence = 'Vous devez sélectionner au maximum 5 compétences.'
export const successCreateTest = 'Les compétences ont été ajoutées avec succès.'
export const passwords = "L'ancien et le nouveau mot de passe ne peuvent pas être identiques."
export const successEvaluation = 'Votre évaluation a été enregistrée avec succès.'
export const sendEvaluation = 'Votre évaluation a été envoyée avec succès.'
export const successEvaluationupdate = 'Votre évaluation a été modifiée avec succès.'
export const successSubmitTest = 'Réponse soumise avec succès.'
export const blockSuccess = 'Entreprise bloquée avec succès.'
export const archiveSuccess = 'Entreprise archivée avec succès.'
export const archiveJobSuccess = 'Offre d’emploi archivée avec succès.'
export const invalidCode = 'Code incorrect.'
export const expiredCode = 'Code expiré.'
export const deblockSuccess = 'Entreprise débloquée avec succès.'
export const successevaluation = 'Évaluation effectuée avec succès.'
export const sucessRemove = 'Élément supprimé avec succès.'
export const messageTest = 'Veuillez consulter votre e-mail pour passer le test.'
export const successTestRequest = 'Votre demande a été envoyée avec succès.'
export const sendRate = 'Votre avis a été envoyé avec succès.'
export const applySuccess = 'Félicitations ! Vous avez postulé avec succès.'
export const restoredSuccess = 'Restauré avec succès.'
export const successCra = 'Votre CRA a été ajouté avec succès.'
export const successTimeSheet = 'Votre TimeSheet a été ajouté avec succès.'
export const successSignature = 'Votre signature a été envoyée avec succès.'
export const maxSizeFile = 'Le fichier à importer ne doit pas dépasser 2 mégaoctets.'
export const fileTypeExtention = 'Format de fichier non compatible.'
