import { ChangeDetectorRef, Component, ElementRef, HostListener, Renderer2, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthentificationService } from '../../authentification/services/authentification.service';
import { CandidateService } from '../../main/dossier/services/candidate.service';
import { LocalStorageService } from '../../shared/shared-services/local-storage.service';
import { SubjectBehaviorService } from '../../shared/shared-services/subject-behavior.service';
import Swal from 'sweetalert2';
import { environment } from '../../../environnements/environment';
import { NotificationService } from '../../main/notifications/services/notification/notification.service';
import { catchError, filter, finalize, forkJoin, Observable, of, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChatService } from '../../main/chats/services/chat.service';
import { PusherService } from '../../main/chats/services/pusher.service';
import * as moment from 'moment';
import { NavigationEnd, Router } from '@angular/router';
import { event } from 'jquery';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  isDropdownOpen = false;
  currentUser: any;
  first_name: any;
  last_name: any;
  user: any;
  image_profile: any;
  picture: any;
  url: string = environment.baseUrl;
  base_url_img_company = environment.baseUrlGaleryCompany;
  base_url_img = environment.baseUrlGalery;
  id: any;
  roleUser: any;
  pagination : boolean =false;

  notifications: any;
  messages: any;
  unreadNotificationCount: number = 0;
  unreadMessageCount: number = 0;
  elapsedTime: any;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  notificationsSubscription!: Subscription;
  messagesSubscription!: Subscription;
  users: any;
  currentConversationUserId: number | null = null;
  updatingProfile: boolean = false;
  notificationReaded: boolean = false;
  itemsPerPage: number = 5
  currentPage: number = 1
  startIndex: number = 0
  endIndex: number = 5
  totalItems:any
  showDropdown = false;
  showDropdownmessage= false;
  showDropdownProfil = false
  private routerEventsSubscription!: Subscription;

  constructor(private authService: AuthentificationService,
    private localStorageService: LocalStorageService,
    private dataService: SubjectBehaviorService,
    public translate: TranslateService,
    private candidateService: CandidateService,
    private notificationService: NotificationService,
    private spinner: NgxSpinnerService,
    private changeDetectorRef: ChangeDetectorRef,
    private chatService: ChatService,
    private pusherService: PusherService,
    private router: Router,
  ) {
    translate.addLangs(['en', 'nl']);
    translate.setDefaultLang('en');
    this.currentUser = this.localStorageService.getData('UserInfo');
    if (!this.currentUser) {
      console.error('User information is not available.');
      return;
    }
    try {
      const userData = JSON.parse(this.currentUser);
      this.id = userData.id;
      // this.first_name = userData.first_name;
      // this.last_name = userData.last_name;
      this.picture = userData.image_url;
      this.roleUser = userData.role_user_id;
      this.image_profile = this.base_url_img + userData.image_url;
      console.log('image profil', this.image_profile);
    } catch (error) {
      console.error('Error parsing user information:', error);
    }
  }

  ngOnInit() {
    this.subscribeToProfileUpdates();
    this.get_profile_user();
    this.routerEventsSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.isDropdownOpen) {
        this.closeDropdown();
      }
    });
    this.notificationsSubscription =
      this.notificationService.messages$.subscribe((notification) => {
        console.log('notification', notification);
        this.unreadNotificationCount++;
        this.notifications = this.notifications?.concat(notification).reverse();
        this.getNotification()
        console.log('notifications2', this.notifications);
        this.changeDetectorRef.detectChanges();
      });

    this.messagesSubscription = this.pusherService.messages$.subscribe(
      (message) => {
        console.log('message', message);
        if (message?.user_id === this.id) {
          this.unreadMessageCount++;
        }

        this.messages = this.messages?.concat(message);
        console.log('message2', this.messages);
        this.changeDetectorRef.detectChanges();
      }
    );
    this.getNotification();
   this.getMessage();
  }

  // isCandidat(): boolean {
  //   return this.user?.role_user_id?.length === 1;
  // }

  // isConsultant(): boolean {
  //   return this.user?.role_user_id?.length > 1;
  // }

  isConsultant(): boolean {
    return (
      this.user?.role_user_id?.includes('3') &&
      this.user?.role_user_id?.includes('4')
    );
  }
  isExternalConsultant(): boolean {
    return (
      this.user?.role_user_id?.includes('3') &&
      this.user?.role_user_id?.includes('5')
    );
  }
  isCandidat(): boolean {
    return this.user?.role_user_id?.length === 1;
  }
  


  logout() {
    this.authService.logOut();
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
  // getItems(event?: any) {
  //   if (event) {
  //     this.startIndex = event.startIndex
  //     this.endIndex = event.endIndex
  //     this.itemsPerPage = event.itemsPerPage,
  //       this.currentPage = event.currentPage
  //     //  if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
  //         this.pagination = true
  //       this.getNotification()
  //       // }

  //   }
  // }
  getNotification() {
    this.notificationService.getListOfNotification(this.itemsPerPage, this.currentPage).subscribe(
      {
        next: (res) => {
          this.notifications = res.data.data.data;
          console.log('user notification ********** ', this.notifications);
  
          this.unreadNotificationCount = this.notifications.filter((notification:any) => notification.read === 0).length;
        },
        error: (e) => {
          console.log('get notification error ===== ', e);
        }
      }
    );
  }

// Method to mark a notification as read


readNotification(id: any, index: number): Observable<any> {
  this.spinner.show();
  const data = { id };

  return this.notificationService.readNotification(data).pipe(
    catchError(err => {
      console.error('Failed to mark notification as read', err);
      return of(null); // Return an empty observable on error
    }),
    finalize(() => {
      this.spinner.hide();
      // Update the notification count and refresh notifications
      this.unreadNotificationCount = this.notifications.filter((notification: any) => !notification.read).length;
    }),
    tap(() => {
      // Mark the notification as read locally
      if (index >= 0 && this.notifications[index]) {
        this.notifications[index].read = true;
      }
    })
  );
}



  readMessage(id: any) {
    this.spinner.show();
    let data = { id: id };
    this.chatService
      .readMessage(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
        },
        error: (err: any) => {
          this.spinner.hide();
        },
      });
  }
  getMessage() {
    this.chatService.getListMessage().subscribe({
      next: (res) => {
        this.messages = res.data;
        console.log('user messages ********** ', this.messages);

        this.unreadMessageCount = this.messages?.reduce(
          (count: any, user: any) => {
            return (
              count +
              user?.chats?.filter((message: any) => message.read === 0).length
            );
          },
          0
        );

        // Assuming that the created_at property is in the first chat entry
        this.elapsedTime = this.formatElapsedTime(
          this.messages[0]?.chats[0]?.created_at
        );
      },
      error: (e) => {
        console.log('get messages error ===== ', e);
      },
    });
  }

  getUsers() {
    this.chatService
      .getListUsers()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.users = res.data.data;
        },
        error: (err) => {
          console.log('error', err);
        },
      });
  }
  navigateToNotification(event: MouseEvent) {
    // Stop event propagation so the dropdown does not close
    event.stopPropagation();

    // Navigate to the notification page (or perform the desired action)
    this.router.navigate(['/wegestu/notifications']);
   this.unreadNotificationCount = 0
  } 
  startConversation(user: any) {
    this.router.navigate(['/wegestu/chat', { user: user }]);
  }
  setHeaderClass(className: any): void {
    this.chatService.setHeaderClass(className);
    console.log('testUserId', className);
    this.router.navigate(['/wegestu/chat', { user: className.id }]);
  }

  formatElapsedTime(created_at: string): string {
    const currentTime = moment();
    const messageTime = moment(created_at);
    const duration = moment.duration(currentTime.diff(messageTime));

    if (duration.asMinutes() <= 59) {
      return this.formatMinutes(duration);
    } else if (duration.asHours() <= 23) {
      return this.formatHours(duration);
    } else if (duration.asDays() <= 6) {
      return this.formatDays(duration);
    } else {
      return this.formatWeeks(duration);
    }
  }

  private formatMinutes(duration: moment.Duration): string {
    const minutes = Math.floor(duration.asMinutes());
    if (minutes < 1) {
      return "à l'instant";
    } else if (minutes === 1) {
      return '1 minute';
    } else {
      return `${minutes} minutes`;
    }
  }

  private formatHours(duration: moment.Duration): string {
    const hours = Math.floor(duration.asHours());
    return hours === 1 ? '1 heure' : `${hours} heures`;
  }

  private formatDays(duration: moment.Duration): string {
    const days = Math.floor(duration.asDays());
    return days === 1 ? '1 jour' : `${days} jours`;
  }

  private formatWeeks(duration: moment.Duration): string {
    const weeks = Math.floor(duration.asDays() / 7);
    return weeks === 1 ? '1 semaine' : `${weeks} semaines`;
  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    const isDropdown = target.closest('#kt_menu_notifications');
    const isMenuTrigger = target.closest('#kt_menu_item_wow');

    if (!isDropdown && !isMenuTrigger) {
      this.closeDropdown();
    }
  }
  toggleDropdownmessage(): void {
    this.showDropdownmessage = !this.showDropdownmessage;
  }
  toggleDropdown(): void {
    this.showDropdown = !this.showDropdown;
  }
  toggleDropdownProfil(): void {
    this.showDropdownProfil = !this.showDropdownProfil;
  }
  closeDropdown(): void {
    this.showDropdown = false;
  }
  showLogoutConfirmation() {
    Swal.fire({
      title: 'Voulez-vous vraiment vous déconnecter de votre espace Wegestu ?',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Se déconnecter',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.logout();
      }
    });
  }
  subscribeToProfileUpdates() {
    this.candidateService.profileUpdated.subscribe(() => {
      this.get_profile_user();
    });
  }

  get_profile_user() {
    this.candidateService.getCandidateProfil(this.id).subscribe({
      next: (res) => {
        this.user = res['data'];
        console.log('user informations********** ', this.user);
        this.image_profile = this.base_url_img + this.user.image_url;
        this.first_name = this.user.first_name;
      this.last_name = this.user.last_name;
      },
      error: (e) => {
        console.log('get user by id error ===== ', e);
      },
      complete: () => {
        console.info('complete');
      },
    });
  }
 



viewAllNotifications(): void {
  // Create a list of observables to mark notifications as read
  const readRequests = this.notifications
    .filter((notification: any) => !notification.read)
    .map((notification: any) => this.readNotification(notification.id, this.notifications.indexOf(notification)));

  // Handle the case when there are no unread notifications
  if (readRequests.length === 0) {
    this.closeDropdown();
    this.router.navigate(['/wegestu/notifications']).then(() => {
      this.unreadNotificationCount = 0;
    });
    return;
  }

  if (this.router.url === '/wegestu/notifications') {
    // If already on the notifications page, reload to reflect the read status
    forkJoin(readRequests).pipe(
      finalize(() => {
        this.closeDropdown();
        window.location.reload(); // Reload the page to show updated notifications
      })
    ).subscribe();
  } else {
    // Navigate to the notifications page and update the read status
    forkJoin(readRequests).pipe(
      finalize(() => {
        this.closeDropdown();
        this.router.navigate(['/wegestu/notifications']).then(() => {
          this.unreadNotificationCount = 0;
        });
      })
    ).subscribe();
  }
}



  readNotificationall(id:any) {
    this.spinner.show();
    let data = { "id": id };
    this.notificationService
      .readNotificationall(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.getNotification()
          this.notifications.read = true;
          this.spinner.hide();
        },
        error: (err: any) => {
          this.spinner.hide();
        },
      });
  }
  notificationRedirection(notification: any, index: number) {
    // Marquer la notification comme lue
    this.readNotification(notification.id, index).subscribe(() => {
      this.closeDropdown(); // Fermer le dropdown après avoir marqué la notification comme lue
  
      // Déterminer l'URL cible en fonction de l'état de la notification
      let targetUrl = '/wegestu/notifications'; // URL par défaut
  
      switch(notification.state) {
        case '4':
        case '5':
        case '6':
        case '7':
        case '8':
        case '9':
        case '11':
          targetUrl = '/wegestu/profil-candidat';
          break;
        case '12':
          targetUrl = '/wegestu/pointage/cra';
          break;
        case '14':
          targetUrl = '/wegestu/evaluations/list-evaluation';
          break;
        case '15':
          targetUrl = '/wegestu/evaluations/pvs-evaluation';
          break;
        case '17':
          targetUrl = '/wegestu/qcm';
          break;
        case '18':
          targetUrl = '/wegestu/pointage/timeSheet';
          break;
          case '19':
            targetUrl = '/wegestu/pointage/cra';
            break;
            case '25':
              targetUrl = '/wegestu/profil-candidat';
              break;
        default:
          targetUrl = '/wegestu/notifications';
          break;
      }
  
      // Vérifie si l'utilisateur est déjà sur la page cible
      if (this.router.url !== targetUrl) {
        // Si ce n'est pas la même URL, navigue vers la nouvelle URL
        this.router.navigate([targetUrl]).then(() => {
          this.closeDropdown(); // Fermer le dropdown après la navigation
        });
      } else {
        // Si c'est la même URL, juste fermer le dropdown
        this.closeDropdown();
        // Optionnel : actualiser la page si nécessaire pour refléter les changements
        this.router.navigate([targetUrl]).then(() => window.location.reload());
      }
    });
  }
  
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    if (this.routerEventsSubscription) {
      this.routerEventsSubscription.unsubscribe();
    }
  }
}
