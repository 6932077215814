import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { IRequstJob } from '../../models/jobOffer';
import { serverError } from '../../../../shared/shared-models/message';
import { JobsService } from '../../services/jobs.service';

@Component({
  selector: 'app-details-job-request',
  templateUrl: './details-job-request.component.html',
  styleUrls: ['./details-job-request.component.css'],
})
export class DetailsJobRequestComponent {
  /* number */
  idRequestJob!: any;
  /* object */
  jobRequest!: IRequstJob;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private jobService: JobsService,
    private toastrService: ToastrService
  ) {}
  ngOnInit() {
    this.getIdRequestJob();
  }
  /* get request job id from route */
  getIdRequestJob() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idRequestJob = params.params['id'];
        this.getRequestJob();
      },
    });
  }
  /* get request job by id */
  getRequestJob() {
    this.spinner.show();
    this.jobService.getJobRequestbyId(this.idRequestJob).subscribe({
      next: (res) => {
        this.spinner.hide();
        this.jobRequest = res.data[0];
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
