export class CJobOffer{
    id:string|undefined
    client_id:string|undefined
    name:string|undefined
    start_date:string|undefined
    candidate_job_offer_creation_date:string|undefined
    end_date:string|undefined
    archived:string|undefined
    state:string|undefined
    desired_salary:string|undefined
    years_of_experience:string|undefined
    availability:string|undefined
    tjm:string|undefined
    skills:string|undefined
    esn_name:string|undefined
    client_final_name:string|undefined
    created_at:string|undefined
    client_final:{name:string} |undefined
    skills_job:{sub_category:{name_FR:string}}[] 
    esn_id:string|undefined
    esn:{name:string}|undefined
    tjm_range:string|undefined
    desired_salary_range:string|undefined
    year_experience:string|undefined
    poste:string|undefined
    salaire:string|undefined
    salaire_range:string|undefined
    constructor(data?:CJobOffer){
      this.id = data && data.id ||""
      this.client_id = data && data.client_id ||""
      this.name = data && data.name ||""
      this.start_date = data && data.start_date ||""
      this.candidate_job_offer_creation_date = data && data.candidate_job_offer_creation_date ||""
      this.end_date = data && data.end_date ||""
      this.archived = data && data.archived ||""
      this.state = data && data.state ||""
      this.desired_salary = data && data.desired_salary ||""
      this.years_of_experience = data && data.years_of_experience ||""
      this.availability = data && data.availability ||""
      this.tjm = data && data.tjm ||""
      this.skills = data && data.skills ||""
      this.esn_name = data && data.esn_name ||""
      this.client_final_name = data && data.client_final_name ||""
      this.created_at = data && data.created_at ||""
      this.skills_job = data && data.skills_job ||[]
      this.esn_id = data && data.esn_id ||""
      this.tjm_range = data && data.tjm_range ||""
      this.desired_salary_range = data && data.desired_salary_range ||""
      this.year_experience = data && data.year_experience ||""
      this.poste = data && data.poste ||""
      this.salaire = data && data.salaire ||""
      this.salaire_range = data && data.salaire_range ||""
  
    }
  }
  export interface IRequstJob{
    name: string | undefined;
  post_type: string | undefined;
  city: string | undefined;
  country : string | undefined; // Mettre à jour le type en number
  availability: string | undefined;
  salary: string | undefined;
  years_of_experience : string | undefined;
  contract_type: string | undefined;
  tjm: string | undefined;
  description : string | undefined;
  skills:string
  start_date:string
  client_final:{name:string}
  esn:{name:string}
  }
  // getSortName(columnName: string): string {
  //   switch (columnName) {
  //     case 'ID':
  //       return 'ID_jobOffer';
  //     case 'Nom entreprise':
  //       return 'esn_name';
  //     case 'Type entreprise':
  //       return 'post_type';
  //     case 'Poste':
  //       return 'name';
  //     case 'Date de publication':
  //       return 'start_date';
  //     case 'Années d’expériences':
  //       return 'years_of_experience';
  //     case 'Type de contrat':
  //       return 'contract_type';
  //     case 'Type de poste':
  //       return 'post_type'; 
  //     case 'Disponibilité':
  //       return 'availability';
  //     case 'Score':
  //       return 'Score';
  //     case 'TJM':
  //       return 'tjm';
  //     case 'Salaire':
  //       return 'salary';
  //     case 'Compétence':
  //       return 'skills';
  //     case 'Pays':
  //       return 'country';
  //     case 'Ville':
  //       return 'city';
  //     case 'Statut':
  //       return 'state';
         
  //     default:
  //       return columnName;
  //   }
  // }
  export const headerColumns1=[
    { checked: true, name: 'ID', code: 'ID_jobOffer' },
    { checked: true, name: 'Nom entreprise', code: 'esn_name' },
    { checked: true, name: 'Type entreprise', code: 'type' },
    { checked: true, name: 'Poste', code: 'name' },
    { checked: true, name: 'Date de publication', code: 'start_date' },
    { checked: true, name: 'Années d’expériences', code: 'years_of_experience' },
    { checked: true, name: 'Type de contrat', code: 'contract_type' },
    { checked: true, name: 'Type de poste', code: 'post_type' },
    { checked: true, name: 'Disponibilité', code: 'availability' },
    { checked: true, name: 'Score', code: 'Score' },
    {checked:true,name:'TJM',code: 'tjm'},
    {checked:true,name:'Salaire',code: 'salary'},
    { checked: true, name: 'Compétence', code: 'skills' },
    { checked: true, name: 'Pays', code:'country' },
    { checked: true, name: 'Ville', code: 'city' },
    { checked: true, name: 'Statut', code: 'state' }, 
  ];
  export const headerColumns2=[
    { checked: true, name: 'ID', code: 'ID_jobOffer' },
    { checked: true, name: 'Nom entreprise', code: 'esn_name' },
    { checked: true, name: 'Type entreprise', code: 'type' },
    { checked: true, name: 'Poste', code: 'name' },
    { checked: true, name: 'Date de publication', code: 'start_date' },
    { checked: true, name: 'Date de candidature', code: 'candidate_job_offer_creation_date' },
    { checked: true, name: 'Années d’expériences', code: 'years_of_experience' },
    { checked: true, name: 'Type de contrat', code: 'contract_type' },
    { checked: true, name: 'Type de poste', code: 'post_type' },
    { checked: true, name: 'Score', code: 'Score' },
    {checked:true,name:'TJM',code: 'tjm'},
    {checked:true,name:'Salaire',code: 'salary'},
    { checked: true, name: 'Compétence', code: 'skills' },
    { checked: true, name: 'Pays', code:'country' },
    { checked: true, name: 'Ville', code: 'city' },
    { checked: true, name: 'Statut', code: 'state' }, 
  ];
  export const headerColumns3=[
    { checked: true, name: 'ID', code: 'ID_jobOffer' },
    { checked: true, name: 'Nom entreprise', code: 'esn_name' },
    { checked: true, name: 'Type entreprise', code: 'type' },
    { checked: true, name: 'Poste', code: 'name' },
    { checked: true, name: 'Date de publication', code: 'start_date' },
    { checked: true, name: 'Date de candidature', code: 'candidate_job_offer_creation_date' },
    { checked: true, name: 'Années d’expériences', code: 'years_of_experience' },
    { checked: true, name: 'Type de contrat', code: 'contract_type' },
    { checked: true, name: 'Type de poste', code: 'post_type' },
    { checked: true, name: 'Disponibilité', code: 'availability' },
    { checked: true, name: 'Score', code: 'Score' },
    {checked:true,name:'TJM',code: 'tjm'},
    {checked:true,name:'Salaire',code: 'salary'},
    { checked: true, name: 'Compétence', code: 'skills' },
    { checked: true, name: 'Pays', code:'country' },
    { checked: true, name: 'Ville', code: 'city' },
    { checked: true, name: 'Statut', code: 'state' }, 
  ];
  export const headerColumns4=[
    { checked: true, name: 'ID', code: 'ID_jobOffer' },
    { checked: true, name: 'Nom entreprise', code: 'esn_name' },
    { checked: true, name: 'Type entreprise', code: 'type' },
    { checked: true, name: 'Poste', code: 'name' },
    { checked: true, name: 'Date de publication', code: 'start_date' },
    { checked: true, name: 'Date de candidature', code: 'candidate_job_offer_creation_date' },
    { checked: true, name: 'Années d’expérience', code: 'years_of_experience' },
    { checked: true, name: 'Type de contrat', code: 'contract_type' },
    { checked: true, name: 'Type de poste', code: 'post_type' },
    { checked: true, name: 'Score', code: 'Score' },
    {checked:true,name:'TJM',code: 'tjm'},
    {checked:true,name:'Salaire',code: 'salary'},
    { checked: true, name: 'Compétence', code: 'skills' },
    { checked: true, name: 'Pays', code:'country' },
    { checked: true, name: 'Ville', code: 'city' },
    { checked: true, name: 'Statut', code: 'state' }, 
  ];