import {
  NgSignaturePadOptions,
  SignaturePadComponent,
} from '@almothafar/angular-signature-pad';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subject } from 'rxjs';
import { EvaluationsService } from '../../../../../../main/evaluation/services/evaluations.service';
import {
  serverError,
  successSignature,
} from '../../../../../../shared/shared-models/message';
import { LocalStorageService } from 'src/app/shared/shared-services/local-storage.service';

@Component({
  selector: 'app-pv-signed',
  templateUrl: './pv-signed.component.html',
  styleUrls: ['./pv-signed.component.css'],
})
export class PvSignedComponent {
  @ViewChild('signature', { static: true })
  signaturePad!: SignaturePadComponent;
  private signatureDataUrl: string | null = null;

  private unsubscribeAll: Subject<void> = new Subject();
  signatureFile: any;
  formData = new FormData();
  itemsPerPage: number = 20;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentUser: any;
  currentSortDirection: number = 2;
  id: any;
  listPv: any;
  totalPvs: any;
  company:any
  currentSortColumn: string = 'name';
  public signaturePadOptions: NgSignaturePadOptions = {
    minWidth: 1,
    canvasWidth: 300,
    canvasHeight: 250,
    backgroundColor: '#ffffff',
  };

  signed = [
    { id: 2, name: 'Signé par l’ESN' },
    { id: 1, name: 'Signé par vous' },
  ];
  idPv: any;
  company_name:any
  detailsPv: any;
  evaluation: any;
  evaluationDetais:any
  constructor(
    private evaluationsService: EvaluationsService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}
  ngOnInit(): void {
    this.getIdPv();
    this.getDetailsPv();
    this.company_name = this.company;
    console.log("company name",this.company_name )
  }
  ngAfterViewInit() {
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.clear();
  }

  getIdPv() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idPv = params.params['idPv'];
      },
    });
  }
  getDetailsPv() {
    let dataPayload = this.idPv;
    this.spinner.show();
    this.evaluationsService
      .getAllDetailsOfPv(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.detailsPv = res?.data;
          console.log("detailspv",this.detailsPv)
          // this.evaluation = res?.data?.evaluation_send?.response_evaluation;
          this.evaluationDetais =  res?.data?.evaluation_send?.evaluation.company.id;
          console.log("detailspv",this.evaluationDetais)
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching detailsPv:', error);
        }
      );
  }
  drawComplete() {
    console.log('Completed drawing', event);
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    console.log('Signature Data URL:', this.signatureDataUrl);
    const blob = this.dataURLToBlob(this.signatureDataUrl);
    this.signatureFile = new File([blob], 'signature.png');
    console.log('this.signatureFilethis.signatureFile', this.signatureFile);
  }
  drawStart(event: MouseEvent | Touch) {
    console.log('Start drawing', event);
  }
  clearSignature() {
    console.log('signaturePadsignaturePadsignaturePad', this.signaturePad);
    this.signaturePad?.clear();
  }
  downloadSignature() {
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    this.convertBase64ToImage(this.signatureDataUrl);
  }

  dataURLToBlob(dataURL: string): Blob {
    const arr = dataURL?.match(/:(.*?);/);
    if (arr && arr[1]) {
      const mime = arr[1];
      const bstr = atob(dataURL?.split(',')[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr?.charCodeAt(n);
      }

      return new Blob([u8arr], { type: mime });
    } else {
      console.error('Failed to determine MIME type from data URL:', dataURL);
      return new Blob();
    }
  }

  convertBase64ToImage(base64Data: string) {
    const image = new Image();
    image.src = base64Data;

    const downloadLink = document.createElement('a');
    downloadLink.href = base64Data;
    downloadLink.download = 'signature.png';

    const container = document.getElementById('imageContainer');
    if (container) {
      container.innerHTML = '';
      container.appendChild(image);
      container.appendChild(downloadLink);
      downloadLink.click();
    }
  }
  sendSignature() {
    this.spinner.show();
    this.signatureDataUrl = this.signaturePad?.toDataURL();
    console.log('Signature Data URL:', this.signatureDataUrl);
    const blob = this.dataURLToBlob(this.signatureDataUrl);
    this.signatureFile = new File([blob], 'signature.png');
    console.log('this.signatureFilethis.signatureFile', this.signatureFile);

    if (!this.signatureFile) {
      console.error('No signature file selected.');
      this.spinner.hide();
      return;
    }

    this.formData.append('signature_file_candidate', this.signatureFile);
    this.evaluationsService.addSignature(this.idPv,this.evaluationDetais,this.formData).subscribe(
      (response) => {
        console.log('Signature sent to the backend successfully', response);
        this.toastr.success(successSignature);
        this.spinner.hide();
        this.router.navigateByUrl(
          `/wegestu/evaluations/details-pv;idPv=${this.idPv}`
        );
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(serverError);
        console.error('Error sending signature to the backend', error);
      }
    );
  }
}
