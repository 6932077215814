import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DossierComptComponent } from './components/dossier-compt/dossier-compt.component';
import { ModifierDossierComponent } from './components/modifier-dossier/modifier-dossier.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CvCandidatComponent } from './components/cv-candidat/cv-candidat.component';

const routes: Routes = [
  {
    path:'',
    component:DossierComptComponent
  },
  {
    path: 'modifier',
    component: ModifierDossierComponent
  },
 {
    path: 'change-password',
    component: ChangePasswordComponent
  },
  {
    path: 'cv-candidat',
    component: CvCandidatComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DossierRoutingModule { }
