import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthentificationService } from '../../../../authentification/services/authentification.service';
import { MustMatch } from '../../../../shared/_helpers/must-match.validator';
import { ChangePassoword } from '../../../../shared/shared-models/login';
import {
  serverError,
  changePasswordSuccess,
  oldPasswordError,
  passwords,
} from '../../../../shared/shared-models/message';
import { patternPassword } from '../../../../shared/shared-models/pattern';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent {
  /* formGroup */
  generateResePasswordForm: FormGroup = this.resetPasswordForm(
    new ChangePassoword()
  );
  showOldPassword: boolean = false;
  showConfirmedNewPassword: boolean = false;
  showNewPassword: boolean = false;
  showPassword: boolean = false;
  userEmail!: string;
  currentUser!: any;
  email_user!: string;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthentificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.email_user = JSON.parse(this.currentUser).email;
    console.log(
      '%cchange-password.component.ts line:36 email_user',
      'color: #007acc;',
      this.email_user
    );
  }

  /* create resetPassword form */
  resetPasswordForm(data: ChangePassoword) {
    return this.formBuilder.group(
      {
        oldPassword: [
          data.oldPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
        newPassword: [
          data.newPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
        confirmNewPassword: [
          data.confirmNewPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
      },
      {
        validator: MustMatch('newPassword', 'confirmNewPassword'),
      }
    );
  }
  togglePasswordVisibility(field: string) {
    if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'oldPassword') {
      this.showOldPassword = !this.showOldPassword;
    } else if (field === 'ConfirmedNewPassword') {
      this.showConfirmedNewPassword = !this.showConfirmedNewPassword;
    }
  }
  /* reset password */
  resetPassword() {
    if (this.generateResePasswordForm.valid) {
      const oldPassword =
        this.generateResePasswordForm.get('oldPassword')?.value;
      const newPassword =
        this.generateResePasswordForm.get('newPassword')?.value;

      // Compare old and new passwords
      if (oldPassword === newPassword) {
        console.log('Old and new passwords cannot be the same.');
        this.toastrService.error(passwords);
      } else {
        const data = {
          oldPassword: oldPassword,
          newPassword: newPassword,
          email: this.email_user,
        };

        this.authService
          .changePassword(data)
          .pipe()
          .subscribe({
            next: (res) => {
              console.log('resss', res);
              if (res.status == 200) {
                this.toastrService.success(changePasswordSuccess);
                this.authService.logOut();
                this.router.navigate(['auth/login']);
              }
            },
            error: (err: any) => {
              console.log(
                '%creset-password.component.ts line:50 err',
                'color: #007acc;',
                err
              );

              if (err.error) {
                this.toastrService.error(
                  err.error.status === 403 ? oldPasswordError : serverError
                );
              }
            },
          });
      }
    }
  }

  logout() {
    this.authService.logOut();
  }

  /* show and hide password */
  show_password(event: any) {
    this.showPassword = (event.target as HTMLInputElement).checked;
  }
}
