    <!--begin::Main-->
    <div class="app-main flex-column flex-row-fluid" id="kt_app_main">
        <!--begin::Content wrapper-->
        <div class="d-flex flex-column flex-column-fluid">

            <!--begin::Toolbar-->
            <div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
                <!--begin::Toolbar container-->
                <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
                    <!--begin::Page title-->
                    <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
                        <!--begin::Title-->
                     <div class="d-flex align-items-center" >
                        <h1 class="page-heading"><svg width="42" height="27" viewBox="0 0 42 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_651_309)">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M32.3028 5.08326C31.7505 5.08326 31.3028 5.53097 31.3028 6.08326V17.5453H27.3636V0.999999C27.3636 0.447714 26.9158 0 26.3636 0H23.9696C23.4173 0 22.9696 0.447715 22.9696 1V17.5453H19.0304V11.5537C19.0304 11.0014 18.5827 10.5537 18.0304 10.5537H15.6364C15.0842 10.5537 14.6364 11.0014 14.6364 11.5537V17.5453H10.6913V8.07453C10.6913 7.52224 10.2436 7.07453 9.69133 7.07453H7.29738C6.74509 7.07453 6.29738 7.52224 6.29738 8.07453V17.5453H4V19H38V17.5453H35.7085V6.08326C35.7085 5.53097 35.2608 5.08326 34.7085 5.08326H32.3028Z" fill="#265D91"/>
                            </g>
                            <defs>
                            <filter id="filter0_d_651_309" x="0" y="0" width="42" height="27" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                            <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="4"/>
                            <feGaussianBlur stdDeviation="2"/>
                            <feComposite in2="hardAlpha" operator="out"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_651_309"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_651_309" result="shape"/>
                            </filter>
                            </defs>
                            </svg>
                            
                            
                            Evaluation
                        </h1>
                            <!--begin::Menu-->
                     </div>
                        <!--end::Title-->
                        <!--begin::Breadcrumb-->
                        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                            <!--begin::Item-->
                            <li class="breadcrumb-item text-muted">
                                <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                            </li>
                            <!--end::Item-->
                            <!--begin::Item-->
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <!--end::Item-->
                            <!--begin::Item-->
                            <li *ngIf="evaluation?.evaluation_send.dateSendByCandidate===null" class="breadcrumb-item text-muted"> <a class="text-muted" routerLink="/wegestu/evaluations/list-evaluation" >Evaluations réçues</a></li>
                            <li *ngIf="evaluation?.evaluation_send.dateSendByCandidate!==null" class="breadcrumb-item text-muted"> <a class="text-muted"routerLink="/wegestu/evaluations/completed-assessment" >Evaluations Remplies</a></li>
                            <li class="breadcrumb-item">
                                <span class="bullet bg-gray-400 w-5px h-2px"></span>
                            </li>
                            <li class="breadcrumb-item text-muted">
                               <span class="text-muted">Détails évaluation</span>
                            </li>
                            <!--end::Item-->
                        </ul>
                        <!--end::Breadcrumb-->
                    </div>
                    <!--end::Page title-->
                    <!--begin::Actions-->

                    <!--end::Actions-->
                </div>
                <!--end::Toolbar container-->
            </div>
            <!--end::Toolbar-->
         
            <!--begin::Content-->

            <div id="kt_app_content" class="app-content flex-column-fluid mb-4">
                <!--begin::Content container-->
                <div id="kt_app_content_container" class="app-container container-fluid">
                    <!--begin::Card-->
                    <div class="card pb-10 pt-10">
                     
                        <!--begin::Card body-->
                        <div class="card-body">
                           
                            <div class="contents">
                                <div class="card-body pt-3">
                                    <!--begin::Table-->
                                  <div class="container">
                                    <div class="contenu">
                                        <div class="bloc-input mb-5">
                                            <h4 class="mb-2">
                                                <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.0386 4.32104V5.58229H8.90331C8.55605 5.58229 8.27344 5.29967 8.27344 4.95242C8.27344 4.60516 8.55605 4.32255 8.90331 4.32255L9.0386 4.32104Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M14.3822 3.06131V3.98582C14.292 3.95876 14.1958 3.94523 14.0966 3.94523H13.9613V3.65961C13.9613 3.61301 13.9448 3.56791 13.9132 3.53333L12.9872 2.49908C12.9526 2.46 12.9045 2.43745 12.8519 2.43595C12.8008 2.43444 12.7497 2.45399 12.7136 2.49157C11.7861 3.41909 9.75666 3.4717 9.31771 3.4717C9.2651 3.4717 9.23503 3.4717 9.23202 3.4702C9.18091 3.46869 9.1313 3.48824 9.09523 3.52281C9.05915 3.55889 9.0381 3.60699 9.0381 3.6581V3.94373H8.90281C8.80359 3.94373 8.70738 3.95876 8.61719 3.98431V3.08386C8.61719 2.30667 8.92385 1.57458 9.47856 1.01987C10.0273 0.474183 10.7473 0.173529 11.5095 0.176536C13.0939 0.184052 14.3822 1.47686 14.3822 3.06131Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M14.0962 4.32105C14.4435 4.32105 14.7261 4.60366 14.7261 4.95091C14.7261 5.29817 14.4435 5.58078 14.0962 5.58078H13.9609V4.31954L14.0962 4.32105Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M13.5841 4.13314V5.71758C13.5841 6.86758 12.6491 7.80262 11.4991 7.80262C10.3491 7.80262 9.41406 6.86758 9.41406 5.71758V4.13314V3.84902C9.6621 3.84602 10.1206 3.82798 10.6482 3.7453C11.6058 3.59497 12.3394 3.30935 12.8325 2.89294L13.5841 3.73177V4.13314Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M11.26 10.2635H6.58789C6.83743 10.0455 7.13057 9.88164 7.45227 9.78242L10.1326 8.95563L11.26 10.2635Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M12.6156 7.91085V8.6715L11.5002 9.96431L10.3848 8.6715V7.91085C10.72 8.08222 11.0988 8.17843 11.5002 8.17843C11.9016 8.17843 12.2804 8.08222 12.6156 7.91085Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M16.4124 10.2635H11.7402L12.8692 8.95563L15.548 9.78393C15.8682 9.88164 16.1613 10.0455 16.4124 10.2635Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M18.3583 10.737C18.417 10.7986 18.444 10.8798 18.438 10.9655L17.8968 18.1977C17.8848 18.3555 17.7525 18.4773 17.5947 18.4773H5.40467C5.24683 18.4773 5.11454 18.354 5.10251 18.1977L4.56134 10.9655C4.55532 10.8798 4.58388 10.8001 4.64101 10.737C4.69964 10.6738 4.77781 10.6408 4.86349 10.6408H6.14428H16.8536H18.1343C18.2215 10.6408 18.2997 10.6754 18.3583 10.737ZM11.6883 14.9416C12.3407 14.8499 12.8443 14.2877 12.8443 13.6097C12.8443 12.8686 12.2415 12.2658 11.5004 12.2658C10.7593 12.2658 10.1565 12.8686 10.1565 13.6097C10.1565 13.7135 10.2407 13.7976 10.3444 13.7976C10.4481 13.7976 10.5323 13.7135 10.5323 13.6097C10.5323 13.0761 10.9668 12.6416 11.5004 12.6416C12.0341 12.6416 12.4685 13.0761 12.4685 13.6097C12.4685 14.1434 12.0341 14.5778 11.5004 14.5778C11.3967 14.5778 11.3125 14.662 11.3125 14.7657V15.8511C11.3125 15.9548 11.3967 16.039 11.5004 16.039C11.6041 16.039 11.6883 15.9548 11.6883 15.8511V14.9416ZM11.7575 16.6719C11.7575 16.5396 11.6507 16.4329 11.5185 16.4329C11.3862 16.4329 11.2794 16.5396 11.2794 16.6719C11.2794 16.8042 11.3862 16.9109 11.5185 16.9109C11.6507 16.9109 11.7575 16.8027 11.7575 16.6719Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M23 20.7157V21.0584H0V20.7157C0 19.6935 0.831307 18.8622 1.85353 18.8622H21.1465C22.1687 18.8607 23 19.6935 23 20.7157Z"
                                                    fill="#265D91" />
                                            </svg> 
                                                Nom</h4>
                                           <span>{{evaluationDetais?.name}}</span>
                                        </div>
                                        <div class="bloc-input mb-5">
                                            <h4 class="mb-2">
                                                <svg width="23" height="22"viewBox="0 0 19 20" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M0.410617 3.65116H3.24048C3.46637 3.65116 3.6512 3.4663 3.6512 3.24044V0.410552C3.6512 0.246303 3.55263 0.094288 3.39655 0.0327197C3.24459 -0.0330099 3.06798 0.00394104 2.94888 0.118955L0.123107 2.94884C0.00398093 3.06791 -0.0329701 3.24455 0.0327345 3.39651C0.0943529 3.55259 0.246318 3.65116 0.410617 3.65116Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M5.60622 14.4206V12.4368C5.60622 11.7573 6.15893 11.2046 6.83839 11.2046H7.34457L6.98479 10.8448C6.75215 10.613 6.6238 10.3038 6.6238 9.97446C6.6238 9.64516 6.75215 9.33591 6.98559 9.10328L8.38943 7.69944C8.5988 7.47683 8.92128 7.33966 9.2578 7.33966C9.5883 7.33966 9.89835 7.46761 10.1302 7.69944L10.49 8.05922V7.55304C10.49 6.87358 11.0427 6.32087 11.7221 6.32087H13.1226V0.410722C13.1226 0.184805 12.9418 0 12.7118 0H4.4012C4.44613 0.129253 4.47272 0.26703 4.47272 0.410722V3.24046C4.47272 3.91991 3.92001 4.47262 3.24056 4.47262H0.410823C0.267205 4.47262 0.129479 4.44595 0 4.40053V16.9546C0 17.1806 0.184805 17.3654 0.410722 17.3654H6.72302C6.65862 17.2146 6.6238 17.0516 6.6238 16.8829C6.6238 16.5536 6.75215 16.2444 6.98559 16.0118L7.34417 15.6528H6.83839C6.15893 15.6528 5.60622 15.1001 5.60622 14.4206ZM4.51925 14.2044H2.46564C2.23862 14.2044 2.05492 14.0207 2.05492 13.7937C2.05492 13.5667 2.23862 13.383 2.46564 13.383H4.51925C4.74627 13.383 4.92997 13.5667 4.92997 13.7937C4.92997 14.0207 4.74627 14.2044 4.51925 14.2044ZM4.51925 11.7401H2.46564C2.23862 11.7401 2.05492 11.5564 2.05492 11.3294C2.05492 11.1023 2.23862 10.9186 2.46564 10.9186H4.51925C4.74627 10.9186 4.92997 11.1023 4.92997 11.3294C4.92997 11.5564 4.74627 11.7401 4.51925 11.7401ZM4.51925 9.27575H2.46564C2.23862 9.27575 2.05492 9.09205 2.05492 8.86503C2.05492 8.63801 2.23862 8.4543 2.46564 8.4543H4.51925C4.74627 8.4543 4.92997 8.63801 4.92997 8.86503C4.92997 9.09205 4.74627 9.27575 4.51925 9.27575ZM4.51925 6.81141H2.46564C2.23862 6.81141 2.05492 6.62771 2.05492 6.40069C2.05492 6.17367 2.23862 5.98997 2.46564 5.98997H4.51925C4.74627 5.98997 4.92997 6.17367 4.92997 6.40069C4.92997 6.62771 4.74627 6.81141 4.51925 6.81141Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M18.5892 12.026H17.0121C16.9381 11.8042 16.8478 11.5865 16.7451 11.3812L17.8581 10.264C18.0224 10.1039 18.0224 9.84505 17.8581 9.68492L16.4576 8.28023C16.2974 8.12004 16.0387 8.12004 15.8785 8.28023L14.7613 9.39738C14.5518 9.29059 14.3383 9.20435 14.1165 9.1304V7.55324C14.1165 7.32326 13.9316 7.14252 13.7057 7.14252H11.722C11.4961 7.14252 11.3112 7.32326 11.3112 7.55324V9.1304C11.0894 9.20435 10.8759 9.29059 10.6664 9.39738L9.54924 8.28023C9.4712 8.20216 9.36852 8.1611 9.25762 8.1611C9.15083 8.1611 9.04403 8.20216 8.97011 8.28023L7.56544 9.68492C7.40526 9.84505 7.40526 10.1039 7.56544 10.264L8.6826 11.3812C8.5758 11.5865 8.48957 11.8042 8.41564 12.026H6.83846C6.60845 12.026 6.42773 12.2108 6.42773 12.4367V14.4206C6.42773 14.6464 6.60845 14.8313 6.83846 14.8313H8.41564C8.48957 15.053 8.5758 15.2666 8.6826 15.4761L7.56544 16.5932C7.40526 16.7534 7.40526 17.0122 7.56544 17.1724L8.97011 18.5729C9.1303 18.7372 9.38905 18.7372 9.54924 18.5729L10.6664 17.4599C10.8759 17.5625 11.0894 17.6529 11.3112 17.7268V19.304C11.3112 19.5299 11.4961 19.7147 11.722 19.7147H13.7057C13.9316 19.7147 14.1165 19.5299 14.1165 19.304V17.7268C14.3383 17.6529 14.5518 17.5625 14.7613 17.4599L15.8785 18.577C15.9524 18.651 16.0592 18.6961 16.166 18.6961C16.2769 18.6961 16.3795 18.651 16.4576 18.577L17.8581 17.1724C18.0224 17.0122 18.0224 16.7534 17.8581 16.5932L16.7451 15.4761C16.8478 15.2666 16.9381 15.053 17.0121 14.8313H18.5892C18.8151 14.8313 18.9999 14.6464 18.9999 14.4206V12.4367C18.9999 12.2108 18.8151 12.026 18.5892 12.026ZM12.7118 16.047C11.2701 16.047 10.0955 14.8723 10.0955 13.4265C10.0955 11.985 11.2701 10.8103 12.7118 10.8103C14.1575 10.8103 15.3322 11.985 15.3322 13.4265C15.3322 14.8723 14.1575 16.047 12.7118 16.047Z"
                                                    fill="#265D91" />
                                            </svg>
                                                Type</h4>
                                           <span>{{evaluationDetais?.type=='1'?'Annuelle':
                                            evaluationDetais?.type=='2'?'Suivi ':'---' }}</span>
                                        </div>
                                        <div class="bloc-input mb-5">
                                            <h4 class="mb-2"> 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 25 25"
                                                fill="none">
                                                <path
                                                    d="M23.1568 9.88122C23.1568 9.68158 23.0768 9.49011 22.9342 9.34894C22.7917 9.20777 22.5984 9.12846 22.3968 9.12846H14.5005C14.2989 9.12846 14.1056 9.20777 13.9631 9.34894C13.8205 9.49011 13.7405 9.68158 13.7405 9.88122V21.4587H12.375V22.9642H24.3196V21.4587H23.1568V9.88122ZM15.2605 10.634H21.6368V21.4587H15.2605V10.634Z"
                                                    fill="#265D91" />
                                                <path d="M17.2134 11.3993H15.6934V13.6726H17.2134V11.3993Z" fill="#265D91" />
                                                <path d="M19.2387 11.3993H17.7188V13.6726H19.2387V11.3993Z" fill="#265D91" />
                                                <path d="M21.2622 11.3993H19.7422V13.6726H21.2622V11.3993Z" fill="#265D91" />
                                                <path d="M17.2134 14.9097H15.6934V17.183H17.2134V14.9097Z" fill="#265D91" />
                                                <path d="M19.2387 14.9097H17.7188V17.183H19.2387V14.9097Z" fill="#265D91" />
                                                <path d="M21.2622 14.9097H19.7422V17.183H21.2622V14.9097Z" fill="#265D91" />
                                                <path d="M17.2134 18.4175H15.6934V20.6909H17.2134V18.4175Z" fill="#265D91" />
                                                <path d="M19.2387 18.4175H17.7188V20.6909H19.2387V18.4175Z" fill="#265D91" />
                                                <path d="M21.2622 18.4175H19.7422V20.6909H21.2622V18.4175Z" fill="#265D91" />
                                                <path
                                                    d="M10.7818 1.88696C10.7818 1.68732 10.7018 1.49585 10.5592 1.35468C10.4167 1.21351 10.2234 1.1342 10.0218 1.1342H2.12546C1.9239 1.1342 1.73059 1.21351 1.58806 1.35468C1.44553 1.49585 1.36546 1.68732 1.36546 1.88696V13.4644H0V14.9699H11.9446V13.4644H10.7818V1.88696ZM2.88546 2.63972H9.26184V13.4644H2.88546V2.63972Z"
                                                    fill="#265D91" />
                                                <path d="M4.83836 3.40749H3.31836V5.68082H4.83836V3.40749Z" fill="#265D91" />
                                                <path d="M6.8657 3.40749H5.3457V5.68082H6.8657V3.40749Z" fill="#265D91" />
                                                <path d="M8.88914 3.40749H7.36914V5.68082H8.88914V3.40749Z" fill="#265D91" />
                                                <path d="M4.83836 6.91538H3.31836V9.18872H4.83836V6.91538Z" fill="#265D91" />
                                                <path d="M6.8657 6.91538H5.3457V9.18872H6.8657V6.91538Z" fill="#265D91" />
                                                <path d="M8.88914 6.91538H7.36914V9.18872H8.88914V6.91538Z" fill="#265D91" />
                                                <path d="M4.83836 10.4232H3.31836V12.6966H4.83836V10.4232Z" fill="#265D91" />
                                                <path d="M6.8657 10.4232H5.3457V12.6966H6.8657V10.4232Z" fill="#265D91" />
                                                <path d="M8.88914 10.4232H7.36914V12.6966H8.88914V10.4232Z" fill="#265D91" />
                                                <path
                                                    d="M21.1531 6.58414H22.6731V2.35363H17.6495V0L13.0996 3.07126L17.6596 6.11994V3.86166H21.1632L21.1531 6.58414ZM15.8052 3.07126L16.1244 2.85547V3.28454L15.8052 3.07126Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M3.16648 17.5142H1.64648V21.7447H6.67007V24.0983L11.2199 21.0271L6.65994 17.9784V20.2367H3.15635L3.16648 17.5142ZM8.51433 21.0271L8.19513 21.2429V20.8188L8.51433 21.0271Z"
                                                    fill="#265D91" />
                                            </svg>
                                                Nom ESN</h4>
                                           <span>{{evaluationDetais?.company?.name}}</span>
                                        </div>
                                        <div class="bloc-input mb-5">
                                            <h4 class="mb-2">  
                                                 <svg width="23" height="22" viewBox="0 0 23 22" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M9.0386 4.32104V5.58229H8.90331C8.55605 5.58229 8.27344 5.29967 8.27344 4.95242C8.27344 4.60516 8.55605 4.32255 8.90331 4.32255L9.0386 4.32104Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M14.3822 3.06131V3.98582C14.292 3.95876 14.1958 3.94523 14.0966 3.94523H13.9613V3.65961C13.9613 3.61301 13.9448 3.56791 13.9132 3.53333L12.9872 2.49908C12.9526 2.46 12.9045 2.43745 12.8519 2.43595C12.8008 2.43444 12.7497 2.45399 12.7136 2.49157C11.7861 3.41909 9.75666 3.4717 9.31771 3.4717C9.2651 3.4717 9.23503 3.4717 9.23202 3.4702C9.18091 3.46869 9.1313 3.48824 9.09523 3.52281C9.05915 3.55889 9.0381 3.60699 9.0381 3.6581V3.94373H8.90281C8.80359 3.94373 8.70738 3.95876 8.61719 3.98431V3.08386C8.61719 2.30667 8.92385 1.57458 9.47856 1.01987C10.0273 0.474183 10.7473 0.173529 11.5095 0.176536C13.0939 0.184052 14.3822 1.47686 14.3822 3.06131Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M14.0962 4.32105C14.4435 4.32105 14.7261 4.60366 14.7261 4.95091C14.7261 5.29817 14.4435 5.58078 14.0962 5.58078H13.9609V4.31954L14.0962 4.32105Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M13.5841 4.13314V5.71758C13.5841 6.86758 12.6491 7.80262 11.4991 7.80262C10.3491 7.80262 9.41406 6.86758 9.41406 5.71758V4.13314V3.84902C9.6621 3.84602 10.1206 3.82798 10.6482 3.7453C11.6058 3.59497 12.3394 3.30935 12.8325 2.89294L13.5841 3.73177V4.13314Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M11.26 10.2635H6.58789C6.83743 10.0455 7.13057 9.88164 7.45227 9.78242L10.1326 8.95563L11.26 10.2635Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M12.6156 7.91085V8.6715L11.5002 9.96431L10.3848 8.6715V7.91085C10.72 8.08222 11.0988 8.17843 11.5002 8.17843C11.9016 8.17843 12.2804 8.08222 12.6156 7.91085Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M16.4124 10.2635H11.7402L12.8692 8.95563L15.548 9.78393C15.8682 9.88164 16.1613 10.0455 16.4124 10.2635Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M18.3583 10.737C18.417 10.7986 18.444 10.8798 18.438 10.9655L17.8968 18.1977C17.8848 18.3555 17.7525 18.4773 17.5947 18.4773H5.40467C5.24683 18.4773 5.11454 18.354 5.10251 18.1977L4.56134 10.9655C4.55532 10.8798 4.58388 10.8001 4.64101 10.737C4.69964 10.6738 4.77781 10.6408 4.86349 10.6408H6.14428H16.8536H18.1343C18.2215 10.6408 18.2997 10.6754 18.3583 10.737ZM11.6883 14.9416C12.3407 14.8499 12.8443 14.2877 12.8443 13.6097C12.8443 12.8686 12.2415 12.2658 11.5004 12.2658C10.7593 12.2658 10.1565 12.8686 10.1565 13.6097C10.1565 13.7135 10.2407 13.7976 10.3444 13.7976C10.4481 13.7976 10.5323 13.7135 10.5323 13.6097C10.5323 13.0761 10.9668 12.6416 11.5004 12.6416C12.0341 12.6416 12.4685 13.0761 12.4685 13.6097C12.4685 14.1434 12.0341 14.5778 11.5004 14.5778C11.3967 14.5778 11.3125 14.662 11.3125 14.7657V15.8511C11.3125 15.9548 11.3967 16.039 11.5004 16.039C11.6041 16.039 11.6883 15.9548 11.6883 15.8511V14.9416ZM11.7575 16.6719C11.7575 16.5396 11.6507 16.4329 11.5185 16.4329C11.3862 16.4329 11.2794 16.5396 11.2794 16.6719C11.2794 16.8042 11.3862 16.9109 11.5185 16.9109C11.6507 16.9109 11.7575 16.8027 11.7575 16.6719Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M23 20.7157V21.0584H0V20.7157C0 19.6935 0.831307 18.8622 1.85353 18.8622H21.1465C22.1687 18.8607 23 19.6935 23 20.7157Z"
                                                    fill="#265D91" />
                                            </svg> 
                                            Nom Consultant</h4>
                                           <span>{{evaluation?.evaluation_send
.candidate?.first_name}} {{evaluation?.evaluation_send.candidate?.last_name}}</span>
                                        </div>
                                        <div class="bloc-input mb-5">
                                            <h4 class="mb-2">
                                                <svg width="23" height="22" viewBox="0 0 16 23" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.73047 4.24548H12.0819V2.56813H9.32905V0H6.42757V2.56813H3.73047V4.24548Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M14.9752 4.1453H13.5351V4.97001C13.5351 5.37014 13.2099 5.69453 12.8087 5.69453H3.00447C2.60331 5.69453 2.27807 5.37014 2.27807 4.97001V4.1453H0.782265C0.350208 4.1453 0 4.49464 0 4.92553V22.0702C0 22.5011 0.350257 22.8504 0.782265 22.8504H14.9752C15.4073 22.8504 15.7575 22.501 15.7575 22.0702V4.92553C15.7574 4.4946 15.4072 4.1453 14.9752 4.1453ZM4.46258 19.4272C4.18463 19.706 3.71982 19.7088 3.43849 19.4333L2.16435 18.1854L3.18227 17.1515L3.94133 17.8949L5.9201 15.9098L6.95036 16.9315L4.46258 19.4272ZM4.46258 11.7348C4.18463 12.0137 3.71987 12.0165 3.43849 11.741L2.16435 10.4932L3.18227 9.45918L3.94133 10.2026L5.9201 8.2175L6.95036 9.23917L4.46258 11.7348ZM13.7105 20.6985H8.64089V19.2494H13.7105V20.6985ZM13.7105 17.0696H8.64089V15.6205H13.7105V17.0696ZM13.7105 12.8824H8.64089V11.4334H13.7105V12.8824ZM13.7105 9.25358H8.64089V7.80453H13.7105V9.25358Z"
                                                    fill="#265D91" />
                                            </svg>
                                                Date de réception</h4>
                                           <span>{{evaluation?.evaluation_send.created_at | date:'dd/MM/yyyy'}}</span>
                                        </div>
                                        
                                        <div class="bloc-input mb-5">
                                            <h4 class="mb-2">
                                                <svg width="23" height="22" viewBox="0 0 16 23" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M3.73047 4.24548H12.0819V2.56813H9.32905V0H6.42757V2.56813H3.73047V4.24548Z"
                                                    fill="#265D91" />
                                                <path
                                                    d="M14.9752 4.1453H13.5351V4.97001C13.5351 5.37014 13.2099 5.69453 12.8087 5.69453H3.00447C2.60331 5.69453 2.27807 5.37014 2.27807 4.97001V4.1453H0.782265C0.350208 4.1453 0 4.49464 0 4.92553V22.0702C0 22.5011 0.350257 22.8504 0.782265 22.8504H14.9752C15.4073 22.8504 15.7575 22.501 15.7575 22.0702V4.92553C15.7574 4.4946 15.4072 4.1453 14.9752 4.1453ZM4.46258 19.4272C4.18463 19.706 3.71982 19.7088 3.43849 19.4333L2.16435 18.1854L3.18227 17.1515L3.94133 17.8949L5.9201 15.9098L6.95036 16.9315L4.46258 19.4272ZM4.46258 11.7348C4.18463 12.0137 3.71987 12.0165 3.43849 11.741L2.16435 10.4932L3.18227 9.45918L3.94133 10.2026L5.9201 8.2175L6.95036 9.23917L4.46258 11.7348ZM13.7105 20.6985H8.64089V19.2494H13.7105V20.6985ZM13.7105 17.0696H8.64089V15.6205H13.7105V17.0696ZM13.7105 12.8824H8.64089V11.4334H13.7105V12.8824ZM13.7105 9.25358H8.64089V7.80453H13.7105V9.25358Z"
                                                    fill="#265D91" />
                                            </svg>
                                            Date d'envoie</h4>
                                           <span>{{!evaluation?.evaluation_send.dateSendByCandidate ? '---':evaluation?.evaluation_send.dateSendByCandidate | date:'dd/MM/yyyy'}}</span>
                                        </div>
                                    </div>
                                    
                                   
                                  </div>
                                  
                                </div>
                            </div>

                        <div class="container mt-15">
                            <div class="evals">
                                <div class="eval mb-5" *ngFor="let module of groupedEvaluationDetails let i = index">
                                    <div class="eval-header accordion-header cursor-pointer"
                                        [id]="'kt_accordion_1_header_' + i"
                                        [attr.data-bs-toggle]="'collapse'"
                                        [attr.data-bs-target]="'#kt_accordion_1_body_' + i"
                                        [attr.aria-expanded]="expandedAccordions[i]"
                                        (click)="toggleAccordion(i)"
                                        [attr.aria-controls]="'kt_accordion_1_body_' + i">
                                        <span>
                                            <button class="accordion-button fw-semibold" type="button">
                                                Module {{ module[1][0]?.chapter_evaluation?.name }}
                                            </button>
                                        </span>
                                        <i [class.fa-chevron-up]="expandedAccordions[i]"
                                        [class.fa-chevron-down]="!expandedAccordions[i]"
                                        class="fa-solid"></i>
                                    </div>
                                      <div [id]="'kt_accordion_1_body_' + i" class="accordion-collapse collapse"
                                      [attr.aria-labelledby]="'kt_accordion_1_header_' + i">
                                      <div class="accordion-body evals"
                                      *ngFor="let evaluations of module[1]; let j = index">
                                    <div class="bloc mb-5 mt-5">
                                        <div class="text">
                                            <span>{{ j + 1 }}</span>
                                            <p>{{evaluations?.question}}</p>
                                        </div>
                                        <div class="edit"  (click)="openModal(ajouter, evaluations)" >
                                           <a href="#" data-bs-toggle="modal" data-bs-target="#ajouter" *ngIf="evaluation?.evaluation_send
.state_fill_by_candidate==='1'|| evaluation?.evaluation_send.state_fill_by_candidate==='2'">
                                            <svg *ngIf="evaluations.response_evaluation[0]?.comment || evaluations?.response_evaluation[0]?.stars" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g filter="url(#filter0_d_3561_441)">
                                                <path d="M4 15.6558V5.59137C4 4.9982 4.23707 4.42933 4.65906 4.00989C5.08106 3.59046 5.6534 3.35482 6.25019 3.35482H13.1301L10.9165 5.59137H6.25019V15.6558H16.376V11.0206L18.6262 8.82039V15.6558C18.6262 16.249 18.3891 16.8179 17.9671 17.2373C17.5451 17.6567 16.9728 17.8924 16.376 17.8924H6.25019C5.6534 17.8924 5.08106 17.6567 4.65906 17.2373C4.23707 16.8179 4 16.249 4 15.6558ZM20.3138 0C19.8664 0.000391512 19.4374 0.177375 19.1212 0.492041L11.1246 8.56878C10.8786 8.8174 10.6929 9.11861 10.5818 9.44942L9.77173 11.8677C9.76161 11.9064 9.76183 11.947 9.77238 11.9855C9.78293 12.0241 9.80343 12.0592 9.83185 12.0875C9.86028 12.1157 9.89564 12.1361 9.93443 12.1466C9.97322 12.1571 10.0141 12.1573 10.053 12.1473L12.4944 11.3505C12.8273 11.2401 13.1304 11.0556 13.3805 10.8109L21.5064 2.86278C21.7422 2.6282 21.9027 2.32944 21.9677 2.00425C22.0326 1.67906 21.9992 1.34202 21.8716 1.03569C21.744 0.729368 21.5279 0.4675 21.2507 0.283164C20.9734 0.098828 20.6474 0.000291618 20.3138 0Z" fill="#265D91"/>
                                                </g>
                                                <defs>
                                                <filter id="filter0_d_3561_441" x="0" y="0" width="26" height="25.8923" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset dy="4"/>
                                                <feGaussianBlur stdDeviation="2"/>
                                                <feComposite in2="hardAlpha" operator="out"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3561_441"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3561_441" result="shape"/>
                                                </filter>
                                                </defs>
                                                </svg>
                                           </a>
                                        </div>
                                    </div>
                                    <!-- [ngClass]="{'stars': !starsArray[i]?.[j]}"      -->
                                 <div class="rating mb-5" *ngIf="evaluations?.typeResponse==='1'">
    <div class="stars" >
        <i class="fa fa-star" (click)="!starsArray[i]?.[j] && setRating(evaluations.id, i, j, 1)" 
        [class.gold]="starsArray[i]?.[j] >= 1"></i>
    <i class="fa fa-star" (click)="!starsArray[i]?.[j] && setRating(evaluations.id, i, j, 2)" 
        [class.gold]="starsArray[i]?.[j] >= 2"></i>
    <i class="fa fa-star" (click)="!starsArray[i]?.[j] && setRating(evaluations.id, i, j, 3)" 
        [class.gold]="starsArray[i]?.[j] >= 3"></i>
    <i class="fa fa-star" (click)="!starsArray[i]?.[j] && setRating(evaluations.id, i, j, 4)" 
        [class.gold]="starsArray[i]?.[j] >= 4"></i>
    <i class="fa fa-star" (click)="!starsArray[i]?.[j] && setRating(evaluations.id, i, j, 5)" 
        [class.gold]="starsArray[i]?.[j] >= 5"></i>
    
    </div>
</div>

                                    <div class="contentComment">
                                    <div  *ngIf="evaluations?.typeResponse==='2' && !evaluations.response_evaluation[0]?.comment" >
                                    <input type="text" name="reponse" id="reponse" class="form-control mb-5 " placeholder="Reponse" 
                                    [(ngModel)]="comments[evaluations?.id]">
                                    </div>
                                    </div>
                                    <div class="mb-3 " *ngIf="evaluations?.typeResponse==='2' && evaluations.response_evaluation[0]?.comment">
                                       <p class="comment">{{ evaluations.response_evaluation[0]?.comment }}</p> 
                                    </div>
                                    <div class="line mb-10"></div>
                                    <br>
                                    <br>
                                    </div>
                                    </div>
                                </div>
                                <div class="actions">
                                    <button  class="annuler" (click)="showDeleteConfirmation()" *ngIf="evaluation?.evaluation_send
.state_fill_by_candidate==='1'||evaluation?.evaluation_send
.state_fill_by_candidate==='2'" [disabled]="isCancelDisabled">Annuler</button> 
                                    <button  (click)="addEvaluation()" class="confirmer" *ngIf="evaluation?.evaluation_send.state_fill_by_candidate==='1'||evaluation?.evaluation_send.state_fill_by_candidate==='2'" [disabled]="starsArray?.length === 0">Enregistrer</button>
                                    <button  (click)="sendEvaluation()" class="envoyer" *ngIf="evaluation?.evaluation_send.state_fill_by_candidate==='1'||evaluation?.evaluation_send.state_fill_by_candidate==='2'" >Envoyer</button>
                                    <!-- [disabled]="isSendDisabled" -->
                                </div>
                               
                            </div>
                        </div>
                        
                            <!--end::Table-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                    <!--begin::Modals-->
                    <!--begin::Modal - Customers - Add-->
              
                    <!--end::Modal - Customers - Add-->
                    <!--begin::Modal - Adjust Balance-->
                
                    <!--end::Modal - New Card-->
                    <!--end::Modals-->
                </div>
                <!--end::Content container-->
            </div>
            <!--end::Content-->
        </div>
        <!--end::Content wrapper-->
        <!--begin::Footer-->

        <!--end::Footer-->
    <!--end:::Main-->
    </div>
    <script src="./assets/js/custom/apps/projects/settings/settings.js"></script>
    <script src="./assets/js/widgets.bundle.js"></script>
    <script src="./assets/js/custom/widgets.js"></script>
    <script src="./assets/js/custom/apps/chat/chat.js"></script>
    <script src="./assets/js/custom/apps/ecommerce/customers/listing/listing.js"></script>
		<script src="./assets/js/custom/apps/ecommerce/customers/listing/add.js"></script>
		<script src="./assets/js/custom/apps/ecommerce/customers/listing/export.js"></script>
    <ng-template #ajouter let-modal class="modal-dialog">
        <!-- <div class="modal fade" id="ajouter" tabindex="-1" aria-hidden="true"> -->
            <!--begin::Modal dialog-->
            <div class="modal-dialog-centered ">
                <!--begin::Modal content-->
                <div class="modal-content pt-5  pb-5" >
                    <!--begin::Modal header-->
                    <div class="modal-header">
                        <!--begin::Modal title-->
                        <h2 class="fw-bold">Modifier reponse</h2>
                        <!--end::Modal title-->
                        <!--begin::Close-->
                        <div id="kt_customers_export_close" class="btn btn-icon btn-sm btn-active-icon-primary" data-bs-dismiss="modal" aria-label="Close"
                        (click)="closeModal()">
                            <i class="ki-duotone ki-cross fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </div>
                        <!--end::Close-->
                    </div>
                    <!--end::Modal header-->
                    <!--begin::Modal body-->
                    <div class="modal-body text-center mx-5 mx-xl-15 my-7">
                        <form  [formGroup]="editReviewForm">
                            <div class="">
                              <div class="card-body">
                                <div class="flex_input mb-3">
                                  <span class="mb-3">Question : </span>
                                  <input type="text" name="entreprise" id="question"
                                         class="form-control form-control-solid mb-3 avis-text" placeholder=""
                                         formControlName="question" />
                                </div>
                                <div class="flex_input">
                                  <span class="mb-3">Reponse</span>
                                  <div class="d-flex">
                                    <div *ngIf="selectedRating">
                                      <div class="stars">
                                        <label class="star-label" *ngFor="let rating of starRatings"
                                        (click)="selectedRating = rating">
                                        <input type="radio" [value]="rating" formControlName="stars">
                                        <i class="fa fa-star" [class.gold]="rating <= selectedRating"></i>
                                    </label>
                                      </div>
                                    </div>
                                    <div class="content" *ngIf="!selectedRating">
                                    <div class="flex_input mb-3">
                                        <input type="text" name="comment" id="comment" class="form-control  mb-3" 
                                        formControlName="comment">
                                    </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            </form>
                          
                    </div>
                    <div class="modal-footer">
                        <div class="actions">
                            <a  class="annuler" data-bs-dismiss="modal" aria-label="Close" (click)="closeModal()" >Annuler</a>
                            <a *ngIf="selectedRating" class="confirmer" (click)="editReviewCandidateForCompany()">Confirmer</a>
                            <a *ngIf="!selectedRating" class="confirmer" (click)="editCommentCandidateForCompany()">Confirmer</a>
                        </div>
                    </div>
                    <!--end::Modal body-->
                </div>
                <!--end::Modal content-->
                
            </div>
            
            <!--end::Modal dialog-->
        <!-- </div> -->
    </ng-template>
    <ngx-spinner class="custom-spinner"
    bdColor="rgba(255,255,255,1)"
      size="medium"
      color="#fff"
      type="ball-atom"
      [fullScreen]="true"
    >
    <span class="loader">
      <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
    </span>
    </ngx-spinner>
    