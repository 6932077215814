import { Component } from '@angular/core';

@Component({
  selector: 'app-nothing-toshow',
  templateUrl: './nothing-toshow.component.html',
  styleUrls: ['./nothing-toshow.component.css']
})
export class NothingToshowComponent {

}
