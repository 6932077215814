import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from '../../services/contact.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { CCandidate } from '../../../../authentification/models/user';
import { ToastrService } from 'ngx-toastr';
import {
  emailSended,
  serverError,
} from '../../../../shared/shared-models/message';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-contact-wegestu',
  templateUrl: './contact-wegestu.component.html',
  styleUrls: ['./contact-wegestu.component.css'],
})
export class ContactWegestuComponent {
  contactForm!: FormGroup;
  currentUser: any;
  id: any;
  filtered: any;
  submitted = false;
  listContactNames: any;

  types = [
    { value: 'Technique', id: '1' },
    { value: 'Service client', id: '2' },
    { value: 'Réclamation', id: '3' },
  ];
  selected: any = this.types[0];
  file: any;
  constructor(
    private contactService: ContactService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService
  ) {
    this.currentUser = new CCandidate();
  }
  toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }], // outdent/indent // outdent/indent
    ['clean'], // remove formatting button
  ];
  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    console.log(
      '%cdossier-compt.component.ts line:57 id++',
      'color: #007acc;',
      this.id
    );
    this.contactFormGroup();
    this.getContactNames();
  }

  contactFormGroup(data?: any) {
    this.contactForm = this.formBuilder.group({
      mailingAdmin_id: [data ? data.mailingAdmin_id : null],
      object: ['', [Validators.required, Validators.maxLength(100)]],
      subject: ['', Validators.required],
      user_id: [''],
      file: [''],
    });
  }
  getContactNames() {
    this.spinner.show();
    this.contactService
      .getNamesForContactWegestu()
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listContactNames = res?.data;
          console.log('data listOfMonths ', this.listContactNames);
        },
        (error) => {
          console.error('Error fetching listOfMonths:', error);
        }
      );
  }
  onFileChange(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files[0]) {
      const file = fileInput.files[0];
      this.file = file;
      this.fileName = this.file.name;
      this.contactForm.get('file')?.setValue(this.file);
    }
  }

  contactWegestU() {
    this.spinner.show();
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactForm.invalid) {
      console.log(
        '%ccontact-wegestu.component.ts line:64 form invalid',
        'color: #007acc;'
      );
      return;
    } else {
      this.contactForm.get('user_id')?.setValue(this.id);
      console.log('contactForm', this.contactForm.value);

      const formData = new FormData();
      formData.append(
        'mailingAdmin_id',
        this.contactForm.get('mailingAdmin_id')?.value
      );
      formData.append('object', this.contactForm.get('object')?.value);
      formData.append('subject', this.contactForm.get('subject')?.value);
      formData.append('user_id', this.id);
      formData.append('file', this.contactForm.get('file')?.value);
      const formDataObject: { [key: string]: any } = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });
      console.log('formDataObject++', formDataObject);
      this.contactService
        .contactWegestU(formData)
        .pipe()
        .subscribe({
          next: (res) => {
            this.toastrService.success(emailSended);
            this.contactForm.get('subject')?.setValue('');
            this.contactForm.get('file')?.setValue('');
            this.onReset();
            this.spinner.hide();
          },
          error: (err) => {
            console.log('error', err);
            this.spinner.hide();
            if (err?.error) {
              this.toastrService.error(serverError);
            }
          },
        });
    }
  }
  fileName: string = '';
  resetFile() {
    const inputFile = document.getElementById('cv_file') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.file = null;
    this.fileName = '';
  }
  get f() {
    return this.contactForm.controls;
  }

  onSelectChange(event: any) {
    console.log('Selected:', event);
  }

  onReset() {
    this.submitted = false;
    this.contactForm.reset({
      user_id: this.id,
    });
  }
}
