import { BrowserModule } from '@angular/platform-browser';
import {
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
  NgModule,
  isDevMode,
} from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './core/header/header.component';
import { SidebarComponent } from './core/sidebar/sidebar.component';
import { FooterComponent } from './core/footer/footer.component';
import { PageNotfoundComponent } from './core/page-notfound/page-notfound.component';
import { NothingToshowComponent } from './core/nothing-toshow/nothing-toshow.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatChipsModule} from '@angular/material/chips';

import { CraModule } from './main/cra/cra.module';

import { JobsModule } from './main/jobs/jobs.module';
import { EvaluationModule } from './main/evaluation/evaluation.module';
import { LayoutComponent } from './core/layout/layout.component';
import { QcmsModule } from './main/qcms/qcms.module';
import { ChatsModule } from './main/chats/chats.module';
import { ContactModule } from './main/contact/contact.module';
import { ConsultantModule } from './main/consultant/consultant.module';
import { ClientFinalModule } from './main/client-final/client-final.module';
import { AuthentificationModule } from './authentification/authentification.module';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { AppInterceptor } from './core/interceptor/app.interceptor';
import { NotificationsComponent } from './main/notifications/notifications.component';
import { FieldErrorModule } from './shared/shared-field-error/field-error.module';
import { ToastrModule } from 'ngx-toastr';
import { SuggestionsModule } from './main/suggestions/suggestions.module';
import { TutoModule } from './main/tuto/tuto.module';
import { FaqModule } from './main/faq/faq.module';
import { EntrepriseModule } from './main/entreprise/entreprise.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule } from '@angular/forms';
import { RateWegestuModule } from './main/rate-wegestu/rate-wegestu.module';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxIntlTelephoneInputModule } from 'ngx-intl-telephone-input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PaginationModule } from './shared/pagination/pagination.module';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DossierModule } from './main/dossier/dossier.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    PageNotfoundComponent,
    NothingToshowComponent,
    LayoutComponent,
    NotificationsComponent,
  ],
  imports: [
    MatChipsModule,
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    AuthentificationModule,
    QcmsModule,
    ChatsModule,
    ConsultantModule,
    ClientFinalModule,
    CraModule,
   DossierModule,
    JobsModule,
    EvaluationModule,
    ContactModule,
    NgxSpinnerModule,
    FieldErrorModule,
    ToastrModule.forRoot({
      preventDuplicates: true,
      progressBar: true,
      timeOut: 4000,
    }),
    SuggestionsModule,
    TutoModule,
    FaqModule,
    EntrepriseModule,
    FormsModule,
    NgSelectModule,
    RateWegestuModule,
    NgxIntlTelInputModule,
    NgxIntlTelephoneInputModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient],
      },
    }),

    PaginationModule,
    ModalModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    JwtHelperService, // required animations providers
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}

// AOT compilation support
export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
