import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CCandidate } from '../../../../authentification/models/user';
import { ContactService } from '../../services/contact.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { ToastrService } from 'ngx-toastr';
import { emailSended, serverError } from '../../../../shared/shared-models/message';
import { ActivatedRoute, Router } from '@angular/router';
import { finalize } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact-responsable',
  templateUrl: './contact-responsable.component.html',
  styleUrls: ['./contact-responsable.component.css'],
})
export class ContactResponsableComponent {
  contactEsnForm!: FormGroup;
  currentUser: any;
  id: any;
  filtered: any;
  submitted = false;
  listContactNames: any;

  types = [
    { contactName: 'Technique', id: '1' },
    { contactName: 'Service client', id: '2' },
    { contactName: 'Réclamation', id: '3' },
  ];

  selected: any = this.types[0];
  idResponsable: any;
  type:any;

  file: File | null = null;
  fileName: string = '';

  constructor(
    private contactService: ContactService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {
    this.currentUser = new CCandidate();
  }
  toolbarOptions = [
    ['bold', 'italic', 'underline'], // toggled buttons
    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }],
    [{ color: [] }, { background: [] }], // outdent/indent // outdent/indent
    ['clean'], // remove formatting button
  ];
  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo')
    this.id = JSON.parse(this.currentUser).id
    this.getId()
    this.contactEsnFormGroup()
    this.getContactNames()
  }

  /* get id esn */
  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idResponsable = params.params['idResponsable'];
        this.type = params.params['Type']
      },
    });
  }
  resetFile() {
    this.file = null;
    this.fileName = '';
    this.contactEsnForm.get('file')?.reset();
    const fileInput = document.getElementById('cv_file') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
  }
  contactEsnFormGroup(data?: any) {
    this.contactEsnForm = this.formBuilder.group({
      mailing_id: [this.type === 'Entreprise' ? (data ? data.mailing_id : null) : null],
      type: [this.type === 'Responsable' ? (data ? data.type : null) : null],
      object: ['', [Validators.required, Validators.maxLength(100)]],
      subject: ['', Validators.required],
      user_id: [''],
      esn_id: this.idResponsable,

      file: [''],
    });
  }

  onFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.file = input.files[0];
      this.fileName = this.file.name;
      this.contactEsnForm.get('file')?.setValue(this.file);
    }
  }

  getContactNames() {
    this.spinner.show();
    this.contactService.getContactNamesByCandidat(this.idResponsable)
      .pipe(
        finalize(() => this.spinner.hide())
      )
      .subscribe(
        (res: any) => {
          if(this.type==='Entreprise'){
            this.listContactNames = res?.data;
          }else{
            this.listContactNames=this.types
          }
        
        },
        (error) => {
        }
      );
  }
  contact(){
    if(this.type=='Entreprise'){
     this.contactEsn()
    }else{
     this.contactResponsable()
    }
  }
  contactEsn() {
    this.submitted = true;
    if (this.contactEsnForm.invalid) {
      return;
    } else {
      this.contactEsnForm.get('user_id')?.setValue(this.id);
      const formData = new FormData();
      formData.append(
        'mailing_id',
        this.contactEsnForm.get('mailing_id')?.value
      );
      formData.append('object', this.contactEsnForm.get('object')?.value);
      formData.append('subject', this.contactEsnForm.get('subject')?.value);
      formData.append('user_id', this.id);
      formData.append('esn_id', this.contactEsnForm.get('esn_id')?.value);
      formData.append('file', this.contactEsnForm.get('file')?.value);

      const formDataObject: { [key: string]: any } = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      this.contactService
        .contactEsn(formData)
        .pipe()
        .subscribe({
          next: (res) => {
            this.toastrService.success(emailSended);
            this.onReset();
          },
          error: (err) => {
            if (err?.error) {
              this.toastrService.error(serverError);
            }
          },
        });
    }
  }
  contactResponsable() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.contactEsnForm.invalid) {
      return;
    } else {
      this.contactEsnForm.get('user_id')?.setValue(this.id);

      const formData = new FormData();
      formData.append('type', this.contactEsnForm.get('type')?.value);
      formData.append('object', this.contactEsnForm.get('object')?.value);
      formData.append('subject', this.contactEsnForm.get('subject')?.value);
      formData.append('id', this.contactEsnForm.get('esn_id')?.value);      
      if(this.file){
        formData.append('file', this.file);
      }
      const formDataObject: { [key: string]: any } = {};
      formData.forEach((value, key) => {
        formDataObject[key] = value;
      });

      this.contactService
        .contactResponsable(formData)
        .pipe()
        .subscribe({
          next: (res) => {
            this.toastrService.success(emailSended);
            this.router.navigateByUrl('/wegestu/jobs/candidatures');
          },
          error: (err) => {
            if (err?.error) {
              this.toastrService.error(
                "Cette entreprise n'a pas de responsable!"
              );
            }
          },
        });
    }
  }

  get f() {
    return this.contactEsnForm.controls;
  }

  onSelectChange(event: any) {
    console.log('Selected:', event);
  }

  onReset() {
    this.submitted = false;
    this.contactEsnForm.reset({
      type: [this.types[1].id],
      esn_id: this.idResponsable,
      user_id: this.id,
    });
    this.contactEsnForm.get('file')?.setValue('');
  }
}
