import { Component, ElementRef } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { environment } from '../../../../../../environnements/environment';
import { CJobOffer } from '../../../models/jobOffer';
import { ICountry } from 'country-state-city';
import { JobsService } from '../../../services/jobs.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup } from '@angular/forms';
import { serverError } from '../../../../../shared/shared-models/message';
import * as moment from 'moment';

@Component({
  selector: 'app-job-entreprise',
  templateUrl: './job-entreprise.component.html',
  styleUrls: ['./job-entreprise.component.css'],
})
export class JobEntrepriseComponent {
  sortAttr: string = '';
  url: string = environment.baseUrl + '/api';
  searchFormGroup: FormGroup = this.createSearchForm();

  /* number */
  seeMore: boolean = false;
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  /* objects */
  jobOffer!: CJobOffer;
  /* arrays */
  search: boolean = false;
  listJobOffer: any;
  skillsList: { name_FR: string }[] = [];
  countries: any[] = [];
  mobilities: any[] = [];
  test: any;
  pagination: boolean = false;

  disponibilite = [
    { id: 1, name: 'Immédiate' },
    { id: 2, name: '1 mois' },
    { id: 3, name: '2 mois' },
    { id: 4, name: '3 mois' },
    { id: 5, name: '4 mois' },
    { id: 6, name: '5 mois' },
  ];
  postType = [
    { id: 1, name: 'à distance' },
    { id: 2, name: 'Hybrid' },
    { id: 3, name: 'sur site' },
  ]
  typesCompanies = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ]
  StateOffre = [
    { id: 0, name: 'Activé' },
    { id: 1, name: 'Désactivé' },
  ]
  dataHeaderSort = [
    { name: 'ID', code: 'ID_jobOffer' },
    { name: 'Nom entreprise', code: 'esn_name' },
    { name: 'Poste', code: 'name' },
    { name: 'Type', code: 'type' },
    { name: 'Date de publication', code: 'start_date' },
    { name: 'Années d’expériences', code: 'years_of_experience' },
    { name: 'Type de contrat', code: 'contract_type' },
    { name: 'Salaire', code: 'salary' },
    { name: 'TJM', code: 'tjm' },
    { name: 'Type de poste', code: 'post_type' },
    { name: 'Compétences', code: 'skills_job' },
    { name: 'Pays', code: 'country' },
    { name: 'Ville', code: 'city' },
    { name: 'Statut', code: 'state' },
  ]
  contratTypeOptions: { [key: string]: string } = {
    '1': 'Alternance',
    '2': 'Autres type de contrat',
    '3': 'CDD',
    '4': 'CDI',
    '5': 'Freelance',
    '6': 'Portage salarial',
    '7': 'Stage',
  };



  stateList: any[] = []
  dataHeader: any = []
  listNames: string[] = []
  paysList: ICountry[] = [];
  type: any
  /* formGroup */
  idCompany!: number;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  constructor(
    private jobOffreService: JobsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private elRef: ElementRef
  ) { }
  ngOnInit() {
    this.getIdCompany();

    this.getListSkills();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
    }
  }
  getIdCompany() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCompany = params.params['idResponsable'];
        this.getListOffre();
      },
    });
  }
  /* create search form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      start_date: ['', data?.start_date],
      name: ['', data?.name],
      state: ['', data?.state],
    });
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true
        this.getListOffre();
      }
    }
  }
  sortData(sortAttribute: string) {
    // this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    // this.sortAttr=sortAttribute
    // if(this.searchElement !='') this.listJobOffer = this.sortDataService.sortArray(this.listJobOffer, sortAttribute, this.currentSortDirection)
    // else this.getListOffre()
  }
  // getListJobOffer() {
  //   this.spinner.show()
  //   let data =this.sortAttr==''? {per_page: this.itemsPerPage, page: this.currentPage}:{ ...{per_page: this.itemsPerPage, page: this.currentPage}, ...{sort:this.currentSortDirection,sortAttribute:this.sortAttr} }
  //   this.jobOffreService.listJobOffer(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
  //     next: (res: any) => {
  //       console.log("listejoboffer",res)

  //       this.spinner.hide()
  //       this.listJobOffer = res.data.data.slice(0, this.endIndex)
  //       this.type=res?.data?.data[0]?.esn?.type
  //       console.log("type",this.type)
  //       this.totalItems = res.data.total
  //       console.log("total",this.totalItems)

  //     },
  //     error: () => {
  //       this.spinner.hide()

  //     }
  //   })
  // }
  applyFilter() {
    this.spinner.show();
    this.jobOffreService
      .searchJobOffer(this.searchFormGroup.value)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.data.data.length && this.currentPage > 1) {
            this.currentPage = 1;
            this.applyFilter();
          } else {
            this.listJobOffer = res.data.data;
            this.totalItems = res.data.total;
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
          this.toastrService.error(serverError);
        },
      });
  }
  updateInputType(type: 'text' | 'date') {
    const inputElement = document.getElementById(
      'dateInput'
    ) as HTMLInputElement;
    inputElement.type = type;
    if (type == 'text') {
      if (
        this.searchFormGroup.value.start_date &&
        this.searchFormGroup.value.start_date != ''
      )
        this.searchFormGroup
          .get('start_date')
          ?.setValue(
            moment(this.searchFormGroup.value.start_date).format('DD/MM/yyyy')
          );
    }
  }
  getListOffre() {
    this.spinner.show();
    let dataPyload =
      this.sortAttr == ''
        ? { per_page: this.itemsPerPage, page: this.currentPage, type: 2 }
        : {
          ...{ per_page: this.itemsPerPage, page: this.currentPage },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
            type: 2,
          },
        };
    this.jobOffreService
      .getJobOfferList(this.idCompany, dataPyload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.spinner.hide();
          this.listJobOffer = res.data.data.slice(0, this.endIndex);
          this.totalItems = res.data.total;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  getListSkills() {
    this.jobOffreService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }

  ngOnDestroy() {
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  // createJobOfferSearchForm(data?: any) {
  //   return this.formBuilder.group({
  //     ID_jobOffer: ['', data && data.ID_jobOffer],
  //     name: ['', data && data.name],
  //     esn_name: ['', data && data.esn_name],
  //     company_nature: [null, data && data.company_nature],
  //     years_of_experience: ['', data && data.years_of_experience],
  //     experience_range: ['', data && data.experience_range],
  //     contract_type: ['', data && data.contract_type],
  //     desired_salary: ['', data && data.desired_salary],
  //     desired_salary_range: ['', data && data.desired_salary_range],
  //     tjm: ['', data && data.tjm],
  //     tjm_range: ['', data && data.tjm_range],
  //     post_type: ['', data && data.post_type],
  //     skillsAnd: ['', data && data.skillsAnd],
  //     skillsOr: ['', data && data.skillsOr],
  //     availability: ['', data && data.availability],
  //     country: [null, data && data.country],
  //     city: ['', data && data.city],
  //     start_date: ['', data && data.start_date],
  //     end_date: ['', data && data.end_date],
  //     profile_abroad: ['', data && data.profile_abroad],
  //     passeport_talent: ['', data && data.passeport_talent],
  //     state: [null, data && data.state],
  //     search: ['', [Validators.pattern(/^("([^"]*"(AND|NOT|,|&&)[^"]*)*"|[^\s"']+)$/)]],

  //   })
  // }
}
