<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
  <!--begin::Toolbar container-->
  <div
    id="kt_app_toolbar_container"
    class="app-container container-fluid d-flex flex-stack"
  >
    <!--begin::Page title-->
    <div
      class="page-title d-flex flex-column justify-content-center flex-wrap me-3"
    >
      <!--begin::Title-->
      <div class="d-flex align-items-center">
        <h1 class="page-heading">
          <svg
            width="40"
            height="40"
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g filter="url(#filter0_d_444_1768)">
              <path
                d="M16.0442 6.79683H23.9584V8.36302H25.1979V5.71521H14.8047V8.36302H16.0442V6.79683Z"
                fill="#265D91"
              />
              <path
                d="M31.989 16.1651C32.4197 15.9199 32.7979 15.6546 33.1092 15.3748V10.3503C33.1092 9.72439 32.5971 9.21387 31.9628 9.21387H8.03994C7.40565 9.21387 6.89355 9.72439 6.89355 10.3503V15.3748C7.20488 15.6546 7.58313 15.9199 8.01376 16.1651C10.2804 17.4428 14.0599 18.1697 18.0519 18.3485V16.9323C18.0519 15.9084 18.8899 15.0806 19.9199 15.0806H20.08C21.1129 15.0806 21.9479 15.9113 21.9479 16.9323V18.3485C25.9428 18.1697 29.7224 17.4428 31.989 16.1651Z"
                fill="#265D91"
              />
              <path
                d="M19.9198 15.9286C19.364 15.9286 18.9072 16.3814 18.9072 16.9323V19.8426C18.9072 20.3935 19.364 20.8464 19.9198 20.8464H20.0798C20.6355 20.8464 21.0924 20.3935 21.0924 19.8426V16.9323C21.0924 16.3814 20.6355 15.9286 20.0798 15.9286H19.9198Z"
                fill="#265D91"
              />
              <path
                d="M30.6681 7.05065L33.8221 3.92405L32.0472 2.16461L28.8932 5.29121L27.7119 4.12306V8.22168H31.8465L30.6681 7.05065Z"
                fill="#265D91"
              />
              <path
                d="M29.4575 3.65587C29.5303 3.65587 29.603 3.62703 29.6583 3.57223L32.7745 0.483123C32.8851 0.373519 32.8851 0.191807 32.7745 0.0822031C32.6639 -0.027401 32.4806 -0.027401 32.37 0.0822031L29.2568 3.17419C29.1462 3.2838 29.1462 3.46551 29.2568 3.57511C29.312 3.62991 29.3848 3.65587 29.4575 3.65587Z"
                fill="#265D91"
              />
              <path
                d="M33.6098 2.78188C33.6826 2.78188 33.7553 2.75303 33.8106 2.69823L35.3672 1.15512C35.4778 1.04552 35.4778 0.863804 35.3672 0.7542C35.2567 0.644596 35.0734 0.644596 34.9628 0.7542L33.4062 2.29731C33.2956 2.40691 33.2956 2.58863 33.4062 2.69823C33.4644 2.75592 33.5371 2.78188 33.6098 2.78188Z"
                fill="#265D91"
              />
              <path
                d="M32.3993 6.69015C32.4546 6.74495 32.5274 6.77379 32.6001 6.77379C32.6728 6.77379 32.7456 6.74495 32.8009 6.69015L35.9171 3.60104C36.0276 3.49144 36.0276 3.30973 35.9171 3.20012C35.8065 3.09052 35.6232 3.09052 35.5126 3.20012L32.3993 6.28923C32.2888 6.40172 32.2888 6.58054 32.3993 6.69015Z"
                fill="#265D91"
              />
              <path
                d="M9.33567 24.6797L6.18164 27.8063L7.95651 29.5657L11.1105 26.4391L12.2918 27.6073V23.5087H8.15727L9.33567 24.6797Z"
                fill="#265D91"
              />
              <path
                d="M10.3407 28.1553L7.22746 31.2444C7.11689 31.354 7.11689 31.5357 7.22746 31.6453C7.28274 31.7001 7.35548 31.7289 7.42822 31.7289C7.50096 31.7289 7.5737 31.7001 7.62898 31.6453L10.7452 28.5562C10.8558 28.4466 10.8558 28.2649 10.7452 28.1553C10.6346 28.0457 10.4542 28.0457 10.3407 28.1553Z"
                fill="#265D91"
              />
              <path
                d="M6.19229 29.0292L4.63273 30.5752C4.52216 30.6848 4.52216 30.8665 4.63273 30.9761C4.68801 31.0309 4.76075 31.0597 4.83349 31.0597C4.90623 31.0597 4.97897 31.0309 5.03426 30.9761L6.5909 29.433C6.70147 29.3234 6.70147 29.1417 6.5909 29.0321C6.48325 28.9196 6.30285 28.9196 6.19229 29.0292Z"
                fill="#265D91"
              />
              <path
                d="M7.60356 25.0402C7.493 24.9306 7.30969 24.9306 7.19913 25.0402L4.08292 28.1293C3.97236 28.2389 3.97236 28.4206 4.08292 28.5302C4.13821 28.585 4.21095 28.6139 4.28369 28.6139C4.35643 28.6139 4.42917 28.585 4.48445 28.5302L7.60356 25.4411C7.71413 25.3286 7.71413 25.1498 7.60356 25.0402Z"
                fill="#265D91"
              />
              <path
                d="M6.18164 3.92405L9.33567 7.05065L8.15727 8.22168H12.2918V4.12306L11.1105 5.29121L7.95651 2.16461L6.18164 3.92405Z"
                fill="#265D91"
              />
              <path
                d="M7.19913 6.69013C7.25441 6.74494 7.32715 6.77378 7.39989 6.77378C7.47263 6.77378 7.54537 6.74494 7.60065 6.69013C7.71122 6.58053 7.71122 6.39882 7.60065 6.28921L4.48736 3.20299C4.3768 3.09339 4.19349 3.09339 4.08292 3.20299C3.97236 3.3126 3.97236 3.49431 4.08292 3.60391L7.19913 6.69013Z"
                fill="#265D91"
              />
              <path
                d="M6.31826 2.57709C6.37354 2.63189 6.44629 2.66074 6.51903 2.66074C6.59177 2.66074 6.66451 2.63189 6.71979 2.57709C6.83036 2.46749 6.83036 2.28578 6.71979 2.17617L5.16314 0.630177C5.05258 0.520573 4.86927 0.520573 4.75871 0.630177C4.64814 0.739781 4.64814 0.921493 4.75871 1.0311L6.31826 2.57709Z"
                fill="#265D91"
              />
              <path
                d="M10.3407 3.57516C10.396 3.62996 10.4688 3.6588 10.5415 3.6588C10.6142 3.6588 10.687 3.62996 10.7423 3.57516C10.8528 3.46555 10.8528 3.28384 10.7423 3.17424L7.62896 0.0851328C7.5184 -0.0244713 7.33509 -0.0244713 7.22453 0.0851328C7.11396 0.194737 7.11396 0.376449 7.22453 0.486053L10.3407 3.57516Z"
                fill="#265D91"
              />
              <path
                d="M33.8221 27.8063L30.6681 24.6797L31.8465 23.5087H27.7119V27.6073L28.8932 26.4391L32.0472 29.5657L33.8221 27.8063Z"
                fill="#265D91"
              />
              <path
                d="M32.8038 25.0402C32.6932 24.9306 32.5099 24.9306 32.3993 25.0402C32.2888 25.1498 32.2888 25.3315 32.3993 25.4411L35.5155 28.5302C35.5708 28.585 35.6436 28.6139 35.7163 28.6139C35.789 28.6139 35.8618 28.585 35.9171 28.5302C36.0276 28.4206 36.0276 28.2389 35.9171 28.1293L32.8038 25.0402Z"
                fill="#265D91"
              />
              <path
                d="M33.6846 29.1532C33.5741 29.0436 33.3908 29.0436 33.2802 29.1532C33.1696 29.2628 33.1696 29.4445 33.2802 29.5541L34.8368 31.0972C34.8921 31.152 34.9649 31.1809 35.0376 31.1809C35.1103 31.1809 35.1831 31.152 35.2384 31.0972C35.3489 30.9876 35.3489 30.8059 35.2384 30.6963L33.6846 29.1532Z"
                fill="#265D91"
              />
              <path
                d="M29.6612 28.1553C29.5506 28.0457 29.3673 28.0457 29.2568 28.1553C29.1462 28.2649 29.1462 28.4466 29.2568 28.5562L32.373 31.6453C32.4282 31.7001 32.501 31.7289 32.5737 31.7289C32.6465 31.7289 32.7192 31.7001 32.7745 31.6453C32.8851 31.5357 32.8851 31.354 32.7745 31.2444L29.6612 28.1553Z"
                fill="#265D91"
              />
              <path
                d="M31.9899 17.1256C29.6884 18.2765 26.066 19.0206 21.9517 19.1994V19.8426C21.9517 20.8666 21.1138 21.6973 20.0838 21.6973H19.9237C18.8908 21.6973 18.0558 20.8637 18.0558 19.8426V19.1994C13.9387 19.0206 10.3162 18.2765 8.01758 17.1256V22.6606H13.1501V26.0151H26.8574V22.6606H31.9899V17.1256Z"
                fill="#265D91"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_444_1768"
                x="0"
                y="0"
                width="40"
                height="39.7289"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_444_1768"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_444_1768"
                  result="shape"
                />
              </filter>
            </defs>
          </svg>
          Offres d'emploi
        </h1>
        <!--begin::Menu-->
      </div>
      <!--end::Title-->
      <!--begin::Breadcrumb-->
      <ul
        class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1"
      >
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">
          <a
            [routerLink]="['/wegestu/profil-candidat']"
            class="text-muted"
            routerLinkActive="router-link-active"
            >Accueil</a
          >
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item">
          <span class="bullet bg-gray-400 w-5px h-2px"></span>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="breadcrumb-item text-muted">Liste des offres d’emploi archivées</li>
        <!--end::Item-->
      </ul>
      <!--end::Breadcrumb-->
    </div>
    <!--end::Page title-->
    <!--begin::Actions-->

    <!--end::Actions-->
  </div>
  <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-15">
  <!--begin::Content container-->
  <div id="kt_app_content_container" class="app-container container-fluid">
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->

      <div class="card-body tools">
        <div class="title mb-10">
          <h4>Recherche</h4>
        </div>
        <div [formGroup]="searchForm">
          <div class="row">
              <div class="col-lg-6 mb-5">
               
                <div class="input-group search-libre">
        
                  <span class="input-group-text">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z" fill="#7E7C7C" />
                    </svg>
                  </span>
                  <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid ps-5"
                  placeholder="Rechercher" formControlName="search"  (keyup.enter)="searchJobArchived()"/>
               
             
                  <div class="tooltip-container tooltip-info">
                    <div class="tooltip-trigger"><i class="fa-solid fa-circle-info" style="font-size: 20px;"></i></div>
                    <div class="tooltip-content">
                      <div class="content">
                        <h4>Opérateur AND :</h4>
                        <p>Utilisez l'opérateur AND pour spécifier que les résultats doivent inclure plusieurs termes.
                        </p>
                        <p>Exemple : software AND engineer renverra des profils qui mentionnent à la fois "software" et "engineer".
                        </p>
                        <h4>Opérateur OR :</h4>
                        <p>Utilisez l'opérateur OR pour spécifier que les résultats peuvent inclure l'un des termes.
                        </p>
                        <p>Exemple : sales OR marketing renverra des profils qui mentionnent soit "sales" soit "marketing".
                        </p>
                        <h4>Opérateur NOT :</h4>
                        <p>Utilisez l'opérateur NOT pour exclure des termes spécifiques des résultats.
                        </p>
                        <p>Exemple : developer NOT manager renverra des profils qui mentionnent "developer" mais pas "manager".
                        </p>
                        <h4>Guillemets pour une expression exacte :</h4>
                        <p>Utilisez des guillemets pour rechercher une expression exacte.
                        </p>
                        <p>Exemple : "product manager" renverra des profils qui mentionnent exactement "product manager".
                        </p>
                        <h4>Parenthèses pour grouper des opérations :</h4>
                        <p>Utilisez des parenthèses pour définir l'ordre d'évaluation des opérations.
                        </p>
                        <p>Exemple : (sales OR marketing) AND manager renverra des profils qui mentionnent "sales" ou "marketing" et "manager".
                        </p>
                        <h4>Recherche standard :</h4>
                        <p>Si aucun opérateur n'est spécifié dans la recherche, le texte sera interprété comme s'il était entre guillemets.
                        </p>
                        <p>Exemple : La recherche product manager sera traitée comme "product manager", suivant la règle 4.
                        </p>
                      </div>
                    </div>
                  </div>
               
                </div>
                <!-- <div class="text-danger mt-2"
                *ngIf="searchForm.get('search')?.hasError('pattern') && searchForm.get('search')?.touched && isSimpleWord(searchForm.get('search')?.value)">
                Le modèle de recherche est invalide. Utilisez le format correct (par exemple "hello" OR "hello").
              </div> -->
              </div>
             
            </div>
          <div class="row mb-3 mb-0-sm">
              <div class="col-lg-3  mb-3">
                  <input type="text" name="entreprise" id="entreprise"
                      class="form-control form-control-solid mb-3" placeholder="ID"
                      formControlName="ID_jobOffer">
              </div>
              <div class="col-lg-3 mb-3">
                  <input type="text" name="Poste" id="Poste" class="form-control form-control-solid mb-3"
                      placeholder="Poste" formControlName="name">
              </div>
              <div class="col-lg-3  mb-3">
                  <input type="text" name="entreprise" id="entreprise"
                      class="form-control form-control-solid mb-3" placeholder="Nom entreprise"
                      formControlName="company_name">
              </div>
              <div class="col-lg-3  mb-3">
                  <ng-select [items]="typeEntreprise" bindLabel="name" bindValue="id" [multiple]="true"
                      id="typeEntreprise" placeholder="Type d’entreprise" [notFoundText]="'Aucun élément trouvé'" formControlName="typeEntreprise">
                  </ng-select>
              </div>
          </div>
          <div class="row mb-3 mb-0-sm" *ngIf="!isCollapsed">
              <div class="col-lg-3 mb-3">
                <div class="combined-input  mb-3">
                  <input type="text" name="entreprise" class="nb1" placeholder="Années d’expériences"
                      id="entreprise" formControlName="years_of_experience" (input)="preventNegativeInput($event)">
                  <input type="number" name="number2" class="nb2" value="1" id="marge_expérience"
                      placeholder="Marge" formControlName="date_range" min="0" (input)="preventNegativeInput($event)">
                  <div class="plusetmois">
                      <i class="fa-solid fa-plus-minus"></i>
                  </div>
              </div>
              </div>
              <div class="col-lg-3  mb-3">
                  <ng-select [items]="type" bindLabel="name" bindValue="id" [multiple]="true" id="dispo"
                      placeholder="Type de contrat" [notFoundText]="'Aucun élément trouvé'" formControlName="contract_type">
                  </ng-select>
              </div>


             


              <div class="col-lg-3 mb-3">
                  <div class="combined-input  mb-3">
                      <input type="number" name="number1" class="nb1" placeholder="Salaire" id="Salaire"
                      formControlName="salary" (input)="preventNegativeInput($event)">
                      <div class="euro">
                          <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z"
                                  fill="#4B4B4B" />
                          </svg>

                      </div>
                      <input type="number" name="number2" class="nb2" value="1" id="marge_salaire" (input)="onSalaryRangeInput($event)"
                      formControlName="salary_range" min="0">
                      <div class="icon">
                          <svg width="20" height="15" viewBox="0 0 19 11" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M5.37 10L1.352 5.548V10H0.0780001V0.242H1.352V4.764L5.384 0.242H6.994L2.57 5.128L7.036 10H5.37Z"
                                  fill="#4B4B4B" />
                              <path
                                  d="M13.889 8.739C14.435 8.739 14.9117 8.62633 15.319 8.401C15.735 8.167 16.06 7.82467 16.294 7.374H18.075C17.737 8.24067 17.1953 8.908 16.45 9.376C15.7047 9.844 14.851 10.078 13.889 10.078C12.875 10.078 11.9737 9.805 11.185 9.259C10.3963 8.70433 9.846 7.96767 9.534 7.049H8.338V6.035H9.3C9.28267 5.79233 9.274 5.60167 9.274 5.463C9.274 5.32433 9.28267 5.13367 9.3 4.891H8.338V3.877H9.534C9.846 2.95833 10.3963 2.226 11.185 1.68C11.9737 1.12533 12.875 0.847999 13.889 0.847999C14.8423 0.847999 15.6917 1.08633 16.437 1.563C17.191 2.031 17.737 2.69833 18.075 3.565H16.294C16.06 3.11433 15.735 2.77633 15.319 2.551C14.9117 2.317 14.435 2.2 13.889 2.2C13.2563 2.2 12.6973 2.34733 12.212 2.642C11.7353 2.93667 11.3713 3.34833 11.12 3.877H13.889V4.891H10.834C10.808 5.073 10.795 5.26367 10.795 5.463C10.795 5.66233 10.808 5.853 10.834 6.035H13.889V7.049H11.12C11.3713 7.58633 11.7353 8.00233 12.212 8.297C12.6973 8.59167 13.2563 8.739 13.889 8.739Z"
                                  fill="#4B4B4B" />
                          </svg>
                      </div>
                      <div class="plusetmois">
                          <i class="fa-solid fa-plus-minus"></i>
                      </div>

                  </div>
              </div>
              <div class="col-lg-3 mb-3">

                  <div class="combined-input  mb-3">
                      <input type="number" name="number1" class="nb1" placeholder="TJM" id="tjm"
                      formControlName="tjm" (input)="preventNegativeInput($event)">
                      <div class="euro">
                          <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z"
                                  fill="#4B4B4B" />
                          </svg>
                      </div>
                      <input type="number" name="number2" class="nb2" value="1" id="marge_tjm"
                      formControlName="tjm_range" min="0" (input)="preventNegativeInput($event)">
                      <div class="icon">
                          <svg width="11" height="11" viewBox="0 0 11 11" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path
                                  d="M5.889 8.739C6.435 8.739 6.91167 8.62633 7.319 8.401C7.735 8.167 8.06 7.82467 8.294 7.374H10.075C9.737 8.24067 9.19533 8.908 8.45 9.376C7.70467 9.844 6.851 10.078 5.889 10.078C4.875 10.078 3.97367 9.805 3.185 9.259C2.39633 8.70433 1.846 7.96767 1.534 7.049H0.338V6.035H1.3C1.28267 5.79233 1.274 5.60167 1.274 5.463C1.274 5.32433 1.28267 5.13367 1.3 4.891H0.338V3.877H1.534C1.846 2.95833 2.39633 2.226 3.185 1.68C3.97367 1.12533 4.875 0.847999 5.889 0.847999C6.84233 0.847999 7.69167 1.08633 8.437 1.563C9.191 2.031 9.737 2.69833 10.075 3.565H8.294C8.06 3.11433 7.735 2.77633 7.319 2.551C6.91167 2.317 6.435 2.2 5.889 2.2C5.25633 2.2 4.69733 2.34733 4.212 2.642C3.73533 2.93667 3.37133 3.34833 3.12 3.877H5.889V4.891H2.834C2.808 5.073 2.795 5.26367 2.795 5.463C2.795 5.66233 2.808 5.853 2.834 6.035H5.889V7.049H3.12C3.37133 7.58633 3.73533 8.00233 4.212 8.297C4.69733 8.59167 5.25633 8.739 5.889 8.739Z"
                                  fill="#4B4B4B" />
                          </svg>
                      </div>
                      <div class="plusetmois">
                          <i class="fa-solid fa-plus-minus"></i>
                      </div>

                  </div>
              </div>
                  <div class="col-lg-3  mb-3">
                      <ng-select [items]="poste" bindLabel="name" bindValue="id" [multiple]="true" id="dispo"
                          placeholder="Type de poste" [notFoundText]="'Aucun élément trouvé'" formControlName="post_type">
                      </ng-select>

                  </div>
               <div class="col-lg-3  mb-3">
                  <ng-select [items]="skillsList" bindLabel="name_FR" bindValue="name_FR" [multiple]="true"
                      id="skillsList" placeholder="Comptétences (Et)" formControlName="skillsAnd" [notFoundText]="'Aucun élément trouvé'">
                  </ng-select>
              </div>
              <div class="col-lg-3  mb-3">
                  <ng-select [items]="skillsList" bindLabel="name_FR" bindValue="name_FR" [multiple]="true"
                      id="skillsList" placeholder="Comptétences (Ou)" formControlName="skillsOr" [notFoundText]="'Aucun élément trouvé'">
                  </ng-select>
              </div>
             
              <div class="col-lg-3 mb-3">
                  <ng-select [items]="disponibilite" bindLabel="name" bindValue="id" [multiple]="true"
                      id="dispo" placeholder="Disponibilité" formControlName="availability" [notFoundText]="'Aucun élément trouvé'">

                  </ng-select>

              </div>

              
          </div>
          <div class="row mb-3 mb-0-sm" *ngIf="!isCollapsed">
              <div class="col-lg-3 mb-3">
                  <ng-select (change)="onCountryChange('add')" [items]="paysList" [multiple]="true"
                    formControlName="country" bindLabel="name" bindValue="name"
                    [placeholder]="'Pays'" [notFoundText]="'Aucun élément trouvé'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                  </ng-select>
              </div>
              <div class="col-lg-3  mb-3">
                  <ng-select [items]="stateList" (change)="oncityChange('add')" bindLabel="name" formControlName="city" bindValue="name"
                  [multiple]="true" [placeholder]="'Ville'" [notFoundText]="'Aucun élément trouvé'" [notFoundText]="'Aucun élément trouvé'" autoComplete>
                  </ng-select>
              </div>
              <div class="col-lg-3  mb-3">
                  <div class="position-relative d-flex align-items-center calenderDate">
                     
                      <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy" 
                      placeholder="Date de candidature" inputId="yearpicker"
                      formControlName="date_candidature" class="form-control"
                     ></p-calendar>
                    </div>
              </div>
              <div class="col-lg-3  mb-3">
                  <div class="position-relative d-flex align-items-center calenderDate">
                      <!--begin::Datepicker-->
                   

                      <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy" 
                      placeholder="Date de début de la publication" inputId="yearpicker"
                      formControlName="start_date" class="form-control"
                     ></p-calendar>
                    </div>
              </div>
              <div class="col-lg-3  mb-3">
                  <div class="position-relative d-flex align-items-center calenderDate">
                      <!--begin::Datepicker-->
                   
                      <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy"  placeholder="Date de fin de publication" inputId="yearpicker"
                      formControlName="end_date" class="form-control" [minDate]="searchForm.get('start_date')?.value"
                     ></p-calendar>
                      <!--end::Datepicker-->
                    </div>
              </div>
              <div class="col-lg-3 mb-3">
                  <ng-select [items]="profile_abroad" bindLabel="name" bindValue="id" [multiple]="true" id="date"
                      placeholder="Profil étranger" formControlName="profile_abroad" [notFoundText]="'Aucun élément trouvé'">
                  </ng-select>
              </div>
              <div class="col-lg-3 mb-3">
                  <ng-select [items]="passeport_talent" bindLabel="name" bindValue="id" [multiple]="true" id="date"
                      placeholder="Passeport talent" formControlName="passeport_talent" [notFoundText]="'Aucun élément trouvé'">
                  </ng-select>
              </div>
              <div class="col-lg-3 mb-3">
                  <ng-select [items]="state" bindLabel="name" bindValue="id" [multiple]="true" id="state"
                      placeholder="Statut" formControlName="state" [notFoundText]="'Aucun élément trouvé'">
                  </ng-select>
              </div>
          </div>
          <div class="row mb-3 mb-0-sm">
              <div class="col-lg-3 mb-3"></div>
              <div class="col-lg-3 mb-3"></div>
              <div class="col-lg-3 mb-3"></div>
              <div class="col-lg-3 mb-3">
                 <div class="see-more">
                  <a [ngClass]="{'show-more-button': !isCollapsed, 'emphasized-button': isCollapsed}"
                  (click)="toggleCollapsible()" class="show-more-button">
                  {{ isCollapsed ? 'Voir plus de critères de recherche' : 'Voir moins de critères de recherche' }}
              </a>
                 </div>
              </div>
          </div>
          <div class="row mb-3">
              <div class="col-lg-3"></div>
              <div class="col-lg-3"></div>
              <div class="col-lg-3"></div>
              <div class="col-lg-3 rechercher">
                  <div class="mb-3 search-btn d-flex justify-content-end">
                      <div class="btn_search">
                          <a (click)="searchJobArchived()"  type="button" class="search"> <svg width="20" height="20"
                                  viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                      fill="white" />
                              </svg>
                              Rechercher
                          </a>
                      </div> &nbsp;&nbsp;
                      <div class="resett-btn">
             
                          <div class="reset-icon cursor-pointer">
                           <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                           </div>
                        </div>
                    </div>
              </div>
          </div>


      </div>
      </div>
    </div>
  </div>
  <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
  <!--begin::Content container-->
  <div
    id="kt_app_content_container"
    class="app-container container-fluid for-web"
  >
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->
      <div class="d-flex flex-wrap my-5 header-grid align-items-center">
        <div class="section_title">
          <h1 class="section-heading my-5">
            Listes d'offres d'emploi archivés
          </h1>
        </div>
        <!--begin::Tab nav-->
        <ul class="nav nav-pills me-5">
          <li class="nav-item m-0">
            <a
              class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary me-3"
              data-bs-toggle="tab"
              href="#kt_project_targets_card_pane"
            >
              <i class="ki-duotone ki-element-plus fs-1">
                <span class="path1"></span>
                <span class="path2"></span>
                <span class="path3"></span>
                <span class="path4"></span>
                <span class="path5"></span>
              </i>
            </a>
          </li>
          <li class="nav-item m-0">
            <a
              class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active"
              data-bs-toggle="tab"
              href="#kt_project_targets_table_pane"
            >
              <i class="ki-duotone ki-row-horizontal fs-2">
                <span class="path1"></span>
                <span class="path2"></span>
              </i>
            </a>
          </li>
        </ul>
        <!--end::Tab nav-->
      </div>
      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body">
        <div class="tab-content">
          <!--begin::Tab pane-->
          <div id="kt_project_targets_card_pane" class="tab-pane fade">
            <!--begin::Row-->
            <div class="row g-6 g-xl-9">
              <!--begin::Col-->
              <div
                class="col-md-6 col-xxl-4"
                *ngFor="let archivedJob of archivedJobs"
              >
                <!--begin::Card-->
                <div class="card pt-5 p-5">
                  <div class="type">
                    <a
                      class="text-gray-800 esn mb-1"
                      [ngClass]="
                        archivedJob?.e_s_n?.type == '2'
                          ? 'esn-type'
                          : archivedJob?.e_s_n?.type == '3'
                          ? 'client-type'
                          : 'centre-type'
                      "
                      >{{
                        archivedJob?.e_s_n?.type == "2"
                          ? "ESN"
                          : archivedJob?.e_s_n?.type == "3"
                          ? "Client Final"
                          : "Cabinet de Recrutement"
                      }}
                    </a>
                  </div>
                  <div class="tools">
                    <div class="dropdown">
                      <button
                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          width="19"
                          height="5"
                          viewBox="0 0 19 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item dropdown"
                          *ngIf="archivedJob.e_s_n?.type === '2'"
                          (click)="detailsJobOfferNavigation(archivedJob.id)"
                          >Voir offre</a
                        >
                        <a
                          class="dropdown-item dropdown"
                          *ngIf="archivedJob.e_s_n?.type === '3'"
                          (click)="detailsJobRequestNavigation(archivedJob.id)"
                          >Voir offre</a
                        >

                        <a
                          class="dropdown-item dropdown"
                          (click)="
                            restoredJob(archivedJob.id, archivedJob.e_s_n?.type)
                          "
                          >Restaurer</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-center flex-column">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-65px symbol-circle mb-5">
                      <img
                        *ngIf="archivedJob.e_s_n?.logo"
                        src="{{
                          url +
                            '/Company/file/gallery_company/' +
                            archivedJob.e_s_n?.logo
                        }}"
                        alt="logo"
                      />
                      <img
                        *ngIf="!archivedJob.e_s_n?.logo"
                        src="/assets/media/img/modules/avatar.jpg"
                        alt="image"
                      />
                      <div
                        class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"
                      ></div>
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Name-->
                    <a
                      class="job-title fs-4 text-gray-800 text-hover-primary fw-bold mb-7"
                      >{{
                        archivedJob.e_s_n?.name && archivedJob.e_s_n?.name!=="null" && archivedJob.e_s_n?.name !='0'
                          ? archivedJob.e_s_n?.name
                          : "---"
                      }}
                    </a>
                    <div class="poste">
                      <span>
                        {{ archivedJob?.name && archivedJob?.name!=="0" && archivedJob?.name!='0' ? archivedJob?.name : "---" }}
                      </span>
                    </div>
                    <!--end::Name-->
                    <!--begin::Position-->
                    <!--end::Position-->
                    <!--begin::Info-->
                    <div class="d-flex flex-center flex-wrap mt-5 mb-5">
                      <!--begin::Stats-->
                      <div class="skills">
                        <div
                          class="skill"
                          *ngFor="
                            let skill of getSkillsArray(archivedJob)?.slice(0, 3);
                            let i = index
                          "
                        >
                          <span [ngClass]="'badge style' + ((i % 3) + 1)">{{
                            skill
                          }}</span>
                        </div>
                        <span
                          *ngIf="
                            getSkillsArray(archivedJob) &&
                            getSkillsArray(archivedJob).length > 3
                          "
                        >
                          +
                        </span>
                      </div>
                      <!--end::Stats-->
                    </div>
                    <div class="info">
                      <div class="type mb-3">
                        <span>Salaire :</span>
                        {{ archivedJob?.salary && archivedJob?.salary!=="null" && archivedJob?.salary!='0' ? archivedJob?.salary : "---" }}
                      </div>
                      <div class="type mb-3">
                        <span>TJM :</span>
                        {{ archivedJob?.tjm && archivedJob?.tjm!=="null" && archivedJob?.tjm!='0' ? archivedJob?.tjm : "---" }}
                      </div>
                    </div>
                    <div class="experience">
                      <div class="exper mb-3">
                        <span>Expérience :</span>
                        {{
                          archivedJob.years_of_experience && archivedJob.years_of_experience!=="null" && archivedJob.years_of_experience!='0'
                            ? archivedJob.years_of_experience
                            : "---"
                        }}
                        ans
                      </div>
                    </div>

                    <!--end::Info-->
                  </div>
                  <div class="sep"></div>
                  <div class="foot p-5">
                    
                    <div class="plus">
                      <a
                        *ngIf="archivedJob.e_s_n?.type === '2'"
                        (click)="detailsJobOfferNavigation(archivedJob.id)"
                        >Voir plus</a
                      >
                      <a
                        *ngIf="archivedJob.e_s_n?.type === '3'"
                        (click)="detailsJobRequestNavigation(archivedJob.id)"
                        >Voir plus</a
                      >
                    </div>
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>
          <!--end::Tab pane-->
          <!--begin::Tab pane-->
          <div
            id="kt_project_targets_table_pane"
            class="tab-pane fade active show"
          >
            <div class="content">
              <div class="card-body pt-3">
                <!--begin::Table-->
                <div class="table-responsive" id="kt_customers_table">
                  <table class="table align-middle gy-5">
                    <thead>
                      <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">





                        <ng-container
                        *ngFor="let item of listNames; let i = index"
                      >
                        <th
                          class="min-w-175px"
                          [ngClass]="{
                            'th-fixed-2': item === 'Poste',
                            'th-fixed': item === 'Nom entreprise'
                          }"
                        >
                          <span>{{ item }}</span>
                          <i
                            *ngIf="
                              ![
                                'Logo entreprise',
                                'Score',
                                'Compétences',
                                'Type'
                              ].includes(item)
                            "
                            (click)="sortData(item)"
                            class="fa-solid fa-sort cursor-pointer"
                          ></i>
                        </th>
                      </ng-container>






                        <!--<th *ngIf="filterColumns('id')" class="min-w-175px">
                          <span
                            >Id
                            <i
                              (click)="sortData('ID_jobOffer')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('logo')" class="min-w-175px">
                          <span>Logo entreprise</span>
                        </th>
                        <th
                          *ngIf="filterColumns('societe')"
                          class="min-w-175px th-fixed"
                        >
                          <span
                            >Nom entreprise
                            <i
                              (click)="sortData('esn_name')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('type')" class="min-w-175px">
                          <span>Type</span>
                        </th>
                        <th
                          *ngIf="filterColumns('poste')"
                          class="min-w-175px th-fixed-2"
                        >
                          <span
                            >POSTE
                            <i
                              (click)="sortData('name')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('dateOfCandidate')"
                          class="min-w-175px"
                        >
                          <span
                            >Date de candidature
                            <i
                              (click)="
                                sortData('candidate_job_offer_creation_date')
                              "
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('dateOfPublication')"
                          class="min-w-175px"
                        >
                          <span
                            >Date de publication
                            <i
                              (click)="sortData('start_date')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('experience')"
                          class="min-w-175px"
                        >
                          <span
                            >ANNéE EXPERIENCE
                            <i
                              (click)="sortData('years_of_experience')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('typeOfContract')"
                          class="min-w-175px"
                        >
                          <span
                            >TYPE DE CONTRAT
                            <i
                              (click)="sortData('contract_type')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>

                        <th *ngIf="filterColumns('salary')" class="min-w-175px">
                          <span
                            >SALAIRE
                            <i
                              (click)="sortData('salary')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('tjm')" class="min-w-175px">
                          <span
                            >TJM
                            <i
                              (click)="sortData('tjm')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('typeOfPost')"
                          class="min-w-175px"
                        >
                          <span
                            >TYPE DE POSTE
                            <i
                              (click)="sortData('post_type')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('skills')" class="min-w-175px">
                          <span
                            >COMPéTENCES
                            <i
                              (click)="sortData('skills')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('availability')"
                          class="min-w-175px"
                        >
                          <span
                            >DISPONIBILITÉ
                            <i
                              (click)="sortData('availability')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('scoreJobOffer')" class="min-w-175px">
                          <span
                            >Score
                            <i
                              (click)="sortData('name')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('city')" class="min-w-175px">
                          <span
                            >Pays
                            <i
                              (click)="sortData('country')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th
                          *ngIf="filterColumns('country')"
                          class="min-w-175px"
                        >
                          <span
                            >Ville
                            <i
                              (click)="sortData('city')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>
                        <th *ngIf="filterColumns('state')" class="min-w-175px">
                          <span
                            >STATUT
                            <i
                              (click)="sortData('state')"
                              class="fa-solid fa-sort cursor-pointer"
                            ></i
                          ></span>
                        </th>-->
                        <th class="stiky-column-action"><span>Actions</span></th>
                        <th class="stiky-column">
                          <div class="dropdown" id="table-filter">
                            <button
                              class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <svg
                                width="28"
                                height="28"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g filter="url(#filter0_d_444_2002)">
                                  <rect
                                    x="4"
                                    width="20"
                                    height="20"
                                    rx="4"
                                    fill="#63C3E8"
                                    fill-opacity="0.12"
                                    shape-rendering="crispEdges"
                                  />
                                </g>
                                <path
                                  d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                  fill="#265D91"
                                  stroke="#265D91"
                                  stroke-width="0.2"
                                />
                                <defs>
                                  <filter
                                    id="filter0_d_444_2002"
                                    x="0"
                                    y="0"
                                    width="28"
                                    height="28"
                                    filterUnits="userSpaceOnUse"
                                    color-interpolation-filters="sRGB"
                                  >
                                    <feFlood
                                      flood-opacity="0"
                                      result="BackgroundImageFix"
                                    />
                                    <feColorMatrix
                                      in="SourceAlpha"
                                      type="matrix"
                                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                      result="hardAlpha"
                                    />
                                    <feOffset dy="4" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite
                                      in2="hardAlpha"
                                      operator="out"
                                    />
                                    <feColorMatrix
                                      type="matrix"
                                      values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in2="BackgroundImageFix"
                                      result="effect1_dropShadow_444_2002"
                                    />
                                    <feBlend
                                      mode="normal"
                                      in="SourceGraphic"
                                      in2="effect1_dropShadow_444_2002"
                                      result="shape"
                                    />
                                  </filter>
                                </defs>
                              </svg>
                            </button>

                           <!-- <div class="list-actions">
                              <div
                                class="list-actions-content dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                <div
                                  *ngFor="
                                    let column of getCheckboxStateEntries()
                                  "
                                >
                                  <div
                                    class="form-check form-check-custom form-check-solid mb-3"
                                  >
                                    <input
                                      class="form-check-input"
                                      type="checkbox"
                                      [checked]="column[1].isChecked"
                                      (change)="updateCheckboxState(column[0])"
                                    />
                                    <label
                                      class="form-check-label"
                                      [for]="
                                        'checkbox' + column[0].toUpperCase()
                                      "
                                      >{{ column[1].displayName }}</label
                                    >
                                  </div>
                                </div>
                              </div>
                            </div>-->




                            <div class="list-actions">
                              <div
                                class="list-actions-content dropdown-menu eliminate"
                                aria-labelledby="dropdownMenuButton"
                                data-bs-popper="static"
                              >
                                <div
                                  class="d-flex checkbox mb-2 menu"
                                  *ngFor="
                                    let item of dataHeader;
                                    let i = index
                                  "
                                >
                                  <div
                                    class="form-check form-check-sm form-check-custom form-check-solid"
                                    *ngIf="item.name != 'checkbox'"
                                  >
                                    <input
                                      class="form-check-input checkbox-border"
                                      [checked]="
                                        (item.checked == 'oui' &&
                                          !item.checked_user) ||
                                        (item.checked_user == 'oui' &&
                                          (item.checked == 'non' ||
                                            item.checked == 'oui'))
                                      "
                                      (change)="
                                        changeSelection($event, item, i)
                                      "
                                      type="checkbox"
                                      value="1"
                                      [disabled]="
                                        item.name === 'Logo entreprise' ||
                                        item.name === 'Nom entreprise' || 
                                        item.name === 'Poste'
                                      "
                                    />
                                    <a class="dropdown-item">{{
                                      item.name
                                    }}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <!-- <td>
                                            <img *ngIf="archivedJob.e_s_n?.logo" src="{{url+'/Company/file/gallery_company/'+ archivedJob?.name?.logo}}" alt="logo" width="75px">
                                            <img *ngIf="!archivedJob.e_s_n?.logo" src="/assets/media/img/modules/avatar.jpg" alt="logo" width="75px">
                                        </td> -->
                    <tbody
                      class="fw-semibold text-gray-600"
                      *ngFor="let archivedJob of archivedJobs"
                    >
                      <tr>
                         <!--<td *ngIf="filterColumns('id')">-->
                          <td *ngIf="listNames?.includes('ID')">
                          <a
                            class="esn"
                            class="first-td"
                            [routerLink]="[
                              '/wegestu/jobs/details-job-offer',
                              { id: archivedJob.id }
                            ]"
                          >
                            {{
                              archivedJob?.ID_jobOffer
                                ? archivedJob?.ID_jobOffer
                                : "---"
                            }}
                          </a>
                        </td>
                        <!--<td *ngIf="filterColumns('logo')">-->
                        <td *ngIf="listNames?.includes('Logo entreprise')">  
                          <img
                            *ngIf="archivedJob.e_s_n?.logo"
                            class="table-img logo-img"
                            src="{{
                              url +
                                '/Company/file/gallery_company/' +
                                archivedJob?.e_s_n?.logo
                            }}"
                            alt="logo"
                            width="75px"
                          />
                          <img
                            *ngIf="!archivedJob.e_s_n?.logo"
                            class="table-img logo-img"
                            src="/assets/media/img/modules/avatar.jpg"
                            alt="logo"
                            width="75px"
                          />
                        </td>
                        <!--<td *ngIf="filterColumns('societe')" class="wrapping td-fixed">-->
                          <td *ngIf="listNames?.includes('Nom entreprise')" class="wrapping td-fixed">
                            <a class="first-td"
                            [routerLink]="['/wegestu/entreprises/details',{ id: archivedJob?.esn_id }]">
                            {{
                              archivedJob.e_s_n?.name && archivedJob.e_s_n?.name !=="null" && archivedJob.e_s_n?.name !='0' 
                                ? archivedJob.e_s_n?.name
                                : "---"
                            }}</a>
                          <span class="esn">
                            
                          </span>
                        </td>
                        <!--<td *ngIf="filterColumns('type')">-->
                        <td *ngIf="listNames?.includes('Type entreprise')">  
                          <span
                            class="esn"
                            [ngClass]="
                              archivedJob?.e_s_n?.type == '2'
                                ? 'esn-type'
                                : archivedJob?.e_s_n?.type == '3'
                                ? 'client-type'
                                : 'centre-type'
                            "
                            >{{
                              archivedJob?.e_s_n?.type == "2"
                                ? "ESN"
                                : archivedJob?.e_s_n?.type == "3"
                                ? "Client Final"
                                : "Cabinet de Recrutement"
                            }}
                          </span>
                        </td>

                        <!--<td *ngIf="filterColumns('poste')" class="wrapping td-fixed-2">-->
                        <td *ngIf="listNames?.includes('Poste')" class="wrapping poste-wrapper td-fixed-2">
                          <a
                            class="poste"
                            class="first-td"
                            [routerLink]="[
                              '/wegestu/jobs/details-job-offer',
                              { id: archivedJob.id }
                            ]"
                          >
                            {{
                              archivedJob?.name && archivedJob?.name !=="null" && archivedJob?.name !='0' ? archivedJob?.name : "---"
                            }}</a
                          >
                        </td>
                        <!--<td *ngIf="filterColumns('dateOfCandidate')">-->
                          <td *ngIf="listNames?.includes('Date de candidature')">
                          <span
                            *ngIf="
                              archivedJob.candidate_job_offer_creation_date
                            "
                            >{{
                              archivedJob.candidate_job_offer_creation_date
                                | date : "dd/MM/yyyy"
                            }}</span
                          >
                          <span *ngIf="!archivedJob.candidate_job_offer_creation_date">
                            ---</span
                          >
                        </td>
                        <!--<td *ngIf="filterColumns('dateOfPublication')">-->
                          <td *ngIf="listNames?.includes('Date de publication')">
                          <span>
                            {{
                              archivedJob.start_date | date : "dd/MM/yyyy"
                            }}</span
                          >
                        </td>
                        <!--<td *ngIf="filterColumns('experience')">-->
                          <td *ngIf="listNames?.includes('Années d’expérience')">
                          <span
                            >{{
                              archivedJob.years_of_experience && archivedJob.years_of_experience !=="null" && archivedJob.years_of_experience != '0'
                                ? archivedJob.years_of_experience
                                : "---"
                            }}
                            ans</span
                          >
                        </td>
                        <!--<td *ngIf="filterColumns('typeOfContract')">-->
                        <td *ngIf="listNames?.includes('Type de contrat')">
                          <span>
                            <!-- {{archivedJob?.contract_type=='1'?'CDD':archivedJob?.contract_type=='2'?'CDI':
                                                    archivedJob?.contract_type=='3'?'Portage
                                                    salarial':archivedJob?.contract_type=='4'?'Freelance':
                                                    archivedJob?.contract_type=='5'?'Stage':archivedJob?.contract_type=='6'?'Alternance':'Autre
                                                    type de contrat'}} -->
                            <!-- <div *ngFor="let contractType of archivedJob?.contract_type">
                                                        <span class="color-blue" *ngIf="archivedJob?.contract_type">{{ contratTypeOptions[contractType] }}</span>
                                                      </div> -->
                            <ng-container>
                              <div class="contracts">
                                <div
                                  *ngFor="
                                    let contract of archivedJob.contract_type
                                  "
                                >
                                  <span *ngIf="contract == 1">Alternance</span>
                                  <span *ngIf="contract == 2"
                                    >Autres type de contrat</span
                                  >

                                  <span *ngIf="contract == 3">CDD</span>
                                  <span *ngIf="contract == 4">CDI</span>
                                  <span *ngIf="contract == 5">Freelance</span>
                                  <span *ngIf="contract == 6"
                                    >Portage salarial</span
                                  >
                                  <span *ngIf="contract == 7">Stage</span>
                                </div>
                              </div>
                            </ng-container>
                            <span *ngIf="archivedJob.contract_type.length == 0"
                              >---</span
                            >
                          </span>
                        </td>
                        <!--<td *ngIf="filterColumns('salary')">-->
                        <td *ngIf="listNames?.includes('Salaire')">
                          <span>{{
                            archivedJob?.salary &&
                            archivedJob?.salary !== "null" &&  archivedJob?.salary != '0'
                              ? archivedJob?.salary
                              : "---"
                          }}</span>
                        </td>
                        <!--<td *ngIf="filterColumns('tjm')">-->
                        <td *ngIf="listNames?.includes('TJM')">
                          <span>{{
                            archivedJob?.tjm && archivedJob?.tjm !== "null" && archivedJob?.tjm !='0'
                              ? archivedJob?.tjm
                              : "---"
                          }}</span>
                        </td>
                        <!--<td *ngIf="filterColumns('typeOfPost')">-->
                        <td *ngIf="listNames?.includes('Type de poste')">  
                          <span
                            class="badge teletravail"
                            [ngClass]="
                              archivedJob?.post_type == '1'
                                ? 'teletravail'
                                : archivedJob?.post_type == '2'
                                ? 'hybrid'
                                : archivedJob?.post_type == '3'
                                ? 'onsite'
                                : ''
                            "
                          >
                            {{
                              archivedJob?.post_type == "1"
                                ? "à distance"
                                : archivedJob?.post_type == "2"
                                ? "hybrid"
                                : archivedJob?.post_type == "3"
                                ? "sur site"
                                : "---"
                            }}</span
                          >
                        </td>
                        <!--<td *ngIf="filterColumns('skills')">-->
                        <td *ngIf="listNames?.includes('Compétences')">
                          <div class="skills">
                            <span
                              *ngFor="
                                let skill of getSkillsArray(archivedJob)?.slice(
                                  0,
                                  3
                                );
                                let i = index
                              "
                              class="badge"
                              [ngClass]="i % 2 === 0 ? 'laravel' : 'postgres'"
                              >{{ skill }}</span
                            >
                            <span
                              *ngIf="
                                getSkillsArray(archivedJob) &&
                                getSkillsArray(archivedJob).length > 3
                              "
                            >
                              +</span
                            >
                          </div>
                          <div class="skill">
                            <span *ngIf="archivedJob.skills == null">---</span>
                          </div>
                        </td>
                        <!--<td *ngIf="filterColumns('availability')">-->
                        <td *ngIf="listNames?.includes('Disponibilité')">
                          <span class="mb-1 immediate">{{
                            archivedJob.availability == "1"
                              ? "Immédiate"
                              : archivedJob.availability == "2"
                              ? "1 mois"
                              : archivedJob.availability == "3"
                              ? "2 mois"
                              : archivedJob.availability == "4"
                              ? "3 mois"
                              : archivedJob.availability == "5"
                              ? "4 mois"
                              : "5 mois"
                          }}</span>
                        </td>

                        <!--<td *ngIf="filterColumns('scoreJobOffer')">-->
                        <td *ngIf="listNames?.includes('Score')">
                          <ng-container *ngIf="archivedJob?.scoreJobOffer !== undefined && archivedJob?.scoreJobOffer !== null; else noScore">
                            <div
                                class="d-flex justify-content-center w-100 mt-auto mb-2">
                                 <span class="fw-bold fs-6 text-gray-400">
                                  {{ archivedJob?.scoreJobOffer != null ? (archivedJob.scoreJobOffer | number: '1.2-2') + '%' : '---' }}
                                 </span>
                             </div>
                            <div class="h-8px mx-3 w-100 score-light rounded">
                                <div class="score-qcm rounded h-8px"
                                    role="progressbar"
                                    [style.width.%]="archivedJob.scoreJobOffer"
                                    aria-valuenow="archivedJob.scoreJobOffer"
                                    aria-valuemin="0"
                                    aria-valuemax="100">
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #noScore>
                            <span>---</span>
                        </ng-template>
                        </td>
                        <!--<td *ngIf="filterColumns('country')">-->
                        <td *ngIf="listNames?.includes('Pays')">
                          <span>
                            {{
                              archivedJob?.country &&
                              archivedJob?.country !== "null" && archivedJob?.country != '0'
                                ? archivedJob?.country
                                : "---"
                            }}</span
                          >
                        </td>
                        <!--<td *ngIf="filterColumns('city')">-->
                        <td *ngIf="listNames?.includes('Ville')">
                          <span>
                            {{
                              archivedJob?.city && archivedJob?.city !== "null" && archivedJob?.city != '0'
                                ? archivedJob?.city
                                : "---"
                            }}</span
                          >
                          <span *ngIf="archivedJob?.city?.length == 0"
                            >---</span
                          >
                        </td>
                        <td *ngIf="listNames?.includes('Profil étranger')">
                          <span>{{ archivedJob.profile_abroad == "1" ? "OUI" : "NON" }}</span>
                        </td>
                        <td *ngIf="listNames?.includes('Passeport talent')">
                          <span>{{ archivedJob.passeport_talent == "1" ? "OUI" : "NON" }}</span>
                        </td>
                        <!--<td *ngIf="filterColumns('state')">-->
                        <td *ngIf="listNames?.includes('Statut')">
                          <span
                            class="badge"
                            [ngClass]="
                              archivedJob.state == '1'
                                ? 'deactive-badge'
                                : 'active-badge'
                            "
                          >
                            <i class="fa-solid fa-circle"></i>
                            {{
                              archivedJob.state == "1" ? "Non Actif" : "Actif"
                            }}
                          </span>
                        </td>

                        <td class="td-fixed-3">
                          <div class="tools">
                            <div class="dropdown">
                              <button
                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                type="button"
                                id="dropdownMenuButton"
                                data-bs-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <svg
                                  width="19"
                                  height="5"
                                  viewBox="0 0 19 5"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    cx="2.44739"
                                    cy="2.44739"
                                    r="2.44739"
                                    transform="matrix(1 0 0 -1 0 4.89478)"
                                    fill="#265D91"
                                  />
                                  <circle
                                    cx="2.44739"
                                    cy="2.44739"
                                    r="2.44739"
                                    transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                    fill="#265D91"
                                  />
                                  <circle
                                    cx="2.44739"
                                    cy="2.44739"
                                    r="2.44739"
                                    transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                    fill="#265D91"
                                  />
                                </svg>
                              </button>
                              <div class="list-btn-action">
                                <div
                                class="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                                >
                                <a
                                  class="dropdown-item"
                                  *ngIf="archivedJob.e_s_n?.type === '2'"
                                  (click)="
                                    detailsJobOfferNavigation(archivedJob.id)
                                  "
                                  >Voir offre</a
                                >
                                <a
                                  class="dropdown-item"
                                  *ngIf="archivedJob.e_s_n?.type === '3'"
                                  (click)="
                                    detailsJobRequestNavigation(archivedJob.id)
                                  "
                                  >Voir offre</a
                                >

                                <a
                                  class="dropdown-item"
                                  (click)="
                                    restoredJob(
                                      archivedJob.id,
                                      archivedJob.e_s_n?.type
                                    )
                                  "
                                  >Restaurer</a
                                >
                                </div>
                              </div>
                             
                            </div>
                          </div>
                        </td>
                        <td class="td-fixed-4"></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <!--end::Table-->
                <div
                class="alert alert-warning text-center mt-5"
                role="alert"
                *ngIf="archivedJobs && !archivedJobs.length"
              >
                <img
                  src="/assets/media/logos/logo-no-data.png"
                  alt=""
                  class="mb-5"
                />
                <span class="empty-list"> La liste est vide </span>
              </div>
              </div>
            </div>
          </div>

          <div class="pagination" *ngIf="archivedJobs && archivedJobs.length">
            <app-pagination
              [data]="archivedJobs"
              [lengthData]="totalJobArchived"
              [currentPageList]="currentPage"
              [endexPageList]=" endIndex"
              (setPages)="getItems($event)"
            >
            </app-pagination>
    
         
          </div>

        
          <!--end::Tab pane-->
        </div>

        <!--end::Table-->
      </div>
      <!--end::Card body-->
    </div>
  </div>
  <div
    id="kt_app_content_container"
    class="app-container container-fluid for-mobile"
  >
    <!--begin::Card-->
    <div class="card">
      <!--begin::Card header-->

      <!--end::Card header-->
      <!--begin::Card body-->
      <div class="card-body">
        <div class="tab-content">
          <!--begin::Tab pane-->

          <!--end::Tab pane-->
          <!--begin::Tab pane-->
          <div
            id="kt_project_targets_card_pane"
            class="tab-pane fade show active"
          >
            <!--begin::Row-->
            <div class="row g-6 g-xl-9 justify-content-center">
              <!--begin::Col-->
              <div
                class="col-md-6 col-xxl-4"
                *ngFor="let archivedJob of archivedJobs"
              >
                <!--begin::Card-->
                <div class="card pt-5 p-5">
                  <div class="type">
                    <a
                      class="text-gray-800 esn mb-1"
                      [ngClass]="
                        archivedJob?.e_s_n?.type == '2'
                          ? 'esn-type'
                          : archivedJob?.e_s_n?.type == '3'
                          ? 'client-type'
                          : 'centre-type'
                      "
                      >{{
                        archivedJob?.e_s_n?.type == "2"
                          ? "ESN"
                          : archivedJob?.e_s_n?.type == "3"
                          ? "Client Final"
                          : "Cabinet de Recrutement"
                      }}
                    </a>
                  </div>
                  <div class="tools">
                    <div class="dropdown">
                      <button
                        class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        data-boundary="window"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <svg
                          width="19"
                          height="5"
                          viewBox="0 0 19 5"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 0 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 6.85254 4.89478)"
                            fill="#265D91"
                          />
                          <circle
                            cx="2.44739"
                            cy="2.44739"
                            r="2.44739"
                            transform="matrix(1 0 0 -1 13.7061 4.89478)"
                            fill="#265D91"
                          />
                        </svg>
                      </button>
                      <div
                        class="dropdown-menu"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          class="dropdown-item dropdown"
                          *ngIf="archivedJob.e_s_n?.type === '2'"
                          (click)="detailsJobOfferNavigation(archivedJob.id)"
                          >Voir offre</a
                        >
                        <a
                          class="dropdown-item dropdown"
                          *ngIf="archivedJob.e_s_n?.type === '3'"
                          (click)="detailsJobRequestNavigation(archivedJob.id)"
                          >Voir offre</a
                        >

                        <a
                          class="dropdown-item dropdown"
                          (click)="
                            restoredJob(archivedJob.id, archivedJob.e_s_n?.type)
                          "
                          >Restaurer</a
                        >
                      </div>
                    </div>
                  </div>
                  <!--begin::Card body-->
                  <div class="card-body d-flex flex-center flex-column">
                    <!--begin::Avatar-->
                    <div class="symbol symbol-65px symbol-circle mb-5">
                      <img
                        *ngIf="archivedJob.e_s_n?.logo"
                        src="{{
                          url +
                            '/Company/file/gallery_company/' +
                            archivedJob.e_s_n?.logo
                        }}"
                        alt="logo"
                      />
                      <img
                        *ngIf="!archivedJob.e_s_n?.logo"
                        src="/assets/media/img/modules/avatar.jpg"
                        alt="image"
                      />
                      <div
                        class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"
                      ></div>
                    </div>
                    <!--end::Avatar-->
                    <!--begin::Name-->
                    <a
                      class="fs-4 text-gray-800 text-hover-primary fw-bold mb-7"
                      >{{
                        archivedJob.e_s_n?.name && archivedJob.e_s_n?.name !=="null" && archivedJob.e_s_n?.name!='0'
                          ? archivedJob.e_s_n?.name
                          : "---"
                      }}
                    </a>
                    <!--end::Name-->
                    <!--begin::Position-->
                    <!--end::Position-->
                    <!--begin::Info-->
                    <div class="info">
                      <div class="type mb-3">
                        <span>Salaire :</span>
                        {{ archivedJob?.salary && archivedJob?.salary!=="null" && archivedJob?.salary!='0' ? archivedJob?.salary : "---" }}
                      </div>
                      <div class="type mb-3">
                        <span>TJM :</span>
                        {{ archivedJob?.tjm && archivedJob?.tjm!=="null" && archivedJob?.tjm!='0' ? archivedJob?.tjm : "---" }}
                      </div>
                    </div>
                    <div class="experience">
                      <div class="exper mb-3">
                        <span>Expérience :</span>
                        {{
                          archivedJob.years_of_experience && archivedJob.years_of_experience!=="null" && archivedJob.years_of_experience!='0'
                            ? archivedJob.years_of_experience
                            : "---"
                        }}
                        ans
                      </div>
                    </div>

                    <!--end::Info-->
                  </div>
                  <div class="sep"></div>
                  <div class="foot p-5">
                    <div class="poste">
                      <span>
                        {{ archivedJob?.name && archivedJob?.name!=="null" && archivedJob?.name!='0' ? archivedJob?.name : "---" }}
                      </span>
                    </div>
                    <div class="plus">
                      <a
                        *ngIf="archivedJob.e_s_n?.type === '2'"
                        (click)="detailsJobOfferNavigation(archivedJob.id)"
                        >Voir plus</a
                      >
                      <a
                        *ngIf="archivedJob.e_s_n?.type === '3'"
                        (click)="detailsJobRequestNavigation(archivedJob.id)"
                        >Voir plus</a
                      >
                    </div>
                  </div>
                  <!--end::Card body-->
                </div>
                <!--end::Card-->
              </div>
              <!--end::Col-->
            </div>
            <!--end::Row-->
          </div>

       

          <div
            class="alert alert-warning text-center mt-5"
            role="alert"
            *ngIf="archivedJobs && !archivedJobs.length"
          >
            <img src="/assets/media/no data.png" alt="" class="mb-5" />
            <span class="empty-list"> La liste est vide </span>
          </div>
          <!--end::Tab pane-->
        </div>

        <!--end::Table-->
      </div>
      <!--end::Card body-->
    </div>
  </div>
  <!--end::Content container-->
</div>
<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
