import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthentificationRoutingModule } from './authentification-routing.module';
import { LoginComponent } from './components/login/login.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ResetMailComponent } from './components/reset-mail/reset-mail.component';
import { CodeResetComponent } from './components/code-reset/code-reset.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RegisterComponent } from './components/register/register.component';
import { ChangePasswordComponent } from '../main/dossier/components/change-password/change-password.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FieldErrorModule } from '../shared/shared-field-error/field-error.module';
import { CodeVerficationComponent } from './components/code-verfication/code-verfication.component';
import { NgxIntlTelephoneInputModule } from 'ngx-intl-telephone-input';
import { ErrorMessageComponent } from '../shared/error-message/error-message.component';



@NgModule({
  declarations: [
    LoginComponent,
    ResetPasswordComponent,
    ResetMailComponent,
    CodeResetComponent,
    RegisterComponent,
    ChangePasswordComponent,
    CodeVerficationComponent,
  ],
  imports: [
    CommonModule,
    AuthentificationRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    NgxIntlTelInputModule,
    FieldErrorModule,
    NgxIntlTelephoneInputModule,
    ErrorMessageComponent

  ]
})
export class AuthentificationModule { }
