import { Component, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, takeUntil } from 'rxjs';
import { CompanyService } from '../../../../main/entreprise/services/company.service';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { environment } from '../../../../../environnements/environment';

@Component({
  selector: 'app-my-reviews',
  templateUrl: './my-reviews.component.html',
  styleUrls: ['./my-reviews.component.css'],
})
export class MyReviewsComponent {
  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  note: number = 4;
  stars!: number;
  id: any;
  listDefaultReview: any;
  companyDetails: any;
  currentUser: any;
  idCandidate: number = 0;
  listCompany: any;
  searchElement: string = '';
  currentSortDirection: number = 1;
  pagination: boolean = false;

  url: string = environment.baseUrl + '/api';
  dataCompany: any = [];

  checkboxState: {
    [key: string]: { displayName: string; isChecked: boolean };
  } = {
      logo: { displayName: 'Logo', isChecked: true },
      societe: { displayName: 'Société', isChecked: true },
      type: { displayName: 'Type', isChecked: true },
      siteWeb: { displayName: 'Site Web', isChecked: true },
      avis: { displayName: 'Avis', isChecked: true },
    };

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private elRef: ElementRef
  ) { }

  ngOnInit() {
    this.getIdCandidate();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }
  getIdCandidate() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idCandidate = JSON.parse(this.currentUser).id;
    this.getListCompaniesReviews();
  }

  /*get list Companies reviews by candidate */
  getListCompaniesReviews() {
    let data = this.sortAttr == '' ? { user_id: this.idCandidate, per_page: this.itemsPerPage, page: this.currentPage } : { ...{ per_page: this.itemsPerPage, page: this.currentPage }, ...{ user_id: this.idCandidate, sort: this.currentSortDirection, sortAttribute: this.sortAttr } }

    this.spinner.show()
    this.companyService.getListCompaniesReviews(data).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res: any) => {
        this.listCompany = res?.data?.data
        this.totalItems = res?.data?.total
        if (this.currentPage > 1 && !res.data.data.length) {
          this.currentPage = 1
          this.getListCompaniesReviews()
        }
        this.spinner.hide()
        console.log('listCompany', this.listCompany)
      },
      error: () => {
        this.spinner.hide()
      },
    })
  }

  filterColumns(columnName: string): boolean {
    return this.checkboxState[columnName]?.isChecked || false;
  }

  updateCheckboxState(columnName: string): void {
    const checkbox = this.checkboxState[columnName];
    if (checkbox) {
      checkbox.isChecked = !checkbox.isChecked;
    }
  }

  getCheckboxStateEntries(): [
    string,
    { displayName: string; isChecked: boolean }
  ][] {
    return Object.entries(this.checkboxState);
  }

  /* ************************* navigation to reviews by company ********************* */
  reviewCandidateNavigation(idcompany: any) {
    this.router.navigate([
      '/wegestu/evaluations/review-company',
      { idCompany: idcompany },
    ]);
  }

  /* ************************* navigation to details company ********************* */
  companyDetailsNavigation(idcompany: any) {
    this.router.navigate(['/wegestu/entreprises/details', { id: idcompany }]);
  }

  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchElement != '') {
        this.applyFilter();
        this.currentPage = 1;
        this.endIndex = 5;
      }
      else {
        this.pagination = true
        this.getListCompaniesReviews()
      }
    }

  }

  /* sort data */
  // sortData() {
  //   this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
  //   this.getListCompaniesReviews()
  // }

  sortAttr: string = ''
  sortData(sortAttribute: string) {
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.sortAttr = sortAttribute
    this.currentPage = 1;
    this.endIndex = 5;
    this.listCompany = this.companyService.sortArray(this.listCompany, sortAttribute, this.currentSortDirection)
    this.getListCompaniesReviews()
  }
  clickCount: number = 0;
  applyFilter() {
    if (this.searchElement != '' || this.clickCount) {
      this.clickCount++;
      // if (this.clickCount == 1) this.currentPage = 1;

      let data = {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        sort: this.currentSortDirection,
        user_id: this.idCandidate,
        search: this.searchElement?.trim().toLowerCase(),
      };

      this.spinner.show();

      console.log(
        '%centreprises.component.ts line:203 data search',
        'color: #007acc;',
        data
      );

      this.companyService
        .searchCompanyreviewd(this.idCandidate, data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res: any) => {

            if (this.currentPage > 1) {
              this.currentPage = 1;

              this.endIndex = 5;
              this.applyFilter();
            } else {
              this.listCompany = res?.data?.data;
              this.totalItems = res?.data?.total
            }

            this.spinner.hide();
          },
          error: () => {
            this.spinner.hide();
          },
        });
    }
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
