export const environment = {
  production: false,
  url: '',
  urlLocal: '',
  baseUrl: 'https://api.test.wegestu.com',
  baseUrlGaleryCompany:
    'https://api.test.wegestu.com/api/Company/file/gallery_company/',
  baseUrlGalery: 'https://api.test.wegestu.com/api/User/file/gallery_users/',
  baseUrlGaleryCv: 'https://api.test.wegestu.com/api/User/file/gallery_cvs/',
  baseUrlStoragePdf: 'https://api.test.wegestu.com/api/storage/',
  CLIENT_ID: 1,
  CLIENT_SECRET: 'X4N7aSQ6DEfMuAmndhRn4eOwhXkd0AFGd33We5hj',
};
