import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConsultantComponent } from './components/consultant-list/consultant.component';
import { DetailsConsultantComponent } from './components/consultant-details/details-consultant.component';

const routes: Routes = [
  {
    path:'',
    component:ConsultantComponent
  },
  {
    path:'detailsConsultant',
    component:DetailsConsultantComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ConsultantRoutingModule { }
