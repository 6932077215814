import { Component, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil } from 'rxjs';
import { EvaluationsService } from '../../../../main/evaluation/services/evaluations.service';
import {
  sendEvaluation,
  serverError,
  successEvaluation,
  successEvaluationupdate,
} from '../../../../shared/shared-models/message';
import { Review } from '../../models/review';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-assessment-details',
  templateUrl: './assessment-details.component.html',
  styleUrls: ['./assessment-details.component.css'],
})
export class AssessmentDetailsComponent {
  isCollapsed = true; // Initial state
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  modalRef?: BsModalRef;
  currentUser: any;
  id: any;
  idEvaluation: any;
  evaluationDetais: any;
  
  evaluation: any;
  itemsPerPage: number = 20;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  ratings: any = [];
  evaluationData: any;
  evaluationDetailsArray: any[] = [];
  comments: { [key: number]: { [key: number]: string } } = {};
  comment: any;
  commentt: any;
  selectedQuestionId: any;
  isCancelDisabled: boolean = true;
  isSendDisabled: boolean = true;
  notecheck: boolean=false;
  idResponse_evaluation: any;
  starsArray: any[] = [];
  editReviewForm!: FormGroup;
  starRatings: number[] = [1, 2, 3, 4, 5];
  selectedRating: number = 0;
  selectedStars: any = [];
  commentToEdit: any;
  expandedAccordions: { [key: number]: boolean } = {};

  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private evaluationsService: EvaluationsService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getEvaluationDeatils();
    this.getId();
    this.editReviewForm = this.reviewForm(new Review());
    this.editReviewForm.get('stars')?.valueChanges.subscribe((rating) => {
      this.selectedRating = rating;
    });
  }

  getId() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEvaluation = params.params['idEvaluation'];
      },
    });
  }
  reviewForm(data: Review) {
    return this.formBuilder.group({
      id: [data.id, [Validators.required]],
      question: [data.question, [Validators.required]],
      stars: [data.stars, [Validators.required]],
      comment: [data.comment, [Validators.required]],
    });
  }

  openModal(template?: TemplateRef<any>, idquestion?: any) {
    if (template) {
      this.modalRef = this.modalService.show(template, { backdrop: 'static' });
      this.editReviewForm = this.reviewForm(idquestion);
      this.editReviewForm
        .get('stars')
        ?.setValue(Number(idquestion.response_evaluation[0]?.stars));
      this.editReviewForm
        .get('comment')
        ?.setValue(idquestion.response_evaluation[0]?.comment);
      this.editReviewForm.get('question')?.setValue(idquestion.question);
      this.selectedRating = idquestion.response_evaluation[0]?.stars;
      this.editReviewForm.get('question')?.disable();
      this.editReviewForm
        .get('id')
        ?.setValue(idquestion.response_evaluation[0]?.id);
    } else {
      console.error('Invalid template reference');
    }
  }

  closeModal() {
    this.modalRef?.hide();
  }
  groupedEvaluationDetails: any[] = [];
  getEvaluationDeatils() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idEvaluation = params.params['idEvaluation'];
      },
    });
    let dataPayload = this.idEvaluation;
    console.log('idEvaluation', dataPayload);

    this.spinner.show();
    this.evaluationsService
      .getEvaluationsByCandidateDeatils(dataPayload)
      .subscribe(
        (res: any) => {
          this.evaluation = res?.data;
          if(this.evaluation.evaluation===null)
          {
           this.evaluationDetais=res.data.default_evaluation_company

          }
          if(this.evaluation.default_evaluation_company===null)
            {
             this.evaluationDetais=res.data.evaluation
  
            }
        
          this.evaluationDetailsArray = this.evaluationDetais?.question_evaluation || [];

        
          const questionsByModule = new Map<number, any[]>();
          
          this.evaluationDetailsArray.forEach((item) => {
              const moduleId = item.chapter_eval_id;
              if (!questionsByModule.has(moduleId)) {
                  questionsByModule.set(moduleId, []);
              }
              questionsByModule.get(moduleId)?.push(item);
          });
          

          this.groupedEvaluationDetails = Array.from(questionsByModule.entries());

          console.log("groupedEvaluationDetails",this.groupedEvaluationDetails)

          // Initialisation du tableau des étoiles
          this.starsArray = this.groupedEvaluationDetails.map((module) => {
            return module[1].map((question:any) => {
                const responseEvaluation = question.response_evaluation[0];
                if (responseEvaluation) {
                    return responseEvaluation.stars;
                } else {
                    return 0;
                }
            });
          });
          console.log("starsss",this.starsArray)
          this.commentt = this.groupedEvaluationDetails.map((module) => {
            return module[1].map((question:any) => {
                const responseEvaluationn = question.response_evaluation[0];
                if (responseEvaluationn) {
                    return responseEvaluationn.comment;
                } else {
                    return 0;
                }
            });
          });
          console.log("commentt",this.commentt)
          this.spinner.hide();
          console.log('data evaluation', this.evaluationDetais);
          console.log('starsArray', this.starsArray);
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching evaluation:', error);
        }
      );
  }


  extractTypeResponses(): string[] {
    const typeResponses: string[] = [];

    if (this.evaluationDetais && this.evaluationDetais.question_evaluation) {
      const questions = this.evaluationDetais.question_evaluation;

      for (const questionId in questions) {
        if (questions.hasOwnProperty(questionId)) {
          const question = questions[questionId];
          if (question && question.typeResponse) {
            typeResponses.push(question.typeResponse);
          }
        }
      }
    }

    return typeResponses;
  }

  setRating(questionId: any, moduleIndex: number, questionIndex: number, stars: number) {
    // Assurez-vous que starsArray[moduleIndex] est un tableau
    if (!Array.isArray(this.starsArray[moduleIndex])) {
        this.starsArray[moduleIndex] = [];
    }

    // Mettre à jour les étoiles pour la question spécifique
    this.starsArray[moduleIndex][questionIndex] = stars;

    let id = this.evaluation.evaluation_send.id;
    this.ratings.push({
        question_evaluation_id: questionId,
        evaluation_send_id: id,
        stars: stars,
        comment: this.comments[questionId],
    });

    this.selectedStars.push({
        id: questionId,
        stars: stars,
    });

    let data = {
        responses: this.ratings,
    };

    this.evaluationData = data;
    console.log('evaluationData', this.evaluationData);
    this.notecheck=true;
}


  // addEvaluation() {
  //   console.log('selectedStars', this.selectedStars);
  //   for (const key in this.comments) {
  //     if (Object.prototype.hasOwnProperty.call(this.comments, key)) {
  //       const element = this.comments[key];
  //       console.log('element', key);
  //       let id = this.evaluation.id;
  //       this.ratings.push({
  //         question_evaluation_id: key,
  //         evaluation_send_id: id,
  //         comment: element||'',
  //       });
  //     }
  //   }

  //   let data = {
  //     responses: this.ratings,
  //   };

  //   this.spinner.show();

  //   this.evaluationsService
  //     .addEvaluationResponses(data)
  //     .pipe(takeUntil(this.unsubscribeAll))
  //     .subscribe({
  //       next: (res) => {
  //         if (res.status == 200) {
  //           this.toastrService.success(successEvaluation);
  //           this.getEvaluationDeatils();
  //           this.isCancelDisabled = false;
  //           this.isSendDisabled = false;
  //         }
  //         this.spinner.hide();
  //       },
  //       error: (err: any) => {
  //         this.spinner.hide();
  //         if (err.error) {
  //           this.toastrService.error(serverError);
  //         }
  //         this.isCancelDisabled = true;
  //         this.isSendDisabled = true;
  //       },
  //     });
  // }
  addEvaluation() {
    
    console.log('selectedStars', this.selectedStars);
    for (const key in this.comments) {
        if (Object.prototype.hasOwnProperty.call(this.comments, key)) {
            const element = this.comments[key];
            console.log('element', key);

            // Vérification si le commentaire est non vide avant d'ajouter à the `ratings` array
            if (element) {
                let id = this.evaluation.evaluation_send.id;
                this.ratings.push({
                    question_evaluation_id: key,
                    evaluation_send_id: id,
                    comment: element,
                });
            }
        }
    }

    let data = {
        responses: this.ratings,
    };

    this.spinner.show();

    this.evaluationsService
        .addEvaluationResponses(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
            next: (res) => {
                if (res.status == 200) {
                    this.toastrService.success(successEvaluation);
                    this.getEvaluationDeatils();
                    this.isCancelDisabled = false;
                    this.isSendDisabled = false;
                }
                this.spinner.hide();
            },
            error: (err: any) => {
                this.spinner.hide();
                if (err.error) {
                    this.toastrService.error(serverError);
                }
                this.isCancelDisabled = true;
                this.isSendDisabled = true;
            },
        });
}


  sendEvaluation() {

    // console.log("commet",this.commentt)
    // let noResponse = false;

    // this.groupedEvaluationDetails.forEach((module: any) => {
    //   module[1].forEach((question: any) => {
    //     const responseEvaluation = question.response_evaluation;
        
   
    //     if (!responseEvaluation || responseEvaluation.length === 0) {
    //       noResponse = true;
    //     }
    //   });
    // });

    // if (!this.notecheck) {
    //   this.toastrService.error(" Remplir les notes et les commentaires avant d'envoyer !");
    //   return;
    // }
    console.log('selectedStars', this.selectedStars);
    for (const key in this.comments) {
        if (Object.prototype.hasOwnProperty.call(this.comments, key)) {
            const element = this.comments[key];
            console.log('element', key);

            // Vérification si le commentaire est non vide avant d'ajouter à the `ratings` array
            if (element) {
                let id = this.evaluation.evaluation_send.id;
                this.ratings.push({
                    question_evaluation_id: key,
                    evaluation_send_id: id,
                    comment: element,
                });
            }
        }
    }

    let data = {
        responses: this.ratings,
    };

    this.spinner.show();

    this.evaluationsService
        .addEvaluationResponses(data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
            next: (res) => {
                if (res.status == 200) {
                    this.toastrService.success(successEvaluation);
                    this.getEvaluationDeatils();
                    this.isCancelDisabled = false;
                    this.isSendDisabled = false;
                }
                this.spinner.hide();
            },
            error: (err: any) => {
                this.spinner.hide();
                if (err.error) {
                    this.toastrService.error(serverError);
                }
                this.isCancelDisabled = true;
                this.isSendDisabled = true;
            },
        });
    let dataa = {
      id: this.evaluation.evaluation_send.id,
    };

    this.spinner.show();
if(this.evaluation.default_evaluation_company===null){
    this.evaluationsService
      .sendEvaluationResponses(this.evaluation.evaluation_send.id,this.evaluation.evaluation.esn_id, dataa)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            // this.toastrService.success(sendEvaluation);
            this.getEvaluationDeatils();
            this.router.navigate(['/wegestu/evaluations/completed-assessment']);
          }
          this.spinner.hide();
        },
        error: (err: any) => {
          this.spinner.hide();
          if (err.error) {
            // this.toastrService.error(serverError);
          }
        },
      });
    }
    if(this.evaluation.evaluation===null){
      this.evaluationsService
        .sendEvaluationResponses(this.evaluation.evaluation_send.id,this.evaluation.default_evaluation_company.esn_id, data)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status == 200) {
              this.toastrService.success(sendEvaluation);
              this.getEvaluationDeatils();
              this.router.navigate(['/wegestu/evaluations/completed-assessment']);
            }
            this.spinner.hide();
          },
          error: (err: any) => {
            this.spinner.hide();
            if (err.error) {
              this.toastrService.error(serverError);
            }
          },
        });
      }

  }

  showDeleteConfirmation() {
    Swal.fire({
      title: 'Êtes-vous sûr de supprimer les evaluations sélectionnés ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteEvaluation();
      }
    });
  }
  deleteEvaluation() {
    const responseIds = this.evaluationDetailsArray
      .flatMap((question) => question.response_evaluation)
      .filter((response) => response)
      .map((response) => response.id);

    this.evaluationsService.deleteEvaluation(responseIds).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.toastr.success('Evaluations supprimés avec succès');
        this.getEvaluationDeatils();
      },
      error: () => {
        this.spinner.hide();
        this.toastr.error(serverError);
      },
    });
  }
  editCommentCandidateForCompany() {
    this.id = this.editReviewForm.get('id')?.value;
    const data = {
      comment: this.editReviewForm.get('comment')?.value,
    };
    console.log('edit evaluation', this.id, data);
    this.evaluationsService
      .updateEvaluationResponses(this.id, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('resss', res);
          if (res.status == 200) {
            this.toastrService.success(successEvaluationupdate);
            this.getEvaluationDeatils();
            this.closeModal();
          }
        },
        error: (err: any) => {
          if (err.error) {
            this.toastrService.error(serverError);
          }
        },
      });
  }

  editReviewCandidateForCompany() {
    this.id = this.editReviewForm.get('id')?.value;

    const data = {
      stars: this.editReviewForm.get('stars')?.value,
    };

    console.log('edit evaluation', this.id, data);

    this.evaluationsService
      .updateEvaluationResponses(this.id, data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('resss', res);
          if (res.status == 200) {
            this.toastrService.success(successEvaluationupdate);
            this.getEvaluationDeatils();
            this.closeModal();
          }
        },
        error: (err: any) => {
          if (err.error) {
            this.toastrService.error(serverError);
          }
        },
      });
  }

  toggleAccordion(index: number): void {
    this.expandedAccordions[index] = !this.expandedAccordions[index];
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  
}
