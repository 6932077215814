<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>


<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_534_1023)">
                            <path d="M8.73438 5.38853H19.3343V3.25956H15.8403V0H12.1576V3.25956H8.73438V5.38853Z"
                                fill="#265D91" />
                            <path
                                d="M23.0071 5.26135H21.1792V6.30811C21.1792 6.81596 20.7664 7.2277 20.2573 7.2277H7.81339C7.30421 7.2277 6.8914 6.81596 6.8914 6.30811V5.26135H4.99288C4.4445 5.26135 4 5.70476 4 6.25165V28.0122C4 28.5592 4.44456 29.0025 4.99288 29.0025H23.0071C23.5555 29.0025 24 28.5591 24 28.0122V6.25165C23.9999 5.70469 23.5554 5.26135 23.0071 5.26135ZM9.66407 24.6577C9.31129 25.0116 8.72134 25.0152 8.36426 24.6654L6.74707 23.0816L8.03906 21.7693L9.00248 22.7128L11.514 20.1933L12.8217 21.49L9.66407 24.6577ZM9.66407 14.8943C9.31129 15.2482 8.7214 15.2518 8.36426 14.9021L6.74707 13.3183L8.03906 12.0059L9.00248 12.9495L11.514 10.4299L12.8217 11.7267L9.66407 14.8943ZM21.4019 26.2713H14.9673V24.4321H21.4019V26.2713ZM21.4019 21.6653H14.9673V19.8261H21.4019V21.6653ZM21.4019 16.3509H14.9673V14.5117H21.4019V16.3509ZM21.4019 11.745H14.9673V9.90579H21.4019V11.745Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_534_1023" x="0" y="0" width="28" height="37.0026"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_534_1023" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_534_1023"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>


                    Evaluation
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Evaluer une société</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
            <div class="card-body tools">
                <div class="title mb-10">
                    <h4>Evaluer une société</h4>
                </div>
                <div class="container-fluid">
                    <div class="row">
                        <!--begin::Input group-->
                        <!--begin::Main wrapper-->
                        <div class="col-lg-6 seach-free">
                            <div class="input-group mb-3">
                                <input type="text" data-kt-customer-table-filter="search" [(ngModel)]="searchElement"  (keyup.enter)="applyFilter()"
                                    class="form-control form-control-solid ps-15" placeholder="Rechercher" />
                                    <div class="input-group-append">
                                    <svg  width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                            fill="#7E7C7C" />
                                    </svg>
        
                                    <button  class="input-group-text cursor-pointer" id="basic-addon2" (click)="applyFilter()" ><span>Rechercher</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!--end::Main wrapper-->
                        <!--end:Action-->
                    </div>
                </div>
            </div>


            <div *ngIf="!isLoadSpinner" id="kt_app_content_container" class="app-container container-fluid for-web">

            <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                <div class="section_title">
                    <h1 class="section-heading my-0"></h1>
                </div>
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3"
                            data-bs-toggle="tab" href="#grid">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active "
                            data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->

            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body ">
                <div class="tab-content for-web pb-5">
                    <!--begin::Tab pane-->
                    <div id="grid" class="tab-pane fade ">
                        <!--begin::Row-->
                        <div class="row g-6 g-xl-9 cards justify-content-center">
                            <!--begin::Col-->
                            <div class="col-sm-6 col-xl-4" *ngFor="let company of listCampany">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="poste"> 
                                        <span class="wrapping" *ngIf="company?.company?.type!=='1'" [ngClass]="company.company?.type=='2'?
                                        'esn-type':company.company?.type=='3'?'client-type':'centre-type'">
                                          {{company.company?.type=='2'||company.company?.type=='5'?
                                          'ESN':company.company?.type=='3'?'Client Final':company.company?.type=='4'?'Cabinet de Recrutement':'Particulier'}}</span>
                                          <span *ngIf="company?.company?.type=='1'">---</span>
                                    </div>
                                    <div class="card-toolbar m-0">
                                        <!--begin::Menu-->
                                        <div class="tools">
                                           
                                            <div class="actions">
                                                <div class="tools">
                                                    <div class="dropdown">
                                                        <button
                                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                            type="button" id="dropdownMenuButton"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                    transform="matrix(1 0 0 -1 0 4.89478)"
                                                                    fill="#265D91" />
                                                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                    transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                    fill="#265D91" />
                                                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                    transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                    fill="#265D91" />
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item btn-details"
                                                            (click)="reviewCompanyNavigation(company.company.id,'evaluation')">Donner
                                                                un avis</a>
                                                            <a class="dropdown-item btn-details"
                                                                (click)="profilCompanyNavigation(company.company.id)">Voir
                                                                Détails</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!--begin::Card header-->
                                    <div class="card-header flex-nowrap border-0 pt-9">
                                        <!--begin::Card title-->
                                        <div class="card-title m-0">
                                            <!--begin::Icon-->
                                            <div class="symbol">
                                                <img *ngIf="company.company.logo" class="table-img logo-img"
                                                    src="{{url+'/Company/file/gallery_company/'+ company.company.logo}}"
                                                    alt="">
                                                <img *ngIf="!company.company.logo" class="table-img logo-img"
                                                    src="/assets/media/img/modules/avatar.jpg" alt="">
                                            </div>
                                            <!--end::Icon-->
                                            <!--begin::Title-->
                                            <a
                                                class="fs-4 fw-semibold text-hover-primary name">{{company?.company?.name?company?.company?.name:'---'}}</a>
                                                
                                           
                                            <!--end::Title-->
                                            <div class="ratings">
                                                <rating [(ngModel)]="company.average_note"
                                                    (ngModelChange)="onRatingChange($event)" [max]="5" [readonly]="true">
                                                </rating>
                                            </div>
                                        </div>
                                        <!--end::Card title-->
                                        <!--begin::Card toolbar-->

                                        <!--end::Card toolbar-->
                                    </div>
                                    <!--end::Card header-->
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-column px-9 pt-6 pb-8">
                                        <!--begin::Heading-->
                                        <!-- <div class="fw-bold mb-3 mail text-center"> <svg width="15" height="10"
                                                viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.51185 0H13.4193C13.8339 0 14.212 0.169447 14.4868 0.443242C14.7611 0.717038 14.9307 1.09327 14.9307 1.50635V8.12962C14.9307 8.54414 14.7606 8.92085 14.4868 9.19369L14.4724 9.20709C14.199 9.47227 13.8272 9.63597 13.4188 9.63597H1.51137C1.0963 9.63597 0.718214 9.46653 0.44438 9.19321C0.170065 8.92085 0 8.54414 0 8.12962V1.50635C0 1.09327 0.170065 0.716559 0.44486 0.442764C0.719655 0.169447 1.09726 0.000478766 1.51185 0.000478766V0ZM1.81691 1.2742L1.55989 1.67772L7.46558 5.42038L13.3708 1.67772L13.1138 1.2742L7.4651 4.85365L1.81643 1.2742H1.81691Z"
                                                    fill="#265D91" />
                                            </svg>
                                            {{company.company.email?company.company.email:'---'}}
                                        </div>
                                        <div class="fw-bold mb-3 phone text-center"><svg width="13" height="16"
                                                viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.3933 12.9149C12.0867 11.986 11.5346 11.1928 10.7542 10.5586C10.4535 10.3129 10.1549 10.1686 9.84222 10.1178C9.7207 10.0981 9.59605 10.093 9.4714 10.102C9.05836 10.1338 8.64624 10.2571 8.2118 10.4795C8.07498 10.5503 7.91751 10.5713 7.74455 10.5433C7.5336 10.5091 7.31749 10.4 7.1803 10.2584C6.18421 9.23037 5.37914 8.1136 4.71883 6.84499C4.58938 6.59524 4.53609 6.09005 4.83555 5.79797C5.18516 5.45768 5.43317 5.10671 5.59451 4.72519C5.76766 4.31403 5.75899 3.86957 5.56778 3.36769C5.21263 2.42649 4.63419 1.65278 3.84923 1.06715C3.59495 0.877219 3.32278 0.757591 3.03937 0.711397C2.56621 0.634467 2.08974 0.767714 1.66176 1.09623C1.26845 1.39769 0.910722 1.72658 0.494545 2.12742C0.113218 2.4944 0.0604813 2.9812 0.0136452 3.4102L0.0123545 3.41922C-0.0368787 3.96564 0.0606656 4.63703 0.311626 5.47222C0.735916 6.88548 1.43864 8.27611 2.46111 9.72397C2.67113 10.0218 3.5116 11.1873 3.72789 11.4807C4.77912 12.9079 5.87737 14.0151 7.08516 14.8647C7.79913 15.3673 8.40597 15.673 8.94053 15.7996L8.94956 15.8013C9.05983 15.8252 9.17803 15.8504 9.29383 15.8692C9.57945 15.9157 9.95414 15.9444 10.3211 15.7536C10.8343 15.4867 11.2604 15.2524 11.6703 14.9766C12.3909 14.4934 12.6609 13.7225 12.3933 12.9149ZM3.06647 3.48805C2.52196 2.73292 2.36227 1.91835 2.70985 1.66879C3.05744 1.41904 3.78063 1.8289 4.32515 2.58403C4.86966 3.33916 5.02935 4.15373 4.68195 4.4033C4.33418 4.65304 3.61099 4.24318 3.06647 3.48805ZM11.5305 13.9649C11.1829 14.2146 10.4598 13.8048 9.91523 13.0496C9.37072 12.2945 9.21103 11.4799 9.55843 11.2304C9.90583 10.9808 10.6292 11.3905 11.1737 12.1456C11.7184 12.9007 11.8781 13.7153 11.5305 13.9649Z"
                                                    fill="#265D91" />
                                            </svg>
                                            {{company.company.phone?company.company.phone:'---'}}
                                        </div> -->
                                        <!--end::Heading-->

                                        <!--begin::Indicator-->
                                        <div class="footer d-flex fw-semibold">
                                            <div class="socials">
                                                <span class="px-3 py-2 me-2" *ngIf="company.company?.linkedin_link">
                                                    <a target="_blank"
                                                        [href]="linkedinPrefix+company.company?.linkedin_link"
                                                        [attr.title]="company.company?.linkedin_link ? '' : 'le lien linkedin non disponible pour le moment'">
                                                        <svg width="25" height="25" viewBox="0 0 17 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_426_2396)">
                                                                <path
                                                                    d="M14.2669 17.8389H2.84767C1.3399 17.8389 0.113281 16.5429 0.113281 14.9497V2.88923C0.113281 1.296 1.3399 0 2.84767 0H14.2669C15.7742 0 17.0008 1.296 17.0008 2.88923V14.9502C17.0008 16.5434 15.7742 17.8389 14.2669 17.8389ZM5.81961 14.7475H5.8231V7.05277H3.5621V14.7475H5.81918H5.81961ZM4.69108 6.00185C4.86298 6.00209 5.03325 5.96642 5.19211 5.89687C5.35098 5.82733 5.49532 5.72528 5.61688 5.59657C5.73844 5.46787 5.83482 5.31503 5.9005 5.14682C5.96618 4.97861 5.99987 4.79833 5.99964 4.61631C5.99895 4.24906 5.86086 3.89707 5.61561 3.63739C5.37035 3.37771 5.03792 3.2315 4.69108 3.23077C4.34406 3.23089 4.01129 3.37691 3.76591 3.63672C3.52053 3.89653 3.38263 4.24888 3.38251 4.61631C3.38263 4.98374 3.52053 5.33608 3.76591 5.5959C4.01129 5.85571 4.34406 6.00172 4.69108 6.00185ZM14.2799 14.748V10.5295C14.2799 8.45677 13.8549 6.86215 11.5708 6.86215C10.4728 6.86215 9.73572 7.49908 9.43277 8.10369H9.40226V7.05277H7.23715V14.7475H9.49423V10.9398C9.49423 9.93554 9.67425 8.964 10.8499 8.964C12.0059 8.964 12.0229 10.1123 12.0229 11.0045V14.7475H14.2799V14.748Z"
                                                                    fill="#265D91" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_426_2396">
                                                                    <rect width="17" height="18" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>

                                                    </a>

                                                </span>
                                                <span *ngIf="company.company?.website_link" >
                                                    <a target="_blank"
                                                        [attr.href]="company.company?.website_link ? company.company?.website_link : null"
                                                        [attr.title]="company.company?.website_link ? '' : 'Site Web non disponible pour le moment'">
                                                        <svg width="25" height="25" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.0410156" y="0.0407715" width="17.9592"
                                                                height="17.9592" rx="3" fill="#265D91" />
                                                            <path
                                                                d="M8.72077 14.4082C5.4083 14.4082 2.73438 12.0017 2.73438 9.02045C2.73438 6.03922 5.4083 3.63269 8.72077 3.63269C12.0332 3.63269 14.7072 6.03922 14.7072 9.02045C14.7072 12.0017 12.0332 14.4082 8.72077 14.4082ZM8.72077 4.35106C5.8473 4.35106 3.53256 6.43432 3.53256 9.02045C3.53256 11.6066 5.8473 13.6898 8.72077 13.6898C11.5942 13.6898 13.909 11.6066 13.909 9.02045C13.909 6.43432 11.5942 4.35106 8.72077 4.35106Z"
                                                                fill="white" />
                                                            <path
                                                                d="M11.833 10.3494C11.9128 9.91841 11.9128 9.48738 11.9128 9.02045C11.9128 8.55351 11.8729 8.12249 11.833 7.69147C11.7532 7.11677 11.5936 6.578 11.4339 6.07514C10.8353 4.60249 9.87746 3.63269 8.72009 3.63269C7.36317 3.63269 6.24571 4.96167 5.7668 6.90126C5.72689 7.08085 5.68698 7.22453 5.64707 7.40412C5.56725 7.90698 5.52734 8.44575 5.52734 9.02045C5.52734 9.48738 5.56725 9.91841 5.60716 10.3494C5.68698 10.9241 5.84662 11.4629 6.00626 11.9658C6.56499 13.4384 7.56272 14.4082 8.68018 14.4082C9.79764 14.4082 10.7954 13.4384 11.3541 11.9658C11.0747 12.0376 10.7555 12.0735 10.4362 12.1453C10.0371 13.1151 9.35864 13.6898 8.72009 13.6898C8.08154 13.6898 7.40308 13.1151 6.96408 12.1453C6.76453 11.7143 6.60489 11.1755 6.48517 10.6009C6.36544 10.098 6.32553 9.59514 6.32553 9.02045C6.32553 8.44575 6.36544 7.90698 6.48517 7.44004C6.52508 7.26045 6.56499 7.11677 6.60489 6.93718V6.90126C6.6448 6.72167 6.68471 6.578 6.76453 6.43432C6.80444 6.32657 6.84435 6.21881 6.88426 6.14698C6.88426 6.14698 6.88426 6.14698 6.88426 6.11106L6.96408 5.96738C6.96408 5.96738 6.96408 5.93147 7.00399 5.89555C7.40308 4.92575 8.08154 4.35106 8.72009 4.35106C9.35864 4.35106 10.0371 4.92575 10.4761 5.89555C10.6756 6.32657 10.8353 6.86534 10.955 7.44004C11.0747 7.94289 11.1146 8.44575 11.1146 9.02045C11.1146 9.59514 11.0747 10.098 10.9949 10.6009C11.2743 10.529 11.5536 10.4572 11.833 10.3494Z"
                                                                fill="white" />
                                                            <path
                                                                d="M11.9933 6.61416C11.4745 6.43456 10.836 6.32681 10.1974 6.25497C9.7185 6.18314 9.23959 6.14722 8.72077 6.14722C8.20195 6.14722 7.72304 6.18314 7.24412 6.21905C7.16431 6.43456 7.04458 6.68599 6.96476 6.97334C7.52349 6.9015 8.08222 6.86558 8.72077 6.86558C9.35932 6.86558 9.91805 6.9015 10.4768 6.97334C11.1153 7.08109 11.714 7.22477 12.1929 7.40436C13.2704 7.83538 13.909 8.41007 13.909 8.98477C13.909 9.55946 13.2704 10.1701 12.1929 10.5652C11.714 10.7448 11.1153 10.8884 10.4768 10.9962C9.91805 11.1399 9.35932 11.1758 8.72077 11.1758C8.08222 11.1758 7.52349 11.1399 6.96476 11.0321C6.32621 10.9603 5.72757 10.8166 5.24866 10.6011C4.17111 10.206 3.53256 9.59538 3.53256 9.02069C3.53256 8.44599 4.17111 7.83538 5.24866 7.44028C5.28857 7.15293 5.36839 6.86558 5.44821 6.61416C3.81193 7.11701 2.73438 7.97905 2.73438 9.02069C2.73438 10.0623 3.81193 10.9244 5.44821 11.4272C5.96703 11.6068 6.60558 11.7146 7.24412 11.7864C7.72304 11.8582 8.20195 11.8582 8.72077 11.8582C9.23959 11.8582 9.7185 11.8223 10.1974 11.7864C10.836 11.7146 11.4745 11.5709 11.9933 11.4272C13.6296 10.9244 14.7072 10.0264 14.7072 9.02069C14.7072 8.01497 13.6296 7.11701 11.9933 6.61416Z"
                                                                fill="white" />
                                                        </svg>
                                                    </a>


                                                </span>
                                            </div>

                                            <div class="details">
                                                <a (click)="profilCompanyNavigation(company.company.id)">Voir Détails</a>
                                            </div>
                                        </div>
                                        <!--end::Indicator-->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->

                    </div>
                    <!--end::Tab pane-->

                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_table_pane" class="tab-pane fade  active show">
                        <div class="content">
                            <div class="card-body pt-3">
                                <!--begin::Table-->
                                <div class="table-responsive"    id="kt_customers_table">
                                    <table class="table align-middle  fs-6 gy-5"
                                     >
                                        <thead>
                                            <tr class="text-start fw-bold fs-7 text-uppercase gs-0">
                                                <th *ngIf="filterColumns('logo')" class="min-w-70px"><span>logo</span></th>
                                                <th *ngIf="filterColumns('societe')" class="min-w-125px"><span>société
                                                    <i (click)="sortData('name')"  class="fa-solid fa-sort cursor-pointer"></i></span>
                                                </th>
                                                <th *ngIf="filterColumns('type')" class="min-w-125px"><span>Type
                                                    </span>
                                                </th>
                                               
                                                <th *ngIf="filterColumns('siteWeb')" class="min-w-125px"><span>SITE WEB</span>
                                                </th>
                                                <th *ngIf="filterColumns('avis')" class="min-w-125px"><span>avis <i (click)="sortData('average_note')"  class="fa-solid fa-sort cursor-pointer"></i></span>
                                                </th>
                                                <th class="min-w-70px"><span>Actions</span></th>
                                                <th class="min-w-90px">
                                                    <div class="dropdown" id="table-filter">
                                                        <button
                                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                            type="button" id="dropdownMenuButton"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_d_444_2002)">
                                                                    <rect x="4" width="20" height="20" rx="4"
                                                                        fill="#63C3E8" fill-opacity="0.12"
                                                                        shape-rendering="crispEdges" />
                                                                </g>
                                                                <path
                                                                    d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                                                    fill="#265D91" stroke="#265D91"
                                                                    stroke-width="0.2" />
                                                                <defs>
                                                                    <filter id="filter0_d_444_2002" x="0" y="0"
                                                                        width="28" height="28"
                                                                        filterUnits="userSpaceOnUse"
                                                                        color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0"
                                                                            result="BackgroundImageFix" />
                                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                            result="hardAlpha" />
                                                                        <feOffset dy="4" />
                                                                        <feGaussianBlur stdDeviation="2" />
                                                                        <feComposite in2="hardAlpha" operator="out" />
                                                                        <feColorMatrix type="matrix"
                                                                            values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                            result="effect1_dropShadow_444_2002" />
                                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                            in2="effect1_dropShadow_444_2002"
                                                                            result="shape" />
                                                                    </filter>
                                                                </defs>
                                                            </svg>
                                                        </button>
                                                       <div class="list-actions">
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            
                                                            <div *ngFor="let column of getCheckboxStateEntries()">
                                                                <div class="form-check form-check-custom form-check-solid mb-3">
                                                                    <input class="form-check-input" type="checkbox" 
                                                                           [checked]="column[1].isChecked" 
                                                                           (change)="updateCheckboxState(column[0])" />
                                                                    <label class="form-check-label" [for]="'checkbox' + column[0].toUpperCase()">{{ column[1].displayName }}</label>
                                                                </div>
                                                            </div>
                                                            
                                                        </div>
                                                       </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-semibold text-gray-600">
                                            <tr *ngFor="let company of listCampany">

                                                <td *ngIf="filterColumns('logo')">
                                                    <img *ngIf="company.company.logo" class="table-img logo-img"
                                                        src="{{url+'/Company/file/gallery_company/'+ company.company.logo}}"
                                                        alt="">
                                                    <img *ngIf="!company.company.logo" class="table-img logo-img"
                                                        src="/assets/media/img/modules/avatar.jpg" alt="">
                                                </td>
                                                <td *ngIf="filterColumns('societe')">
                                                    <!-- <span class="name"> {{company.company.name?company.company.name:'---'}}</span> -->
                                                   <a class="first-td"  
                                                   [routerLink]="['/wegestu/evaluations/review-company', { idCompany: company.company.id , redirection:'evaluation' }]"
                                                   > {{company.company.name?company.company.name:'---'}}</a>

                                                </td>
                                                <td *ngIf="filterColumns('type')">
                                                    <span class="wrapping" *ngIf="company?.company?.type!=='1'" [ngClass]="company.company?.type=='2'?
                                                    'esn-type':company.company?.type=='3'?'client-type':'centre-type'">
                                                      {{company.company?.type=='2'||company.company?.type=='5'?
                                                      'ESN':company.company?.type=='3'?'Client Final':company.company?.type=='4'?'Cabinet de Recrutement':'Particulier'}}</span>
                                                      <span *ngIf="company?.company?.type=='1'">---</span>
                                                </td>

                                                <!-- <td>
                                                    <a target="_blank"
                                                        [attr.href]="company.company?.linkedin_link ? company.company?.linkedin_link : null"
                                                        [attr.title]="company.company?.linkedin_link ? '' : 'le lien linkedin non disponible pour le moment'">

                                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_539_3243)">
                                                                <path
                                                                    d="M20.1417 24.7763H4.02046C1.89185 24.7763 0.160156 22.9763 0.160156 20.7635V4.01282C0.160156 1.8 1.89185 0 4.02046 0H20.1417C22.2697 0 24.0014 1.8 24.0014 4.01282V20.7641C24.0014 22.9769 22.2697 24.7763 20.1417 24.7763ZM8.21616 20.4827H8.22108V9.79551H5.02908V20.4827H8.21554H8.21616ZM6.62292 8.3359C6.86562 8.33623 7.10599 8.28669 7.33027 8.1901C7.55455 8.09351 7.75834 7.95178 7.92994 7.77302C8.10156 7.59426 8.23762 7.38198 8.33034 7.14836C8.42307 6.91473 8.47063 6.66434 8.47031 6.41154C8.46933 5.90148 8.27439 5.4126 7.92814 5.05193C7.5819 4.69127 7.11258 4.4882 6.62292 4.48718C6.13302 4.48735 5.66322 4.69015 5.31681 5.051C4.97039 5.41185 4.7757 5.90122 4.77554 6.41154C4.7757 6.92186 4.97039 7.41123 5.31681 7.77208C5.66322 8.13293 6.13302 8.33573 6.62292 8.3359ZM20.1602 20.4833V14.6244C20.1602 11.7455 19.5602 9.53077 16.3355 9.53077C14.7854 9.53077 13.7448 10.4154 13.3171 11.2551H13.274V9.79551H10.2174V20.4827H13.4038V15.1942C13.4038 13.7994 13.658 12.45 15.3177 12.45C16.9497 12.45 16.9737 14.0449 16.9737 15.284V20.4827H20.1602V20.4833Z"
                                                                    fill="#265D91" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_539_3243">
                                                                    <rect width="24" height="25" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </a>
                                                </td> -->
                                                <td *ngIf="filterColumns('siteWeb')">
                                                    <a target="_blank"
                                                        [attr.href]="company.company?.website_link ? company.company?.website_link : null"
                                                        [attr.title]="company.company?.website_link ? '' : 'Site Web non disponible pour le moment'">
                                                      
                                                        <svg width="25" height="25" viewBox="0 0 25 25" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="25" height="25" rx="3" fill="#265D91" />
                                                            <path
                                                                d="M12.0833 20.0001C7.47222 20.0001 3.75 16.6501 3.75 12.5001C3.75 8.35012 7.47222 5.00012 12.0833 5.00012C16.6944 5.00012 20.4167 8.35012 20.4167 12.5001C20.4167 16.6501 16.6944 20.0001 12.0833 20.0001ZM12.0833 6.00012C8.08333 6.00012 4.86111 8.90012 4.86111 12.5001C4.86111 16.1001 8.08333 19.0001 12.0833 19.0001C16.0833 19.0001 19.3056 16.1001 19.3056 12.5001C19.3056 8.90012 16.0833 6.00012 12.0833 6.00012Z"
                                                                fill="white" />
                                                            <path
                                                                d="M16.4145 14.3501C16.5256 13.7501 16.5256 13.1501 16.5256 12.5001C16.5256 11.8501 16.4701 11.2501 16.4145 10.6501C16.3034 9.85012 16.0812 9.10012 15.8589 8.40012C15.0256 6.35012 13.6923 5.00012 12.0812 5.00012C10.1923 5.00012 8.63672 6.85012 7.97005 9.55012C7.9145 9.80012 7.85894 10.0001 7.80339 10.2501C7.69227 10.9501 7.63672 11.7001 7.63672 12.5001C7.63672 13.1501 7.69227 13.7501 7.74783 14.3501C7.85894 15.1501 8.08116 15.9001 8.30339 16.6001C9.08116 18.6501 10.4701 20.0001 12.0256 20.0001C13.5812 20.0001 14.9701 18.6501 15.7478 16.6001C15.3589 16.7001 14.9145 16.7501 14.4701 16.8501C13.9145 18.2001 12.9701 19.0001 12.0812 19.0001C11.1923 19.0001 10.2478 18.2001 9.63672 16.8501C9.35894 16.2501 9.13672 15.5001 8.97005 14.7001C8.80339 14.0001 8.74783 13.3001 8.74783 12.5001C8.74783 11.7001 8.80339 10.9501 8.97005 10.3001C9.02561 10.0501 9.08116 9.85012 9.13672 9.60012V9.55012C9.19227 9.30012 9.24783 9.10012 9.35894 8.90012C9.4145 8.75012 9.47005 8.60012 9.52561 8.50012C9.52561 8.50012 9.52561 8.50012 9.52561 8.45012L9.63672 8.25012C9.63672 8.25012 9.63672 8.20012 9.69227 8.15012C10.2478 6.80012 11.1923 6.00012 12.0812 6.00012C12.9701 6.00012 13.9145 6.80012 14.5256 8.15012C14.8034 8.75012 15.0256 9.50012 15.1923 10.3001C15.3589 11.0001 15.4145 11.7001 15.4145 12.5001C15.4145 13.3001 15.3589 14.0001 15.2478 14.7001C15.6367 14.6001 16.0256 14.5001 16.4145 14.3501Z"
                                                                fill="white" />
                                                            <path
                                                                d="M16.6389 9.15049C15.9167 8.90049 15.0278 8.75049 14.1389 8.65049C13.4722 8.55049 12.8056 8.50049 12.0833 8.50049C11.3611 8.50049 10.6944 8.55049 10.0278 8.60049C9.91667 8.90049 9.75 9.25049 9.63889 9.65049C10.4167 9.55049 11.1944 9.50049 12.0833 9.50049C12.9722 9.50049 13.75 9.55049 14.5278 9.65049C15.4167 9.80049 16.25 10.0005 16.9167 10.2505C18.4167 10.8505 19.3056 11.6505 19.3056 12.4505C19.3056 13.2505 18.4167 14.1005 16.9167 14.6505C16.25 14.9005 15.4167 15.1005 14.5278 15.2505C13.75 15.4505 12.9722 15.5005 12.0833 15.5005C11.1944 15.5005 10.4167 15.4505 9.63889 15.3005C8.75 15.2005 7.91667 15.0005 7.25 14.7005C5.75 14.1505 4.86111 13.3005 4.86111 12.5005C4.86111 11.7005 5.75 10.8505 7.25 10.3005C7.30556 9.90049 7.41667 9.50049 7.52778 9.15049C5.25 9.85049 3.75 11.0505 3.75 12.5005C3.75 13.9505 5.25 15.1505 7.52778 15.8505C8.25 16.1005 9.13889 16.2505 10.0278 16.3505C10.6944 16.4505 11.3611 16.4505 12.0833 16.4505C12.8056 16.4505 13.4722 16.4005 14.1389 16.3505C15.0278 16.2505 15.9167 16.0505 16.6389 15.8505C18.9167 15.1505 20.4167 13.9005 20.4167 12.5005C20.4167 11.1005 18.9167 9.85049 16.6389 9.15049Z"
                                                                fill="white" />
                                                        </svg>
                                                    </a>
                                                </td>
                                                <td *ngIf="filterColumns('avis')">
                                                    <!-- <a *ngIf="!company?.company?.average_note" class="entreprise-name  name mb-1 ">
                                                    ---
                                                    </a> -->
                                                    <!-- <a *ngIf="company?.company?.average_note" class="entreprise-name  name mb-1 ">
                                                        {{ company?.company?.average_note | number:'1.1-1' }}
                                                    </a> -->
                                                    <rating [(ngModel)]="company.average_note" [titles]="[' ',' ',' ',' ',' ']" [max]="5"
                                                    [readonly]="true" class="rating-stars disable-hover">
                                                  </rating>

                                                </td>
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item btn-details"
                                                                    (click)="reviewCompanyNavigation(company.company.id,'evaluation')">Donner
                                                                    un avis</a>
                                                                <a class="dropdown-item btn-details"
                                                                    (click)="profilCompanyNavigation(company.company.id)">Voir
                                                                    Détails</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <!--end::Table-->
                                <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCampany&&!listCampany.length ">
                                    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                                    <span class="empty-list">
                                     La liste est vide
                                 </span>
                                 </div> 
                    
                            </div>
                        </div>
                    </div>
                    <!--end::Tab pane-->
                    <!-- <div class="pagination" *ngIf="listCampany && listCampany.length">
                        <app-pagination [data]="listCampany" [lengthData]="totalItems" [currentPageList]="currentPage" [endexPageList]="endIndex" (setPages)="getItems($event)">
                        </app-pagination>
                       
                    </div> -->
                    <div class="pagination" *ngIf="listCampany && listCampany.length">
                        <app-pagination
                          [data]="listCampany"
                          [lengthData]="totalItems"
                          [currentPageList]="currentPage"
                          [endexPageList]=" endIndex"
                          (setPages)="getItems($event)"
                        >
                        </app-pagination>
                    
                    
          


                </div>

                <div class="tab-content for-mobile pb-5">
                    <!--begin::Tab pane-->
                    <div id="grid" class="tab-pane fade  active show">
                        <!--begin::Row-->
                        <div class="row g-6 g-xl-9 cards">
                            <!--begin::Col-->
                            <div class="col-sm-6 col-xl-4" *ngFor="let company of listCampany">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="poste"> 
                                        <span class="wrapping" *ngIf="company?.company?.type!=='1'" [ngClass]="company.company?.type=='2'?
                                        'esn-type':company.company?.type=='3'?'client-type':'centre-type'">
                                          {{company.company?.type=='2'||company.company?.type=='5'?
                                          'ESN':company.company?.type=='3'?'Client Final':company.company?.type=='4'?'Cabinet de Recrutement':'Particulier'}}</span>
                                          <span *ngIf="company?.company?.type=='1'">---</span>
                                    </div>
                                    <div class="card-toolbar m-0">
                                        <!--begin::Menu-->
                                        <div class="tools">
                                           
                                            <div class="actions">
                                                <div class="tools">
                                                    <div class="dropdown">
                                                        <button
                                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                            type="button" id="dropdownMenuButton"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                    transform="matrix(1 0 0 -1 0 4.89478)"
                                                                    fill="#265D91" />
                                                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                    transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                    fill="#265D91" />
                                                                <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                    transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                    fill="#265D91" />
                                                            </svg>
                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <a class="dropdown-item btn-details"
                                                            (click)="reviewCompanyNavigation(company.company.id,'evaluation')">Donner
                                                                un avis</a>
                                                            <a class="dropdown-item btn-details"
                                                                (click)="profilCompanyNavigation(company.company.id)">Voir
                                                                Détails</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <!--begin::Card header-->
                                    <div class="card-header flex-nowrap border-0 pt-9">
                                        <!--begin::Card title-->
                                        <div class="card-title m-0">
                                            <!--begin::Icon-->
                                            <div class="symbol">
                                                <img *ngIf="company.company.logo" class="table-img logo-img"
                                                    src="{{url+'/Company/file/gallery_company/'+ company.company.logo}}"
                                                    alt="">
                                                <img *ngIf="!company.company.logo" class="table-img logo-img"
                                                    src="/assets/media/img/modules/avatar.jpg" alt="">
                                            </div>
                                            <!--end::Icon-->
                                            <!--begin::Title-->
                                            <a class="fs-4 fw-semibold text-hover-primary name">{{company?.company?.name?company?.company?.name:'---'}}</a>
                                           
                                            <!--end::Title-->
                                            <div class="ratings">
                                                <rating [(ngModel)]="company.average_note"
                                                    (ngModelChange)="onRatingChange($event)" [max]="5" [readonly]="true">
                                                </rating>
                                            </div>
                                        </div>
                                        <!--end::Card title-->
                                        <!--begin::Card toolbar-->

                                        <!--end::Card toolbar-->
                                    </div>
                                    <!--end::Card header-->
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-column px-9 pt-6 pb-8">
                                        <!--begin::Heading-->
                                        <!-- <div class="fw-bold mb-3 mail text-center"> <svg width="15" height="10"
                                                viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M1.51185 0H13.4193C13.8339 0 14.212 0.169447 14.4868 0.443242C14.7611 0.717038 14.9307 1.09327 14.9307 1.50635V8.12962C14.9307 8.54414 14.7606 8.92085 14.4868 9.19369L14.4724 9.20709C14.199 9.47227 13.8272 9.63597 13.4188 9.63597H1.51137C1.0963 9.63597 0.718214 9.46653 0.44438 9.19321C0.170065 8.92085 0 8.54414 0 8.12962V1.50635C0 1.09327 0.170065 0.716559 0.44486 0.442764C0.719655 0.169447 1.09726 0.000478766 1.51185 0.000478766V0ZM1.81691 1.2742L1.55989 1.67772L7.46558 5.42038L13.3708 1.67772L13.1138 1.2742L7.4651 4.85365L1.81643 1.2742H1.81691Z"
                                                    fill="#265D91" />
                                            </svg>
                                            {{company.company.email?company.company.email:'---'}}
                                        </div>
                                        <div class="fw-bold mb-3 phone text-center"><svg width="13" height="16"
                                                viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M12.3933 12.9149C12.0867 11.986 11.5346 11.1928 10.7542 10.5586C10.4535 10.3129 10.1549 10.1686 9.84222 10.1178C9.7207 10.0981 9.59605 10.093 9.4714 10.102C9.05836 10.1338 8.64624 10.2571 8.2118 10.4795C8.07498 10.5503 7.91751 10.5713 7.74455 10.5433C7.5336 10.5091 7.31749 10.4 7.1803 10.2584C6.18421 9.23037 5.37914 8.1136 4.71883 6.84499C4.58938 6.59524 4.53609 6.09005 4.83555 5.79797C5.18516 5.45768 5.43317 5.10671 5.59451 4.72519C5.76766 4.31403 5.75899 3.86957 5.56778 3.36769C5.21263 2.42649 4.63419 1.65278 3.84923 1.06715C3.59495 0.877219 3.32278 0.757591 3.03937 0.711397C2.56621 0.634467 2.08974 0.767714 1.66176 1.09623C1.26845 1.39769 0.910722 1.72658 0.494545 2.12742C0.113218 2.4944 0.0604813 2.9812 0.0136452 3.4102L0.0123545 3.41922C-0.0368787 3.96564 0.0606656 4.63703 0.311626 5.47222C0.735916 6.88548 1.43864 8.27611 2.46111 9.72397C2.67113 10.0218 3.5116 11.1873 3.72789 11.4807C4.77912 12.9079 5.87737 14.0151 7.08516 14.8647C7.79913 15.3673 8.40597 15.673 8.94053 15.7996L8.94956 15.8013C9.05983 15.8252 9.17803 15.8504 9.29383 15.8692C9.57945 15.9157 9.95414 15.9444 10.3211 15.7536C10.8343 15.4867 11.2604 15.2524 11.6703 14.9766C12.3909 14.4934 12.6609 13.7225 12.3933 12.9149ZM3.06647 3.48805C2.52196 2.73292 2.36227 1.91835 2.70985 1.66879C3.05744 1.41904 3.78063 1.8289 4.32515 2.58403C4.86966 3.33916 5.02935 4.15373 4.68195 4.4033C4.33418 4.65304 3.61099 4.24318 3.06647 3.48805ZM11.5305 13.9649C11.1829 14.2146 10.4598 13.8048 9.91523 13.0496C9.37072 12.2945 9.21103 11.4799 9.55843 11.2304C9.90583 10.9808 10.6292 11.3905 11.1737 12.1456C11.7184 12.9007 11.8781 13.7153 11.5305 13.9649Z"
                                                    fill="#265D91" />
                                            </svg>
                                            {{company.company.phone?company.company.phone:'---'}}
                                        </div> -->
                                        <!--end::Heading-->

                                        <!--begin::Indicator-->
                                        <div class="footer d-flex fw-semibold">
                                            <div class="socials">
                                                <span class="px-3 py-2 me-2" *ngIf="company.company?.linkedin_link">
                                                    <a target="_blank"
                                                        [attr.href]="linkedinPrefix+company.company?.linkedin_link ? company.company?.linkedin_link : null"
                                                        [attr.title]="linkedinPrefix+company.company?.linkedin_link ? '' : 'le lien linkedin non disponible pour le moment'">
                                                        <svg width="25" height="25" viewBox="0 0 17 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <g clip-path="url(#clip0_426_2396)">
                                                                <path
                                                                    d="M14.2669 17.8389H2.84767C1.3399 17.8389 0.113281 16.5429 0.113281 14.9497V2.88923C0.113281 1.296 1.3399 0 2.84767 0H14.2669C15.7742 0 17.0008 1.296 17.0008 2.88923V14.9502C17.0008 16.5434 15.7742 17.8389 14.2669 17.8389ZM5.81961 14.7475H5.8231V7.05277H3.5621V14.7475H5.81918H5.81961ZM4.69108 6.00185C4.86298 6.00209 5.03325 5.96642 5.19211 5.89687C5.35098 5.82733 5.49532 5.72528 5.61688 5.59657C5.73844 5.46787 5.83482 5.31503 5.9005 5.14682C5.96618 4.97861 5.99987 4.79833 5.99964 4.61631C5.99895 4.24906 5.86086 3.89707 5.61561 3.63739C5.37035 3.37771 5.03792 3.2315 4.69108 3.23077C4.34406 3.23089 4.01129 3.37691 3.76591 3.63672C3.52053 3.89653 3.38263 4.24888 3.38251 4.61631C3.38263 4.98374 3.52053 5.33608 3.76591 5.5959C4.01129 5.85571 4.34406 6.00172 4.69108 6.00185ZM14.2799 14.748V10.5295C14.2799 8.45677 13.8549 6.86215 11.5708 6.86215C10.4728 6.86215 9.73572 7.49908 9.43277 8.10369H9.40226V7.05277H7.23715V14.7475H9.49423V10.9398C9.49423 9.93554 9.67425 8.964 10.8499 8.964C12.0059 8.964 12.0229 10.1123 12.0229 11.0045V14.7475H14.2799V14.748Z"
                                                                    fill="#265D91" />
                                                            </g>
                                                            <defs>
                                                                <clipPath id="clip0_426_2396">
                                                                    <rect width="17" height="18" fill="white" />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </a>
                                                </span>
                                                <span *ngIf="company.company?.website_link" >
                                                    <a target="_blank"
                                                        [attr.href]="company.company?.website_link ? company.company?.website_link : null"
                                                        [attr.title]="company.company?.website_link ? '' : 'Site Web non disponible pour le moment'">
                                                        <svg width="25" height="25" viewBox="0 0 18 18" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <rect x="0.0410156" y="0.0407715" width="17.9592"
                                                                height="17.9592" rx="3" fill="#265D91" />
                                                            <path
                                                                d="M8.72077 14.4082C5.4083 14.4082 2.73438 12.0017 2.73438 9.02045C2.73438 6.03922 5.4083 3.63269 8.72077 3.63269C12.0332 3.63269 14.7072 6.03922 14.7072 9.02045C14.7072 12.0017 12.0332 14.4082 8.72077 14.4082ZM8.72077 4.35106C5.8473 4.35106 3.53256 6.43432 3.53256 9.02045C3.53256 11.6066 5.8473 13.6898 8.72077 13.6898C11.5942 13.6898 13.909 11.6066 13.909 9.02045C13.909 6.43432 11.5942 4.35106 8.72077 4.35106Z"
                                                                fill="white" />
                                                            <path
                                                                d="M11.833 10.3494C11.9128 9.91841 11.9128 9.48738 11.9128 9.02045C11.9128 8.55351 11.8729 8.12249 11.833 7.69147C11.7532 7.11677 11.5936 6.578 11.4339 6.07514C10.8353 4.60249 9.87746 3.63269 8.72009 3.63269C7.36317 3.63269 6.24571 4.96167 5.7668 6.90126C5.72689 7.08085 5.68698 7.22453 5.64707 7.40412C5.56725 7.90698 5.52734 8.44575 5.52734 9.02045C5.52734 9.48738 5.56725 9.91841 5.60716 10.3494C5.68698 10.9241 5.84662 11.4629 6.00626 11.9658C6.56499 13.4384 7.56272 14.4082 8.68018 14.4082C9.79764 14.4082 10.7954 13.4384 11.3541 11.9658C11.0747 12.0376 10.7555 12.0735 10.4362 12.1453C10.0371 13.1151 9.35864 13.6898 8.72009 13.6898C8.08154 13.6898 7.40308 13.1151 6.96408 12.1453C6.76453 11.7143 6.60489 11.1755 6.48517 10.6009C6.36544 10.098 6.32553 9.59514 6.32553 9.02045C6.32553 8.44575 6.36544 7.90698 6.48517 7.44004C6.52508 7.26045 6.56499 7.11677 6.60489 6.93718V6.90126C6.6448 6.72167 6.68471 6.578 6.76453 6.43432C6.80444 6.32657 6.84435 6.21881 6.88426 6.14698C6.88426 6.14698 6.88426 6.14698 6.88426 6.11106L6.96408 5.96738C6.96408 5.96738 6.96408 5.93147 7.00399 5.89555C7.40308 4.92575 8.08154 4.35106 8.72009 4.35106C9.35864 4.35106 10.0371 4.92575 10.4761 5.89555C10.6756 6.32657 10.8353 6.86534 10.955 7.44004C11.0747 7.94289 11.1146 8.44575 11.1146 9.02045C11.1146 9.59514 11.0747 10.098 10.9949 10.6009C11.2743 10.529 11.5536 10.4572 11.833 10.3494Z"
                                                                fill="white" />
                                                            <path
                                                                d="M11.9933 6.61416C11.4745 6.43456 10.836 6.32681 10.1974 6.25497C9.7185 6.18314 9.23959 6.14722 8.72077 6.14722C8.20195 6.14722 7.72304 6.18314 7.24412 6.21905C7.16431 6.43456 7.04458 6.68599 6.96476 6.97334C7.52349 6.9015 8.08222 6.86558 8.72077 6.86558C9.35932 6.86558 9.91805 6.9015 10.4768 6.97334C11.1153 7.08109 11.714 7.22477 12.1929 7.40436C13.2704 7.83538 13.909 8.41007 13.909 8.98477C13.909 9.55946 13.2704 10.1701 12.1929 10.5652C11.714 10.7448 11.1153 10.8884 10.4768 10.9962C9.91805 11.1399 9.35932 11.1758 8.72077 11.1758C8.08222 11.1758 7.52349 11.1399 6.96476 11.0321C6.32621 10.9603 5.72757 10.8166 5.24866 10.6011C4.17111 10.206 3.53256 9.59538 3.53256 9.02069C3.53256 8.44599 4.17111 7.83538 5.24866 7.44028C5.28857 7.15293 5.36839 6.86558 5.44821 6.61416C3.81193 7.11701 2.73438 7.97905 2.73438 9.02069C2.73438 10.0623 3.81193 10.9244 5.44821 11.4272C5.96703 11.6068 6.60558 11.7146 7.24412 11.7864C7.72304 11.8582 8.20195 11.8582 8.72077 11.8582C9.23959 11.8582 9.7185 11.8223 10.1974 11.7864C10.836 11.7146 11.4745 11.5709 11.9933 11.4272C13.6296 10.9244 14.7072 10.0264 14.7072 9.02069C14.7072 8.01497 13.6296 7.11701 11.9933 6.61416Z"
                                                                fill="white" />
                                                        </svg>
                                                    </a>


                                                </span>
                                            </div>

                                            <div class="details">
                                                <a (click)="profilCompanyNavigation(company.company.id)">Voir Détails</a>
                                            </div>
                                        </div>
                                        <!--end::Indicator-->
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->

                    </div>
                    <!--end::Tab pane-->

                    <!--begin::Tab pane-->
                  
                    <!--end::Tab pane-->
                    <div class="pagination" *ngIf="listCampany && listCampany.length">
                        <!-- <app-pagination [data]="listCampany" [lengthData]="totalItems" (setPages)="getItems($event)">
                        </app-pagination> -->
                        <app-pagination [data]="listCampany" [lengthData]="totalItems" [currentPageList]="currentPage"  (setPages)="getItems($event)">
                        </app-pagination>
                    </div>
                    
                    
            <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCampany&&!listCampany.length ">
                <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                <span class="empty-list">
                 La liste est vide
             </span>
             </div> 



                </div>


                <!--end::Table-->
            </div>
</div>

<div id="kt_app_content_container" class="app-container container-fluid for-mobile">

<div class="d-flex flex-wrap my-1 header-grid align-items-center">
    <div class="section_title">
        <h1 class="section-heading my-0"></h1>
    </div>
    <!--begin::Tab nav-->
 
    <!--end::Tab nav-->

</div>
<!--end::Card header-->
<!--begin::Card body-->
<div class="card-body ">
  
    <div class="tab-content  pb-5">
        <!--begin::Tab pane-->
        <div id="grid" class="tab-pane fade  active show">
            <!--begin::Row-->
            <div class="row g-6 g-xl-9 cards">
                <!--begin::Col-->
                <div class="col-sm-6 col-xl-4" *ngFor="let company of listCampany">
                    <!--begin::Card-->
                    <div class="card pt-5 p-5">
                        <div class="poste"> 
                            <span class="wrapping" [ngClass]="company.company.type=='2'?
                            'esn-type':company.company.type=='3'?'client-type':'centre-type'">
                                {{company?.type=='1'?'Entreprise':company?.company?.type=='2'?
                                'ESN':company?.company?.type=='3'?'Client Final':'Cabinet de
                                Recrutement'}}
                            </span>
                        </div>
                        <div class="card-toolbar m-0">
                            <!--begin::Menu-->
                            <div class="tools">
                               
                                <div class="actions">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton"
                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                        fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                        fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                        fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item btn-details"
                                                (click)="reviewCompanyNavigation(company.company.id,'evaluation')">Donner
                                                    un avis</a>
                                                <a class="dropdown-item btn-details"
                                                    (click)="profilCompanyNavigation(company.company.id)">Voir
                                                    Détails</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                        <!--begin::Card header-->
                        <div class="card-header flex-nowrap border-0 pt-9">
                            <!--begin::Card title-->
                            <div class="card-title m-0">
                                <!--begin::Icon-->
                                <div class="symbol">
                                    <img *ngIf="company.company.logo" class="table-img logo-img"
                                        src="{{url+'/Company/file/gallery_company/'+ company.company.logo}}"
                                        alt="">
                                    <img *ngIf="!company.company.logo" class="table-img logo-img"
                                        src="/assets/media/img/modules/avatar.jpg" alt="">
                                </div>
                                <!--end::Icon-->
                                <!--begin::Title-->
                                <a class="fs-4 fw-semibold text-hover-primary name">{{company?.company?.name?company?.company?.name:'---'}}</a>
                               
                                <!--end::Title-->
                                <div class="ratings">
                                    <rating [(ngModel)]="company.average_note"
                                        (ngModelChange)="onRatingChange($event)" [max]="5" [readonly]="true">
                                    </rating>
                                </div>
                            </div>
                            <!--end::Card title-->
                            <!--begin::Card toolbar-->

                            <!--end::Card toolbar-->
                        </div>
                        <!--end::Card header-->
                        <!--begin::Card body-->
                        <div class="card-body d-flex flex-column px-9 pt-6 pb-8">
                            <!--begin::Heading-->
                            <!-- <div class="fw-bold mb-3 mail text-center"> <svg width="15" height="10"
                                    viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1.51185 0H13.4193C13.8339 0 14.212 0.169447 14.4868 0.443242C14.7611 0.717038 14.9307 1.09327 14.9307 1.50635V8.12962C14.9307 8.54414 14.7606 8.92085 14.4868 9.19369L14.4724 9.20709C14.199 9.47227 13.8272 9.63597 13.4188 9.63597H1.51137C1.0963 9.63597 0.718214 9.46653 0.44438 9.19321C0.170065 8.92085 0 8.54414 0 8.12962V1.50635C0 1.09327 0.170065 0.716559 0.44486 0.442764C0.719655 0.169447 1.09726 0.000478766 1.51185 0.000478766V0ZM1.81691 1.2742L1.55989 1.67772L7.46558 5.42038L13.3708 1.67772L13.1138 1.2742L7.4651 4.85365L1.81643 1.2742H1.81691Z"
                                        fill="#265D91" />
                                </svg>
                                {{company.company.email?company.company.email:'---'}}
                            </div>
                            <div class="fw-bold mb-3 phone text-center"><svg width="13" height="16"
                                    viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.3933 12.9149C12.0867 11.986 11.5346 11.1928 10.7542 10.5586C10.4535 10.3129 10.1549 10.1686 9.84222 10.1178C9.7207 10.0981 9.59605 10.093 9.4714 10.102C9.05836 10.1338 8.64624 10.2571 8.2118 10.4795C8.07498 10.5503 7.91751 10.5713 7.74455 10.5433C7.5336 10.5091 7.31749 10.4 7.1803 10.2584C6.18421 9.23037 5.37914 8.1136 4.71883 6.84499C4.58938 6.59524 4.53609 6.09005 4.83555 5.79797C5.18516 5.45768 5.43317 5.10671 5.59451 4.72519C5.76766 4.31403 5.75899 3.86957 5.56778 3.36769C5.21263 2.42649 4.63419 1.65278 3.84923 1.06715C3.59495 0.877219 3.32278 0.757591 3.03937 0.711397C2.56621 0.634467 2.08974 0.767714 1.66176 1.09623C1.26845 1.39769 0.910722 1.72658 0.494545 2.12742C0.113218 2.4944 0.0604813 2.9812 0.0136452 3.4102L0.0123545 3.41922C-0.0368787 3.96564 0.0606656 4.63703 0.311626 5.47222C0.735916 6.88548 1.43864 8.27611 2.46111 9.72397C2.67113 10.0218 3.5116 11.1873 3.72789 11.4807C4.77912 12.9079 5.87737 14.0151 7.08516 14.8647C7.79913 15.3673 8.40597 15.673 8.94053 15.7996L8.94956 15.8013C9.05983 15.8252 9.17803 15.8504 9.29383 15.8692C9.57945 15.9157 9.95414 15.9444 10.3211 15.7536C10.8343 15.4867 11.2604 15.2524 11.6703 14.9766C12.3909 14.4934 12.6609 13.7225 12.3933 12.9149ZM3.06647 3.48805C2.52196 2.73292 2.36227 1.91835 2.70985 1.66879C3.05744 1.41904 3.78063 1.8289 4.32515 2.58403C4.86966 3.33916 5.02935 4.15373 4.68195 4.4033C4.33418 4.65304 3.61099 4.24318 3.06647 3.48805ZM11.5305 13.9649C11.1829 14.2146 10.4598 13.8048 9.91523 13.0496C9.37072 12.2945 9.21103 11.4799 9.55843 11.2304C9.90583 10.9808 10.6292 11.3905 11.1737 12.1456C11.7184 12.9007 11.8781 13.7153 11.5305 13.9649Z"
                                        fill="#265D91" />
                                </svg>
                                {{company.company.phone?company.company.phone:'---'}}
                            </div> -->
                            <!--end::Heading-->

                            <!--begin::Indicator-->
                            <div class="footer d-flex fw-semibold">
                                <div class="socials">
                                    <span class="px-3 py-2 me-2" *ngIf="company.company?.linkedin_link">
                                        <a target="_blank"
                                            [attr.href]="linkedinPrefix+company.company?.linkedin_link ? company.company?.linkedin_link : null"
                                            [attr.title]="linkedinPrefix+company.company?.linkedin_link ? '' : 'le lien linkedin non disponible pour le moment'">
                                            <svg width="25" height="25" viewBox="0 0 17 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_426_2396)">
                                                    <path
                                                        d="M14.2669 17.8389H2.84767C1.3399 17.8389 0.113281 16.5429 0.113281 14.9497V2.88923C0.113281 1.296 1.3399 0 2.84767 0H14.2669C15.7742 0 17.0008 1.296 17.0008 2.88923V14.9502C17.0008 16.5434 15.7742 17.8389 14.2669 17.8389ZM5.81961 14.7475H5.8231V7.05277H3.5621V14.7475H5.81918H5.81961ZM4.69108 6.00185C4.86298 6.00209 5.03325 5.96642 5.19211 5.89687C5.35098 5.82733 5.49532 5.72528 5.61688 5.59657C5.73844 5.46787 5.83482 5.31503 5.9005 5.14682C5.96618 4.97861 5.99987 4.79833 5.99964 4.61631C5.99895 4.24906 5.86086 3.89707 5.61561 3.63739C5.37035 3.37771 5.03792 3.2315 4.69108 3.23077C4.34406 3.23089 4.01129 3.37691 3.76591 3.63672C3.52053 3.89653 3.38263 4.24888 3.38251 4.61631C3.38263 4.98374 3.52053 5.33608 3.76591 5.5959C4.01129 5.85571 4.34406 6.00172 4.69108 6.00185ZM14.2799 14.748V10.5295C14.2799 8.45677 13.8549 6.86215 11.5708 6.86215C10.4728 6.86215 9.73572 7.49908 9.43277 8.10369H9.40226V7.05277H7.23715V14.7475H9.49423V10.9398C9.49423 9.93554 9.67425 8.964 10.8499 8.964C12.0059 8.964 12.0229 10.1123 12.0229 11.0045V14.7475H14.2799V14.748Z"
                                                        fill="#265D91" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_426_2396">
                                                        <rect width="17" height="18" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </span>
                                    <span *ngIf="company.company?.website_link" >
                                        <a target="_blank"
                                            [attr.href]="company.company?.website_link ? company.company?.website_link : null"
                                            [attr.title]="company.company?.website_link ? '' : 'Site Web non disponible pour le moment'">
                                            <svg width="25" height="25" viewBox="0 0 18 18" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.0410156" y="0.0407715" width="17.9592"
                                                    height="17.9592" rx="3" fill="#265D91" />
                                                <path
                                                    d="M8.72077 14.4082C5.4083 14.4082 2.73438 12.0017 2.73438 9.02045C2.73438 6.03922 5.4083 3.63269 8.72077 3.63269C12.0332 3.63269 14.7072 6.03922 14.7072 9.02045C14.7072 12.0017 12.0332 14.4082 8.72077 14.4082ZM8.72077 4.35106C5.8473 4.35106 3.53256 6.43432 3.53256 9.02045C3.53256 11.6066 5.8473 13.6898 8.72077 13.6898C11.5942 13.6898 13.909 11.6066 13.909 9.02045C13.909 6.43432 11.5942 4.35106 8.72077 4.35106Z"
                                                    fill="white" />
                                                <path
                                                    d="M11.833 10.3494C11.9128 9.91841 11.9128 9.48738 11.9128 9.02045C11.9128 8.55351 11.8729 8.12249 11.833 7.69147C11.7532 7.11677 11.5936 6.578 11.4339 6.07514C10.8353 4.60249 9.87746 3.63269 8.72009 3.63269C7.36317 3.63269 6.24571 4.96167 5.7668 6.90126C5.72689 7.08085 5.68698 7.22453 5.64707 7.40412C5.56725 7.90698 5.52734 8.44575 5.52734 9.02045C5.52734 9.48738 5.56725 9.91841 5.60716 10.3494C5.68698 10.9241 5.84662 11.4629 6.00626 11.9658C6.56499 13.4384 7.56272 14.4082 8.68018 14.4082C9.79764 14.4082 10.7954 13.4384 11.3541 11.9658C11.0747 12.0376 10.7555 12.0735 10.4362 12.1453C10.0371 13.1151 9.35864 13.6898 8.72009 13.6898C8.08154 13.6898 7.40308 13.1151 6.96408 12.1453C6.76453 11.7143 6.60489 11.1755 6.48517 10.6009C6.36544 10.098 6.32553 9.59514 6.32553 9.02045C6.32553 8.44575 6.36544 7.90698 6.48517 7.44004C6.52508 7.26045 6.56499 7.11677 6.60489 6.93718V6.90126C6.6448 6.72167 6.68471 6.578 6.76453 6.43432C6.80444 6.32657 6.84435 6.21881 6.88426 6.14698C6.88426 6.14698 6.88426 6.14698 6.88426 6.11106L6.96408 5.96738C6.96408 5.96738 6.96408 5.93147 7.00399 5.89555C7.40308 4.92575 8.08154 4.35106 8.72009 4.35106C9.35864 4.35106 10.0371 4.92575 10.4761 5.89555C10.6756 6.32657 10.8353 6.86534 10.955 7.44004C11.0747 7.94289 11.1146 8.44575 11.1146 9.02045C11.1146 9.59514 11.0747 10.098 10.9949 10.6009C11.2743 10.529 11.5536 10.4572 11.833 10.3494Z"
                                                    fill="white" />
                                                <path
                                                    d="M11.9933 6.61416C11.4745 6.43456 10.836 6.32681 10.1974 6.25497C9.7185 6.18314 9.23959 6.14722 8.72077 6.14722C8.20195 6.14722 7.72304 6.18314 7.24412 6.21905C7.16431 6.43456 7.04458 6.68599 6.96476 6.97334C7.52349 6.9015 8.08222 6.86558 8.72077 6.86558C9.35932 6.86558 9.91805 6.9015 10.4768 6.97334C11.1153 7.08109 11.714 7.22477 12.1929 7.40436C13.2704 7.83538 13.909 8.41007 13.909 8.98477C13.909 9.55946 13.2704 10.1701 12.1929 10.5652C11.714 10.7448 11.1153 10.8884 10.4768 10.9962C9.91805 11.1399 9.35932 11.1758 8.72077 11.1758C8.08222 11.1758 7.52349 11.1399 6.96476 11.0321C6.32621 10.9603 5.72757 10.8166 5.24866 10.6011C4.17111 10.206 3.53256 9.59538 3.53256 9.02069C3.53256 8.44599 4.17111 7.83538 5.24866 7.44028C5.28857 7.15293 5.36839 6.86558 5.44821 6.61416C3.81193 7.11701 2.73438 7.97905 2.73438 9.02069C2.73438 10.0623 3.81193 10.9244 5.44821 11.4272C5.96703 11.6068 6.60558 11.7146 7.24412 11.7864C7.72304 11.8582 8.20195 11.8582 8.72077 11.8582C9.23959 11.8582 9.7185 11.8223 10.1974 11.7864C10.836 11.7146 11.4745 11.5709 11.9933 11.4272C13.6296 10.9244 14.7072 10.0264 14.7072 9.02069C14.7072 8.01497 13.6296 7.11701 11.9933 6.61416Z"
                                                    fill="white" />
                                            </svg>
                                        </a>


                                    </span>
                                </div>

                                <div class="details">
                                    <a (click)="profilCompanyNavigation(company.company.id)">Voir Détails</a>
                                </div>
                            </div>
                            <!--end::Indicator-->
                        </div>
                        <!--end::Card body-->
                    </div>
                    <!--end::Card-->
                </div>
                <!--end::Col-->
            </div>
            <!--end::Row-->

        </div>
        <!--end::Tab pane-->

        <!--begin::Tab pane-->
       
        <!--end::Tab pane-->
        <div class="pagination" *ngIf="listCampany && listCampany.length">
            <app-pagination [data]="listCampany" [lengthData]="totalItems" [currentPageList]="currentPage" (setPages)="getItems($event)">
            </app-pagination>
        </div>
        
        
<div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listCampany&&!listCampany.length ">
    <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
    <span class="empty-list">
     La liste est vide
 </span>
 </div> 



    </div>


    <!--end::Table-->
</div>
</div>

            <!--end::Card body-->
        </div>

    </div>
    <!--end::Content container-->
</div>

