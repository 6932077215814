<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_534_711)">
                            <path d="M8.73438 5.38853H19.3343V3.25956H15.8403V0H12.1576V3.25956H8.73438V5.38853Z"
                                fill="#265D91" />
                            <path
                                d="M23.0071 5.26135H21.1792V6.30811C21.1792 6.81596 20.7664 7.2277 20.2573 7.2277H7.81339C7.30421 7.2277 6.8914 6.81596 6.8914 6.30811V5.26135H4.99288C4.4445 5.26135 4 5.70476 4 6.25165V28.0122C4 28.5592 4.44456 29.0025 4.99288 29.0025H23.0071C23.5555 29.0025 24 28.5591 24 28.0122V6.25165C23.9999 5.70469 23.5554 5.26135 23.0071 5.26135ZM9.66407 24.6577C9.31129 25.0116 8.72134 25.0152 8.36426 24.6654L6.74707 23.0816L8.03906 21.7693L9.00248 22.7128L11.514 20.1933L12.8217 21.49L9.66407 24.6577ZM9.66407 14.8943C9.31129 15.2482 8.7214 15.2518 8.36426 14.9021L6.74707 13.3183L8.03906 12.0059L9.00248 12.9495L11.514 10.4299L12.8217 11.7267L9.66407 14.8943ZM21.4019 26.2713H14.9673V24.4321H21.4019V26.2713ZM21.4019 21.6653H14.9673V19.8261H21.4019V21.6653ZM21.4019 16.3509H14.9673V14.5117H21.4019V16.3509ZM21.4019 11.745H14.9673V9.90579H21.4019V11.745Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_534_711" x="0" y="0" width="28" height="37.0026"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_534_711" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_534_711"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>

                    Evaluation
                </h1>
                <!--begin::Menu-->
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Evaluation</li>
                <!--end::Item-->
                 <!--begin::Item-->
                 <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Evaluations reçues</li>
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->

            <div class="card-body tools">
                <div class="title mb-10">
                    <h4>Recherche</h4>
                </div>
                <div [formGroup]="searchForm">
                    <div class="row mb-3">
                        <div class="col-lg-4">
                            <div class="input-shadow mb-3">
                                <input type="text" name="ESN" id="ESN" class="form-control form-control-solid mb-3"
                                    placeholder="Entreprise" formControlName="company_name">
                            </div>
                        </div>
                        <div class="col-lg-4 mb-3">
                            <ng-select [items]="type" bindLabel="name" bindValue="id" id="type" placeholder="Type"
                                formControlName="evaluation_type" [notFoundText]="'Aucun élément trouvé'">
                            </ng-select>
                        </div>
                        <div class="col-lg-4 mb-3">
                            <ng-select [items]="remplie" bindLabel="name" bindValue="id" id="type"
                                placeholder="État de Remplissage" [notFoundText]="'Aucun élément trouvé'" formControlName="state_fill_by_candidate">
                            </ng-select>
                        </div>
                    </div>

                    <div class="row mb-3 rechercher" *ngIf="!isCollapsed">
                        <div class="col-lg-4">
                            <div class="position-relative d-flex align-items-center calenderDate">
                               

                                <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy" 
                                placeholder="Date de début" inputId="yearpicker"
                                formControlName="start_date" class="form-control"
                               ></p-calendar>
                            </div>
                            
                        </div>
                        <div class="col-lg-4">
                            <div class="position-relative d-flex align-items-center calenderDate">
                             

                                <p-calendar [style]="{'border': 'none','margin-top': '-6px'}" dateFormat="dd/mm/yy"  placeholder="Date de fin" 
                                inputId="yearpicker"
                                formControlName="end_date" class="form-control" [minDate]="searchForm.get('start_date')?.value"
                               ></p-calendar>
                            </div>
                        </div>
                        <div class="col-lg-4">
                        </div>
                    </div>
                    <div class="row mb-3 mb-0-sm">
                        <div class="col-lg-3 mb-3"></div>
                        <div class="col-lg-3 mb-3"></div>
                        <div class="col-lg-3 mb-3"></div>
                        <div class="col-lg-3 mb-3">
                          <div class="see-more">
                            <a [ngClass]="{'show-more-button': !isCollapsed, 'emphasized-button': isCollapsed}"
                            (click)="toggleCollapsible()" class="show-more-button">
                            {{ isCollapsed ? 'Voir plus de critères de recherche' : 'Voir moins de critères de recherche' }}
                        </a>
                          </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4">
                                <!-- <a (click)="searchEvaluations()" type="submit" class="search">
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                            fill="white" />
                                    </svg>
                                    Rechercher
                                </a> -->
                                <div class="mb-3 search-btn d-flex justify-content-end">
                                    <div class="btn_search">
                                        <a type="submit" (click)="searchEvaluations()" class="search"> <svg width="20" height="20"
                                                viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                                    fill="white" />
                                            </svg>
                                            Rechercher
                                        </a>
                                    </div> &nbsp;&nbsp;
                           
                                    <div class="resett-btn">
                       
                                        <div class="reset-icon cursor-pointer"  >
                                         <a (click)="reset()"> <img src="/assets/media/icons/icon-reset.png" alt="" width="30px"></a>
                                         </div>
                                      </div>
                                  
                                  </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->

            <div class="card-body tools">
                <div class="title mb-15">
                    <h4>Evaluations reçues</h4>
                </div>

                <div class="container-fluid">

                    <div class="table-responsive mt-15">
                        <table class="table align-middle gy-5" id="list_cra">
                            <thead class="text-start fw-bold  text-uppercase gs-0">
                                <th>
                                    <span>Logo</span>
                                </th>
                                <th>
                                    <span>Entreprise</span>
                                </th>
                                <th>
                                    <span>Type</span>
                                </th>
                                <th >
                                    <span>État de Remplissage</span>
                                </th>
                                <th>
                                    <span>Date</span>
                                </th>
                                <th>
                                    <span>Action</span>
                                </th>
                            </thead>
                            <tbody>

                                <tr *ngFor="let evaluation of evaluations">
                                    <td>
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img *ngIf="evaluation?.company?.logo"
                                                src="{{url+'/Company/file/gallery_company/'+ evaluation?.company?.logo}}"
                                                alt="logo">
                                            <img *ngIf="!evaluation?.company?.logo"
                                                src="/assets/media/img/modules/avatar.jpg" alt="image" />
                                        </div>
                                    </td>
                                    <td><a (click)="visualiserEvaluation(evaluation?.id)"
                                        [routerLink]="['/wegestu/evaluations/details-evaluation',{idEvaluation:evaluation?.id}]">
                                        {{evaluation?.company?.name}}</a></td>
                                    <td>
                                        <span class="badge hybrid">{{evaluation?.eval_type=='1'?'Annuelle':
                                            evaluation?.eval_type=='2'?'Suivi ':'---' }}
                                        </span>
                                    </td>
                                    <td>
                                        <span class="status-valid"
                                            [ngClass]="evaluation?.state_fill_by_candidate=='2'?'status-valid':'status-no-valid'">
                                            {{evaluation?.state_fill_by_candidate=='1'?'Non remplie':evaluation?.state_fill_by_candidate=='2'?'En cours':'Remplie'}}</span>
                                    </td>
                                    <td>
                                        <span>{{evaluation?.created_at | date:'dd/MM/yyyy'}}</span>
                                    </td>
                                    <td>
                                        <div class="seemore" (click)="visualiserEvaluation(evaluation?.id)">
                                            <a>Visualiser</a>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>

                        </table>
                    </div>
                    <div class="pagination" *ngIf="evaluations && evaluations.length">
                        <!-- <app-pagination [data]="evaluations" [lengthData]="totalEvaluation"
                            (setPages)="getItems($event)">
                        </app-pagination> -->
                        <app-pagination
                        [data]="evaluations"
                        [lengthData]="totalEvaluation"
                        [currentPageList]="currentPage"
                        [endexPageList]="endIndex"
                        (setPages)="getItems($event)"
                      >
                      </app-pagination>
                    </div>
                   
                    <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="evaluations&&!evaluations.length">
                        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                        <span class="empty-list">
                         La liste est vide
                     </span>
                     </div> 


                </div>
            </div>
        </div>
    </div>
    <!--end::Content container-->
</div>
<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
