import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { linkedInRegExp, patternInfoUser, patternTjm, patternsalaire } from '../shared-models/pattern';

@Component({
  selector: 'app-feild-error',
  templateUrl: './feild-error.component.html',
  styleUrls: ['./feild-error.component.css']
})
export class FeildErrorComponent implements OnInit {
  /* formControl */
  _control: AbstractControl | null = new FormControl();
  /* string */
  linkedInRegExp = linkedInRegExp
  patternTjm = patternTjm
  patternInfoUser = patternInfoUser
  patternsalaire = patternsalaire
  @Input() submitted: boolean = false;


  /* Input */
  @Input() set control(value: AbstractControl | null | undefined) {
    if (value) {
      this._control = value;
    }
  }

  @Input() controlName!: string
  ngOnInit(): void {
  }
}
