  export interface UserInfo {
    id: number
    first_name: string
    last_name: string
    email: string
    image_url: string
    phone_number: string
    adress: any
    country: any
    enabled: number
    role_id: number
  }
  
  export interface Token {
    token_type: string
    expires_in: number
    access_token: string
    refresh_token: string
}
export class CCandidate{
  first_name:string|undefined
  last_name:string|undefined
  email:string|undefined
  phone_number:string|undefined
  desired_contrat:string|undefined
  mobility:string|undefined
  availability:string|undefined
  month_exp:string|undefined
  year_exp:string|undefined
  date_first_experience:string|undefined
  current_country:string|undefined
  desired_contract:string|undefined
  desired_salary:string|undefined
  linkedin_link:string|undefined
  open_for_mission:number|undefined
  tjm:string|undefined
  certificates:[]|undefined
  skill:[]|undefined
  diplomas:[]|undefined
  cv:[]|undefined
  destination_country:string|undefined
  english_level:string|undefined
  frensh_level:string|undefined
  yearControl:string|undefined
  monthControl:string|undefined
  constructor(data?: CCandidate) {
    this.first_name = data?.first_name ?? "";
    this.last_name = data?.last_name ?? "";
    this.email = data?.email ?? "";
    this.phone_number = data?.phone_number ?? "";
    this.current_country = data?.current_country ?? "";
    this.desired_contrat = data?.desired_contrat ?? "";
    this.mobility = data?.mobility ?? "";
    this.availability = data?.availability ?? "";
    this.month_exp = data?.month_exp ?? "";
    this.year_exp = data?.year_exp ?? "";
    this.availability = data?.availability ?? "";
    this.date_first_experience = data?.date_first_experience ?? "";
    this.desired_contract = data?.desired_contract ?? "";
    this.desired_salary = data?.desired_salary ?? "";
    this.linkedin_link = data?.linkedin_link ?? "";
    this.open_for_mission = data?.open_for_mission ?? 0;
    this.tjm = data?.tjm ?? "";
    this.certificates = data?.certificates ?? [];
    this.skill = data?.skill ?? [];
    this.diplomas = data?.diplomas ?? [];
    this.cv = data?.cv ?? [];
    this.destination_country = data?.destination_country ?? '';
    this.frensh_level = data?.frensh_level ?? '';
    this.english_level = data?.english_level ?? '';
    this.yearControl = data?.yearControl ?? '';
    this.monthControl = data?.monthControl ?? '';
  }
}  