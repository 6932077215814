<span style="color: red" *ngIf="control && control.errors && (control.dirty || control.touched || submitted)">
  <small *ngIf="control?.hasError('required') && controlName != 'mobility'">Ce champ est obligatoire.</small>
  <small *ngIf="!control?.value?.length && controlName == 'mobility'">Champ obligatoire.</small>
  <small *ngIf="controlName && controlName === 'email' && !control.errors['required']">La valeur de ce champ doit avoir une adresse email valide.</small>
  <small *ngIf="controlName === 'password' && control.errors['pattern']">Le mot de passe doit respecter un certain format.</small>
  <small *ngIf="controlName === 'oldPassword' && !control.errors['required']">Votre mot de passe doit contenir au moins 8 caractères dont une lettre MAJ , une lettre min et un caractère spécial.</small>
  <small *ngIf="controlName === 'newPassword' && !control.errors['required']">Votre mot de passe doit contenir au moins 8 caractères dont une lettre MAJ , une lettre min et un caractère spécial.</small>
  <small *ngIf="!control.errors['required'] && control.errors['pattern'] && control.errors['pattern']['requiredPattern'] == linkedInRegExp">Entrer une URL valide exemple: https://www.linkedin.com/in/exemple</small>
  <small *ngIf="!control.errors['required'] && control.errors['pattern'] && control.errors['pattern']['requiredPattern'] == patternInfoUser">ce champ doit contenir que des caractères</small>
  <small *ngIf="control.errors['required'] && ['linkedin_link', 'cv'].includes(controlName)">Au moins l'un des champs CV ou LinkedIn est requis.</small>
</span>
