import { Component } from '@angular/core';
import { ServiceCraService } from '../../services/service-cra.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { Observable, Subject, finalize, forkJoin, of, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-cra',
  templateUrl: './lista-cra.component.html',
  styleUrls: ['./lista-cra.component.css'],
})
export class ListaCraComponent {
  Valide = [
    { id: 1, name: 'Non envoyer' },
    { id: 2, name: 'Envoyer' },
    { id: 4, name: 'Approuvé' },
  ];
  annee = [
    { id: 2024, name: '2024' },
    { id: 2025, name: '2025' },
    { id: 2026, name: '2026' },
    { id: 2027, name: '2027' },
  ];

  currentYear!: number;
  currentUser: any;
  id: any;
  cras: any;
  listOfMonths: any;
  today: any;
  selectedMonth: any;
  testDateInFuture: boolean = false;
  searchForm: FormGroup = this.createSearchForm();
  private unsubscribeAll: Subject<void> = new Subject();

  constructor(
    private serviceCra: ServiceCraService,
    private spinner: NgxSpinnerService,
    private toastrService: ToastrService,
    private localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    forkJoin([this.getCurrentYear(), this.getIdUser()]).subscribe(() => {
      this.getListOfMonths();
    });
    // Assuming you receive the language preference from the API
    const userLanguage = 'fr'; // Replace with the actual language received from the API

    this.translate.setDefaultLang('en');
    this.translate.use(userLanguage);

    // Optionally, you can load translations dynamically based on the user's language
    this.translate.getTranslation(userLanguage).subscribe((translations) => {
      this.translate.setTranslation(userLanguage, translations, true);
    });
  }

  getCurrentYear(): Observable<void> {
    const now = new Date();
    this.currentYear = now.getFullYear();
    return of(undefined);
  }

  getIdUser(): Observable<void> {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    return of(undefined);
  }

  getListOfMonths() {
    let dataPayload = { year: this.currentYear, user_id: this.id };

    console.log('currentYear', this.currentYear);
    console.log('id', this.id);

    this.spinner.show();

    this.serviceCra
      .getAllListOfMonthsOfYear(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listOfMonths = res?.data;
          console.log('data listOfMonths ', this.listOfMonths);
        },
        (error) => {
          console.error('Error fetching listOfMonths:', error);
        }
      );
  }

  createSearchForm(data?: any) {
    return this.formBuilder.group({
      year: [data ? data.year : null],
      state: [data ? data.state : null],
    });
  }
  searchYearCra() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    let data = {
      year: this.searchForm.value.year,
      state: this.searchForm.value.state,
      user_id: this.id,
    };
    this.spinner.show();
    this.serviceCra
      .searshCra(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          console.log('response format:', res);
          this.listOfMonths = res.data;
          this.spinner.hide();
        },
        error: () => {
          console.error('Error during cra search');
          this.spinner.hide();
        },
      });
  }
  checkAndCreateCRAs() {
    this.serviceCra.checkAndCreateCRAs().subscribe({
      next: (res) => {
        console.log('response format:', res);
      },
      error: (res) => {
        console.log('response error:', res);
      }
    })
  }
  voirListCraEntreprisesByMonth(month: any) {
    this.today = new Date();
    console.log('today', this.today);
    this.selectedMonth = new Date(month.year, month.monthId - 1, 1);
    console.log('selectedMonth', this.selectedMonth);

    if (
      this.selectedMonth instanceof Date &&
      this.selectedMonth.getTime() > this.today?.getTime()
    ) {
      const diffMonths =
        this.selectedMonth.getMonth() -
        this.today.getMonth() +
        12 * (this.selectedMonth.getFullYear() - this.today.getFullYear());
      if (diffMonths <= 3) {
        this.testDateInFuturePopup(month);
      } else {
        this.testDateInFuture = true;
        this.toastrService.info(
          'Vous ne pouvez remplir le CRA que pour les trois prochains mois.'
        );
      }
    } else {
      this.router.navigate([
        '/wegestu/pointage/list-cra-entreprises',
        { month: month.monthId, year: month.year },
      ]);
    }
  }
  testDateInFuturePopup(month: any) {
    Swal.fire({
      title: 'Remplir le CRA',
      text: 'Êtes-vous certain de vouloir remplir le CRA pour une date future?',
      icon: 'info',
      confirmButtonText: 'OK',
    }).then((result) => {
      if (result.isConfirmed) {
        if(month.Cras.length === 1){
          this.router.navigate([
            '/wegestu/pointage/details-cra',
            { idCra: month.Cras[0].id },
          ]);
        }else
        this.router.navigate([
          '/wegestu/pointage/list-cra-entreprises',
          { month: month.monthId, year: month.year },
        ]);
      }
    });
  }

  voirListDetailsCra(month: any) {
    this.today = new Date();
    console.log('today', this.today);
    this.selectedMonth = new Date(month.year, month.monthId - 1, 1);
    console.log('selectedMonth', this.selectedMonth);

    if (this.selectedMonth.getTime() > this.today.getTime()) {
      const diffMonths =
        this.selectedMonth.getMonth() -
        this.today.getMonth() +
        12 * (this.selectedMonth.getFullYear() - this.today.getFullYear());
      if (diffMonths <= 3) {
        this.testDateInFuturePopup(month);
      } else {
        console.log(
          'Vous ne pouvez remplir le CRA que pour les trois prochains mois.'
        );
      }
    } else {
      this.router.navigate([
        '/wegestu/pointage/details-cra',
        { idCra: month.Cras[0].id },
      ]);
    }
  }

  isCurrentMonth(month: number): boolean {
    const now = new Date();
    console.log('dateeeeeeeeeeeeeeeee', now.getMonth());

    return now.getMonth() === month - 1;
  }
}
