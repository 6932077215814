import { Component } from '@angular/core';

@Component({
  selector: 'app-pvs-semester-evaluation',
  templateUrl: './pvs-semester-evaluation.component.html',
  styleUrls: ['./pvs-semester-evaluation.component.css']
})
export class PvsSemesterEvaluationComponent {

}
