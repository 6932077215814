<div class="spin" *ngIf="loadSpinner">
    <ngx-spinner class="custom-spinner"
    bdColor="rgba(255,255,255,1)"
      size="medium"
      color="#fff"
      type="ball-atom"
      [fullScreen]="true"
    >
    <span class="loader">
      <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
    </span>
    </ngx-spinner>
    
</div>
<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="37" height="33" viewBox="0 0 37 33" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_373_862)">
                            <path
                                d="M30.5143 18.7765C32.1417 16.7776 33 14.4593 33 12.0451C33 5.40329 26.4955 0 18.5 0C10.5045 0 4 5.40226 4 12.0451C4 18.6879 10.5045 24.0902 18.5 24.0902C21.0193 24.0902 23.4946 23.5389 25.6907 22.4941C27.975 23.6111 30.6108 24.9114 30.6108 24.9114C30.7315 24.9722 30.8616 25 30.9917 25C31.1805 25 31.3673 24.9371 31.5216 24.8176C31.7807 24.6146 31.8951 24.2808 31.8164 23.9655L30.5143 18.7765ZM19.926 18.454C19.926 19.233 19.4527 19.6967 18.6595 19.6967H17.7477C16.967 19.6967 16.4833 19.2206 16.4833 18.454V17.7039C16.4833 16.9383 16.967 16.4602 17.7477 16.4602H18.6595C19.4402 16.4602 19.926 16.9373 19.926 17.7039V18.454ZM22.2606 10.5964C22.0203 11.1621 21.6667 11.6721 21.1966 12.1275C20.9983 12.3068 20.7255 12.5479 20.373 12.8519C19.7067 13.4309 19.3069 13.8513 19.1736 14.1141C19.075 14.2934 18.9995 14.5571 18.9459 14.9054C18.8924 15.2537 18.7655 15.4278 18.563 15.4278H17.9628C17.7718 15.4278 17.6207 15.3485 17.5032 15.1898C17.3888 15.0321 17.329 14.8137 17.329 14.5386V13.8874C17.329 13.4464 17.628 12.8148 18.2251 11.9946L18.2681 11.9328C18.8505 11.1404 19.2292 10.5418 19.4055 10.1389C19.5797 9.73499 19.6678 9.33005 19.6678 8.92305C19.6678 8.43981 19.5692 8.0596 19.373 7.78037C19.1757 7.50113 18.9061 7.361 18.564 7.361C18.2545 7.361 17.9942 7.46816 17.7833 7.68248C17.5745 7.8968 17.4675 8.1616 17.4675 8.47896C17.4675 8.69328 17.5084 8.87669 17.5945 9.03227C17.6784 9.18786 17.9817 9.8102 17.6417 10.0513C17.3017 10.2924 16.9555 10.414 16.6029 10.414C15.9303 10.414 15.392 10.1873 14.9881 9.73602C14.5841 9.28471 14.3816 8.684 14.3816 7.93183C14.3816 6.94576 14.7772 6.1091 15.5662 5.42287C16.3563 4.73354 17.3342 4.39043 18.5 4.39043C19.7214 4.39043 20.7129 4.79537 21.4758 5.60627C22.2386 6.41615 22.6174 7.4661 22.6174 8.75613C22.6174 9.41763 22.4988 10.0307 22.2606 10.5964Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_373_862" x="0" y="0" width="37" height="33"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_373_862" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_373_862"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>
                    QCM
                </h1>
                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">QCM</li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->
        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid" >
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="col-lg-4" *ngIf="!loadSpinner">
            <h4 class="score">
              statut du test : 
              <span [ngClass]="complet_test == '2' ? 'score_value' : complet_test == '3' ?'score_value_test' :'score_value'">
                {{ complet_test == '2' ? 'Test non terminé' : complet_test == '3' ?'Test terminé' :'Test en attente' }}
              </span>
            </h4>
          </div>
          
        <div class="card" *ngIf="state_passed_test !='0'">
            <!--begin::Card header-->
            <div class="card-body tools">
                <div class="row mt-10">
                    <div class="col-lg-4">
                        <div class="title mb-10">
                            <h4>Test personnalisé :</h4>
                        </div>
                    </div>
                    <div class="col-lg-4" *ngIf="roundedPercentageScore || roundedPercentageScore == '0'">
                        <h4 class="score">Votre score est :<span class="score_value" > {{roundedPercentageScore}} % </span> </h4>
                    </div>
                    
                    <div class="col-lg-4  demande" *ngIf="(request_response_test=='0' || request_response_test=='3') || (complet_test =='2' && request_response_test=='2') || (complet_test =='2' && request_response_test=='1')" >
                        <a class="test btn_test" (click)="fixRequestNewTest()" > Demande nouveau test </a>
                    </div>
                    <div class="col-lg-4  demande" *ngIf="request_response_test=='1' && complet_test =='0' " >
                        <a class="test " > Demande en cours </a>
                    </div>
                    <div class="col-lg-4  demande" *ngIf="stateTest=='2' ? request_response_test=='2' && complet_test =='1' : false" >
                        <a class="test "> Demande accepté  </a>
                    </div>
                    
                </div>
            </div>
            <!--end::Card header-->
        </div>
    </div>
    <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <div class="pt-4 mb-6 mb-xl-9">
            <!--begin::Card header-->
            <div class="content border-0">
                <!--begin::Card title-->
                <!-- <div class="card">
                    <div class="card-body"> -->
                <div class="content">
                    <div class="row mb-10 mt-10">
                        <div class="title mb-5">
                            <h4>Modules :</h4>
                        </div>
                        <div class="col-lg-6">
                            <div class="input_compt">
                                <ng-select [items]="selectedSubcategories" bindLabel="name" bindValue="name"
                                    [multiple]="true" id="competences" placeholder="Sélectionnez des compétences"
                                    [(ngModel)]="selectedSubcategories"
                                    (ngModelChange)="onSelectedSubcategoriesChange()" disabled>
                                </ng-select>
                                <p *ngIf="skillsCandidate.length==0  && !loadSpinnerTable">
                                  Nous tenons à vous informer qu'il est impératif de compléter
                                  les compétences requises dans votre
                                  <a routerLink="/wegestu/profil-candidat/modifier"> Profil </a>
                                  pour pouvoir passer le test
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5">
                        <div class="col-lg-4" *ngFor="let category of skillsCandidate ; let i = index">
                            <div class="language mt-10 mb-10">
                                <div class="accordion" id="k't_accordion_'+ category.name_FR">
                                    <div class="accordion-item">
                                        <h2 class="accordion-header"
                                            [attr.id]="'kt_accordion_1_header_' + category.name_FR">
                                            <button class="accordion-button fs-4 fw-semibold" type="button"
                                                data-bs-toggle="collapse"
                                                [attr.data-bs-target]="'#kt_accordion_1_body_' + category.name_FR"
                                                aria-expanded="true"
                                                [attr.aria-controls]="'kt_accordion_1_body_' + category.name_FR"
                                                [style.background]="colors[i % colors.length]"
                                                [style.color]="i < colors.length ? '#2C2C2C' : ''">
                                                {{ category.name_FR }}
                                            </button>
                                        </h2>
                                        <div [attr.id]="'kt_accordion_1_body_' + category.name_FR"
                                            class="accordion-collapse collapse show"
                                            [attr.aria-labelledby]="'kt_accordion_1_header_' + category.name_FR"
                                            data-bs-parent="#kt_accordion_1">
                                            <div class="accordion-body">
                                                <div class="py-5 fs-6">
                                                    <div class="checks">
                                                        <div class="row">
                                                            <div class="col-lg-12"
                                                                *ngFor="let subcategory of category.sub_category">
                                                                <div
                                                                    class="form-check form-check-custom form-check-solid mb-5">
                                                                    <input class="form-check-input" type="checkbox" 
                                                                        [id]="'flexCheckDefault_' + subcategory.name_FR"
                                                                        (change)="onCheckboxChange(category, subcategory, $event)"
                                                                        [checked]="subcategory.question && subcategory.question.length > 0 ? selectedSubcategoryIds.includes(subcategory.id) : false"
                                                                        [disabled]="!(subcategory.nb_question > 0) || show_score" />

                                                                        <label class="form-check-label" [for]="'flexCheckDefault_' + subcategory.name_FR">
                                                                            {{ subcategory.name_FR }}
                                                                            <span class="no-questions" *ngIf="!(subcategory.nb_question > 0)"> <span>(Pas des Questions)</span> </span>
                                                                        </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      

                        <div class="alert alert-warning text-center mt-5" role="alert"   *ngIf="!skillsCandidate?.length && !loadSpinnerTable">
                            <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                            <span class="empty-list">
                             La liste est vide
                         </span>
                         </div> 


                        <div class="spin" *ngIf="!totalItems && loadSpinnerTable">
                            <ngx-spinner class="custom-spinner"
                            bdColor="rgba(255,255,255,1)"
                              size="medium"
                              color="#fff"
                              type="ball-atom"
                              [fullScreen]="true"
                            >
                            <span class="loader">
                              <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
                            </span>
                            </ngx-spinner>
                            
                        </div>
                        <div class="pagination mt-5 " *ngIf="skillsCandidate && skillsCandidate.length">
                            <app-pagination [data]="skillsCandidate" [lengthData]="totalItems"
                                (setPages)="getItems($event)"> </app-pagination>
                        </div>
                    </div>
                    <div class="send mt-10" *ngIf="(stateTest !== '1' && complet_test =='1' && !loadSpinner) || (stateTest == '0' && complet_test =='0' && !loadSpinner && state_passed_test=='0')">

                                <a class="save btn_test" id="sendSelectedModules" (click)="sendSelectedModules() ; startTestQcm()">Passer le test</a>
                    </div>
                </div>
                <!-- </div>
                </div> -->
                <!--end::Card header-->
                <!--begin::Card body-->
                <!--end::Card body-->
            </div>
        </div>
    </div>
</div>

