export class CCandidate {
    id: any
    ID_user: string
    first_name: string
    last_name: string
    english_level: string
    frensh_level: string
    email: string
    personal_email: any
    email_verified_at: any
    image_url: string
    phone_number: string
    current_country: any
    address: any
    destination_country: any
    mobility: any
    years_of_experience: any
    role_id: any
    score: any
    last_connection: any
    status: string
    availability: any
    tjm: any
    desired_contract: any
    desired_salary: any
    requested_salary: any
    linkedin_url: any
    date_first_experience: any
    date_last_experience: any
    date_last_update: any
    date_test: any
    linkedin_link: any
    open_for_mission: number
    diplomas: any[]
    certificates: any[]
    softSkills:any[]
    cv: any
    created_at: string
    skills_text:any
    updated_at: string
    oauth_id: any
    oauth_type: any
    desired_workstation: any
    skills: [] | undefined
    sub_category_id: [] | undefined
    diplome: [] | undefined
    certificat: [] | undefined
    prof_experience: [] | undefined
    langue_user: [] | undefined
    savoir_faire: string | undefined
    emailProESN: string | undefined
    emailProClient: string | undefined
    constructor(model?: CCandidate) {
        this.id = model && model.id || null;
        this.ID_user = model && model.ID_user || ""
        this.first_name = model && model.first_name || ""
        this.desired_workstation = model && model.desired_workstation
        this.last_name = model && model.last_name || ""
        this.frensh_level = model && model.frensh_level || ""
        this.english_level = model && model.english_level || ""
        this.email = model && model.email || ""
        this.personal_email = model && model.personal_email || "";
        this.email_verified_at = model && model.email_verified_at || "";
        this.image_url = model && model.image_url || "";
        this.phone_number = model && model.phone_number || "";
        this.current_country = model && model.current_country
        this.address = model && model.address || "";
        this.destination_country = model && model.destination_country || "";
        this.mobility = model && model.mobility || "";
        this.years_of_experience = model && model.years_of_experience || "";
        this.role_id = model && model.role_id || null
        this.score = model && model.score || "";
        this.last_connection = model && model.last_connection || "";
        this.status = model && model.status || "";
        this.availability = model && model.availability || "";
        this.tjm = model && model.tjm || "";
        this.desired_contract = model && model.desired_contract || "";
        this.desired_salary = model && model.desired_salary || "";
        this.requested_salary = model && model.requested_salary || "";
        this.tjm = model && model.tjm || "";
        this.date_first_experience = model && model.date_first_experience
        this.date_last_experience = model && model.date_last_experience || "";
        this.date_last_update = model && model.date_last_update || "";
        this.date_test = model && model.date_test || "";
        this.linkedin_link = model && model.linkedin_link || "";
        this.open_for_mission = model && model.open_for_mission || 1
        this.diplomas = model && model.diplomas || []
        this.certificates = model && model.certificates || []
        this.cv = model && model.cv || "";
        this.created_at = model && model.created_at || "";
        this.updated_at = model && model.updated_at || "";
        this.oauth_id = model && model.oauth_id || "";
        this.oauth_type = model && model.oauth_type || "";
        this.softSkills = model && model.softSkills || [];

        

    }

}
export const listContracts = [
    { id: 1, value: 'Alternance', label: 'Alternance', checked: false },
    { id: 2, value: 'Autres type de contrat', label: 'Autres type de contrat', checked: false },
    { id: 3, value: 'cdd', label: 'CDD', checked: false },
    { id: 4, value: 'cdi', label: 'CDI', checked: false },
    { id: 5, value: 'Freelance', label: 'Freelance', checked: false },
    { id: 6, value: 'Portage_salarial', label: 'Portage salarial', checked: false },
    { id: 7, value: 'Stage', label: 'Stage', checked: false },
  
  ];