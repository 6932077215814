import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListaCraComponent } from './components/lista-cra/lista-cra.component';
import { DetailCraComponent } from './components/detail-cra/detail-cra.component';
import { ListCraCompanyComponent } from './components/list-cra-company/list-cra-company.component';
import { DetailTimeSheetComponent } from './components/detail-time-sheet/detail-time-sheet.component';
import { TimeSheetCompanyComponent } from './components/time-sheet-company/time-sheet-company.component';
import { ListTimeSheetComponent } from './components/list-time-sheet/list-time-sheet.component';

const routes: Routes = [
  {
    path: 'cra',
    component: ListaCraComponent
  },
  {
    path: 'details-cra',
    component: DetailCraComponent
  },
  {
    path: 'list-cra-entreprises',
    component: ListCraCompanyComponent
  },
  {
    path: 'details-timeSheet',
    component: DetailTimeSheetComponent
  },
  {
    path: 'list-timeSheet-entreprises',
    component: TimeSheetCompanyComponent
  },
  {
    path:'timeSheet',
    component: ListTimeSheetComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CraRoutingModule { }
