import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthentificationService } from '../../services/authentification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  codeSended,
  codeexpire,
  invalidVerificationCode,
  successCodeVerficiation,
} from '../../../shared/shared-models/message';

@Component({
  selector: 'app-code-verfication',
  templateUrl: './code-verfication.component.html',
  styleUrls: ['./code-verfication.component.css'],
})
export class CodeVerficationComponent {
  generateCodeForm: FormGroup = this.codeForm();
  userEmail!: string;

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthentificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.userEmail = params.params['email'];
        console.log('userEmail', this.userEmail);
      },
    });
  }

  codeForm() {
    return this.formBuilder.group({
      number1: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      number2: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      number3: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      number4: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
    });
  }

  onInput(currentInput: HTMLInputElement, nextInput?: HTMLInputElement) {
    const inputValue = currentInput.value.replace(/[^0-9]/g, '');
    currentInput.value = inputValue;
    if (inputValue.length === 1 && nextInput) {
      nextInput.focus();
    }
  }

  codeVerification() {
    console.log(
      'generateCodeForm',
      Object.values(this.generateCodeForm.value).join('')
    );
    if (this.generateCodeForm.valid) {
      let codeVerification = Object.values(this.generateCodeForm.value).join(
        ''
      );
      this.authService
        .codeVerification({ email: this.userEmail, code: codeVerification })
        .pipe()
        .subscribe({
          next: (data) => {
            console.log('data', data);
            this.toastrService.success(successCodeVerficiation);
            this.router.navigateByUrl('/auth/login');
          },
          error: (err) => {
            console.log('error', err);
            if (err?.error.message === 'Invalid Code') {
              this.toastrService.error(invalidVerificationCode);
            }
            if (err?.error.message === 'code expired') {
              this.toastrService.error(codeexpire);
            }
          },
        });
    }
  }

  resendCodeVerification() {
    const data = { email: this.userEmail };
    this.authService
      .resendCodeVerification(data)
      .pipe()
      .subscribe({
        next: (data) => {
          console.log('data', data);
          this.toastrService.success(codeSended);
        },
        error: (err) => {
          console.log('error', err);
          if (err?.error) {
            this.toastrService.error(err);
          }
        },
      });
  }
}
