import { ChangeDetectorRef, Component, LOCALE_ID } from '@angular/core';
import { CalendarOptions } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import { ServiceCraService } from '../../services/service-cra.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  Observable,
  Subject,
  finalize,
  forkJoin,
  takeUntil
} from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { serverError } from '../../../../shared/shared-models/message';
import { ActivatedRoute } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { CandidateService } from '../../../../main/dossier/services/candidate.service';
import Swal from 'sweetalert2';
import * as saveAs from 'file-saver';

registerLocaleData(localeFr, 'fr');
@Component({
  selector: 'app-detail-cra',
  templateUrl: './detail-cra.component.html',
  styleUrls: ['./detail-cra.component.css'],
  providers: [{ provide: LOCALE_ID, useValue: 'fr' }],
})
export class DetailCraComponent {
  allSelectedValues: any[] = [];
  listOfCra: any;
  idCra: any;
  listDetailsCraForMonths: any;
  totalTypeOfCra: any;
  rowIndex!: number;
  numberOfDays!: number;
  addCraData: any;
  alladdCraData: any[] = [];
  totalClientOfCra: any;
  listClientOfCra: any[] = [];
  durationCra: any;
  typeIds: any = [];
  totalDurations: any[] = [];
  searchElement: string = '';
  totalDurationByfamilyByType: any;
  totalDurationForAllFamilies: any;
  type_id!: number;
  idUser: any;
  unknownFamily: boolean = false;
  unknownType: boolean = false;
  currentUser: any;
  company_name: any;
  company_id: any;
  isInvalidDuration: boolean = false;
  durationMapping:any = {
    0: 0,
    1: 0.25,
    2: 0.5,
    3: 0.75,
    4: 1,
  };
  id: any;
  selectedCandidate: any;
  sums: any[] = [];

  craAdded: boolean = false;
  sendCra: boolean = false;
  cancelCra: boolean = false;
  validationCraState: any;
  items_values = [
    { id: 0, name: '0' },
    { id: 1, name: '0.25' },
    { id: 2, name: '0.5' },
    { id: 3, name: '0.75' },
    { id: 4, name: '1' },
  ];
  isButtonDisabled: boolean = false;
  totalDurationByDay: { day: number, total_duration: number }[] = [];
  isAnnulerButtonDisabled: boolean = false;
  testArray: any = [1, 2, 3];
  modelSlect = '1';

  selectedValues: { [type_id: number]: { duration: any }[] } = {};
  previousValues: { [typeId: string]: { [j: number]: any } } = {};

  intermediateValue: any = null;

  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();

  searchFilter: string = '';
  isEnregistrerVisible: boolean = true;
  isEnvoyerDisabled: boolean = true;

  constructor(
    private serviceCra: ServiceCraService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private candidatService: CandidateService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.calculateSums();
    this.checkEnvoyerButton();
    this.getlistClientOfCra();
    forkJoin([this.getId()]).subscribe(() => {
      this.getCandidateById();
    });
  }
  storePreviousValue(typeId: string, j: number, oldValue: string) {
    if (!this.previousValues[typeId]) {
      this.previousValues[typeId] = {};
    }
    this.previousValues[typeId][j] = oldValue;
  }
  calculateSums(): void {
    // Initialize sums array based on number of days
    const numDays = this.generateDaysArray().length;
    const sums = new Array(numDays).fill(0);

    for (const typeId in this.selectedValues) {
      this.selectedValues[typeId].forEach((day, index) => {
        const code = day.duration;
        if (code != null && this.durationMapping[code] != null) {
          sums[index] += this.durationMapping[code];
        }
      });
    }

    this.sums = sums;
  }
  checkEnvoyerButton(): void {
    // Enable the Envoyer button if all required fields are filled
    const allFilled = this.sums.every(sum => sum === 1);
    this.isEnvoyerDisabled = !allFilled;
  }
  // Requirement 6, 16, 17, 18
  validateTotals(): boolean {
    return this.sums.every(sum => sum <= 1);
  }
  // Requirement 11: Deleting inputs in rows
  deleteInput(typeId: number, dayIndex: number): void {
    if (this.selectedValues[typeId] && this.selectedValues[typeId][dayIndex]) {
      this.selectedValues[typeId][dayIndex].duration = null;
      this.calculateSums();
      this.checkEnvoyerButton();
    }
  }
  getCandidateById() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idUser = JSON.parse(this.currentUser).id;
    this.spinner.show();
    this.candidatService
      .getCandidate(this.idUser)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];
          this.company_name = this.selectedCandidate?.ESN_name;
          this.company_id = this.selectedCandidate?.id;
          this.spinner.hide();
          this.getListTypeOfCra();
          this.getDetailsListCra();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  getId(): Observable<void> {
    return new Observable((observer) => {
      this.activatedRoute.paramMap.subscribe({
        next: (params: any) => {
          this.idCra = params.params['idCra'];
          observer.next();
          observer.complete();
        },
        error: (err) => observer.error(err),
      });
    });
  }

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, timeGridPlugin, listPlugin],
    initialView: 'dayGridMonth',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth,timeGridWeek',
    },
  };
  getBackgroundColor(family: string): string {
    switch (family) {
      case 'Production':
        return 'rgb(252 228 236 / 41%)';
      case 'Interne':
        return 'rgb(232 245 233 / 66%)';
      case 'Absence':
        return 'rgb(224 247 250 / 48%)';
      default:
        return '#ffffff';
    }
  }
  getListTypeOfCra() {
    this.spinner.show();
    this.serviceCra
      .getListOfCra(this.company_name)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listOfCra = res?.data.items;

          this.listOfCra = this.listOfCra.map((item: any) => {
            item['checked'] = false;
            return item;
          });
          this.typeIds = this.listOfCra.type_id;
          this.totalTypeOfCra = res.data.count;
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  /* filter data on key up input */
  applyFilter() {
    const searchTerm = this.searchElement.trim().toLowerCase();
    this.spinner.show();
    this.serviceCra
      .searchCra(this.idCra, searchTerm, this.company_name, this.idUser)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.listDetailsCraForMonths = res?.data;
          if (this.listDetailsCraForMonths?.desiredCRA?.ligne_cra) {
            this.listOfCra = this.listDetailsCraForMonths?.desiredCRA?.ligne_cra;
            this.typeIds = this.listOfCra.map((cra: any) => cra.type_id);
            this.listClientOfCra = []
          }
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }


  getlistClientOfCra() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    this.spinner.show();
    this.serviceCra
      .getClientsByCandidate(this.id)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.listClientOfCra = res.data;
          this.listClientOfCra = this.listClientOfCra.map((item: any) => {
            item['checked'] = false;
            return item;
          });
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  getDetailsListCra() {
    let dataPayload = this.idCra;
    this.spinner.show();
    this.serviceCra
      .getDetailsCra(dataPayload, this.company_name, this.id)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.listDetailsCraForMonths = res?.data;
          this.totalDurationByDay = this.listDetailsCraForMonths.desiredCRA.total_duration_by_day;
          this.listDetailsCraForMonths.desiredCRA.ligne_cra = this.listDetailsCraForMonths?.desiredCRA?.ligne_cra?.filter((item: any) => {
            item.days = item?.days?.map((item: any) => {
              item['values'] = this.items_values;
              return item;
            });
            return item;
          })
          const numberOfRows = Number(this.numberOfRows);
          const numberOfDays = Number(
            this.listDetailsCraForMonths?.numberOfDays
          );
          this.selectedValues = {};
          for (let i = 0; i < numberOfRows; i++) {
            this.selectedValues[i] = Array.from(
              { length: numberOfDays },
              () => ({ duration: null })
            );
          }
          for (const cra of this.listDetailsCraForMonths?.desiredCRA
            ?.ligne_cra) {
            const type_id = cra.type_id;
            for (const day of cra.days) {
              const dayIndex = day.day - 1;
              const duration = day.duration ? parseInt(day.duration) : null;
              if (!this.selectedValues[type_id]) {
                this.selectedValues[type_id] = Array.from(
                  { length: numberOfDays },
                  () => ({ duration: null })
                );
              }
              this.selectedValues[type_id][dayIndex].duration = duration;
            }
          }
          for (const totalDuration of this.listDetailsCraForMonths?.desiredCRA
            ?.total_duration_by_day) {
            const dayIndex = totalDuration.day - 1;
            this.totalDurations[dayIndex] = totalDuration.total_duration;
          }
          this.calculateSums();
          this.totalDurationByfamilyByType =
            this.listDetailsCraForMonths?.desiredCRA?.totalDurationByfamilyByType;
          this.totalDurationForAllFamilies =
            this.listDetailsCraForMonths?.desiredCRA?.totalDurationForAllFamilies;

          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
  }

  getTypeName(typeId: number): string | undefined {
    const family = this.totalDurationByfamilyByType?.find(
      (fam: any) => fam.family_id === 'Production'
    );
    return family?.types.find((type: any) => type.id === typeId)?.type_name;
  }

  getTotalDurationByType(familyId: string): any[] {
    const family = this.totalDurationByfamilyByType?.find(
      (fam: any) => fam.family_id === familyId
    );
    return family ? family.types : [];
  }

  getTotalDurationByTypeProduction(): any[] {
    return this.getTotalDurationByType('Production');
  }

  getTotalDurationByTypeAbsence(): any[] {
    return this.getTotalDurationByType('Absence');
  }

  getTotalDurationByTypeInterne(): any[] {
    return this.getTotalDurationByType('Interne');
  }

  get numberOfRows(): number[] {
    return Array.from({ length: this.totalTypeOfCra }, (_, index) => index + 1);
  }

  isWeekend(day: number): boolean {
    if (
      !this.listDetailsCraForMonths?.weekends ||
      this.listDetailsCraForMonths.weekends.length === 0
    ) {
      return false;
    }

    const firstWeekendDate = this.listDetailsCraForMonths.weekends[0];
    const yearMonth = firstWeekendDate.substring(0, 7);

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const formattedDate = `${yearMonth}-${dayString}`;

    return this.listDetailsCraForMonths.weekends.includes(formattedDate);
  }
  isHoliday(day: number): boolean {
    if (
      !this.listDetailsCraForMonths?.holidays ||
      this.listDetailsCraForMonths.holidays.length === 0
    ) {
      return false;
    }

    const firstWeekendDate = this.listDetailsCraForMonths.holidays[0];
    const yearMonth = firstWeekendDate.substring(0, 7);

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const formattedDate = `${yearMonth}-${dayString}`;

    return this.listDetailsCraForMonths.holidays.includes(formattedDate);
  }

  addCra() {
    if (this.alladdCraData.length == 0 ) {
      this.toastr.info("Vous n'avez rien à changer dans le CRA.");
      return;
    }

    this.spinner.show();

    this.alladdCraData.forEach(async (item: any) => {
      await this.serviceCra
        .addListCra(item)
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe({
          next: (res) => {
            if (res.status === 200) {
              this.isEnvoyerDisabled = false;
              this.toastr.success('CRA ajouté avec succès.');
              this.getDetailsListCra();
              this.craAdded = true;
              this.allSelectedValues = [];
              this.listClientOfCra = this.listClientOfCra.map((item: any) => {
                item['checked'] = false;
                return item;
              });
              this.listOfCra = this.listOfCra.map((item: any) => {
                item['checked'] = false;
                return item;
              });
            }
            this.getDetailsListCra();
            this.spinner.hide();
          },
          error: (err: any) => {
            this.spinner.hide();
            this.allSelectedValues = [];
            if (err.error) {
              this.toastr.error('Erreur serveur.');
              this.allSelectedValues = [];
            }
          },
        });
    });
  }




  rejectCra() {
    Swal.fire({
      title: "Voulez-vous vraiment être sûr d'annuler votre CRA ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          cra_id: this.idCra,
          state_validation: '3',
        };
        this.serviceCra.cancelAndValidateCra(data).subscribe({
          next: (res: any) => {
            this.cancelCra = true;
            this.toastr.success('Cra a été annuler avec succès.');
            this.getDetailsListCra();
          },
          error: (error) => {
            this.toastr.error(serverError);
          },
        });
      }
    });
  }
  validateCra() {
    const incompleteDays = this.sums.filter(sum => sum < 1).length;

    Swal.fire({
      title: incompleteDays? "Il y a des jours non remplis. Voulez-vous quand même envoyer le CRA?" : "Voulez-vous vraiment envoyer votre CRA ?",
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Confirmer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.addCra();
        let data = {
          cra_id: this.idCra,
          state_validation: '2',
        };
        this.serviceCra.cancelAndValidateCra(data).subscribe({
          next: (res: any) => {
            this.validationCraState = res.state_validation;
            this.sendCra = true;
            this.toastr.success('Cra a été envoyé avec succès.');
            this.getDetailsListCra();
          },
          error: (error) => {
            this.toastr.error(serverError);
          },
        });
      }
    });
  }
  selectedTypeId!: number;



  updateButtonState() {
    const totalSums = this.sums.reduce((acc, curr) => acc + curr, 0);
    this.isButtonDisabled = totalSums === 1;
  }

  getNeededValue(remaining: number): number {
    if (remaining >= 0.75) return 0.75;
    if (remaining >= 0.5) return 0.5;
    if (remaining >= 0.25) return 0.25;
    return 0;
  }

 /**
 * Exports the CRA (Compte Rendu d'Activité) for a specific month and country as a PDF file.
 * The generated file is automatically downloaded to the user's system.
 *
 * @throws {Error} Displays a user-friendly error message if the export fails.
 */
  exportCra() {
  this.spinner.show();
  const EXTENSION = ".pdf"
  const COUNTRY = "FR"
  this.spinner.show()
  this.serviceCra.exportCra(this.listDetailsCraForMonths?.desiredCRA?.id, COUNTRY).subscribe({
    next: (res: any) => {
      const blob = new Blob([res], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      let fileName = this.listDetailsCraForMonths?.desiredCRA?.name
      fileName = fileName.concat(EXTENSION)

      saveAs(blob, fileName);
      this.spinner.hide();
      this.toastr.success('CRA téléchargé avec succès');
    },
    error: () => {
      this.spinner.hide();
      this.toastr.error(serverError);
    },
  });
  }

  getClassForDuration(duration: number): string {
    switch (duration) {
      case 1:
        return 'duration-1';
      case 4:
        return 'duration-4';
      case 2:
        return 'duration-2';
      default:
        return 'default-duration';
    }
  }

  generateDayNumbersArray(): number[] {
    return Array.from(
      { length: this.listDetailsCraForMonths?.numberOfDays },
      (_, index) => index + 1
    );
  }

  generateDaysArray(): string[] {
    // Generate days based on French calendar
    // Assuming you have a method to get all days of the month
    const days = [];
    const month = this.listDetailsCraForMonths?.desiredCRA?.date;
    if (month) {
      const date = new Date(month);
      const year = date.getFullYear();
      const monthIndex = date.getMonth();
      const numDays = new Date(year, monthIndex + 1, 0).getDate();
      for (let i = 1; i <= numDays; i++) {
        const day = new Date(year, monthIndex, i);
        days.push(day.toLocaleDateString('fr-FR', { weekday: 'short', day: 'numeric' }));
      }
    }
    return days;
  }

  // Requirement 14: Add search filter
  get filteredListClientOfCra() {
    if (!this.searchFilter) {
      return this.listClientOfCra;
    }
    return this.listClientOfCra.filter((client:any) =>
      client?.client_final_e_s_n?.name.toLowerCase().includes(this.searchFilter.toLowerCase())
    );
  }

  // Requirement 2 & 3: Remplir button functionalities
  remplirLigne(typeId: number, index: number, type: string): void {
    this.allSelectedValues.length = 0;
    this.selectedTypeId = typeId;
    const isClient = (type === 'project');
    const sourceList = isClient ? this.listClientOfCra : this.listOfCra;

    sourceList[index].checked = true;
    if (!sourceList[index].days) {
      sourceList[index].days = Array.from(
        { length: this.listDetailsCraForMonths.numberOfDays },
        () => ({ duration: null })
      );
    }

    // Recalculate sums before filling
    this.calculateSums();

    // Ensure selectedValues[typeId] is initialized and has the correct length
    if (!this.selectedValues[typeId]) {
      this.selectedValues[typeId] = Array.from(
        { length: this.listDetailsCraForMonths.numberOfDays },
        () => ({ duration: null })
      );
    }

    // Fill only the empty cells based on how much is needed to reach 1 for that day
    sourceList[index].days.forEach((day: any, i: number) => {
      const currentVal = this.selectedValues[typeId][i]?.duration;

      if (currentVal == null || currentVal === 0) {
        // Calculate how much is needed to reach 1 on day i
        const neededValue = 1 - this.sums[i];

        // Match neededValue to one of the increments in items_values
        const matchedItem = this.items_values.find(item => parseFloat(item.name) === neededValue);

        if (matchedItem) {
          // Set the day duration and selectedValues to the matched increment
          day.duration = matchedItem.id;
          this.selectedValues[typeId][i].duration = matchedItem.id;
        }
      }
    });

    this.toastr.success('Ligne remplie avec succès.');

    // Recalculate sums and update buttons after filling
    this.calculateSums();
    this.checkEnvoyerButton();
    this.buildCraData(typeId, isClient);
    this.updateButtonState();
  }

  private buildCraData(typeId: number, isClient: boolean) {
    // Use a Set to store unique items for allSelectedValues
    const uniqueSelectedValues = new Set();

    for (let day = 0; day < this.selectedValues[typeId].length; day++) {
      let obj: any;

      // Fill with maximum (1)
      obj = {
        client_id: isClient ? typeId : "",
        type_id: isClient ? "" : typeId,
        duration: this.selectedValues[typeId][day].duration,
        day: day + 1,
      };

      // Add the object to the Set (it will ensure uniqueness)
      uniqueSelectedValues.add(JSON.stringify(obj)); // Convert to string to store in Set
    }
    // Convert Set back to an array
    const uniqueValuesArray = Array.from(uniqueSelectedValues).map(item => JSON.parse(item as string)); // Cast 'item' as string

    let data = {
      date: this.listDetailsCraForMonths?.desiredCRA?.date,
      ligneCra: uniqueValuesArray,
    };

    this.addCraData = data;

    // Use a Set to ensure uniqueness in alladdCraData
    const dataSet = new Set(this.alladdCraData.map(item => JSON.stringify(item))); // Convert existing alladdCraData to a Set

    // Add the new data object to the Set (if unique)
    dataSet.add(JSON.stringify(data));

    // Convert the Set back to an array and assign it to alladdCraData
    this.alladdCraData = Array.from(dataSet).map(item => JSON.parse(item as string)); // Cast 'item' as string
  }

  removeLigne(clientId: number, index: number, type: string): void {
    let typeId: number;
    let isClient = false;

    if (type === 'project') {
      // Uncheck the line and reset days
      this.listClientOfCra[index].checked = false;
      this.listClientOfCra[index].days.forEach((day: any) => day.duration = null);

      this.selectedValues[clientId] = this.listClientOfCra[index].days.map(() => ({ duration: null }));
      typeId = clientId;
      isClient = true;
      this.buildCraData(typeId, isClient);
    } else if (type === 'timesheet') {
      // Uncheck the line and reset days
      this.listOfCra[index].checked = false;
      this.listOfCra[index].days.forEach((day: any) => day.duration = null);

      typeId = this.listOfCra[index].id;
      this.selectedValues[typeId] = this.listOfCra[index].days.map(() => ({ duration: null }));
      isClient = false;
      this.buildCraData(typeId, isClient);
    }

    // Recalculate sums and update the state of buttons
    this.calculateSums();
    this.checkEnvoyerButton();

    // Rebuild the CRA data structure after removal
    this.updateButtonState();
  }
  trackByCra(index: number, cra: any): number {
    return cra.type_id;
  }

  trackByDay(index: number, day: any): any {
    return day.day;
  }

  // Requirement 4 & 5: Real-time total update
  onDurationChange(event: any, typeId: number, type: string, dayIndex: number, day: any, selectRef: any): void {
    let value = this.previousValues[typeId][dayIndex] || 0;
    const newTotal = this.sums[dayIndex] - this.durationMapping[value] + this.durationMapping[event.id];
    console.log('newTotal', newTotal);
    if (newTotal > 1) {
      this.toastr.error('Le total des activités ne peut pas dépasser 1.');
    } else {
      value = event.id

    }
    this.previousValues[typeId][dayIndex] = value;
    this.selectedValues[typeId][dayIndex].duration = value;
    this.calculateSums();

    // Update the source list to keep it in sync
    const isClient = (type === 'client');
    const sourceList = isClient ? this.listClientOfCra : this.listOfCra;

    // Find the row corresponding to the given typeId
    const rowIndex = sourceList.findIndex((item: any) =>
      (isClient && item.client_id === typeId) || (!isClient && item.type_id === typeId)
    );
    if (rowIndex !== -1 && sourceList[rowIndex].days) {
      sourceList[rowIndex].days[dayIndex].duration = value;
    }

    this.buildCraData(typeId, isClient);
    this.checkEnvoyerButton();
    if (day?.values) {
      day.values = [...day.values]; // create a new array reference
    }
    selectRef.close();
  }

  // Requirement 13a: Display client line first if consultant is assigned
  get sortedListClientOfCra() {
    if (!this.listClientOfCra) {
      return [];
    }
    return this.listClientOfCra.sort((a: any, b: any) => {
      if (a.assigned && !b.assigned) return -1;
      if (!a.assigned && b.assigned) return 1;
      return 0;
    });
  }
  // Requirement 15 & 16: Color totals based on sum
  getTotalColor(total: number): string {
    if (total === 1) {
      return 'green';
    } else if (total < 1) {
      return 'red';
    }
    return 'black';
  }

  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
