import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EntrepriseRoutingModule } from './entreprise-routing.module';
import { EntreprisesComponent } from './components/entreprises/entreprises.component';
import { DetailsCompanyComponent } from './components/details-company/details-company.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModuleModule } from '../../shared/pipes/pipes-module.module';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StarRatingComponent } from '../../shared/rate/star-rating/star-rating.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { CalendarModule } from 'primeng/calendar';


@NgModule({
  declarations: [
    EntreprisesComponent,
    DetailsCompanyComponent,
    StarRatingComponent,
  ],
  imports: [
    CommonModule,
    EntrepriseRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModuleModule,
    PaginationModule,
    NgxSpinnerModule,
    RatingModule.forRoot(),
    CalendarModule,
  
  

    ]
})
export class EntrepriseModule {}
