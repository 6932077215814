import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RateService } from '../../services/rate.service';
import { ToastrService } from 'ngx-toastr';
import {
  serverError,
  successEvaluation,
  successEvaluationupdate,
} from '../../../../shared/shared-models/message';
import { Subject, takeUntil } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CResponseReview } from '../../models/rate';

@Component({
  selector: 'app-rate-wegest-u',
  templateUrl: './rate-wegest-u.component.html',
  styleUrls: ['./rate-wegest-u.component.css'],
})
export class RateWegestUComponent {
  evaluationForm!: FormGroup;
  listQuestions: any;
  questions: any[] = [];
  idKey: any[] = [];
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  /* pagination */
  totalItems: number = 10;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 10;
  dataComment: any;
  isLoadSpinner: boolean = true;

  loadSpinner: boolean = false;
  // userResponses: { [question_id: number]: number } = {};
  responseData: any[] = [];
  userResponses: number[] = [];
  userResponse: number[] = [];

  comment: string = '';
  evaluationData: any;
  dataEditEvaluation: any;
  idResponse: any;
  idCandidate: any;
  currentUser: any;
  responsecandidat!: any[];
  /* modal */
  @ViewChild('modal') modal: any;
  /* modal */
  modalRef?: BsModalRef;

  editReviewForm!: FormGroup;
  starRatings: number[] = [1, 2, 3, 4, 5];
  selectedRating: number = 0;
  showBtnEdit: boolean = false;
  id_comment: any;
  addComment: boolean = false;
  show_submit: boolean = true;
  isCommentEmpty: boolean = true;
  idEvaluate: any;
  idEdit: any;

  showcomment: boolean = false;
  showStar: boolean = false;
  commentToEdit: any;
  commentLengthError: boolean = false;
  pagination : boolean =false;

  selectedStar: number = 0;

  constructor(
    private formBuilder: FormBuilder,
    private evaluationService: RateService,
    private toastrService: ToastrService,
    private spinner: NgxSpinnerService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.idCandidate = JSON.parse(this.currentUser).id;
    this.getEvaluationWegestuByIdCandidate();
    this.editReviewForm = this.reviewForm(new CResponseReview());
    this.editReviewForm.get('stars')?.valueChanges.subscribe((rating) => {
      this.selectedRating = rating;
    });
  }

  getEvaluationWegestuByIdCandidate() {
    this.spinner.show();
    this.evaluationService
      .getEvaluationWegestuByIdCandidate(this.idCandidate)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.isLoadSpinner = false;
          this.spinner.hide();
          if (res.status == 200) {
            this.responsecandidat = res.data;
            console.log('+++++responsecandidat', this.responsecandidat);
            this.dataComment = res.comment;
            console.log(
              '+++++responsecandidashowcommentshowcommentshowcommentshowcommentt',
              this.dataComment?.comment
            );

            if (
              this.dataComment?.comment !== null &&
              this.dataComment?.comment !== undefined
            ) {
              this.showBtnEdit = true;
              this.id_comment = this.dataComment?.id;
              this.comment = this.dataComment?.comment;
              this.commentToEdit = this.comment;
              this.showcomment = true;
            } else {
              this.showcomment = false;
              console.log(
                '%crate-wegest-u.component.ts line:150 not exist',
                'color: #007acc;'
              );
              this.addComment = true;
            }

            // Filter the data array to include only items with a null comment
            const responsesWithoutComment = this.responsecandidat.filter(
              (response: any) =>
                response.response_evaluate_wegestu[0]?.comment === null
            );

            // Map the filtered array to the userResponses array
            this.userResponses = new Array(responsesWithoutComment.length).fill(
              0
            );

            // Map the responses to the userResponses array based on the question IDs
            responsesWithoutComment.forEach((response: any, index: number) => {
              const questionId =
                response.response_evaluate_wegestu[0].evaluate_wegestu_id;
              const questionIndex = this.responsecandidat.findIndex(
                (question: any) => question.id === questionId
              );

              if (questionIndex !== -1) {
                this.userResponses[questionIndex] =
                  parseInt(response.response_evaluate_wegestu[0].stars, 10) ||
                  0;
              }
            });

            console.log('userResponses+++++', this.userResponses);
          }
        },
        error: (err: any) => {
          this.spinner.hide();
          if (err.error) {
            this.toastrService.error(serverError);
          }
        },
      });
  }

  /* get pagination items */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
       if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
         this.getEvaluationWegestuByIdCandidate();
        }
    }
  }

  /* create review form */
  reviewForm(data: CResponseReview) {
    return this.formBuilder.group({
      id: [data.id, [Validators.required]],
      question: [data.question, [Validators.required]],
      stars: [data.stars, [Validators.required]],
    });
  }

  ratings: any = [];
  // update new senario
  setRating(questionId: any, index: number, stars: number) {
    console.log('addComment', this.addComment);
    console.log('questionId', questionId);
    this.userResponses[index] = stars;
    this.ratings.push({
      evaluate_wegestu_id: questionId.id,
      stars: stars,
    });
    let data2 = {
      responses: this.ratings,
    };
    this.dataEditEvaluation = data2;
    console.log('dataEditEvaluation', this.dataEditEvaluation);
  }

  /* edite response */
  editResponse() {
    const id = this.id_comment;
    const comment = this.commentToEdit;
    let data = { ...this.dataEditEvaluation };
    if (comment !== '') {
      data = { ...this.dataEditEvaluation, comment };
    }
    this.spinner.show();
    this.loadSpinner = true;
    this.evaluationService
      .updateEvaluation(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastrService.success(successEvaluationupdate);
            this.getEvaluationWegestuByIdCandidate();
            this.spinner.hide();
            this.loadSpinner = false;
            
          }
        },
        error: (err: any) => {
          this.spinner.hide();
          this.loadSpinner = false;
          if (err.error) {
            this.toastrService.error(serverError);
          }
        },
      });
  }

  /* edite Response */
  submitFormEdit() {
    this.editResponse();
  }

  addEvaluationwegestU() {
    const data = {
      comment: this.comment,
    };
    this.addEvaluationwegestU_1(this.comment);
  }

  addEvaluationwegestU_1(comment: any) {
    let data = { ...this.dataEditEvaluation };
    if (comment !== '') {
      data = { ...data, comment };
    }
    // let newData = { ...this.evaluationData, ...data }
    this.evaluationService
      .addEvaluationWegestu(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          if (res.status == 200) {
            this.toastrService.success(successEvaluation);
            this.getEvaluationWegestuByIdCandidate();
            this.addComment = false;
            this.showcomment = true;
            this.showStar = true;
            
          }
        },
        error: (err: any) => {
          if (err.error) {
            this.toastrService.error(serverError);
          }
        },
      });
  }

  checkCommentLength() {
    if (this.comment.length > 400 || this.commentToEdit.length > 400) {
      this.commentLengthError = true;
    } else {
      this.commentLengthError = false;
    }
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
