import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientFinalRoutingModule } from './client-final-routing.module';
import { ClientFinalComponent } from './components/client-final-List/client-final.component';


@NgModule({
  declarations: [
    ClientFinalComponent
  ],
  imports: [
    CommonModule,
    ClientFinalRoutingModule
  ]
})
export class ClientFinalModule { }
