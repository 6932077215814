<head><title>Reset-code-interface Wegestu</title>
    <meta name="description" content="Reset-code-interface Wegestu"></head>
<div class="code">
  <div class="d-flex flex-column flex-root" id="kt_app_root">

    <!--begin::codeReset -->
    <div class="d-flex flex-column flex-center flex-column-fluid">
      <!--begin::Content-->
      <div class="d-flex flex-column flex-center p-10">
        <!--begin::Wrapper-->
        <div class="card-warp w-md-650px py-5">
          <div class="content py-15 py-lg-20">
            <!--begin::Logo-->
            <div class="mb-15 text-center">
              <a href="/" class="">
                <img alt="Logo" src="/assets/media/logos/logo-wegestu-color.png" class="h-40px" />
              </a>
            </div>
            <!--end::Logo-->

            <!--begin::Illustration-->
            <div class="mb-10">
              <!--begin::Label-->
              <div class="text-center code-title fs-6 mb-10 ms-1">
                Saisir votre code
              </div>
              <!--end::Label-->
              <!--begin::Input group-->
              <div class="d-flex flex-wrap flex-stack" [formGroup]="generateCodeForm" (keyup.enter)="reinitiate()">
                <input type="text" name="code_1" formControlName="number1" #input1
                  (keyup)="moveFocus(input1, input2)" (keypress)="validateInput($event)"
                  maxlength="1"
                  class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2" />
                <input type="text" name="code_2" formControlName="number2" #input2
                  (keyup)="moveFocus(input2, input3)" (keypress)="validateInput($event)"
                  maxlength="1"
                  class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2" />
                <input type="text" name="code_3" formControlName="number3" #input3
                  (keyup)="moveFocus(input3, input4)" (keypress)="validateInput($event)"
                  maxlength="1"
                  class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2" />
                <input type="text" name="code_4" formControlName="number4" #input4
                  (keypress)="validateInput($event)"
                  maxlength="1"
                  class="form-control bg-transparent h-60px w-60px fs-2qx text-center mx-1 my-2" />
              </div>
              
              <!--begin::Input group-->
            </div>
            <!--end::Section-->

            <!--begin::Submit-->
            <div class="d-flex flex-center">
              <button [disabled]="!this.generateCodeForm.valid" (click)="reinitiate()" type="button"
                id="kt_sing_in_two_factor_submit" class="btn btn-lg w-100 pt-5 pb-5 btnconfirm">
                <span class="indicator-label">Réinitialiser</span>
                <span class="indicator-progress">Please wait...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
              </button>
            </div>
            <!--end::Submit-->

              <!--start::Link-->
              <div class="redirect-login mt-5 d-flex justify-content-center">
                <label class="form-check-label" for="flexCheckDefault">
                    Vous n'avez pas reçu le code?
                </label>
                <a (click)="resendCodeVerification()" class="link-primary btn_renvoyer ">&nbsp; Renvoyer </a>
            </div>
            <!--end::Link-->
          </div>
        </div>
        <!--end::Wrapper-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::codeReset -->
  </div>
</div>
  <script src="./assets/js/custom/authentication/sign-in/two-factor.js"></script>
  
