import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class ConsultantService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get list consultant  */
  getListConsultant(
    params: {
      per_page?: number;
      page?: number;
      sort?: number;
      sortAttribute?: string;
    } = {}
  ) {
    let sort = '';
    if (params?.sort)
      sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/User/getCandidatesConsultants?per_page=${params?.per_page}&page=${params?.page}${sort}`
    );
  }
  getSkillsbyIdCandidate(id: any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(this._base + `/Skill/getSkillsByCandidate/${id}`);
  }
  /* search Consultant */
  searchConsultant(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/User/searchCandidate`,
      data
    );
  }
  /* get elment of header table list Consultant */
  getHeaderElements(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/getPagesWithSubPagesForUser?role_id=3&interface=3&id=${id}`
    );
  }
  changeDelectedElementHeader(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/addRemoveSubPageToUser?role_id=3&interface=3`,
      data
    );
  }
  sortArray(
    array: any[],
    column: string | undefined,
    direction: number
  ): any[] {
    const compareFn = (a: any, b: any): any => {
      if (column) {
        const aValue = this.getValue(a, column);
        const bValue = this.getValue(b, column);

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return direction * (aValue - bValue);
        } else {
          const aString = String(aValue).toLowerCase();
          const bString = String(bValue).toLowerCase();
          return direction * aString.localeCompare(bString);
        }
      }
      return 0;
    };

    return array.sort(compareFn);
  }

  getValue(obj: any, column: string): any {
    return column.includes('.')
      ? column.split('.').reduce((o, i) => o[i], obj)
      : obj[column];
  }
  /* get list Skills */
  getSkills() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SubCategory/getListSubCateg`
    );
  }
  /* get profile Candidate  */
  getConsultant(id: any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + '/User/userProfile' + '?user_id=' + id
    );
  }
  getCandidate(id: number) {
    return this._http.get<ApiResponse<any>>(this._base + `/ConsultantEsn/getConsultantDetails?user_id=${id}`);
  }
   /* get skills by id Candidate */
   getSkillsbyIdConsultant(id:any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(this._base + '/Skill/getSkillsByCandidate'+"/"+id);
  }

  /* download PDF*/
  downloadPDF(url: string): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' };
    return this._http
      .get<Blob>(url, options)
      .pipe(map((res: any) => new Blob([res], { type: 'application/pdf' })));
  }
}
