import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RateWegestUComponent } from './components/rate-wegest-u/rate-wegest-u.component';

const routes: Routes = [
  {
    path: '',
    component: RateWegestUComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RateWegestuRoutingModule { }
