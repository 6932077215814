<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="28" height="38" viewBox="0 0 28 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_534_1023)">
                        <path d="M8.73438 5.38853H19.3343V3.25956H15.8403V0H12.1576V3.25956H8.73438V5.38853Z" fill="#265D91"/>
                        <path d="M23.0071 5.26135H21.1792V6.30811C21.1792 6.81596 20.7664 7.2277 20.2573 7.2277H7.81339C7.30421 7.2277 6.8914 6.81596 6.8914 6.30811V5.26135H4.99288C4.4445 5.26135 4 5.70476 4 6.25165V28.0122C4 28.5592 4.44456 29.0025 4.99288 29.0025H23.0071C23.5555 29.0025 24 28.5591 24 28.0122V6.25165C23.9999 5.70469 23.5554 5.26135 23.0071 5.26135ZM9.66407 24.6577C9.31129 25.0116 8.72134 25.0152 8.36426 24.6654L6.74707 23.0816L8.03906 21.7693L9.00248 22.7128L11.514 20.1933L12.8217 21.49L9.66407 24.6577ZM9.66407 14.8943C9.31129 15.2482 8.7214 15.2518 8.36426 14.9021L6.74707 13.3183L8.03906 12.0059L9.00248 12.9495L11.514 10.4299L12.8217 11.7267L9.66407 14.8943ZM21.4019 26.2713H14.9673V24.4321H21.4019V26.2713ZM21.4019 21.6653H14.9673V19.8261H21.4019V21.6653ZM21.4019 16.3509H14.9673V14.5117H21.4019V16.3509ZM21.4019 11.745H14.9673V9.90579H21.4019V11.745Z" fill="#265D91"/>
                        </g>
                        <defs>
                        <filter id="filter0_d_534_1023" x="0" y="0" width="28" height="37.0026" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                        <feOffset dy="4"/>
                        <feGaussianBlur stdDeviation="2"/>
                        <feComposite in2="hardAlpha" operator="out"/>
                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_534_1023"/>
                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_534_1023" result="shape"/>
                        </filter>
                        </defs>
                        </svg>
                        

                        Evaluation
                </h1>

                <!--begin::Menu-->
              
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Listes des PVs</li>
               
               
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->
        
          <div class="card-body tools">
           <div class="title mb-3">
            <h4>Recherche</h4>
           </div>
          <div class="container">
            <div class="row mb-3">
                <div class="col-lg-3">
                    <div class="input-shadow">
                        <select class="form-select" data-control="select2" data-placeholder="In Progress" data-hide-search="true">
                            <option value="">PV num</option>
                            <option value="1">Pays de résidence</option>
                            <option value="2" selected="selected">Pays de résidence</option>
                            <option value="3">Tunisie</option>
                        </select>
                       
                    </div>
                </div>
                <div class="col-lg-3">
                    <select class="form-select" data-control="select2" data-placeholder="In Progress" data-hide-search="true">
                        <option value="">Date</option>
                        <option value="1">Pays de résidence</option>
                        <option value="2" selected="selected">Pays de résidence</option>
                        <option value="3">Tunisie</option>
                    </select>
                </div>
                <div class="col-lg-3">
                    <input type="text" name="ESN" id="ESN" class="form-control form-control-solid mb-3" placeholder="Signé par l’ESN">

                   
                </div>
                <div class="col-lg-3">
                    <input type="text" name="vous" id="vous" class="form-control form-control-solid mb-3" placeholder="Signé par vous">

                   
                </div>
            </div>
         
          
            <div class="row mb-3 rechercher">
                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3">
                 <div class="btn_search">
                  <a href="#" class="search"> <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z" fill="white"/>
                      </svg>
                       Rechercher</a>
                 </div>
                </div>
              </div>
          </div>
        
           
          </div>
        </div>
     
    </div>
    <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
         
            <!--begin::Card header-->
            <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                <div class="section_title">
                    <h1 class="section-heading my-0">Listes des PVs</h1>
                   </div>
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3" data-bs-toggle="tab" href="#kt_project_targets_card_pane">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active" data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->
                <!--begin::Wrapper-->
             
                <!--end::Wrapper-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
            <div class="tab-content pt-5 pb-5">
                <!--begin::Tab pane-->
                <div id="kt_project_targets_card_pane" class="tab-pane fade ">
                    <!--begin::Row-->
                	<div class="row g-6 g-xl-9">
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1  </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap">
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                            <div class="fw-semibold text-gray-400">Année création</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">23</div>
                                            <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                            <div class="fw-semibold text-gray-400">Note</div>
                                        </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Domaine 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1  </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap">
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                            <div class="fw-semibold text-gray-400">Année création</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">23</div>
                                            <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                            <div class="fw-semibold text-gray-400">Note</div>
                                        </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Domaine 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1  </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap">
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                            <div class="fw-semibold text-gray-400">Année création</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">23</div>
                                            <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                            <div class="fw-semibold text-gray-400">Note</div>
                                        </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Domaine 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1  </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap">
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                            <div class="fw-semibold text-gray-400">Année création</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">23</div>
                                            <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                            <div class="fw-semibold text-gray-400">Note</div>
                                        </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Domaine 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1  </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap">
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                            <div class="fw-semibold text-gray-400">Année création</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">23</div>
                                            <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                        </div>
                                        <!--end::Stats-->
                                        <!--begin::Stats-->
                                        <div class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                            <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                            <div class="fw-semibold text-gray-400">Note</div>
                                        </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Domaine 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia Meddeb </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap mt-5">
                                        <!--begin::Stats-->
                                      <div class="skills">
                                        <div class="skill">
                                            <span class="badge style1">Python</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style2">Laravel</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style3">C#</span>
                                        </div>
                                      </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Poste 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia Meddeb </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap mt-5">
                                        <!--begin::Stats-->
                                      <div class="skills">
                                        <div class="skill">
                                            <span class="badge style1">Python</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style2">Laravel</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style3">C#</span>
                                        </div>
                                      </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Poste 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia Meddeb </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap mt-5">
                                        <!--begin::Stats-->
                                      <div class="skills">
                                        <div class="skill">
                                            <span class="badge style1">Python</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style2">Laravel</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style3">C#</span>
                                        </div>
                                      </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Poste 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                        <!--begin::Col-->
                        <div class="col-md-6 col-xxl-4">
                            <!--begin::Card-->
                            <div class="card pt-5 p-5">
                                <div class="tools">
                                     
                                        <td class="actions">
                                            <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">

                                             <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91"/>
                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91"/>
                                        </svg>  </a>
                                            <!--begin::Menu-->
                                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Voir profil</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <!--begin::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Postuler</a>
                                                </div>
                                                <!--end::Menu item-->
                                                <div class="menu-item px-3">
                                                    <a  class="menu-link px-3">Ne jamais voir cette offre</a>
                                                </div>
                                            </div>
                                            <!--end::Menu-->
                                        </td>                                   
                                </div>
                                <!--begin::Card body-->
                                <div class="card-body d-flex flex-center flex-column">
                                    <!--begin::Avatar-->
                                    <div class="symbol symbol-65px symbol-circle mb-5">
                                        <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                        <div class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3"></div>
                                    </div>
                                    <!--end::Avatar-->
                                    <!--begin::Name-->
                                    <a href="#" class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia Meddeb </a>
                                    <!--end::Name-->
                                    <!--begin::Position-->
                                    <!--end::Position-->
                                    <!--begin::Info-->
                                    <div class="d-flex flex-center flex-wrap mt-5">
                                        <!--begin::Stats-->
                                      <div class="skills">
                                        <div class="skill">
                                            <span class="badge style1">Python</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style2">Laravel</span>
                                        </div>
                                        <div class="skill">
                                            <span class="badge style3">C#</span>
                                        </div>
                                      </div>
                                        <!--end::Stats-->
                                    </div>
                                  
                                    <!--end::Info-->
                                </div>
                                <div class="foot p-5">
                                    <div class="poste">

                                        <span> Poste 1 </span>
                                    </div>
                                    <div class="plus">
                                        <a >Voir plus</a>
                                    </div>
                                </div>
                                <!--end::Card body-->
                            </div>
                            <!--end::Card-->
                        </div>
                        <!--end::Col-->
                    </div>
                    <!--end::Row-->
                </div>
                <!--end::Tab pane-->
                <!--begin::Tab pane-->
                <div id="kt_project_targets_table_pane" class="tab-pane fade  active show">
                    <div class="content">
                        <div class="card-body pt-3">
                            <!--begin::Table-->
                            <div class="table-responsive">
                                <table class="table align-middle  fs-6 gy-5" id="kt_customers_table">
                                    <thead>
                                        <tr class="text-start fw-bold fs-7 text-uppercase gs-0">
                                           
                                            <th class="min-w-125px">PV NUM</th>
                                            <th class="min-w-125px">DATE</th>
                                            <th class="min-w-125px">SIGNé PAR L’ESN</th>
                                            <th class="min-w-125px">SIGNé PAR VOUS </th>
                                            <th class="min-w-70px">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody class="fw-semibold text-gray-600">
                                        <tr>
                                          
                                            <td>
                                                <span class="num">PV-1452</span>
                                            </td>
                                            <td>
                                              <span>12/06/2023</span>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                              </td>
                                              <td class="actions">
                                                <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click">
                                                    <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85352 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7051 4.89478)" fill="#265D91"/>
                                                        </svg>
                                                        </a>
                                                <!--begin::Menu-->
                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/" class="menu-link px-3">Voir Détails</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Télécharger</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Signer</a>
                                                    </div>
                                                  
                                                </div>
                                                <!--end::Menu-->
                                            </td>
                                        </tr>
                                        <tr>
                                          
                                            <td>
                                                <span class="num">PV-1452</span>
                                            </td>
                                            <td>
                                              <span>12/06/2023</span>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                              </td>
                                              <td class="actions">
                                                <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click">
                                                    <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85352 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7051 4.89478)" fill="#265D91"/>
                                                        </svg>
                                                        </a>
                                                <!--begin::Menu-->
                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/" class="menu-link px-3">Voir Détails</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Télécharger</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Signer</a>
                                                    </div>
                                                  
                                                </div>
                                                <!--end::Menu-->
                                            </td>
                                        </tr>
                                        <tr>
                                          
                                            <td>
                                                <span class="num">PV-1452</span>
                                            </td>
                                            <td>
                                              <span>12/06/2023</span>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                              </td>
                                              <td class="actions">
                                                <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click">
                                                    <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85352 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7051 4.89478)" fill="#265D91"/>
                                                        </svg>
                                                        </a>
                                                <!--begin::Menu-->
                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/" class="menu-link px-3">Voir Détails</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Télécharger</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Signer</a>
                                                    </div>
                                                  
                                                </div>
                                                <!--end::Menu-->
                                            </td>
                                        </tr>
                                        <tr>
                                          
                                            <td>
                                                <span class="num">PV-1452</span>
                                            </td>
                                            <td>
                                              <span>12/06/2023</span>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                              </td>
                                              <td class="actions">
                                                <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click">
                                                    <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85352 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7051 4.89478)" fill="#265D91"/>
                                                        </svg>
                                                        </a>
                                                <!--begin::Menu-->
                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/" class="menu-link px-3">Voir Détails</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Télécharger</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Signer</a>
                                                    </div>
                                                  
                                                </div>
                                                <!--end::Menu-->
                                            </td>
                                        </tr>
                                        <tr>
                                          
                                            <td>
                                                <span class="num">PV-1452</span>
                                            </td>
                                            <td>
                                              <span>12/06/2023</span>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                            </td>
                                            <td>
                                                <div class="form-check form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="1" id="flexCheckChecked" checked="checked" />
                                                   
                                                </div>
                                              </td>
                                              <td class="actions">
                                                <a href="#" class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary" data-kt-menu-trigger="click">
                                                    <svg width="19" height="5" viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 6.85352 4.89478)" fill="#265D91"/>
                                                        <circle cx="2.44739" cy="2.44739" r="2.44739" transform="matrix(1 0 0 -1 13.7051 4.89478)" fill="#265D91"/>
                                                        </svg>
                                                        </a>
                                                <!--begin::Menu-->
                                                <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="/" class="menu-link px-3">Voir Détails</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <!--begin::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Télécharger</a>
                                                    </div>
                                                    <!--end::Menu item-->
                                                    <div class="menu-item px-3">
                                                        <a href="#" class="menu-link px-3">Signer</a>
                                                    </div>
                                                  
                                                </div>
                                                <!--end::Menu-->
                                            </td>
                                        </tr>
                                    
                                 
                                    
                                   
                                    </tbody>
                                </table>
                            </div>
                            <!--end::Table-->
                        </div>
                    </div>
                </div>
                <!--end::Tab pane-->
            </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->
  
        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->
    
        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>