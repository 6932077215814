import { LocalStorageService } from './../../shared/shared-services/local-storage.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../../environnements/environment';
import { Observable } from 'rxjs';
import {
  CLogin,
  CPassoword,
  CRegister,
} from '../../shared/shared-models/login';
import { ApiResponse } from '../../shared/shared-models/responseApi';
import { CrudService } from '../../shared/shared-services/crud-service/crud.service';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService extends CrudService<
  ApiResponse<any>,
  number
> {
  constructor(
    private cookieService: CookieService,
    private jwtHelper: JwtHelperService,
    private router: Router,
    _http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    super(_http, `${environment.baseUrl}`);
  }

  /* get token from cookies */
  getToken() {
    if (this.cookieService.get('token')) return this.cookieService.get('token');
  }

  /* get refresh token  */
  getRefreshToken() {
    if (this.cookieService.get('refresh_token'))
      return this.cookieService.get('refresh_token'); // set key
  }
  /* check if token is valid, not expired */
  isAuthenticated() {
    const token = this.getToken();
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    } else {
      return false;
    }
  }

  /* redirect to login page  */
  goToLogin() {
    this.router.navigate(['/']);
    this.clearCookies();
    this.localStorageService.clearData();
  }

  /* clear cookies */
  clearCookies() {
    this.cookieService.deleteAll('/');
    const cookies = document.cookie.split(';');
    for (const cookie of cookies) {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos).trim() : cookie.trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
    }
  }

  /* logout and redirect to login page */
  logOut() {
    const auth = this.getToken();
    if (auth != '') {
      this.disconnect().subscribe({
        next: () => {
          this.goToLogin();
        },
        error: () => {
          this.goToLogin();
        },
      });
    }
  }
  /*disconnect */
  disconnect(): Observable<any> {
    const token = this.getToken();  

    const headers = new HttpHeaders({
      'Authorization': `Bearer ${token}`  
    });
    return this._http.post<ApiResponse<any>>(this._base + '/api/auth/logout', {}, { headers });
  }
  /* set cookies */
  setCookies(key: string, value: string, expireTime?: Date) {
    this.cookieService.delete(key);
    this.cookieService.set(
      key,
      value,
      expireTime,
      '/',
      undefined,
      true,
      'Strict'
    );
  }
  /**Refresh Access Token  */
  refreshAccessToken(refreshToken?: any ): Observable<any> {
    const  formData = new FormData();

    formData.append('refresh_token', refreshToken.refresh_token);
    return this._http.post<ApiResponse<any>>(
      this._base + '/api/auth/refreshToken',
      formData
    );
  }
  /* login  */
  login(body: CLogin): Observable<ApiResponse<CLogin>> {
    return this._http.post<ApiResponse<CLogin>>(
      this._base + '/api/auth/login',
      body
    );
  }
  /* reset Mail */
  resetMail(body: {
    email: string;
  }): Observable<
    ApiResponse<{ status: number; data: { User: { email: string } } }>
  > {
    return this._http.post<
      ApiResponse<{ status: number; data: { User: { email: string } } }>
    >(this._base + '/api/password/forgotPassword', body);
  }
  /* reset Code */
  restCode(body: {
    code: string;
    email: string;
  }): Observable<ApiResponse<{ code: string; email: string }>> {
    return this._http.post<ApiResponse<{ code: string; email: string }>>(
      this._base + '/api/password/verifCodeForgetPassword',
      body
    );
  }
  /* rest Password */
  restPassword(body: CPassoword): Observable<ApiResponse<CPassoword>> {
    return this._http.post<ApiResponse<CPassoword>>(
      this._base + '/api/password/resetPassword',
      body
    );
  }
  /* register */
  register(body: CRegister): Observable<ApiResponse<CRegister>> {
    return this._http.post<ApiResponse<CRegister>>(
      this._base + '/api/auth/signUp',
      body
    );
  }
  /* code Verification */
  codeVerification(body: {
    code: string;
    email: string;
  }): Observable<ApiResponse<{ code: string; email: string }>> {
    return this._http.post<ApiResponse<{ code: string; email: string }>>(
      this._base + '/api/auth/verifCodeVerifyMail',
      body
    );
  }
  /* resend Code Verification */
  resendCodeVerification(body: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<{ email: string }>>(
      this._base + '/api/auth/resendMail',
      body
    );
  }
  /* change Password */
  changePassword(body: CPassoword): Observable<ApiResponse<CPassoword>> {
    return this._http.post<ApiResponse<CPassoword>>(
      this._base + '/api/password/changePassword',
      body
    );
  }
}
