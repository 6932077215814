import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TutoComponent } from './components/tuto/tuto.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'tuto',
  //   pathMatch: 'full',
  // },
  {
    path:'',
    component:TutoComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TutoRoutingModule { }
