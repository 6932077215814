import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectBehaviorService {
  private data = new BehaviorSubject<any>('');
  private picture = new BehaviorSubject<any>('');

  setData(data: any) {
    this.data.next(data);
  }

  getData() {
    return this.data.asObservable();
  }
  setPicture(picture:any){
    this.picture.next(picture);
  }
  getPicture() {
    return this.picture.asObservable();
  }
  constructor() { }
}