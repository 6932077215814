import { Component } from '@angular/core';

@Component({
  selector: 'app-list-semester-evaluation',
  templateUrl: './list-semester-evaluation.component.html',
  styleUrls: ['./list-semester-evaluation.component.css']
})
export class ListSemesterEvaluationComponent {

}
