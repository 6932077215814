import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactEsnComponent } from './components/contact-esn/contact-esn.component';
import { ContactRoutingModule } from './contact-routing.module';
import { ContactWegestuComponent } from './components/contact-wegestu/contact-wegestu.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldErrorModule } from '../../shared/shared-field-error/field-error.module';
import { ContactResponsableComponent } from './components/contact-responsable/contact-responsable.component';
import { QuillModule } from 'ngx-quill';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    ContactEsnComponent,
    ContactWegestuComponent,
    ContactResponsableComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    ContactRoutingModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    FormsModule,
    FieldErrorModule,
    QuillModule.forRoot(),
  ],
})
export class ContactModule {}
