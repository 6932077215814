export const patternEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const patternName = /^(.{3,})$/
export const patternLinkedin = /https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?/
export const patternText = /^[a-zA-ZÀ-ÖØ-öø-ÿ\s-_]+$/
export const patternPassword=/^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*()_+=[\]{};':"<>?/\\]).{8,}$/
export const linkedInRegExp = /https:\/\/www\.linkedin\.com\/in\/[a-zA-Z0-9-]+/
export const patternTjm = /^[0-9]+$/
// export const patternInfoUser = /^[0-9a-zA-ZÀ-ÖØ-öø-ÿ\s\-_\']+$/
export const patternInfoUser = /^[\s\S]{1,}$/;
export const patternsalaire = /^[0-9]+$/








