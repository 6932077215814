import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NetworkService } from './services/NetworkService';

@Component({
  selector: 'app-network-status',
  templateUrl: './network-status.component.html',
  styleUrls: ['./network-status.component.css']
})
export class NetworkStatusComponent {
  isOnline: boolean = true;

  constructor(private networkService: NetworkService, private router: Router) {
    this.networkService.onlineStatus$.subscribe((status) => {
      this.isOnline = status;
    });
    console.log('isOnline :  ', this.isOnline);
    if(this.isOnline){

      this.router.navigate(["/"])
    }
  }
}
