import { Component } from '@angular/core';

@Component({
  selector: 'app-client-final',
  templateUrl: './client-final.component.html',
  styleUrls: ['./client-final.component.css']
})
export class ClientFinalComponent {

}
