import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class TimeSheetService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }
  getAllListOfMonthsOfYear(params: { year?: number; user_id?: number } = {}) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/TimeSheet/getListOfMonthsForYearOfTimeSheet?year=${params.year}&user_id=${params.user_id}`
    );
  }
  searshTimeSheet(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TimeSheet/searchinListOfMonthsForYearofTimeSheet`,
      data
    );
  }
  getAllListOfTimeSheetForMonth(
    params: { year?: number; user_id?: number; month?: string } = {}
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/TimeSheet/getListOfTimeSheetsForMonthByUser?year=${params.year}&user_id=${params.user_id}&month=${params.month}`
    );
  }
  searchTimeSheetForMonth(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TimeSheet/searchinListOfTimeSheetForMonthByUser`,
      data
    );
  }

  getDetailsTimeSheet(idCra: any, company_id: any, consultant_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/TimeSheet/getDetailsTimeSheet?id=${idCra}&company_id=${company_id}&consultant_id=${consultant_id}`
    );
  }
  getProjetByCandidate(id:any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Project/listProjectsByCandidate?id=${id}`
    );
  }
  addListTimeSheet(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TimeSheet/addTimeSheet`,
      data
    );
  }
  checkAndCreateTimeSheets() {
    return this._http.post<ApiResponse<any>>(this._base + `/TimeSheet/checkAndCreateTimeSheets`, null);
  }
  getListOfTimeSheet(company_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/TypeOfTimeSheet/getListWithoutPaginate?company_id=${company_id}`
    );
  }
  searchCra(idTimeSheet:any, searchTerm: any, company_id: any, userId:any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/TimeSheet/searchInDetailsTimeSheet?id=${idTimeSheet}&company_id=${company_id}&consultant_id=${userId}searchTerm=${searchTerm}`,
      {}
    );
  }
  cancelAndValidateTimeSheet(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/TimeSheet/sendRejectTimeSheetByCansultant`,
      data
    );
  }
  searchTimeSheet(data: any, company_name: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/SpecificTypeOfTimeSheet/searchListWithoutPaginate?company_id=${company_name}`,
      data
    );
  }
}
