import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanyService extends CrudService<ApiResponse<any>, number> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get companies */

  listCompany(data: {
    per_page?: number;
    page?: number;
    sort?: number;
    type?: number;
    sortAttribute?: string;
  }) {
    let sort = '';
    if (data?.sort)
      sort = `&sort=${data.sort}&sortAttribute=${data.sortAttribute}`;
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/Company/getListWithDetailsByType?per_page=${data?.per_page}&page=${data?.page}${sort}&type=${data?.type}`
    );
  }
  /* get company by id */
  // getCompanybyId(id: string) {
  //   return this._http.get<ApiResponse<any>>(this._base + `/Company/getById/${id}`);
  // }

  /* get company by id */
  getCompanybyId(id: string) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Company/getCompanyByIdWithDetails?company_id=${id}`
    );
  }

  /* get  defaultResponse review of company */
  getAllReviewCompany(idCompany: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecifictReview/SpecificReviewsbyUser?company_id=` +
        idCompany
    );
  }
  /* get review candidat */
  getReviewCandidat(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getUsersAvisCompany?company_id=${id}`
    );
  }
  /* get candidat rating  wegestu by id*/
  getCandidatReviewById(idUser: number, company_id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getAvisCompanyWithResponses?user_id=${idUser}&company_id=${company_id}`
    );
  }
  /* get review company */
  getReviewCompany(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/ResponseSpecificReview/getCompanyWithAvis?company_id=${id}`
    );
  }
  /* get all default review  */
  getAllDefaultReview(company_id?: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/SpecificReview/getbyCompany/${company_id}`
    );
  }

  /* block company */
  blockCompany(idCompany: any) {
    console.log('%ccompany.service.ts line:39 object', 'color: #007acc;');
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/blockedCompany`,
      { company_id: idCompany }
    );
  }
  /* get elment of header table list company */
  getHeaderElements(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/getPagesWithSubPagesForUser?role_id=3&interface=3&id=${id}`
    );
  }
  changeDelectedElementHeader(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/UserPageActeur/addRemoveSubPageToUser?role_id=3&interface=3`,
      data
    );
  }
  // sortArray(array: any[], column: string | undefined, direction: number): any[] {
  //   const compareFn = (a: any, b: any): any => {
  //     if (column) {
  //       const aValue = a[column] ? a[column] : a.company[column];
  //       const bValue = b[column] ? b[column] : b.company[column];

  //       if (aValue === bValue) {
  //         return 0;
  //       }
  //       if (direction === 1) {
  //         return aValue < bValue ? -1 : 1;
  //       } else {
  //         return aValue > bValue ? -1 : 1;
  //       }
  //     }
  //   };
  //   return array.sort(compareFn);
  // }

  /* deblock company */
  deblockCompany(idCompany: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/deblockCompany`,
      { company_id: idCompany }
    );
  }

  /* get all candidate reviews by company  */
  getReviewCandidateByCompanyId(per_page?: number, page?: number) {
    return this._http.get<ApiResponse<any>>(this._base + ``);
  }

  /* get list company evaluated by candidate */
  getListCompaniesReviews(params: { per_page?: number; page?: number, sort?: number,sortAttribute?:string, user_id?:any } = {}) {  
    let sort=''
    if (params?.sort) sort = `&sort=${params.sort}&sortAttribute=${params.sortAttribute}`
    return this._http.get<ApiResponse<any>>(this._base + `/ResponseSpecificReview/getResponsesSpecificReviewsCompaniesbyUser?per_page=${params?.per_page}&page=${params?.page}${sort}&user_id=${params?.user_id}`);
  }

  /* get  defaultResponse review of company */
  getAllReviewByCompany(idCompany: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getResponsesbyCompanywithAVGReviews?company_id=` +
        idCompany
    );
  }

  /* add Review Candidate For Company  */
  addReviewCandidateForCompany(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseDefaultReview/add`,
      data
    );
  }

  /* edit review candidate for company */
  editReviewCandidateForCompany(idCompany: any, data: any) {
    return this._http.post<ApiResponse<any>>(this._base + ` `, data);
  }

  /* edit review candidate for company */
  getCandidateReviewForCompany(id_user: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/ResponseDefaultReview/getResponsesbyUser?user_id=`,
      id_user
    );
  }

  /* get Candidate Review For One Company */
  getCandidateReviewForOneCompany(id_company: any, id_user: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getResponsesEvaluateCompany?id=` +
        id_user +
        `&company_id=` +
        id_company
    );
  }

  /* search company */
  searchCompany(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/Company/search`,
      data
    );
  }

  /* search company evaluated */
  searchCompanyreviewd(id: any, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/searchinResponsesSpecificReviewsCompaniesbyUser?user_id=${id}`,
      data
    );
  }

  sortArray(
    array: any[],
    column: string | undefined,
    direction: number
  ): any[] {
    const compareFn = (a: any, b: any): any => {
      if (column) {
        const aValue = this.getValue(a, column);
        const bValue = this.getValue(b, column);

        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return direction * (aValue - bValue);
        } else {
          const aString = String(aValue).toLowerCase();
          const bString = String(bValue).toLowerCase();
          return direction * aString.localeCompare(bString);
        }
      }
      return 0;
    };

    return array.sort(compareFn);
  }

  getValue(obj: any, column: string): any {
    return column.includes('.')
      ? column.split('.').reduce((o, i) => o[i], obj)
      : obj[column];
  }
}
