<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
  <!--begin::Toolbar container-->
  <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
          <!--begin::Title parametre-->
          <div class="d-flex align-items-center">
              <h1 class="page-heading">
                <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g filter="url(#filter0_d_1223_3646)">
                      <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M13.75 3.2834C9.86824 4.28202 7 7.80437 7 11.9996V17.9991C7 18.5519 6.55087 19 6.00104 19C4.8959 19 4 19.8977 4 21C4 22.1046 4.89703 23 6.00495 23H25.9951C27.1024 23 28 22.1023 28 21C28 19.8954 27.0999 19 25.999 19C25.4472 19 25 18.5554 25 17.9991V11.9996C25 7.80603 22.1324 4.28236 18.25 3.28343V2.25043C18.25 1.00462 17.2426 0 16 0C14.7599 0 13.75 1.00755 13.75 2.25043V3.2834ZM12.5 24H19.5C19.5 25.933 17.933 27.5 16 27.5C14.067 27.5 12.5 25.933 12.5 24Z"
                          fill="#265D91" />
                  </g>
                  <defs>
                      <filter id="filter0_d_1223_3646" x="0" y="0" width="32" height="35.5"
                          filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feColorMatrix in="SourceAlpha" type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                          <feOffset dy="4" />
                          <feGaussianBlur stdDeviation="2" />
                          <feComposite in2="hardAlpha" operator="out" />
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1223_3646" />
                          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1223_3646"
                              result="shape" />
                      </filter>
                  </defs>
              </svg>
                  Avis entreprise
              </h1>
              <!--begin::Menu-->
          </div>
          <!--end::Title-->
          <!--begin::Breadcrumb-->
          <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <!--begin::Item-->
              <li class="breadcrumb-item text-muted">
                <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
            </li>
              <!--end::Item-->
              <!--begin::Item-->
              <li class="breadcrumb-item">
                  <span class="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <!--end::Item-->
              <!--begin::Item-->
              <li class="breadcrumb-item text-muted">Noter une entreprise</li>
              <li class="breadcrumb-item">
                  <span class="bullet bg-gray-400 w-5px h-2px"></span>
              </li>
              <!--end::Item-->
              <!--begin::Item-->
              <li class="breadcrumb-item text-muted">Evaluation entreprise</li>

              <!--end::Item-->
              <!--begin::Item-->

              <!--end::Item-->
          </ul>
          <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->

      <!--end::Actions-->
  </div>
  <!--end::Toolbar container-->
</div>
<div id="kt_app_content_container" class="app-container container-fluid">
  <!--begin::Card-->
  <div class="card mb-5 pb-8">
      <div class="container-fluid">
          <div class="title">
              <h4>Evaluation entreprise :  {{companyDetails?.name||'---'}} </h4>
          </div>

      </div>
      <div class="container-fluid">
          <div class="card-body pt-5">
              <!--begin::Table-->

              <div class="reviews">
                  <div class="row review mb-10" *ngFor="let review of listReviews" >
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <!-- <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5"> -->

                              <img *ngIf="review.user.image_url&&review.user.image_url!='avatar.png'" class="table-img mb-5" src="{{url+'/User/file/gallery_users/' + review.user.image_url}}" alt="" width="75px">
                              <img *ngIf="review.user.image_url&&review.user.image_url=='avatar.png'" class="table-img mb-5" src="/assets/media/img/modules/avatar.jpg" alt="" width="75px">
                          
                              <h4>{{ review.user.first_name }}  {{ review.user.last_name }} </h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <rating [(ngModel)]="review.average_rating" [max]="5" [readonly]="true">
                                  </rating>
                              </div>
                              <div class="content">
                                  <span>
                                    {{review.comments ?review.comments :'---'}}
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- <div class="row review mb-10">
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5">
                              <h4>Société ITGATE</h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g filter="url(#filter0_d_3400_1337)">
                                      <path d="M10.9129 2.92705C11.2122 2.00574 12.5156 2.00574 12.815 2.92705L13.854 6.12483C13.9879 6.53686 14.3719 6.81582 14.8051 6.81582H18.1674C19.1362 6.81582 19.5389 8.05543 18.7552 8.62483L16.035 10.6012C15.6845 10.8558 15.5379 11.3072 15.6717 11.7192L16.7108 14.917C17.0101 15.8383 15.9556 16.6044 15.1719 16.035L12.4517 14.0587C12.1012 13.804 11.6266 13.804 11.2762 14.0587L8.55596 16.035C7.77224 16.6044 6.71777 15.8383 7.01712 14.917L8.05614 11.7192C8.19002 11.3072 8.04336 10.8558 7.69287 10.6012L4.97267 8.62483C4.18896 8.05543 4.59174 6.81582 5.56046 6.81582H8.92281C9.35603 6.81582 9.73999 6.53686 9.87386 6.12483L10.9129 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter1_d_3400_1337)">
                                      <path d="M27.9793 2.92705C28.2786 2.00574 29.5821 2.00574 29.8814 2.92705L30.9204 6.12483C31.0543 6.53686 31.4383 6.81582 31.8715 6.81582H35.2338C36.2026 6.81582 36.6053 8.05543 35.8216 8.62483L33.1014 10.6012C32.7509 10.8558 32.6043 11.3072 32.7381 11.7192L33.7772 14.917C34.0765 15.8383 33.022 16.6044 32.2383 16.035L29.5181 14.0587C29.1676 13.804 28.6931 13.804 28.3426 14.0587L25.6224 16.035C24.8387 16.6044 23.7842 15.8383 24.0835 14.917L25.1225 11.7192C25.2564 11.3072 25.1098 10.8558 24.7593 10.6012L22.0391 8.62483C21.2554 8.05543 21.6581 6.81582 22.6269 6.81582H25.9892C26.4224 6.81582 26.8064 6.53686 26.9403 6.12483L27.9793 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter2_d_3400_1337)">
                                      <path d="M45.0496 2.92705C45.349 2.00574 46.6524 2.00574 46.9517 2.92705L47.9907 6.12483C48.1246 6.53686 48.5086 6.81582 48.9418 6.81582H52.3041C53.2729 6.81582 53.6756 8.05543 52.8919 8.62483L50.1717 10.6012C49.8212 10.8558 49.6746 11.3072 49.8085 11.7192L50.8475 14.917C51.1468 15.8383 50.0924 16.6044 49.3086 16.035L46.5884 14.0587C46.238 13.804 45.7634 13.804 45.4129 14.0587L42.6927 16.035C41.909 16.6044 40.8545 15.8383 41.1538 14.917L42.1929 11.7192C42.3267 11.3072 42.1801 10.8558 41.8296 10.6012L39.1094 8.62483C38.3257 8.05543 38.7285 6.81582 39.6972 6.81582H43.0595C43.4928 6.81582 43.8767 6.53686 44.0106 6.12483L45.0496 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter3_d_3400_1337)">
                                      <path d="M62.5294 3.06628C62.8212 2.12935 64.1472 2.12935 64.439 3.06628L65.3877 6.11312C65.5179 6.53109 65.9047 6.81582 66.3425 6.81582H69.5002C70.4586 6.81582 70.8681 8.0337 70.1043 8.61269L67.4775 10.6042C67.1418 10.8587 67.0016 11.2962 67.1268 11.6984L68.1128 14.8647C68.402 15.7935 67.3291 16.5466 66.5538 15.9588L64.0883 14.0896C63.7311 13.8188 63.2373 13.8188 62.8801 14.0897L60.4145 15.9588C59.6393 16.5466 58.5664 15.7935 58.8556 14.8647L59.8415 11.6984C59.9668 11.2962 59.8266 10.8587 59.4909 10.6042L56.8641 8.61269C56.1003 8.0337 56.5098 6.81582 57.4682 6.81582H60.6259C61.0637 6.81582 61.4505 6.53109 61.5807 6.11312L62.5294 3.06628Z" fill="#D6D6D6"/>
                                      </g>
                                      <g filter="url(#filter4_d_3400_1337)">
                                      <path d="M79.1863 2.92705C79.4857 2.00574 80.7891 2.00574 81.0884 2.92705L82.1275 6.12483C82.2613 6.53686 82.6453 6.81582 83.0785 6.81582H86.4409C87.4096 6.81582 87.8124 8.05543 87.0286 8.62483L84.3085 10.6012C83.958 10.8558 83.8113 11.3072 83.9452 11.7192L84.9842 14.917C85.2836 15.8383 84.2291 16.6044 83.4454 16.035L80.7252 14.0587C80.3747 13.804 79.9001 13.804 79.5496 14.0587L76.8294 16.035C76.0457 16.6044 74.9912 15.8383 75.2906 14.917L76.3296 11.7192C76.4635 11.3072 76.3168 10.8558 75.9663 10.6012L73.2461 8.62483C72.4624 8.05543 72.8652 6.81582 73.8339 6.81582H77.1962C77.6295 6.81582 78.0134 6.53686 78.1473 6.12483L79.1863 2.92705Z" fill="#D6D6D6"/>
                                      </g>
                                      <defs>
                                      <filter id="filter0_d_3400_1337" x="0.558594" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter1_d_3400_1337" x="17.625" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter2_d_3400_1337" x="34.6953" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter3_d_3400_1337" x="52.4648" y="2.36377" width="22.0391" height="21.8027" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter4_d_3400_1337" x="68.832" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      </defs>
                                      </svg>
                                      
                              </div>
                              <div class="content">
                                  <span>
                                      Lorem ipsum dolor sit amet, jtkjwykudytrqsdg consectetur adipiscing elit adipiscing elit
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row review mb-10">
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5">
                              <h4>Société ITGATE</h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g filter="url(#filter0_d_3400_1337)">
                                      <path d="M10.9129 2.92705C11.2122 2.00574 12.5156 2.00574 12.815 2.92705L13.854 6.12483C13.9879 6.53686 14.3719 6.81582 14.8051 6.81582H18.1674C19.1362 6.81582 19.5389 8.05543 18.7552 8.62483L16.035 10.6012C15.6845 10.8558 15.5379 11.3072 15.6717 11.7192L16.7108 14.917C17.0101 15.8383 15.9556 16.6044 15.1719 16.035L12.4517 14.0587C12.1012 13.804 11.6266 13.804 11.2762 14.0587L8.55596 16.035C7.77224 16.6044 6.71777 15.8383 7.01712 14.917L8.05614 11.7192C8.19002 11.3072 8.04336 10.8558 7.69287 10.6012L4.97267 8.62483C4.18896 8.05543 4.59174 6.81582 5.56046 6.81582H8.92281C9.35603 6.81582 9.73999 6.53686 9.87386 6.12483L10.9129 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter1_d_3400_1337)">
                                      <path d="M27.9793 2.92705C28.2786 2.00574 29.5821 2.00574 29.8814 2.92705L30.9204 6.12483C31.0543 6.53686 31.4383 6.81582 31.8715 6.81582H35.2338C36.2026 6.81582 36.6053 8.05543 35.8216 8.62483L33.1014 10.6012C32.7509 10.8558 32.6043 11.3072 32.7381 11.7192L33.7772 14.917C34.0765 15.8383 33.022 16.6044 32.2383 16.035L29.5181 14.0587C29.1676 13.804 28.6931 13.804 28.3426 14.0587L25.6224 16.035C24.8387 16.6044 23.7842 15.8383 24.0835 14.917L25.1225 11.7192C25.2564 11.3072 25.1098 10.8558 24.7593 10.6012L22.0391 8.62483C21.2554 8.05543 21.6581 6.81582 22.6269 6.81582H25.9892C26.4224 6.81582 26.8064 6.53686 26.9403 6.12483L27.9793 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter2_d_3400_1337)">
                                      <path d="M45.0496 2.92705C45.349 2.00574 46.6524 2.00574 46.9517 2.92705L47.9907 6.12483C48.1246 6.53686 48.5086 6.81582 48.9418 6.81582H52.3041C53.2729 6.81582 53.6756 8.05543 52.8919 8.62483L50.1717 10.6012C49.8212 10.8558 49.6746 11.3072 49.8085 11.7192L50.8475 14.917C51.1468 15.8383 50.0924 16.6044 49.3086 16.035L46.5884 14.0587C46.238 13.804 45.7634 13.804 45.4129 14.0587L42.6927 16.035C41.909 16.6044 40.8545 15.8383 41.1538 14.917L42.1929 11.7192C42.3267 11.3072 42.1801 10.8558 41.8296 10.6012L39.1094 8.62483C38.3257 8.05543 38.7285 6.81582 39.6972 6.81582H43.0595C43.4928 6.81582 43.8767 6.53686 44.0106 6.12483L45.0496 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter3_d_3400_1337)">
                                      <path d="M62.5294 3.06628C62.8212 2.12935 64.1472 2.12935 64.439 3.06628L65.3877 6.11312C65.5179 6.53109 65.9047 6.81582 66.3425 6.81582H69.5002C70.4586 6.81582 70.8681 8.0337 70.1043 8.61269L67.4775 10.6042C67.1418 10.8587 67.0016 11.2962 67.1268 11.6984L68.1128 14.8647C68.402 15.7935 67.3291 16.5466 66.5538 15.9588L64.0883 14.0896C63.7311 13.8188 63.2373 13.8188 62.8801 14.0897L60.4145 15.9588C59.6393 16.5466 58.5664 15.7935 58.8556 14.8647L59.8415 11.6984C59.9668 11.2962 59.8266 10.8587 59.4909 10.6042L56.8641 8.61269C56.1003 8.0337 56.5098 6.81582 57.4682 6.81582H60.6259C61.0637 6.81582 61.4505 6.53109 61.5807 6.11312L62.5294 3.06628Z" fill="#D6D6D6"/>
                                      </g>
                                      <g filter="url(#filter4_d_3400_1337)">
                                      <path d="M79.1863 2.92705C79.4857 2.00574 80.7891 2.00574 81.0884 2.92705L82.1275 6.12483C82.2613 6.53686 82.6453 6.81582 83.0785 6.81582H86.4409C87.4096 6.81582 87.8124 8.05543 87.0286 8.62483L84.3085 10.6012C83.958 10.8558 83.8113 11.3072 83.9452 11.7192L84.9842 14.917C85.2836 15.8383 84.2291 16.6044 83.4454 16.035L80.7252 14.0587C80.3747 13.804 79.9001 13.804 79.5496 14.0587L76.8294 16.035C76.0457 16.6044 74.9912 15.8383 75.2906 14.917L76.3296 11.7192C76.4635 11.3072 76.3168 10.8558 75.9663 10.6012L73.2461 8.62483C72.4624 8.05543 72.8652 6.81582 73.8339 6.81582H77.1962C77.6295 6.81582 78.0134 6.53686 78.1473 6.12483L79.1863 2.92705Z" fill="#D6D6D6"/>
                                      </g>
                                      <defs>
                                      <filter id="filter0_d_3400_1337" x="0.558594" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter1_d_3400_1337" x="17.625" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter2_d_3400_1337" x="34.6953" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter3_d_3400_1337" x="52.4648" y="2.36377" width="22.0391" height="21.8027" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter4_d_3400_1337" x="68.832" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      </defs>
                                      </svg>
                                      
                              </div>
                              <div class="content">
                                  <span>
                                      Lorem ipsum dolor sit amet, jtkjwykudytrqsdg consectetur adipiscing elit adipiscing elit
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                   <div class="row review mb-10">
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5">
                              <h4>Société ITGATE</h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g filter="url(#filter0_d_3400_1337)">
                                      <path d="M10.9129 2.92705C11.2122 2.00574 12.5156 2.00574 12.815 2.92705L13.854 6.12483C13.9879 6.53686 14.3719 6.81582 14.8051 6.81582H18.1674C19.1362 6.81582 19.5389 8.05543 18.7552 8.62483L16.035 10.6012C15.6845 10.8558 15.5379 11.3072 15.6717 11.7192L16.7108 14.917C17.0101 15.8383 15.9556 16.6044 15.1719 16.035L12.4517 14.0587C12.1012 13.804 11.6266 13.804 11.2762 14.0587L8.55596 16.035C7.77224 16.6044 6.71777 15.8383 7.01712 14.917L8.05614 11.7192C8.19002 11.3072 8.04336 10.8558 7.69287 10.6012L4.97267 8.62483C4.18896 8.05543 4.59174 6.81582 5.56046 6.81582H8.92281C9.35603 6.81582 9.73999 6.53686 9.87386 6.12483L10.9129 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter1_d_3400_1337)">
                                      <path d="M27.9793 2.92705C28.2786 2.00574 29.5821 2.00574 29.8814 2.92705L30.9204 6.12483C31.0543 6.53686 31.4383 6.81582 31.8715 6.81582H35.2338C36.2026 6.81582 36.6053 8.05543 35.8216 8.62483L33.1014 10.6012C32.7509 10.8558 32.6043 11.3072 32.7381 11.7192L33.7772 14.917C34.0765 15.8383 33.022 16.6044 32.2383 16.035L29.5181 14.0587C29.1676 13.804 28.6931 13.804 28.3426 14.0587L25.6224 16.035C24.8387 16.6044 23.7842 15.8383 24.0835 14.917L25.1225 11.7192C25.2564 11.3072 25.1098 10.8558 24.7593 10.6012L22.0391 8.62483C21.2554 8.05543 21.6581 6.81582 22.6269 6.81582H25.9892C26.4224 6.81582 26.8064 6.53686 26.9403 6.12483L27.9793 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter2_d_3400_1337)">
                                      <path d="M45.0496 2.92705C45.349 2.00574 46.6524 2.00574 46.9517 2.92705L47.9907 6.12483C48.1246 6.53686 48.5086 6.81582 48.9418 6.81582H52.3041C53.2729 6.81582 53.6756 8.05543 52.8919 8.62483L50.1717 10.6012C49.8212 10.8558 49.6746 11.3072 49.8085 11.7192L50.8475 14.917C51.1468 15.8383 50.0924 16.6044 49.3086 16.035L46.5884 14.0587C46.238 13.804 45.7634 13.804 45.4129 14.0587L42.6927 16.035C41.909 16.6044 40.8545 15.8383 41.1538 14.917L42.1929 11.7192C42.3267 11.3072 42.1801 10.8558 41.8296 10.6012L39.1094 8.62483C38.3257 8.05543 38.7285 6.81582 39.6972 6.81582H43.0595C43.4928 6.81582 43.8767 6.53686 44.0106 6.12483L45.0496 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter3_d_3400_1337)">
                                      <path d="M62.5294 3.06628C62.8212 2.12935 64.1472 2.12935 64.439 3.06628L65.3877 6.11312C65.5179 6.53109 65.9047 6.81582 66.3425 6.81582H69.5002C70.4586 6.81582 70.8681 8.0337 70.1043 8.61269L67.4775 10.6042C67.1418 10.8587 67.0016 11.2962 67.1268 11.6984L68.1128 14.8647C68.402 15.7935 67.3291 16.5466 66.5538 15.9588L64.0883 14.0896C63.7311 13.8188 63.2373 13.8188 62.8801 14.0897L60.4145 15.9588C59.6393 16.5466 58.5664 15.7935 58.8556 14.8647L59.8415 11.6984C59.9668 11.2962 59.8266 10.8587 59.4909 10.6042L56.8641 8.61269C56.1003 8.0337 56.5098 6.81582 57.4682 6.81582H60.6259C61.0637 6.81582 61.4505 6.53109 61.5807 6.11312L62.5294 3.06628Z" fill="#D6D6D6"/>
                                      </g>
                                      <g filter="url(#filter4_d_3400_1337)">
                                      <path d="M79.1863 2.92705C79.4857 2.00574 80.7891 2.00574 81.0884 2.92705L82.1275 6.12483C82.2613 6.53686 82.6453 6.81582 83.0785 6.81582H86.4409C87.4096 6.81582 87.8124 8.05543 87.0286 8.62483L84.3085 10.6012C83.958 10.8558 83.8113 11.3072 83.9452 11.7192L84.9842 14.917C85.2836 15.8383 84.2291 16.6044 83.4454 16.035L80.7252 14.0587C80.3747 13.804 79.9001 13.804 79.5496 14.0587L76.8294 16.035C76.0457 16.6044 74.9912 15.8383 75.2906 14.917L76.3296 11.7192C76.4635 11.3072 76.3168 10.8558 75.9663 10.6012L73.2461 8.62483C72.4624 8.05543 72.8652 6.81582 73.8339 6.81582H77.1962C77.6295 6.81582 78.0134 6.53686 78.1473 6.12483L79.1863 2.92705Z" fill="#D6D6D6"/>
                                      </g>
                                      <defs>
                                      <filter id="filter0_d_3400_1337" x="0.558594" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter1_d_3400_1337" x="17.625" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter2_d_3400_1337" x="34.6953" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter3_d_3400_1337" x="52.4648" y="2.36377" width="22.0391" height="21.8027" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter4_d_3400_1337" x="68.832" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      </defs>
                                      </svg>
                                      
                              </div>
                              <div class="content">
                                  <span>
                                      Lorem ipsum dolor sit amet, jtkjwykudytrqsdg consectetur adipiscing elit adipiscing elit
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                   <div class="row review mb-10">
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5">
                              <h4>Société ITGATE</h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g filter="url(#filter0_d_3400_1337)">
                                      <path d="M10.9129 2.92705C11.2122 2.00574 12.5156 2.00574 12.815 2.92705L13.854 6.12483C13.9879 6.53686 14.3719 6.81582 14.8051 6.81582H18.1674C19.1362 6.81582 19.5389 8.05543 18.7552 8.62483L16.035 10.6012C15.6845 10.8558 15.5379 11.3072 15.6717 11.7192L16.7108 14.917C17.0101 15.8383 15.9556 16.6044 15.1719 16.035L12.4517 14.0587C12.1012 13.804 11.6266 13.804 11.2762 14.0587L8.55596 16.035C7.77224 16.6044 6.71777 15.8383 7.01712 14.917L8.05614 11.7192C8.19002 11.3072 8.04336 10.8558 7.69287 10.6012L4.97267 8.62483C4.18896 8.05543 4.59174 6.81582 5.56046 6.81582H8.92281C9.35603 6.81582 9.73999 6.53686 9.87386 6.12483L10.9129 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter1_d_3400_1337)">
                                      <path d="M27.9793 2.92705C28.2786 2.00574 29.5821 2.00574 29.8814 2.92705L30.9204 6.12483C31.0543 6.53686 31.4383 6.81582 31.8715 6.81582H35.2338C36.2026 6.81582 36.6053 8.05543 35.8216 8.62483L33.1014 10.6012C32.7509 10.8558 32.6043 11.3072 32.7381 11.7192L33.7772 14.917C34.0765 15.8383 33.022 16.6044 32.2383 16.035L29.5181 14.0587C29.1676 13.804 28.6931 13.804 28.3426 14.0587L25.6224 16.035C24.8387 16.6044 23.7842 15.8383 24.0835 14.917L25.1225 11.7192C25.2564 11.3072 25.1098 10.8558 24.7593 10.6012L22.0391 8.62483C21.2554 8.05543 21.6581 6.81582 22.6269 6.81582H25.9892C26.4224 6.81582 26.8064 6.53686 26.9403 6.12483L27.9793 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter2_d_3400_1337)">
                                      <path d="M45.0496 2.92705C45.349 2.00574 46.6524 2.00574 46.9517 2.92705L47.9907 6.12483C48.1246 6.53686 48.5086 6.81582 48.9418 6.81582H52.3041C53.2729 6.81582 53.6756 8.05543 52.8919 8.62483L50.1717 10.6012C49.8212 10.8558 49.6746 11.3072 49.8085 11.7192L50.8475 14.917C51.1468 15.8383 50.0924 16.6044 49.3086 16.035L46.5884 14.0587C46.238 13.804 45.7634 13.804 45.4129 14.0587L42.6927 16.035C41.909 16.6044 40.8545 15.8383 41.1538 14.917L42.1929 11.7192C42.3267 11.3072 42.1801 10.8558 41.8296 10.6012L39.1094 8.62483C38.3257 8.05543 38.7285 6.81582 39.6972 6.81582H43.0595C43.4928 6.81582 43.8767 6.53686 44.0106 6.12483L45.0496 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter3_d_3400_1337)">
                                      <path d="M62.5294 3.06628C62.8212 2.12935 64.1472 2.12935 64.439 3.06628L65.3877 6.11312C65.5179 6.53109 65.9047 6.81582 66.3425 6.81582H69.5002C70.4586 6.81582 70.8681 8.0337 70.1043 8.61269L67.4775 10.6042C67.1418 10.8587 67.0016 11.2962 67.1268 11.6984L68.1128 14.8647C68.402 15.7935 67.3291 16.5466 66.5538 15.9588L64.0883 14.0896C63.7311 13.8188 63.2373 13.8188 62.8801 14.0897L60.4145 15.9588C59.6393 16.5466 58.5664 15.7935 58.8556 14.8647L59.8415 11.6984C59.9668 11.2962 59.8266 10.8587 59.4909 10.6042L56.8641 8.61269C56.1003 8.0337 56.5098 6.81582 57.4682 6.81582H60.6259C61.0637 6.81582 61.4505 6.53109 61.5807 6.11312L62.5294 3.06628Z" fill="#D6D6D6"/>
                                      </g>
                                      <g filter="url(#filter4_d_3400_1337)">
                                      <path d="M79.1863 2.92705C79.4857 2.00574 80.7891 2.00574 81.0884 2.92705L82.1275 6.12483C82.2613 6.53686 82.6453 6.81582 83.0785 6.81582H86.4409C87.4096 6.81582 87.8124 8.05543 87.0286 8.62483L84.3085 10.6012C83.958 10.8558 83.8113 11.3072 83.9452 11.7192L84.9842 14.917C85.2836 15.8383 84.2291 16.6044 83.4454 16.035L80.7252 14.0587C80.3747 13.804 79.9001 13.804 79.5496 14.0587L76.8294 16.035C76.0457 16.6044 74.9912 15.8383 75.2906 14.917L76.3296 11.7192C76.4635 11.3072 76.3168 10.8558 75.9663 10.6012L73.2461 8.62483C72.4624 8.05543 72.8652 6.81582 73.8339 6.81582H77.1962C77.6295 6.81582 78.0134 6.53686 78.1473 6.12483L79.1863 2.92705Z" fill="#D6D6D6"/>
                                      </g>
                                      <defs>
                                      <filter id="filter0_d_3400_1337" x="0.558594" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter1_d_3400_1337" x="17.625" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter2_d_3400_1337" x="34.6953" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter3_d_3400_1337" x="52.4648" y="2.36377" width="22.0391" height="21.8027" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter4_d_3400_1337" x="68.832" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      </defs>
                                      </svg>
                                      
                              </div>
                              <div class="content">
                                  <span>
                                      Lorem ipsum dolor sit amet, jtkjwykudytrqsdg consectetur adipiscing elit adipiscing elit
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row review mb-10">
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5">
                              <h4>Société ITGATE</h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g filter="url(#filter0_d_3400_1337)">
                                      <path d="M10.9129 2.92705C11.2122 2.00574 12.5156 2.00574 12.815 2.92705L13.854 6.12483C13.9879 6.53686 14.3719 6.81582 14.8051 6.81582H18.1674C19.1362 6.81582 19.5389 8.05543 18.7552 8.62483L16.035 10.6012C15.6845 10.8558 15.5379 11.3072 15.6717 11.7192L16.7108 14.917C17.0101 15.8383 15.9556 16.6044 15.1719 16.035L12.4517 14.0587C12.1012 13.804 11.6266 13.804 11.2762 14.0587L8.55596 16.035C7.77224 16.6044 6.71777 15.8383 7.01712 14.917L8.05614 11.7192C8.19002 11.3072 8.04336 10.8558 7.69287 10.6012L4.97267 8.62483C4.18896 8.05543 4.59174 6.81582 5.56046 6.81582H8.92281C9.35603 6.81582 9.73999 6.53686 9.87386 6.12483L10.9129 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter1_d_3400_1337)">
                                      <path d="M27.9793 2.92705C28.2786 2.00574 29.5821 2.00574 29.8814 2.92705L30.9204 6.12483C31.0543 6.53686 31.4383 6.81582 31.8715 6.81582H35.2338C36.2026 6.81582 36.6053 8.05543 35.8216 8.62483L33.1014 10.6012C32.7509 10.8558 32.6043 11.3072 32.7381 11.7192L33.7772 14.917C34.0765 15.8383 33.022 16.6044 32.2383 16.035L29.5181 14.0587C29.1676 13.804 28.6931 13.804 28.3426 14.0587L25.6224 16.035C24.8387 16.6044 23.7842 15.8383 24.0835 14.917L25.1225 11.7192C25.2564 11.3072 25.1098 10.8558 24.7593 10.6012L22.0391 8.62483C21.2554 8.05543 21.6581 6.81582 22.6269 6.81582H25.9892C26.4224 6.81582 26.8064 6.53686 26.9403 6.12483L27.9793 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter2_d_3400_1337)">
                                      <path d="M45.0496 2.92705C45.349 2.00574 46.6524 2.00574 46.9517 2.92705L47.9907 6.12483C48.1246 6.53686 48.5086 6.81582 48.9418 6.81582H52.3041C53.2729 6.81582 53.6756 8.05543 52.8919 8.62483L50.1717 10.6012C49.8212 10.8558 49.6746 11.3072 49.8085 11.7192L50.8475 14.917C51.1468 15.8383 50.0924 16.6044 49.3086 16.035L46.5884 14.0587C46.238 13.804 45.7634 13.804 45.4129 14.0587L42.6927 16.035C41.909 16.6044 40.8545 15.8383 41.1538 14.917L42.1929 11.7192C42.3267 11.3072 42.1801 10.8558 41.8296 10.6012L39.1094 8.62483C38.3257 8.05543 38.7285 6.81582 39.6972 6.81582H43.0595C43.4928 6.81582 43.8767 6.53686 44.0106 6.12483L45.0496 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter3_d_3400_1337)">
                                      <path d="M62.5294 3.06628C62.8212 2.12935 64.1472 2.12935 64.439 3.06628L65.3877 6.11312C65.5179 6.53109 65.9047 6.81582 66.3425 6.81582H69.5002C70.4586 6.81582 70.8681 8.0337 70.1043 8.61269L67.4775 10.6042C67.1418 10.8587 67.0016 11.2962 67.1268 11.6984L68.1128 14.8647C68.402 15.7935 67.3291 16.5466 66.5538 15.9588L64.0883 14.0896C63.7311 13.8188 63.2373 13.8188 62.8801 14.0897L60.4145 15.9588C59.6393 16.5466 58.5664 15.7935 58.8556 14.8647L59.8415 11.6984C59.9668 11.2962 59.8266 10.8587 59.4909 10.6042L56.8641 8.61269C56.1003 8.0337 56.5098 6.81582 57.4682 6.81582H60.6259C61.0637 6.81582 61.4505 6.53109 61.5807 6.11312L62.5294 3.06628Z" fill="#D6D6D6"/>
                                      </g>
                                      <g filter="url(#filter4_d_3400_1337)">
                                      <path d="M79.1863 2.92705C79.4857 2.00574 80.7891 2.00574 81.0884 2.92705L82.1275 6.12483C82.2613 6.53686 82.6453 6.81582 83.0785 6.81582H86.4409C87.4096 6.81582 87.8124 8.05543 87.0286 8.62483L84.3085 10.6012C83.958 10.8558 83.8113 11.3072 83.9452 11.7192L84.9842 14.917C85.2836 15.8383 84.2291 16.6044 83.4454 16.035L80.7252 14.0587C80.3747 13.804 79.9001 13.804 79.5496 14.0587L76.8294 16.035C76.0457 16.6044 74.9912 15.8383 75.2906 14.917L76.3296 11.7192C76.4635 11.3072 76.3168 10.8558 75.9663 10.6012L73.2461 8.62483C72.4624 8.05543 72.8652 6.81582 73.8339 6.81582H77.1962C77.6295 6.81582 78.0134 6.53686 78.1473 6.12483L79.1863 2.92705Z" fill="#D6D6D6"/>
                                      </g>
                                      <defs>
                                      <filter id="filter0_d_3400_1337" x="0.558594" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter1_d_3400_1337" x="17.625" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter2_d_3400_1337" x="34.6953" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter3_d_3400_1337" x="52.4648" y="2.36377" width="22.0391" height="21.8027" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter4_d_3400_1337" x="68.832" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      </defs>
                                      </svg>
                                      
                              </div>
                              <div class="content">
                                  <span>
                                      Lorem ipsum dolor sit amet, jtkjwykudytrqsdg consectetur adipiscing elit adipiscing elit
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div class="row review mb-10">
                      <div class="col-lg-3 " style="margin:auto">
                          <div class="avatar">
                              <img src="/assets/media/avatars/300-1.jpg" alt="" width="100px" class="mb-5">
                              <h4>Société ITGATE</h4>
                          </div>
                      </div>
                      <div class="col-lg-9">  
                          <div class="comment">
                              <div class="header">
                                  <h4>Commentaire</h4>
                                  <svg width="92" height="25" viewBox="0 0 92 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <g filter="url(#filter0_d_3400_1337)">
                                      <path d="M10.9129 2.92705C11.2122 2.00574 12.5156 2.00574 12.815 2.92705L13.854 6.12483C13.9879 6.53686 14.3719 6.81582 14.8051 6.81582H18.1674C19.1362 6.81582 19.5389 8.05543 18.7552 8.62483L16.035 10.6012C15.6845 10.8558 15.5379 11.3072 15.6717 11.7192L16.7108 14.917C17.0101 15.8383 15.9556 16.6044 15.1719 16.035L12.4517 14.0587C12.1012 13.804 11.6266 13.804 11.2762 14.0587L8.55596 16.035C7.77224 16.6044 6.71777 15.8383 7.01712 14.917L8.05614 11.7192C8.19002 11.3072 8.04336 10.8558 7.69287 10.6012L4.97267 8.62483C4.18896 8.05543 4.59174 6.81582 5.56046 6.81582H8.92281C9.35603 6.81582 9.73999 6.53686 9.87386 6.12483L10.9129 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter1_d_3400_1337)">
                                      <path d="M27.9793 2.92705C28.2786 2.00574 29.5821 2.00574 29.8814 2.92705L30.9204 6.12483C31.0543 6.53686 31.4383 6.81582 31.8715 6.81582H35.2338C36.2026 6.81582 36.6053 8.05543 35.8216 8.62483L33.1014 10.6012C32.7509 10.8558 32.6043 11.3072 32.7381 11.7192L33.7772 14.917C34.0765 15.8383 33.022 16.6044 32.2383 16.035L29.5181 14.0587C29.1676 13.804 28.6931 13.804 28.3426 14.0587L25.6224 16.035C24.8387 16.6044 23.7842 15.8383 24.0835 14.917L25.1225 11.7192C25.2564 11.3072 25.1098 10.8558 24.7593 10.6012L22.0391 8.62483C21.2554 8.05543 21.6581 6.81582 22.6269 6.81582H25.9892C26.4224 6.81582 26.8064 6.53686 26.9403 6.12483L27.9793 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter2_d_3400_1337)">
                                      <path d="M45.0496 2.92705C45.349 2.00574 46.6524 2.00574 46.9517 2.92705L47.9907 6.12483C48.1246 6.53686 48.5086 6.81582 48.9418 6.81582H52.3041C53.2729 6.81582 53.6756 8.05543 52.8919 8.62483L50.1717 10.6012C49.8212 10.8558 49.6746 11.3072 49.8085 11.7192L50.8475 14.917C51.1468 15.8383 50.0924 16.6044 49.3086 16.035L46.5884 14.0587C46.238 13.804 45.7634 13.804 45.4129 14.0587L42.6927 16.035C41.909 16.6044 40.8545 15.8383 41.1538 14.917L42.1929 11.7192C42.3267 11.3072 42.1801 10.8558 41.8296 10.6012L39.1094 8.62483C38.3257 8.05543 38.7285 6.81582 39.6972 6.81582H43.0595C43.4928 6.81582 43.8767 6.53686 44.0106 6.12483L45.0496 2.92705Z" fill="#FFD166"/>
                                      </g>
                                      <g filter="url(#filter3_d_3400_1337)">
                                      <path d="M62.5294 3.06628C62.8212 2.12935 64.1472 2.12935 64.439 3.06628L65.3877 6.11312C65.5179 6.53109 65.9047 6.81582 66.3425 6.81582H69.5002C70.4586 6.81582 70.8681 8.0337 70.1043 8.61269L67.4775 10.6042C67.1418 10.8587 67.0016 11.2962 67.1268 11.6984L68.1128 14.8647C68.402 15.7935 67.3291 16.5466 66.5538 15.9588L64.0883 14.0896C63.7311 13.8188 63.2373 13.8188 62.8801 14.0897L60.4145 15.9588C59.6393 16.5466 58.5664 15.7935 58.8556 14.8647L59.8415 11.6984C59.9668 11.2962 59.8266 10.8587 59.4909 10.6042L56.8641 8.61269C56.1003 8.0337 56.5098 6.81582 57.4682 6.81582H60.6259C61.0637 6.81582 61.4505 6.53109 61.5807 6.11312L62.5294 3.06628Z" fill="#D6D6D6"/>
                                      </g>
                                      <g filter="url(#filter4_d_3400_1337)">
                                      <path d="M79.1863 2.92705C79.4857 2.00574 80.7891 2.00574 81.0884 2.92705L82.1275 6.12483C82.2613 6.53686 82.6453 6.81582 83.0785 6.81582H86.4409C87.4096 6.81582 87.8124 8.05543 87.0286 8.62483L84.3085 10.6012C83.958 10.8558 83.8113 11.3072 83.9452 11.7192L84.9842 14.917C85.2836 15.8383 84.2291 16.6044 83.4454 16.035L80.7252 14.0587C80.3747 13.804 79.9001 13.804 79.5496 14.0587L76.8294 16.035C76.0457 16.6044 74.9912 15.8383 75.2906 14.917L76.3296 11.7192C76.4635 11.3072 76.3168 10.8558 75.9663 10.6012L73.2461 8.62483C72.4624 8.05543 72.8652 6.81582 73.8339 6.81582H77.1962C77.6295 6.81582 78.0134 6.53686 78.1473 6.12483L79.1863 2.92705Z" fill="#D6D6D6"/>
                                      </g>
                                      <defs>
                                      <filter id="filter0_d_3400_1337" x="0.558594" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter1_d_3400_1337" x="17.625" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter2_d_3400_1337" x="34.6953" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter3_d_3400_1337" x="52.4648" y="2.36377" width="22.0391" height="21.8027" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      <filter id="filter4_d_3400_1337" x="68.832" y="2.23584" width="22.6094" height="21.9946" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                      <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                                      <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                      <feOffset dy="4"/>
                                      <feGaussianBlur stdDeviation="2"/>
                                      <feComposite in2="hardAlpha" operator="out"/>
                                      <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                                      <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3400_1337"/>
                                      <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3400_1337" result="shape"/>
                                      </filter>
                                      </defs>
                                      </svg>
                                      
                              </div>
                              <div class="content">
                                  <span>
                                      Lorem ipsum dolor sit amet, jtkjwykudytrqsdg consectetur adipiscing elit adipiscing elit
                                  </span>
                              </div>
                          </div>
                      </div>
                  </div> -->
              </div>

              <!--end::Table-->
              <div class="pagination" *ngIf="listReviews && listReviews.length">
                <app-pagination [data]="listReviews" [lengthData]="totalItems" (setPages)="getItems($event)">
                </app-pagination>
            </div>

           

            <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listReviews&&!listReviews.length">
                <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                <span class="empty-list">
                 La liste est vide
             </span>
             </div> 


          </div>
          <!--end::Card body-->
      </div>
  </div>
</div>
<div class="modal fade" id="add_update_Activitie_Type" tabindex="-1" aria-hidden="true">
  <!--begin::Modal dialog-->
  <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content">
          <!--begin::Modal header-->
          <div class="modal-header">
              <!--begin::Modal title-->
              <h2 class="">Type d’activité</h2>
              <!--end::Modal title-->
              <!--begin::Close-->
              <div id="kt_customers_export_close" data-bs-dismiss="modal"
                  class="btn btn-icon btn-sm btn-active-icon-primary">
                  <i class="ki-duotone ki-cross fs-1">
                      <span class="path1"></span>
                      <span class="path2"></span>
                  </i>
              </div>
          </div>
          <div class="modal-body">
              <!--begin::Form-->
              <form id="kt_customers_export_form" class="form tools" action="#">
                  <!--begin::Input group-->
                  <!--begin::Label-->
                  <div class="mb-5">
                      <label for="question" class="form-label">Nom du type d’activité</label>
                      <input type="text" class="form-control" placeholder="Nom du type d’activité" />
                  </div>
              </form>
              <div class="text-end ">
                  <button type="reset" id="kt_customers_export_cancel" data-bs-dismiss="modal"
                      class="footer-btn annuler me-3">Annuler</button>
                  <button type="submit" id="kt_customers_export_submit" class="footer-btn confirmer ">
                      <span class="indicator-label">Confirmer</span>
                      <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                  </button>
              </div>
              <!--end::Form-->
          </div>

          <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
  </div>
  <!--end::Modal dialog-->
</div>

<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
