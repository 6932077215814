import { Component, TemplateRef } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import {
  applySuccess,
  serverError,
} from '../../../../shared/shared-models/message';
import { JobsService } from '../../services/jobs.service';
import { environment } from '../../../../../environnements/environment';
import { LocalStorageService } from '../../../../shared/shared-services/local-storage.service';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Location } from '@angular/common';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: 'app-details-job-offer',
  templateUrl: './details-job-offer.component.html',
  styleUrls: ['./details-job-offer.component.css'],
})
export class DetailsJobOfferComponent {
  colorsHeader: string[] = [
    '#FBF2E9',
    '#E8F6FC',
    '#FDEFEE',
    '#FFF3D7',
    '#FBF2E9',
  ];
  /* number */
  idRequestJob!: any;
  /* object */
  jobRequest!: any;
  /* string */
  url: string = environment.baseUrl + '/api';
  stateTest: any;
  id: any;
  currentUser: any;
  jobSkills: any;
  idJobs: any;
  state: any;
  postuled: any;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  /* colors skills */
  colors = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];
  /* modal */
  modalRef?: BsModalRef;
  applyForm: FormGroup = this.createForm()
  mode: string = ''
  safeDescription!: SafeHtml;
  constructor(private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private jobOffreService: JobsService,
    private toastrService: ToastrService,
    private toastr: ToastrService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private location: Location,
    private sanitizer: DomSanitizer) { }
  ngOnInit() {
    this.getUser();
    this.getIdRequestJob();
    this.getSkillsJob();
  }
  /* get request job id from route */
  getIdRequestJob() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idRequestJob = params.params['id'];
        this.getRequestJob();
      },
    });
  }

  getUser() {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.stateTest = JSON.parse(this.currentUser).stateTest;
  }
  /* Open and close modal */
  openModal(
    template: TemplateRef<any>,
    idJobs: any,
    state: any,
    postuled: any
  ) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
    this.idJobs = idJobs;
    this.state = state;
    this.postuled = postuled;
    if (this.mode == 'apply') {
      this.applyCandidateJob(this.idJobs, this.state, this.postuled, this.mode);
    }
  }
  closeModal() {
    this.modalRef!.hide();
    this.applyForm.reset();
  }
  /* get request job by id */
  getRequestJob() {
    this.spinner.show();
    this.jobOffreService.getJobOfferbyId(this.idRequestJob).subscribe({
      next: (res: any) => {
        this.spinner.hide()
        this.jobRequest = res.data[0]
        this.updateDescription(this.jobRequest.description);
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }
  updateDescription(description: string) {
    this.safeDescription = this.sanitizer.bypassSecurityTrustHtml(description);
  } 

  showTestConfirmation() {
    Swal.fire({
      title: 'Pourriez-vous passer un test pour cette offre d’emploi?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate([
          '/test/welcome-test',
          { idOffre: this.idRequestJob },
        ]);
      } else {
        
      }
    });
  }
  createTest() {
    const data = { job_offer_id: this.idRequestJob };
    this.jobOffreService.createTestEsn(data).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.showTestConfirmation();
      },
      error: () => {
        this.spinner.hide();
      },
    });
  }
  createForm(data?: any) {
    return this.formBuilder.group({
      letter: [data ? data.letter : ''],
      salary: [data ? data.salary : ''],
      tjm: [data ? data.tjm : ''],
    });
  }
  applyCandidateJob(idJobs: any, state: any, postuled: any, mode: string) {
    this.currentUser = this.localStorageService.getData('UserInfo');
    this.id = JSON.parse(this.currentUser).id;
    const data = { job_offer_id: idJobs, user_id: this.id };
    if (state === 0) {
      if (postuled === 0) {
        this.jobOffreService
          .applyCandidateJobOffer({ ...data, ...this.applyForm.value })
          .subscribe({
            next: (res: any) => {
              this.spinner.hide();
              this.toastr.success(applySuccess);
              this.getRequestJob();
              this.closeModal();
              setTimeout(() => {
                this.createTest();
              }, 3000);
            },
            error: () => {
              this.spinner.hide();
              this.toastr.error(serverError);
            },
          });
      } else {
        this.toastr.error("Candidat déjà postulé à l'offre d'emploi.");
      }
    } else {
      this.toastr.error("Cette offre d'emploi est actuellement désactivée.");
    }
  }

  deleteCandidatJobRequest(idJob: any, idUser: any): void {
    this.currentUser = this.localStorageService.getData('UserInfo');
    idUser = JSON.parse(this.currentUser).id;
    this.jobOffreService.deleteCandidatJobOffer(idJob, idUser).subscribe(
      (response: any) => {
        this.router.navigate(['/wegestu/jobs/candidatures']);
      },
      (error) => {
      }
    );
  }
  showDeleteCandidatJobConfirmation(idJob: any, idUser: any) {
    const title = this.jobRequest?.passed_test_esn ? 
    'Êtes-vous sûr de vouloir retirer cette candidature ? Vous n’êtes pas autorisé à repasser le test.' 
    : 'Êtes-vous sûr de vouloir retirer cette candidature ?'
    Swal.fire({
      title: title,
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deleteCandidatJobRequest(idJob, idUser);
      }
    });
  }

  getSkillsJob() {
    this.spinner.show();
    let job_offer_id = this.idRequestJob;
    this.jobOffreService.getskills(job_offer_id).subscribe({
      next: (res: any) => {
        this.spinner.hide();
        this.jobSkills = res.data;
      },
      error: () => {
        this.spinner.hide();
        this.toastrService.error(serverError);
      },
    });
  }

  navigateToTest() {
    this.router.navigate(['/wegestu/qcm']);
    this.toastr.info('Vous devez passer le test avant de postuler');
  }
  retourPagePrecedente(): void {
    this.location.back();
  }
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
