import { ValidationErrors } from "@angular/forms";

// export const getValidatorErrorMessage = (validatorName: string, validatorErrors?: ValidationErrors): string|undefined => {
//     let args = messages.get(validatorName)?.validatorErrorsKey?.map(name => validatorErrors?.[name]);
//     return (args) ? stringFormat(messages.get(validatorName)?.message,...args) : messages.get(validatorName)?.message;
// }

export const getValidatorErrorMessage = (validatorName: string, validatorErrors?: ValidationErrors, controlName?: string): string | undefined => {
  let args = messages.get(validatorName)?.validatorErrorsKey?.map(name => validatorErrors?.[name]);
  if (validatorName === 'mustMatch' && controlName) {
    args = [controlName];
  }
  return args ? stringFormat(messages.get(validatorName)?.message, ...args) : messages.get(validatorName)?.message;
};


const messages = new Map<string, { message: string, validatorErrorsKey?: string[] }>([
  ['required', { message: 'Ce champ est obligatoire' }],
  ['minlength', { message: 'Ce champ doit comporter au moins {0} caractères', validatorErrorsKey: ['requiredLength'] }],
  ['maxlength', { message: 'Ce champ ne peut pas contenir plus de {0} caractères', validatorErrorsKey: ['requiredLength'] }],
  ['email', { message: "L'e-mail doit être une adresse e-mail valide" }],
  ['mustMatch', { message: 'Les champs doivent correspondre' }],
  ['customPattern', { message: 'Ce champ ne doit contenir que des lettres' }],
]);

function stringFormat(template: string | undefined, ...args: any[]) {
  if (template) {
    return template.replace(/{(\d+)}/g, (match, index) => {
      return typeof args[index] !== 'undefined'
        ? args[index]
        : match;
    });
  }
  return undefined;
}
