import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QcmComponent } from './components/qcm/qcm.component';

const routes: Routes = [
  {
    path:'',
    component:QcmComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class QcmsRoutingModule { }
