import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ConsultantRoutingModule } from './consultant-routing.module';
import { ConsultantComponent } from './components/consultant-list/consultant.component';
import { DetailsConsultantComponent } from './components/consultant-details/details-consultant.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModuleModule } from '../../shared/pipes/pipes-module.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { RatingModule } from 'ngx-bootstrap/rating';

@NgModule({
  declarations: [ConsultantComponent, DetailsConsultantComponent],
  imports: [
    CommonModule,
    ConsultantRoutingModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    PipesModuleModule,
    PaginationModule,
    NgxSpinnerModule,
    RatingModule.forRoot(),
  ]
})
export class ConsultantModule {}
