<div class="d-flex flex-column flex-root" id="kt_app_root">
  <!--begin::Page bg image-->
  <!--end::Page bg image-->
  <!--begin::Authentication - Signup Welcome Message -->
  <div class="d-flex flex-column flex-center flex-column-fluid">
    <div class="login-page">
      <!--begin::Page bg image-->
      <!--end::Page bg image-->
      <!--begin::Authentication - Signup Welcome Message -->
      <div class="d-flex flex-column flex-center flex-column-fluid">
        <!--begin::Content-->
        <div class="d-flex flex-column flex-center p-5">
          <!--begin::Wrapper-->
          <div class="card-warp w-md-650px py-5">
            <div
              class="content"
              [formGroup]="generateLoginForm"
              (keyup.enter)="login()"
            >
              <div class="mb-7 signup-wrap">
                <a routerLink="/auth/register" class="signup" id="signup"
                  >S'inscrire</a
                >
              </div>
              <!--begin::Logo-->
              <div class="mb-15 mt-15 text-center">
                <a class="">
                  <img
                    alt="Logo"
                    src="/assets/media/logos/logo-wegestu-color.png"
                    class="h-40px"
                  />
                </a>
              </div>
              <!--end::Logo-->
              <!--begin::Input group-->
              <div class="fv-row mb-8">
                <!--begin::Email-->
                <label for="email" class="mail mb-5">E-mail</label>
                <input
                  type="text"
                  formControlName="username"
                  placeholder="Email"
                  name="email"
                  autocomplete="off"
                  class="form-control bg-transparent"
                  id="email"
                />
                <app-feild-error
                  [control]="generateLoginForm.get('username')"
                  [controlName]="'email'"
                ></app-feild-error>
                <!--end::Email-->
              </div>
              <!--end::Input group=-->
              <div class="fv-row mb-8 input-login">
                <!--begin::Password-->
                <label for="password" class="pass mb-5"> Mot de passe</label>
                <input
                  [type]="showPassword"
                  formControlName="password"
                  placeholder="Mot de passe"
                  name="password"
                  autocomplete="off"
                  class="form-control bg-transparent"
                  id="password"
                />
                <i
                  *ngIf="showPassword == 'password'"
                  (click)="showPassword = 'text'"
                  class="fa-regular fa-eye-slash"
                ></i>
                <i
                  *ngIf="showPassword == 'text'"
                  (click)="showPassword = 'password'"
                  class="fa-regular fa-eye"
                ></i>
                <app-feild-error
                  [control]="generateLoginForm.get('password')"
                ></app-feild-error>
                <!--end::Password-->
              </div>
              <!--end::Input group=-->
              <!--begin::remmebermer-->
              <div
                class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8"
              >
                <div class="form-check form-check-custom form-check-solid">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    value="1"
                    id="flexCheckDefault"
                    formControlName="rememberMe"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Se souvenir de moi
                  </label>
                </div>
                <!--begin::Link-->
                <a routerLink="/auth/reset-mail" class="link-primary"
                  >Mot de passe oublié ?</a
                >
                <!--end::Link-->
              </div>
              <!--end::remmebermer-->
              <!--begin::Link-->
              <div class="mb-8 mt-10" *ngIf="show_button_login">
                <button
                  [disabled]="!this.generateLoginForm.valid"
                  (click)="login()"
                  id="btn_login"
                  class="btn btn-sm btn-primary w-100"
                >
                  SE CONNECTER
                </button>
              </div>
              <div class="mb-8" *ngIf="show_button_resend">
                <button
                  [disabled]="!this.generateLoginForm.valid"
                  (click)="resendCodeVerification()"
                  id="btn_resend_code"
                  class="btn btn-sm btn-primary w-100"
                >
                  Renvoyer code
                </button>
              </div>
              <!--end::Link-->
            </div>
          </div>
          <!--end::Wrapper-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Authentication - Signup Welcome Message-->
    </div>
  </div>
</div>
