import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class TestService extends CrudService<ApiResponse<any>, number>{

  public endpointCreateTest="/api/Test/create"
  public endpointRetestRequest = "/api/Test/RetestRequest"
  public endpointCompleteTest = "/api/ResponseCandidate/completeTest"
  public endpointStarteTest = "/api/Test/create"

  private testResponseSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  testResponse$ = this.testResponseSubject.asObservable();

  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}`);
  }

  setTestResponse(response: any) {
    this.testResponseSubject.next(response);
  }
  /* create test  */
  CreateTest(body: {
    sub_category_ids: number[];
  }): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + this.endpointCreateTest,
      body
    );
  }

  /* request new test */
  requestNewTest(id_user: any): Observable<ApiResponse<any>> {
    console.log("url",this._base + this.endpointRetestRequest, id_user)
    return this._http.post<ApiResponse<any>>(this._base + this.endpointRetestRequest, id_user);
  }
  /* complete test */
completeTest(): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(this._base + this.endpointCompleteTest,null);
  } 
  /* start test */

  startTest(): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(this._base + this.endpointStarteTest,null);
  } 

}
