import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css']
})
export class PaginationComponent {

  @Output() setPages: EventEmitter<any> = new EventEmitter<any>();
  @Input() data: any[] = [/* Your data goes here */];
  pageSize: number = 5; // Number of items per page
  @Input() set currentPageList(value:number){
    this.currentPage=value
  }
  @Input() set endexPageList(value: number) {
    this.endItem = value;
  }
  currentPage: number = 1;
  pagedData: any[] = [];
  totalPages!: number;
  lastPage:boolean=false
@Input() set lengthData(value:number){

if(value){
  this.dataLength=value
  this.calculateTotalPages()
}
}
dataLength!:number
endItem:number=5
constructor(private cdRef: ChangeDetectorRef){}
  ngOnInit(): void {
    this.calculateTotalPages();
    this.updatePageData();
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }
  calculateTotalPages() {
    this.totalPages = Math.ceil(this.dataLength / this.pageSize);
  }

  updatePageData() {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const endIndex = +startIndex + +this.pageSize;
    this.pagedData = this.data.slice(startIndex, endIndex);
    this.setPages.emit({startIndex:startIndex,endIndex:endIndex,currentPage:this.currentPage,itemsPerPage:this.pageSize})
  }

  goToFirstPage() {
    this.lastPage=false
    this.currentPage = 1;
    this.updatePageData();
  }

  goToLastPage() {
    this.lastPage=true
    this.currentPage = this.totalPages;
    //this.updatePageData();
  }

  prevPage() {
    if(this.lastPage){
    }
    if (this.currentPage > 1) {
      this.currentPage--;
      this.updatePageData();
    }
  }

  nextPage() {
    this.lastPage=false
    if (this.currentPage < this.totalPages) {
      this.currentPage++;
      this.updatePageData();
    }
  }

  onPageSizeChange() {
    this.lastPage=false
    this.calculateTotalPages();
    this.currentPage = 1;
    this.updatePageData();
  }
  calculateStartItem(): number {
    let startItem=(this.currentPage - 1) * this.pageSize + 1;
    return startItem
  }

}
