import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable, map, tap } from 'rxjs';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { environment } from '../../../../environnements/environment';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';

@Injectable({
  providedIn: 'root',
})
export class CandidateService extends CrudService<ApiResponse<any>, number> {
  public _endpoint_candidate = '/api/User/userProfile';
  public _endpoint_edit_profile_candidate = '/api/User/editProfileCandidat';
  public endpoint_skills_candidate = '/api/Skill/getByCandidate';
  public endpoint_add_skills_candidate = '/api/Skill/create';
  public endpointSkillsCandidate = '/api/Skill/getSkillsByCandidate';
  public endpointSkillsCandidateForTest =
    '/api/Skill/getSkillsByCandidateForTest';
  public endpoint_skills_candidate_after_test =
    '/api/Skill/getSkillsTestByCandidate';

  profileUpdated: EventEmitter<any> = new EventEmitter();
  private _cachedProfile: any;
  private _cachedId: any;

  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}`);
  }

  /* get profile Candidate  */
  getCandidateProfil(id: any): Observable<any> {
    if (id !== this._cachedId) {
      return this._http
        .get<any>(this._base + this._endpoint_candidate + '?user_id=' + id)
        .pipe(
          tap((profile) => {
            this._cachedProfile = profile;
            this._cachedId = id;
          })
        );
    } else {
      return new Observable((observer) => {
        observer.next(this._cachedProfile);
        observer.complete();
      });
    }
  }
  getAllPermissionsByUser() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/Permission/getAllPermissionsByUser`
    );
  }
  /* edit profile Candidate  */
  editCandidate(id: any, data: any): Observable<any> {
    return this._http
      .post<any>(
        this._base + this._endpoint_edit_profile_candidate + '?user_id=' + id,
        data
      )
      .pipe(
        tap(() => {
          this._cachedProfile = null;
          this._cachedId = null;
          this.profileUpdated.next(null);
        })
      );
  }
 /*disassociate Consultant*/
 disassociateConsultant(data: any): Observable<ApiResponse<any>> {
  return this._http.post<ApiResponse<any>>(
    this._base + `/api/User/dissociateCandidateFromCompany`,
    data
  );
}
  getCandidate(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/ConsultantEsn/getConsultantDetails?user_id=${id}`
    );
  }

exportCVCandidate(idUser: number, type: number, idCompany? : number | null) {
  let url = this._base + `/api/User/cvDownload/${idUser}?type=${type}`
  if(idCompany){
    url = url.concat(`&company_id=${idCompany}`)
  }
  return this._http.get(url, {responseType: 'arraybuffer',});
}

  /* get skills by Candidate  */
  getSkillsCandidate(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/Skill/getSkillsByCandidate/${id}`
    );
  }
  downloadPDF(url: string): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' };
    return this._http
      .get<Blob>(url, options)
      .pipe(map((res: any) => new Blob([res], { type: 'application/pdf' })));
  }
  downloadPDFTest(url: string): Observable<Blob> {
    const options = { responseType: 'blob' as 'json' };
    return this._http
      .get<Blob>(url, options)
      .pipe(map((res: any) => new Blob([res], { type: 'application/pdf' })));
  }
  /* add skills for Candidate  */
  addSkillsForCandidate(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/Skill/candidateAddSkill`,
      data
    );
  }
  /*delete all skills */
  deleteAllSkills(id: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base + `/api/Skill/candidateDeleteAllSkills?id=${id}`
    );
  }
  /*delete on skill */
  deleteOnSkill(id: number, idSubCatgery: number) {
    return this._http.delete<ApiResponse<any>>(
      this._base +
        `/api/Skill/candidateDeleteOneSkill?id=${id}&sub_category_id=${idSubCatgery}`
    );
  }

  /* add skills Candidate  */
  addSkillCandidate(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + this.endpoint_add_skills_candidate,
      data
    );
  }
  addStarsForSkillCandidate(data: any): Observable<ApiResponse<any>> {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/User/addSkills`,
      data
    );
  }

  /* get skills by id Candidate */
  getSkillsbyIdCandidate(id: any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + this.endpointSkillsCandidate + '/' + id
    );
  }
  /* get skills by id Candidate */
  getSkillsbyIdCandidateForTest(id: any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + this.endpointSkillsCandidateForTest + '/' + id
    );
  }

  /* get skills by id Candidate */
  getSkillsbyIdCandidateAfterTest(id: any): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + this.endpoint_skills_candidate_after_test + '/' + id
    );
  }

  /*get candidate  by id */
  getUserById(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/User/getCandidateDetails/${id}`
    );
  }
  /*get candidate  by id */
  getConsultantById(id: number) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/ConsultantEsn/getConsultantDetails?user_id=${id}`
    );
  }
  /*get skills  candidat */
  getSkills() {
    return this._http.get<ApiResponse<any>>(
      this._base + `/api/SubCategory/getListSubCateg`
    );
  }
  getListCategoriesCnadidat(data?: {
    per_page?: number;
    page?: number;
    sort?: number;
  }) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/Category/getListCategorywithDetails`
    );
  }
}
