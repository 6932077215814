<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="39" height="33" viewBox="0 0 39 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_458_5641)">
                            <path
                                d="M32.8463 0H6.15368C4.97895 0 4 0.986779 4 2.17091V22.5315C4 23.7156 4.97895 24.7024 6.15368 24.7024H32.8463C34.0211 24.7024 35 23.7156 35 22.5315V2.17091C35 0.953886 34.0211 0 32.8463 0ZM13.2021 2.2367C13.7242 2.2367 14.1158 2.6643 14.1158 3.15769C14.1158 3.68397 13.6916 4.07869 13.2021 4.07869C12.68 4.07869 12.2884 3.65108 12.2884 3.15769C12.2558 2.63141 12.68 2.2367 13.2021 2.2367ZM10.3958 2.2367C10.9179 2.2367 11.3095 2.6643 11.3095 3.15769C11.3095 3.68397 10.8853 4.07869 10.3958 4.07869C9.87368 4.07869 9.48211 3.65108 9.48211 3.15769C9.44947 2.63141 9.87368 2.2367 10.3958 2.2367ZM7.55684 2.2367C8.07895 2.2367 8.47053 2.6643 8.47053 3.15769C8.47053 3.68397 8.04632 4.07869 7.55684 4.07869C7.06737 4.07869 6.64316 3.65108 6.64316 3.15769C6.64316 2.63141 7.06737 2.2367 7.55684 2.2367ZM32.2263 21.7091C32.2263 21.8078 32.1611 21.8736 32.0632 21.8736H6.93684C6.83895 21.8736 6.77368 21.8078 6.77368 21.7091V6.34828H32.2263V21.7091Z"
                                fill="#265D91" />
                            <path
                                d="M11.5045 15.4267H14.8329C15.2897 15.4267 15.714 15.1964 15.9424 14.7688L17.1497 12.6966L20.7066 18.7159C20.935 19.1107 21.3592 19.3738 21.8161 19.3738C22.2729 19.3738 22.6971 19.1435 22.9255 18.7159L24.8834 15.3938H27.4613C28.1792 15.3938 28.7666 14.8017 28.7666 14.0781C28.7666 13.3544 28.1792 12.7624 27.4613 12.7624H24.1655C23.7087 12.7624 23.2845 12.9926 23.0561 13.4202L21.8487 15.4925L18.2919 9.47311C17.835 8.68369 16.5297 8.68369 16.0403 9.47311L14.0824 12.7953H11.5045C10.7866 12.7953 10.1992 13.3873 10.1992 14.111C10.1992 14.8346 10.7866 15.4267 11.5045 15.4267Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_458_5641" x="0" y="0" width="39" height="32.7024"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_458_5641" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_458_5641"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>

                    TimeSheet
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Liste des TimeSheet</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->

            <div class="card-body ">
                <div class="title mb-15">
                    <h4>Liste des TimeSheet</h4>
                </div>
                <div>
                    <div class="container-fluid">
                      
                            <!--begin::Input group-->
                            <!--begin::Main wrapper-->
                            <!-- <div>
                                <div class="input-group ">
                                    <span class="input-group-text">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                                            viewBox="0 0 20 20" fill="none">
                                            <path
                                                d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                                fill="#7E7C7C" />
                                        </svg>
                                    </span>
                                    <input type="text" class="form-control form-control-lg ps-5" name="search" (keyup)="searchListCraForMonth($event)"
                                        placeholder="Rechercher ..." data-kt-customer-table-filter="search" />
                                </div>
                            </div> -->
                            <div class="input-group mb-3">
                                <input
                                  type="text"
                                  data-kt-customer-table-filter="search"
                                  [(ngModel)]="searchElement" (keyup.enter)="searchListCraForMonth()"
                                  class="form-control form-control-solid ps-15"
                                  placeholder="Rechercher"
                                />
                                <div class="input-group-append">
                                  <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                      fill="#7E7C7C"
                                    />
                                  </svg>
                    
                                  <button
                                    class="input-group-text cursor-pointer"
                                    id="basic-addon2"
                                    (click)="searchListCraForMonth()"
                                  >
                                    <span>Rechercher</span>
                                  </button>
                                </div>
                              </div>
                            <!--end::Main wrapper-->
                            <!--end:Action-->
                     
                    </div>

                    <div class="table-responsive mt-15 table-striped">
                        <table class="table align-middle gy-5" id="list_cra">
                            <thead>
                                <th>
                                    TimeSheet
                                </th>
                                <th>
                                    Entreprise
                                </th>
                                <th>
                                    Date
                                </th>
                                <th>
                                    Status
                                </th>
                                <!-- <th class="bg9">
                                    Signé
                                </th> -->
                                <th>
                                    Action
                                </th>
                            </thead>
                            <tbody>

                                <tr *ngFor="let timeSheet of listTimeSheetForMonths">
                                    <td class="month">
                                        <a [routerLink]="['/wegestu/pointage/details-timeSheet', { idTimeSheet: timeSheet?.id }]"> <span>{{timeSheet?.name?timeSheet?.name:'---'}}</span></a>
                                    </td>
                                    <td>
                                        <span>{{timeSheet?.project_candidate?.project?.client_final?.name?timeSheet?.project_candidate?.project?.client_final?.name:'---'}}</span>
                                    </td>
                                    <td>
                                        <span>{{timeSheet?.date | date: 'dd/MM/yyyy'}}</span>
                                    </td>
                                    <td>
                                        <span [ngClass]="timeSheet?.state_validation == '2' ? 'status-valid' : 
                                        timeSheet?.state_validation == '4' ? 'status-valid' :'status-no-valid'">  
                                            {{
                                               timeSheet?.state_validation == '2' ? 'Envoyé' :
                                               timeSheet?.state_validation == '1' ? 'Non envoyé' :
                                               timeSheet?.state_validation == '3' ? 'Non envoyé' :
                                               timeSheet?.state_validation == '4' ? 'Approuvé' :
                                                'Non existant'
                                            }}
                                        </span>
                                    </td>
                                    <!-- <td class="text-center">
                                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <rect width="36" height="36" rx="9" fill="#B6ECC1" />
                                            <path
                                                d="M14.9375 23.9919C15.1649 24.2181 15.4934 24.3688 15.8218 24.3688C16.1503 24.3688 16.4787 24.2181 16.7314 23.9919L27.621 13.1611C28.1263 12.6585 28.1263 11.8795 27.621 11.3769C27.1157 10.8744 26.3324 10.8744 25.8271 11.3769L15.8218 21.3282L11.1729 16.6792C10.6676 16.1767 9.88431 16.1767 9.37899 16.6792C8.87367 17.1818 8.87367 17.9608 9.37899 18.4634L14.9375 23.9919Z"
                                                fill="#265D91" />
                                        </svg>
                                    </td> -->
                                    <td>
                                        <div class="seemore">
                                            <a (click)="voirListDetailsTimeSheet(timeSheet?.id)">Voir detail</a>
                                        </div>
                                    </td>
                                </tr>
                               
                            </tbody>
                        </table>
                    </div>
                  

                    <div class="alert alert-warning text-center mt-5" role="alert" *ngIf="listTimeSheetForMonths&&!listTimeSheetForMonths.length">
                        <img src="/assets/media/logos/logo-no-data.png" alt="" class="mb-5">
                        <span class="empty-list">
                         La liste est vide
                     </span>
                     </div> 


                </div>
            </div>
        </div>
    </div>
    <!--end::Content container-->
</div>
<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>
