<div class="d-flex flex-column flex-root app-root" id="kt_app_root">
  <!--begin::Page-->
  <div class="app-page flex-column flex-column-fluid" id="kt_app_page">
    <div class="app-wrapper flex-column flex-row-fluid" id="kt_app_wrapper">
      <app-header></app-header>
      <app-sidebar></app-sidebar>
      <router-outlet></router-outlet>
      <app-footer></app-footer>
      <div id="kt_scrolltop" class="scrolltop" data-kt-scrolltop="true">
        <i class="ki-duotone ki-arrow-up">
          <span class="path1"></span>
          <span class="path2"></span>
        </i>
      </div>
    </div>
  </div>
</div>