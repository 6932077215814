import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthentificationService } from '../../services/authentification.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {
  serverError,
  invalidVerificationCode,
  codeSended,
  accountNotExist,
  codeexpire, invalidCode, expiredCode
} from '../../../shared/shared-models/message';

@Component({
  selector: 'app-code-reset',
  templateUrl: './code-reset.component.html',
  styleUrls: ['./code-reset.component.css'],
})
export class CodeResetComponent {
  /* formGroup */
  generateCodeForm: FormGroup = this.codeForm();
  userEmail!: string;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private authService: AuthentificationService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.userEmail = params.params['email'];
      },
    });
  }
  /* create code from */
  codeForm() {
    return this.formBuilder.group({
      number1: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      number2: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      number3: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
      number4: ['', [Validators.required, Validators.pattern('^[0-9]$')]],
    });
  }
  validateInput(event: KeyboardEvent) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  }

  moveFocus(currentInput: HTMLInputElement, nextInput: HTMLInputElement) {
    if (currentInput.value.length === 1 && /^[0-9]$/.test(currentInput.value)) {
      nextInput.focus();
    }
  }
  /* send code  */
  reinitiate() {
    if (this.generateCodeForm.valid) {
      let codeReset = Object.values(this.generateCodeForm.value).join('');
      this.authService.restCode({ code: codeReset, email: this.userEmail }).pipe().subscribe({
        next: (data) => {
          this.router.navigate(['auth/reset-password', { email: this.userEmail }]);
        },
        error :(err:any)=>{
          console.log("error",err)
          // this.toastr.error(err?.error.status==402?invalidCode:err?.error.status==404?expiredCode:serverError)
          if (err?.error.status == 402) {
            this.toastrService.error(invalidCode);
        } else if (err?.error.status == 404 ||err?.error?.message==="expired Code" ) {
            this.toastrService.error(expiredCode);
        }
        }

        
      });
    }
  }

  private handleErrorCode(status: number) {
    let errorMessage: string;

    switch (status) {
      case 402:
      case 405:
      case 422:
        errorMessage = invalidVerificationCode;
        break;
      default:
        errorMessage = serverError;
    }

    this.toastrService.error(errorMessage);
  }

  /* sending mail to get reset link */
  resendCodeVerification() {
    let data = {
      email: this.userEmail,
      role_id: [3],
    };

    this.authService
      .resetMail(data)
      .pipe()
      .subscribe({
        next: (res: { status: number; data: any }) => {
          if (res.status == 200) {
            // this.userInfo = res.data.User
            this.toastrService.success(codeSended);
          }
        },
        error: (err) => {
          console.log('error', err);
          if (err?.error?.message) {
            if (
              err.error.message.includes(
                'Can not find a user with the provided email'
              )
            ) {
              this.toastrService.error(accountNotExist);
            }
          } else {
            this.toastrService.error(serverError);
          }
        },
      });
  }
}
