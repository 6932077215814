import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { QcmsRoutingModule } from './qcms-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { QcmComponent } from './components/qcm/qcm.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { PaginationModule } from '../../shared/pagination/pagination.module';

@NgModule({
  declarations: [QcmComponent],
  imports: [
    CommonModule,
    QcmsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgxSpinnerModule,
    PaginationModule,
  ],
})
export class QcmsModule {}
