<ngx-spinner class="custom-spinner"
bdColor="rgba(255,255,255,1)"
  size="medium"
  color="#fff"
  type="ball-atom"
  [fullScreen]="true"
>
<span class="loader">
  <img src="/assets/media/logos/logo-wegestu-color.png" alt="">
</span>
</ngx-spinner>

<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
         <div class="d-flex align-items-center">
            <h1 class="page-heading"> <svg width="41" height="44" viewBox="0 0 41 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g filter="url(#filter0_d_615_2549)">
                <path d="M15.1976 30.5794C16.2337 29.2326 17.8276 28.4404 19.5013 28.4404H25.7973C26.7537 28.4404 27.6303 28.0443 28.2679 27.4105L36.0782 19.3298C36.3173 19.0922 36.3173 18.6168 36.0782 18.3792C35.8391 18.1415 35.4406 18.0623 35.2016 18.2207L27.8694 22.5779C27.6303 23.7663 26.5943 24.7169 25.3191 24.7169H17.987C17.5885 24.7169 17.19 24.4 17.19 23.9247C17.19 23.5286 17.5885 23.1325 17.987 23.1325H25.3191C25.877 23.1325 26.3552 22.6572 26.3552 22.1026C26.3552 21.5481 25.877 21.0727 25.3191 21.0727H14.1616C12.5676 21.0727 11.0534 21.7857 10.097 22.9741L7.30762 26.3014L14.4803 31.6885L15.1976 30.5794Z" fill="#265D91"/>
                <path d="M14.8784 33.9066L14.1611 33.352L6.27108 27.4104L4.75684 29.3117L13.3641 35.8079L14.8784 33.9066Z" fill="#265D91"/>
                <path d="M9.93705 17.2701H30.8177C31.4552 17.2701 31.9334 16.7948 31.9334 16.161V10.1401C28.108 11.4869 24.2028 12.1999 20.3773 12.1999C16.5519 12.1999 12.6467 11.4869 8.82129 10.1401V16.161C8.82129 16.7948 9.29947 17.2701 9.93705 17.2701Z" fill="#265D91"/>
                <path d="M31.9334 8.15949V4.59451C31.9334 3.96073 31.4552 3.4854 30.8177 3.4854H25.7967V1.10874C25.7967 0.633412 25.3983 0.158081 24.8404 0.158081H15.9143C15.4361 0.158081 14.958 0.55419 14.958 1.10874V3.4854H9.93705C9.29947 3.4854 8.82129 3.96073 8.82129 4.59451V8.15949C16.4722 11.0115 24.2825 11.0115 31.9334 8.15949ZM16.791 1.98018H23.884V3.40618H16.791V1.98018ZM19.1819 6.97116C19.1819 6.25817 19.7398 5.62439 20.3773 5.62439C21.0149 5.62439 21.5728 6.17894 21.5728 6.97116C21.5728 7.68416 21.0149 8.31793 20.3773 8.31793C19.7398 8.31793 19.1819 7.68416 19.1819 6.97116Z" fill="#265D91"/>
                </g>
                <defs>
                <filter id="filter0_d_615_2549" x="0.756836" y="0.158081" width="39.501" height="43.6498" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                <feOffset dy="4"/>
                <feGaussianBlur stdDeviation="2"/>
                <feComposite in2="hardAlpha" operator="out"/>
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_615_2549"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_615_2549" result="shape"/>
                </filter>
                </defs>
                </svg>
                Appel d'offres
            </h1>
                <!--begin::Menu-->
         </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a class="text-muted text-hover-primary">Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted"> Appel d'offres  </li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Détails Appel d'offre </li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->
        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Careers - List-->
        <div class="card">
            <!--begin::Body-->
            <div class="card-body p-lg-17">
                <!--begin::Hero-->
               
                <!--end::-->
                <!--begin::Layout-->
                <div *ngIf="jobRequest" class="d-flex flex-column flex-lg-row mb-10">
                    <!--begin::Content-->
                    <div class="flex-lg-row-fluid me-0 me-lg-20">
                        <!--begin::Job-->
                        <div class="mb-17">
                            <!--begin::Description-->
                            <div class="m-0">
                                <!--begin::Title-->
                                <div class="header-job d-flex align-items-baseline">
                                   <h4>Poste :</h4>
                                <p class="fs-4 text-600  w-gray mb-6"> {{jobRequest.name?jobRequest.name:'---'}}</p> 
                                </div>
                                
                                <!--end::Title-->
                                <!--begin::Text-->
                                <div class="header-job d-flex align-items-baseline">
                                    <h4>Description:</h4>
                                    <p class="fw-semibold fs-4 text-gray-600 mb-5">{{jobRequest.description?jobRequest.description:'---'}}</p>    
                                </div>
                                <!--end::Text-->
                            </div>
                            <!--end::Description-->
                            <!--begin::Accordion-->
                            <!--begin::Section-->
                            <div class="m-0">
                                <div class="flex-lg-row-auto w-100">
                                    <!--begin::Careers about-->
                                    <div class="card bg-light">
                                        <!--begin::Body-->
                                        <div class="card-body">
                                            <!--begin::Top-->
                                            <div class="mb-7">
                                                <!--begin::Title-->
                                                <h2 class=" mb-6">Détails</h2>
                                                <!--end::Title-->
                                                <!--begin::Text-->
                                                <p class="fw-semibold fs-6 text-gray-600">Date : {{!jobRequest.start_date?'---':jobRequest.start_date|formatDate}}</p>
                                                <p class="fw-semibold fs-6 text-gray-600">Client final  : {{jobRequest?.client_final?.name?jobRequest?.client_final?.name:'---'}}</p>    
                                                <p class="fw-semibold fs-6 text-gray-600">Disponibilité  : {{jobRequest.availability=='1'?'Immédiate'
                                                    :jobRequest.availability=='2'?'1 mois'
                                                    :jobRequest.availability=='3'?'2 mois'
                                                    :jobRequest.availability=='4'?'3 mois'
                                                    :jobRequest.availability=='5'?'4 mois':'5 mois'}}</p> 
                                                <p class="fw-semibold fs-6 text-gray-600">Année d'experience  : {{jobRequest.years_of_experience?jobRequest.years_of_experience:'---'}} </p>     
                                                <p class="fw-semibold fs-6 text-gray-600">TJM  : {{jobRequest.tjm?jobRequest.tjm.split(' ')[0] :'---'}} {{jobRequest.tjm?'€':''}}</p>                                        <!--end::Text-->
                                                <p class="fw-semibold fs-6 text-gray-600">Pays  : {{jobRequest.country?jobRequest.country:'---'}}</p>                                        <!--end::Text-->
                                                <p class="fw-semibold fs-6 text-gray-600">Ville  : {{jobRequest.city?jobRequest.city:'---'}}</p>                                        <!--end::Text-->
                                                <p class="fw-semibold fs-6 text-gray-600">Type de contrat  :
                                                    {{jobRequest.contract_type=='1'?'CDI':jobRequest.contract_type=='2'?'CDD':
                                                    jobRequest.contract_type=='3'?'Alternance':jobRequest.contract_type=='4'?'Stage' :'---'}}</p>                      
                                            </div>
                                            <!--end::Top-->
                                            <!--begin::Item-->
                                            <div class="mb-8">
                                                <!--begin::Title-->
                                                <h4 class=" mb-0">Compétences</h4>
                                                <!--end::Title-->
                                                <!--begin::Section-->
                                                <div class="my-2">
                                                    <!--begin::Row-->
                                                    <div class="d-flex align-items-center mb-3" *ngFor="let skill of jobRequest.skills.split(',')">
                                                        <!--begin::Bullet-->
                                                        <span class="bullet me-3"></span>
                                                        <!--end::Bullet-->
                                                        <!--begin::Label-->
                                                        <div class="text-gray-600 fw-semibold fs-6">{{skill}}</div>
                                                        <!--end::Label-->
                                                    </div>
                                                    <!--end::Row-->
                                                </div>
                                                <!--end::Section-->
                                            </div>
                                            
                                        </div>
                                        <!--end::Body-->
                                    </div>
                                    <!--end::Careers about-->
                                </div>
                            <!--end::Section-->
                            <!--end::Accordion-->
                            <!--begin::Apply-->
                            <!--end::Apply-->
                        </div>
                        <!--end::Job-->
                      
                    </div>
                    <!--end::Content-->
                    <!--begin::Sidebar-->
                    
                    <!--end::Sidebar-->
                </div>
                <!--end::Layout-->
                <!--begin::Section-->
             
                <!--end::Section-->
                <!--begin::Card-->
            
                <!--end::Card-->
            </div>
            <!--end::Body-->
        </div>
        <!--end::Careers - List-->
    </div>
    <!--end::Content container-->
</div>