import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, finalize, forkJoin } from 'rxjs';
import { EvaluationsService } from '../../../../../../main/evaluation/services/evaluations.service';
import { environment } from '../../../../../../../environnements/environment';

@Component({
  selector: 'app-details-pv',
  templateUrl: './details-pv.component.html',
  styleUrls: ['./details-pv.component.css'],
})
export class DetailsPvComponent {
  type = [
    { id: 2, name: 'Annuelle' },
    { id: 3, name: 'Semestre' },
    { id: 4, name: 'Trimestre' },
  ];
  dedicated_to = [
    { id: 1, name: 'consultant' },
    { id: 2, name: 'client final' },
  ];

  idPv: any;
  detailsPv: any;
  evaluation: any;
  evaluationArray!: any[];
  url: string = environment.baseUrl + '/api/Pv/file/signature_files/';
  groupedEvaluationDetails: any[] = [];
  evaluationDetailsArray: any[] = [];
  evaluationDetais: any;
  constructor(
    private evaluationsService: EvaluationsService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    forkJoin([this.getId()]).subscribe(() => {
      this.getDetailsPv();
    });
  }

  getId(): Observable<void> {
    return new Observable((observer) => {
      this.activatedRoute.paramMap.subscribe({
        next: (params: any) => {
          this.idPv = params.params['idPv'];
          observer.next();
          observer.complete();
        },
        error: (err) => observer.error(err),
      });
    });
  }

  getDetailsPv() {
    let dataPayload = this.idPv;
    this.spinner.show();
    this.evaluationsService
      .getAllDetailsOfPv(dataPayload)
      .pipe(finalize(() => this.spinner.hide()))
      .subscribe(
        (res: any) => {
          this.detailsPv = res?.data;
          console.log("detailspv",this.detailsPv)
          // this.evaluation = res?.data?.evaluation_send?.response_evaluation;
          this.evaluationDetais =  res?.data?.evaluation_send?.evaluation;
          this.evaluationDetailsArray = this.evaluationDetais?.question_evaluation || [];
         // Regrouper les questions par module
         const questionsByModule = new Map<number, any[]>();

         this.evaluationDetailsArray.forEach((item) => {
             const moduleId = item.chapter_eval_id;
             if (!questionsByModule.has(moduleId)) {
                 questionsByModule.set(moduleId, []);
             }
             questionsByModule.get(moduleId)?.push(item);
         });

         this.groupedEvaluationDetails = Array.from(questionsByModule.entries());
         
         // Pour déboguer
         console.log('groupedEvaluationDetails', this.groupedEvaluationDetails);
         
         console.log('data evaluation ', this.evaluation);
        },
        (error) => {
          this.spinner.hide();
          console.error('Error fetching detailsPv:', error);
        }
      );
  }

  exportToPDF() {
    const element = document.getElementById('kt_app_content_container');

    if (element) {
      this.spinner.show();
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('export_Pv.pdf');
        this.spinner.hide();
      });
    } else {
      console.error('Element not found!');
    }
  }
  addSigned(id: any) {
    this.router.navigate(['/wegestu/evaluations/signature-PV', { idPv: id }]);
  }
  print() {
    const contenuImprimable = document.getElementById(
      'kt_app_content_container'
    );

    if (contenuImprimable) {
      window.print();
    } else {
      console.error('Élément à imprimer non trouvé.');
    }
  }
  //  print() {
  //   const contenuImprimable = document.getElementById('kt_app_content_container');
  //   if (contenuImprimable) {
  //     const contenuAimprimer = contenuImprimable.innerHTML;
  //     window.print(contenuAimprimer);
  //   } else {
  //     console.error('Élément à imprimer non trouvé.');
  //   }
  // }
}
