import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { inject } from '@angular/core';
import { AuthentificationService } from '../../authentification/services/authentification.service';
import { NetworkService } from '../../main/network-status/services/NetworkService';

export namespace authenticationGuard {
  export const canActivate: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => {
    const authService = inject(AuthentificationService);
    const router = inject(Router);
    const networkService = inject(NetworkService);

    // Check network connectivity before proceeding
    if (!networkService.getOnlineStatus()) {
      router.navigate(['/reconnect']);
      return false;
    }

    // Allow access to the login page
    if (state.url.includes('auth') && !authService.isAuthenticated()) {
      return true;
    }

    if (state.url.includes('auth') && authService.isAuthenticated()) {
      router.navigate(['/wegestu/profil-candidat']);
      return false;
    }

    if (authService.isAuthenticated()) {
      return true;
    }

    router.navigate(['/wegestu/profil-candidat']);
    return false;
  };

  export const canActivateChild: CanActivateChildFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) => canActivate(route, state);
}
