<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading"><svg width="42" height="27" viewBox="0 0 42 27" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_651_309)">
                            <path fill-rule="evenodd" clip-rule="evenodd"
                                d="M32.3028 5.08326C31.7505 5.08326 31.3028 5.53097 31.3028 6.08326V17.5453H27.3636V0.999999C27.3636 0.447714 26.9158 0 26.3636 0H23.9696C23.4173 0 22.9696 0.447715 22.9696 1V17.5453H19.0304V11.5537C19.0304 11.0014 18.5827 10.5537 18.0304 10.5537H15.6364C15.0842 10.5537 14.6364 11.0014 14.6364 11.5537V17.5453H10.6913V8.07453C10.6913 7.52224 10.2436 7.07453 9.69133 7.07453H7.29738C6.74509 7.07453 6.29738 7.52224 6.29738 8.07453V17.5453H4V19H38V17.5453H35.7085V6.08326C35.7085 5.53097 35.2608 5.08326 34.7085 5.08326H32.3028Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_651_309" x="0" y="0" width="42" height="27"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_651_309" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_651_309"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>


                    Evaluation
                </h1>
                <!--begin::Menu-->
            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted"> <a routerLink="/wegestu/evaluations/pvs-evaluation" class="text-muted">Liste Des PV</a></li>
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <li class="breadcrumb-item text-muted">
                   <span class="text-muted">Détails Pv</span>
                </li>
                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <div class="actions-btns mb-15">
        <div class="actions">
          <a (click)="exportToPDF()" class="download"><svg width="13" height="14" viewBox="0 0 13 14" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M2.38333 13.095H10.6167C10.9297 13.095 11.2396 13.0316 11.5287 12.9084C11.8179 12.7852 12.0806 12.6047 12.3019 12.377C12.5233 12.1494 12.6988 11.8792 12.8186 11.5817C12.9384 11.2843 13 10.9655 13 10.6436V7.30076C13 7.06434 12.9087 6.8376 12.7462 6.67043C12.5836 6.50325 12.3632 6.40933 12.1333 6.40933C11.9035 6.40933 11.683 6.50325 11.5205 6.67043C11.358 6.8376 11.2667 7.06434 11.2667 7.30076V10.6436C11.2667 10.8209 11.1982 10.991 11.0763 11.1164C10.9544 11.2418 10.7891 11.3122 10.6167 11.3122H2.38333C2.21094 11.3122 2.04561 11.2418 1.92371 11.1164C1.80182 10.991 1.73333 10.8209 1.73333 10.6436V7.30076C1.73333 7.06434 1.64202 6.8376 1.47949 6.67043C1.31696 6.50325 1.09652 6.40933 0.866667 6.40933C0.636812 6.40933 0.416372 6.50325 0.253841 6.67043C0.0913094 6.8376 0 7.06434 0 7.30076V10.6436C0 11.2938 0.2511 11.9173 0.698062 12.377C1.14502 12.8368 1.75123 13.095 2.38333 13.095Z"
                fill="#265D91" />
              <path
                d="M6.49962 0C6.26976 0 6.04932 0.0939179 5.88679 0.261093C5.72426 0.428268 5.63295 0.655007 5.63295 0.891429V6.5052L4.31562 5.27949C4.14524 5.12079 3.92055 5.03821 3.69097 5.04991C3.46139 5.06161 3.24574 5.16664 3.09145 5.34189C2.93716 5.51713 2.85687 5.74825 2.86825 5.98438C2.87963 6.22052 2.98174 6.44233 3.15212 6.60103L5.91895 9.17503C5.93628 9.19063 5.95362 9.20177 5.97095 9.21514C5.98828 9.22851 6.01212 9.24857 6.03378 9.26417C6.05972 9.28049 6.08648 9.29536 6.11395 9.30874L6.17895 9.34217C6.21011 9.35448 6.24194 9.3649 6.27428 9.37337L6.33495 9.3912C6.44362 9.41389 6.55561 9.41389 6.66428 9.3912L6.72495 9.37337C6.75729 9.36487 6.78912 9.35445 6.82028 9.34217L6.88528 9.30874C6.91276 9.29537 6.93952 9.28049 6.96545 9.26417C6.98712 9.24857 7.00662 9.23297 7.02828 9.21514C7.04995 9.19732 7.06295 9.19063 7.08028 9.17503L9.84712 6.60103C9.93148 6.52245 9.99997 6.42755 10.0487 6.32174C10.0974 6.21594 10.1254 6.1013 10.131 5.98438C10.1366 5.86746 10.1198 5.75054 10.0815 5.6403C10.0432 5.53006 9.98418 5.42866 9.90779 5.34189C9.83139 5.25511 9.73912 5.18467 9.63626 5.13457C9.53339 5.08447 9.42194 5.0557 9.30827 5.04991C9.19459 5.04411 9.08092 5.06141 8.97374 5.1008C8.86657 5.14019 8.76798 5.20091 8.68362 5.27949L7.36628 6.5052V0.891429C7.36628 0.655007 7.27497 0.428268 7.11244 0.261093C6.94991 0.0939179 6.72947 0 6.49962 0Z"
                fill="#265D91" />
            </svg>
            Télécharger</a>
         
          <a (click)="print()" class="saved"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 512 512">
            <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zM432 248a24 24 0 1 1 0 48 24 24 0 1 1 0-48z" fill="#265D91"/></svg>
            Imprimer</a>
        </div>
      </div>
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->

        

        <div class="cardds mb-10 pt-15">

            <!--begin::Card header-->
            <div class="my-1 header-grid">
                <div class="section_title">
                    <h1 class="section-heading text-center  my-0">Détails PV : {{detailsPv?.pv_num?detailsPv?.pv_num:'---'}} </h1>
                </div>
            </div>
            <div class="card-body pt-15 ">
                <div class="content">
                    <div class="card-body pt-3">
                        <!--begin::Table-->
                      <div class="row">
                        <div class="col-lg-6">
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Nom</h4>
                               <span>{{detailsPv?.evaluation_send?.evaluation?.name?detailsPv?.evaluation_send?.evaluation?.name:'---'}}</span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Type</h4>
                               <span>{{detailsPv?.evaluation_send?.evaluation?.type=='1'?'Annuelle':
                                detailsPv?.evaluation_send?.evaluation?.type=='2'?'Suivi':'---'}}</span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Date d'envoi</h4>
                               <span *ngIf="detailsPv?.evaluation_send?.evaluation?.created_at" >{{detailsPv?.evaluation_send?.evaluation?.created_at | date:'dd/MM/yyyy'}}</span>
                               <span *ngIf="!detailsPv?.evaluation_send?.evaluation?.created_at">---</span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Date de réception</h4>
                               <span *ngIf="detailsPv?.date_of_receipt_for_candidate">{{detailsPv?.date_of_receipt_for_candidate | date:'dd/MM/yyyy'}}</span>
                               <span *ngIf="!detailsPv?.date_of_receipt_for_candidate">---</span>
                               
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Date remplissage compte rendu</h4>
                               <span *ngIf="detailsPv?.created_at" >{{detailsPv?.created_at | date:'dd/MM/yyyy'}}</span>
                               <span *ngIf="!detailsPv?.created_at">---</span>
                            </div>
                          
                        </div>
                        <div class="col-lg-6">
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Nom consultant</h4>
                               <span>{{detailsPv?.evaluation_send?.candidate?.first_name?
                                detailsPv?.evaluation_send?.candidate?.first_name:'---'}}
                                {{detailsPv?.evaluation_send?.candidate?.last_name?
                                    detailsPv?.evaluation_send?.candidate?.last_name:'---'}} </span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Nom de l’entreprise</h4>
                               <span>{{detailsPv?.evaluation_send?.evaluation?.company?.name?
                                detailsPv?.evaluation_send?.evaluation?.company?.name:'---'}}</span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Rempli par</h4>
                               <span>{{detailsPv?.name_of_rapporteur && detailsPv?.name_of_rapporteur!=" " ?detailsPv?.name_of_rapporteur:'---'}}</span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Signer par</h4>
                               <span>{{detailsPv?.name_of_rapporteur && detailsPv?.name_of_rapporteur!=" " ?detailsPv?.name_of_rapporteur:'---'}}</span>
                            </div>
                            <div class="bloc-input mb-5">
                                <h4 class="mb-2">Date d'envoi signature</h4>
                               <span *ngIf="detailsPv?.
date_signed_by_candidate" >{{detailsPv?.
    date_signed_by_candidate | date:'dd/MM/yyyy' }}</span>
                               <span *ngIf="!detailsPv?.
date_signed_by_candidate" >---</span>
                            </div>
                           
                        </div>
                      </div>
                      
                    </div>
                </div>



                <!--end::Table-->
            </div>
        </div>

        <!--end::Card header-->
        <!--begin::Card body-->
      <div class="mb-15">
        <div class="card-body pt-15" >
            <div class="container" >
                <div class="evals">
                    <div class="accordion-item eval mb-10" *ngFor="let module of groupedEvaluationDetails; let i = index">
                        <h2 class="accordion-header" [id]="'kt_accordion_1_header_' + i">
                          <button class="accordion-button fw-semibold" type="button"
                            [attr.data-bs-toggle]="'collapse'"
                            [attr.data-bs-target]="'#kt_accordion_1_body_' + i"
                            [attr.aria-expanded]="i === 0" aria-controls="'kt_accordion_1_body_' + i">
                            Module {{ module[1][0].chapter_evaluation?.name }}
                          </button>
                        </h2>
                        <div [id]="'kt_accordion_1_body_' + i" class="accordion-collapse collapse"
                          [attr.aria-labelledby]="'kt_accordion_1_header_' + i">
                          <div class="accordion-body evals"
                            *ngFor="let evaluations of module[1]; let j = index">
                            <div class="bloc mb-5">
                              <div class="text">
                                <!-- Utiliser j + 1 pour indexer les questions à l'intérieur du module -->
                                <span>{{ j + 1 }}</span>
                                <p>{{ evaluations?.question }}</p>
                              </div>
                            </div>
                          
                            <div class="rating" *ngIf="evaluations?.typeResponse==='1'">
                                <div class="rating mb-5" >
                                    <div class="stars">
                                        <i class="fa fa-star" [class.gold]="evaluations?.response_evaluation[0]?.stars >= 1"></i>
                                        <i class="fa fa-star" [class.gold]="evaluations?.response_evaluation[0]?.stars >= 2"></i>
                                        <i class="fa fa-star" [class.gold]="evaluations?.response_evaluation[0]?.stars >= 3"></i>
                                        <i class="fa fa-star" [class.gold]="evaluations?.response_evaluation[0]?.stars >= 4"></i>
                                        <i class="fa fa-star" [class.gold]="evaluations?.response_evaluation[0]?.stars >= 5"></i>
                                    </div>
                                </div>
                            </div>
                            <div class="contentComment">
                                <div  *ngIf="evaluations?.typeResponse==='2' && !evaluations.response_evaluation[0]?.comment" >
                                <input type="text" name="reponse" id="reponse" class="form-control mb-5 " placeholder="Reponse">
                                </div>
                                </div>
                                <div class="mb-3 " *ngIf="evaluations?.typeResponse==='2' && evaluations.response_evaluation[0]?.comment">
                                   <p class="comment">{{ evaluations.response_evaluation[0]?.comment }}</p> 
                                </div>

                              <div class="line mb-10"></div>
                            <br>
                            <br>
                          </div>
                        </div>
                      </div>
            </div>
            <!--end::Table-->
        </div>
      </div>
      <div class="cardds mb-10 pt-15">

        <!--begin::Card header-->
        <div class="my-1 header-grid">
            <div class="section_title">
                <h1 class="section-heading text-center  my-0">Compte rendu </h1>
            </div>
        </div>
        <div class="card-body pt-15 ">
            <div class="content">
                <div class="evals">
                    <div class="eval mb-10" >

                    

                        <div class="bloc">
                            <div class="compte">
                                <p [innerHTML]="detailsPv?.report ? detailsPv?.report : '---'"></p>
                    </div>
                        

                        </div>
                       
                    </div>
                

                </div>
            </div>



            <!--end::Table-->
        </div>
    </div>
    

      <div class="mt-10 mb-10">
        <div class="card-body">
            <div class="container pb-10">
              <div class="row">
              <div class="col-lg-6 col-md-6 col-sm-12 text-center mb-4">
                    <div class="my-1">
                        <div class="title-sginer mb-5">
                            <h1 >Signature par ESN </h1>
                        </div>
                    </div>
                    <div class="signature">
                        <div class="img-wrap mb-5">
                            <p style="margin: 60px;" *ngIf="!detailsPv?.signature_file_esn">PV pas encore signer par ESN !</p>
                            <img *ngIf="detailsPv?.signature_file_esn" src="{{ url +detailsPv?.signature_file_esn}} "alt="">

                        </div>
                        <div class="nom ">
                            <span *ngIf="detailsPv?.signature_file_esn">{{ detailsPv?.name_of_rapporteur || '---' }}</span><br>
                    <p *ngIf="detailsPv?.signature_file_esn">{{ detailsPv?.date_signed_by_esn | date:'dd/MM/yyyy' }}</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 text-center mb-4">
                    <div class="my-1">
                        <div class="title-sginer mb-5">
                            <h1>Signature par Consultant</h1>
                        </div>
                    </div>
                    <div class="signature ">
                        <div class="img-wrap mb-5">
                            <button *ngIf="!detailsPv?.signature_file_candidate" class="btnSigne" (click)="addSigned(detailsPv?.id)">Signer</button>
                            <img *ngIf="detailsPv?.signature_file_candidate" src="{{ url +detailsPv?.signature_file_candidate}} "alt="">

                        </div>
                        <div class="nom ">
                            <span *ngIf="detailsPv?.signature_file_candidate">{{detailsPv?.evaluation_send?.candidate?.first_name?
                                detailsPv?.evaluation_send?.candidate?.first_name:'---'}}
                                {{detailsPv?.evaluation_send?.candidate?.last_name?
                                    detailsPv?.evaluation_send?.candidate?.last_name:'---'}}</span><br>
                            <p *ngIf="detailsPv?.date_signed_by_candidate">Date : {{detailsPv?. date_signed_by_candidate | date:'dd/MM/yyyy'}}</p>
                            <p *ngIf="!detailsPv?.date_signed_by_candidate">Date : ---</p>

                        </div>
                    </div>
                </div>
              </div>
            </div>
        </div>
      </div>
        <!--end::Card body-->
    </div>
    <!--end::Card-->
    <!--begin::Modals-->
    <!--begin::Modal - Customers - Add-->

    <!--end::Modal - Customers - Add-->
    <!--begin::Modal - Adjust Balance-->

    <!--end::Modal - New Card-->
    <!--end::Modals-->
</div>
<!--end::Content container-->
