<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
      <!--begin::Page title-->
      <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
        <!--begin::Title-->
        <div class="d-flex align-items-center">
          <h1 class="page-heading">
            <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g filter="url(#filter0_d_1223_3646)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M13.75 3.2834C9.86824 4.28202 7 7.80437 7 11.9996V17.9991C7 18.5519 6.55087 19 6.00104 19C4.8959 19 4 19.8977 4 21C4 22.1046 4.89703 23 6.00495 23H25.9951C27.1024 23 28 22.1023 28 21C28 19.8954 27.0999 19 25.999 19C25.4472 19 25 18.5554 25 17.9991V11.9996C25 7.80603 22.1324 4.28236 18.25 3.28343V2.25043C18.25 1.00462 17.2426 0 16 0C14.7599 0 13.75 1.00755 13.75 2.25043V3.2834ZM12.5 24H19.5C19.5 25.933 17.933 27.5 16 27.5C14.067 27.5 12.5 25.933 12.5 24Z"
                  fill="#265D91" />
              </g>
              <defs>
                <filter id="filter0_d_1223_3646" x="0" y="0" width="32" height="35.5" filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha" />
                  <feOffset dy="4" />
                  <feGaussianBlur stdDeviation="2" />
                  <feComposite in2="hardAlpha" operator="out" />
                  <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1223_3646" />
                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1223_3646" result="shape" />
                </filter>
              </defs>
            </svg>
  
            Mes avis
           
          </h1>
  
          <!--begin::Menu-->
  
        </div>
        <!--end::Title-->
        <!--begin::Breadcrumb-->
        <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">
            <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
        </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item">
            <span class="bullet bg-gray-400 w-5px h-2px"></span>
          </li>
          <!--end::Item-->
          <!--begin::Item-->
          <li class="breadcrumb-item text-muted">Mes Avis</li>
  
  
          <!--end::Item-->
        </ul>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page title-->
      <!--begin::Actions-->
  
      <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
  </div>
  
  <div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
      <!--begin::Card-->
      <div class="card">
        <!--begin::Card header-->
  
        <div class="card-body tools">
            <div class="title mb-15">
                <h4>Mes Avis à propos: {{companyDetails?.name||'---'}} </h4>
            </div>
  
            <div class="container">
                <div class="content">
                    <div class="row evaluation">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <div class="reviews pt-10">
                                <div class="review mb-15 pd-l-0">
                                    <div class="reply">
                                        <!--begin::questions-->
                                        <div class="choices ">
                                            <div   class="form-check form-check-custom form-check-solid questions items-stars">
                                                <label class="form-check-label question" for="flexCheckDefault">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                                                </label>
                                                <div class="d-flex">
                                                    <div class="">
                                                        <div class="stars">
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                        </div>
                                                    </div>
                                                    <div class="edit-icon"  data-bs-toggle="modal" data-bs-target="#edit_review" >
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16" fill="none">
                                                            <path
                                                                d="M0 13.9163V4.97011C0 4.44285 0.210731 3.93718 0.585835 3.56435C0.960938 3.19152 1.46969 2.98206 2.00016 2.98206H8.11567L6.14801 4.97011H2.00016V13.9163H11.0009V9.79608L13.0011 7.84034V13.9163C13.0011 14.4436 12.7903 14.9492 12.4152 15.3221C12.0401 15.6949 11.5314 15.9043 11.0009 15.9043H2.00016C1.46969 15.9043 0.960938 15.6949 0.585835 15.3221C0.210731 14.9492 0 14.4436 0 13.9163ZM14.5012 0C14.1035 0.000348011 13.7222 0.157667 13.4411 0.437369L6.33302 7.61669C6.11429 7.83769 5.94925 8.10543 5.85048 8.39948L5.13042 10.5491C5.12143 10.5834 5.12163 10.6195 5.131 10.6538C5.14038 10.6881 5.1586 10.7193 5.18387 10.7444C5.20914 10.7695 5.24057 10.7877 5.27505 10.797C5.30953 10.8063 5.34586 10.8065 5.38044 10.7976L7.55062 10.0893C7.84651 9.99123 8.1159 9.82717 8.33819 9.6097L15.5613 2.54469C15.7708 2.33618 15.9135 2.07062 15.9712 1.78156C16.029 1.4925 15.9993 1.19291 15.8859 0.920616C15.7725 0.648327 15.5804 0.415556 15.3339 0.251701C15.0875 0.0878471 14.7977 0.000259216 14.5012 0Z"
                                                                fill="#265D91" />
                                                        </svg>
                                                    </div>
                                                </div>
  
  
                                            </div>
                                            <div class="line"></div>
                                            <div
                                                class="form-check form-check-custom form-check-solid  questions items-stars">
  
                                                <label class="form-check-label question" for="flexCheckDefault">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                                                </label>
                                                <div class="d-flex">
                                                    <div class="">
                                                        <div class="stars">
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                        </div>
                                                    </div>
                                                    <div class="edit-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16" fill="none">
                                                            <path
                                                                d="M0 13.9163V4.97011C0 4.44285 0.210731 3.93718 0.585835 3.56435C0.960938 3.19152 1.46969 2.98206 2.00016 2.98206H8.11567L6.14801 4.97011H2.00016V13.9163H11.0009V9.79608L13.0011 7.84034V13.9163C13.0011 14.4436 12.7903 14.9492 12.4152 15.3221C12.0401 15.6949 11.5314 15.9043 11.0009 15.9043H2.00016C1.46969 15.9043 0.960938 15.6949 0.585835 15.3221C0.210731 14.9492 0 14.4436 0 13.9163ZM14.5012 0C14.1035 0.000348011 13.7222 0.157667 13.4411 0.437369L6.33302 7.61669C6.11429 7.83769 5.94925 8.10543 5.85048 8.39948L5.13042 10.5491C5.12143 10.5834 5.12163 10.6195 5.131 10.6538C5.14038 10.6881 5.1586 10.7193 5.18387 10.7444C5.20914 10.7695 5.24057 10.7877 5.27505 10.797C5.30953 10.8063 5.34586 10.8065 5.38044 10.7976L7.55062 10.0893C7.84651 9.99123 8.1159 9.82717 8.33819 9.6097L15.5613 2.54469C15.7708 2.33618 15.9135 2.07062 15.9712 1.78156C16.029 1.4925 15.9993 1.19291 15.8859 0.920616C15.7725 0.648327 15.5804 0.415556 15.3339 0.251701C15.0875 0.0878471 14.7977 0.000259216 14.5012 0Z"
                                                                fill="#265D91" />
                                                        </svg>
                                                    </div>
                                                </div>
  
                                            </div>
                                            <div class="line"></div>
                                            <div
                                                class="form-check form-check-custom form-check-solid  questions items-stars">
  
                                                <label class="form-check-label question" for="flexCheckDefault">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                                                </label>
  
                                                <div class="d-flex">
                                                    <div class="">
                                                        <div class="stars">
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                        </div>
                                                    </div>
                                                    <div class="edit-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16" fill="none">
                                                            <path
                                                                d="M0 13.9163V4.97011C0 4.44285 0.210731 3.93718 0.585835 3.56435C0.960938 3.19152 1.46969 2.98206 2.00016 2.98206H8.11567L6.14801 4.97011H2.00016V13.9163H11.0009V9.79608L13.0011 7.84034V13.9163C13.0011 14.4436 12.7903 14.9492 12.4152 15.3221C12.0401 15.6949 11.5314 15.9043 11.0009 15.9043H2.00016C1.46969 15.9043 0.960938 15.6949 0.585835 15.3221C0.210731 14.9492 0 14.4436 0 13.9163ZM14.5012 0C14.1035 0.000348011 13.7222 0.157667 13.4411 0.437369L6.33302 7.61669C6.11429 7.83769 5.94925 8.10543 5.85048 8.39948L5.13042 10.5491C5.12143 10.5834 5.12163 10.6195 5.131 10.6538C5.14038 10.6881 5.1586 10.7193 5.18387 10.7444C5.20914 10.7695 5.24057 10.7877 5.27505 10.797C5.30953 10.8063 5.34586 10.8065 5.38044 10.7976L7.55062 10.0893C7.84651 9.99123 8.1159 9.82717 8.33819 9.6097L15.5613 2.54469C15.7708 2.33618 15.9135 2.07062 15.9712 1.78156C16.029 1.4925 15.9993 1.19291 15.8859 0.920616C15.7725 0.648327 15.5804 0.415556 15.3339 0.251701C15.0875 0.0878471 14.7977 0.000259216 14.5012 0Z"
                                                                fill="#265D91" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <!--end::Questions-->
                                            <div class="line"></div>
                                            <div
                                                class="form-check form-check-custom form-check-solid  questions items-stars">
  
                                                <label class="form-check-label question" for="flexCheckDefault">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ?
                                                </label>
                                                <div class="d-flex">
                                                    <div class="">
                                                        <div class="stars">
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star gold"></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                            <i class="fa fa-star "></i>
                                                        </div>
                                                    </div>
                                                    <div class="edit-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                            height="16" viewBox="0 0 16 16" fill="none">
                                                            <path
                                                                d="M0 13.9163V4.97011C0 4.44285 0.210731 3.93718 0.585835 3.56435C0.960938 3.19152 1.46969 2.98206 2.00016 2.98206H8.11567L6.14801 4.97011H2.00016V13.9163H11.0009V9.79608L13.0011 7.84034V13.9163C13.0011 14.4436 12.7903 14.9492 12.4152 15.3221C12.0401 15.6949 11.5314 15.9043 11.0009 15.9043H2.00016C1.46969 15.9043 0.960938 15.6949 0.585835 15.3221C0.210731 14.9492 0 14.4436 0 13.9163ZM14.5012 0C14.1035 0.000348011 13.7222 0.157667 13.4411 0.437369L6.33302 7.61669C6.11429 7.83769 5.94925 8.10543 5.85048 8.39948L5.13042 10.5491C5.12143 10.5834 5.12163 10.6195 5.131 10.6538C5.14038 10.6881 5.1586 10.7193 5.18387 10.7444C5.20914 10.7695 5.24057 10.7877 5.27505 10.797C5.30953 10.8063 5.34586 10.8065 5.38044 10.7976L7.55062 10.0893C7.84651 9.99123 8.1159 9.82717 8.33819 9.6097L15.5613 2.54469C15.7708 2.33618 15.9135 2.07062 15.9712 1.78156C16.029 1.4925 15.9993 1.19291 15.8859 0.920616C15.7725 0.648327 15.5804 0.415556 15.3339 0.251701C15.0875 0.0878471 14.7977 0.000259216 14.5012 0Z"
                                                                fill="#265D91" />
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pd-20">
                                        <div class="reply-input mb-10">
                                            <!--begin::Body-->
                                            <div class="card-body d-flex align-items-center pt-10 pb-10">
                                                <!--begin::Input-->
                                                <textarea class="form-control bg-transparent border-0 px-0"
                                                    id="kt_social_feeds_post_input" name="message"
                                                    data-kt-autosize="true" rows="1"
                                                    placeholder="Laisser votre avis ...."></textarea>
                                                <!--end::Input-->
                                                <a class="btn btn-sm btn-primary" id="kt_social_feeds_post_btn">
                                                    <!--begin::Indicator label-->
                                                    <span class="indicator-label">Envoyer</span>
                                                    <!--end::Indicator label-->
                                                    <!--begin::Indicator progress-->
                                                    <span class="indicator-progress">S'il vous plaît, attendez
                                                        ...
                                                        <span
                                                            class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    <!--end::Indicator progress-->
                                                </a>
                                            </div>
                                            <!--end::Body-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
      <!--end::Card-->
      <!--begin::Modals-->
      <!--begin::Modal - Customers - Add-->
  
      <!--end::Modal - Customers - Add-->
      <!--begin::Modal - Adjust Balance-->
  
      <!--end::Modal - New Card-->
      <!--end::Modals-->
    </div>
    <!--end::Content container-->
  </div>
  
  
  <div class="modal fade" id="edit_review" tabindex="-1" aria-hidden="true">
    <!--begin::Modal dialog-->
    <div class="modal-dialog modal-dialog-centered mw-650px">
      <!--begin::Modal content-->
      <div class="modal-content pt-5 pb-15">
        <!--begin::Modal header-->
        <div class="modal-header">
          <!--begin::Modal title-->
          <h2 class="fw-bold">Modifier avis</h2>
          <!--end::Modal title-->
          <!--begin::Close-->
          <div id="kt_customers_export_close" class="btn btn-icon btn-sm btn-active-icon-primary">
            <i class="ki-duotone ki-cross fs-1" data-bs-dismiss="modal">
              <span class="path1"></span>
              <span class="path2"></span>
            </i>
          </div>
          <!--end::Close-->
        </div>
        <hr class="line">
        <!--end::Modal header-->
        <!--begin::Modal body-->
        <div class="modal-body text-center mx-5 mx-xl-15 my-7">
          <!--begin::Form-->
          <div class="">
            <div class="card-body">
              <div class="flex_input">
                <span class="mb-3">Nom de l'entreprise</span>
                <input type="text" name="entreprise" id="entreprise" class="form-control form-control-solid mb-3 avis-text"  placeholder="entreprise"/>
              </div>
              <div class="flex_input">
                <span class="mb-3">Avis</span>
                <textarea type="text" name="avis" id="avis" class="form-control form-control-solid mb-3 avis-text" rows="3"
                  placeholder="Avis"></textarea>
              </div>
            </div>
          </div>
          <!--end::Form-->
        </div>
        <div class="card-footer mx-5 mx-xl-15 my-7">
          <div class="actions">
            <a data-bs-dismiss="modal" class="annuler">Annuler</a>
            <a  class="confirmer">Confirmer</a>
          </div>
        </div>
        <!--end::Modal body-->
      </div>
      <!--end::Modal content-->
    </div>
    <!--end::Modal dialog-->
  </div>