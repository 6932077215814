import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { CPassoword } from '../../../shared/shared-models/login';
import {
  serverError,
  changePasswordSuccess,
} from '../../../shared/shared-models/message';
import { AuthentificationService } from '../../services/authentification.service';
import { MustMatch } from '../../../shared/_helpers/must-match.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { patternPassword } from '../../../shared/shared-models/pattern';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent {
  /* formGroup */
  generateResePasswordForm: FormGroup = this.resetPasswordForm(
    new CPassoword()
  );
  userEmail!: string;
  showPassword: string = 'password';
  showNewPassword: boolean = false;
  showOldPassword: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private toastrService: ToastrService,
    private authService: AuthentificationService,
    private activatedRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        console.log('routes', params.params['email']);
        this.userEmail = params.params['email'];
        console.log(
          '%creset-password.component.ts line:35 userEmail',
          'color: #007acc;',
          this.userEmail
        );
      },
    });
  }

  /* create resetPassword form */
  resetPasswordForm(data: CPassoword) {
    return this.formBuilder.group(
      {
        oldPassword: [
          data.oldPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
        newPassword: [
          data.newPassword,
          [Validators.required, Validators.pattern(patternPassword)],
        ],
        email: [''],
      },
      {
        validator: MustMatch('oldPassword', 'newPassword'),
      }
    );
  }

  /* reset password */
  resetPassword() {
    if (this.generateResePasswordForm.valid) {
      this.generateResePasswordForm.get('email')?.setValue(this.userEmail);
      let data: any = {
        email: this.generateResePasswordForm.get('email')?.value,
        newPassword: this.generateResePasswordForm.get('newPassword')?.value,
      };

      this.authService
        .restPassword(data)
        .pipe()
        .subscribe({
          next: (res) => {
            console.log('resss', res);
            if (res.status == 200) {
              this.toastrService.success(changePasswordSuccess);

              this.router.navigate(['auth/login']);
            }
          },
          error: (err: Error) => {
            console.log(
              '%creset-password.component.ts line:50 err',
              'color: #007acc;',
              err
            );

            this.toastrService.error(serverError);
          },
        });
    }
  }

  togglePasswordVisibility(field: string) {
    if (field === 'newPassword') {
      this.showNewPassword = !this.showNewPassword;
    } else if (field === 'oldPassword') {
      this.showOldPassword = !this.showOldPassword;
    }
  }
}
