import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListEvaluationAnnualComponent } from './components/annual-evaluation/components/list-evaluation-annual/list-evaluation-annual.component';
import { AnnualEvaluationComponent } from './components/annual-evaluation/annual-evaluation.component';
import { SocietyEvaluationComponent } from './components/society-evaluation/society-evaluation.component';
import { TrimersterEvaluationComponent } from './components/trimerster-evaluation/trimerster-evaluation.component';
import { SemesterEvaluationComponent } from './components/semester-evaluation/semester-evaluation.component';
import { PvsEvaluationAnnualComponent } from './components/annual-evaluation/components/pvs-evaluation-annual/pvs-evaluation-annual.component';
import { ReviewComponent } from './components/society-evaluation/components/review/review.component';
import { CompletedAssessmentComponent } from './components/completed-assessment/completed-assessment.component';
import { ReviewsCandidatComponent } from './components/reviews-candidat/reviews-candidat.component';
import { AssessmentDetailsComponent } from './components/assessment-details/assessment-details.component';
import { MyReviewsComponent } from './components/my-reviews/my-reviews.component';
import { DetailsMyReviewsComponent } from './components/details-my-reviews/details-my-reviews.component';
import { DetailsPvComponent } from './components/annual-evaluation/components/details-pv/details-pv.component';
import { PvSignedComponent } from './components/annual-evaluation/components/pv-signed/pv-signed.component';


const routes: Routes = [
  {
    path: 'evaluation-annuel',
    component: AnnualEvaluationComponent
  },
  {
    path: 'list-evaluation',
    component: ListEvaluationAnnualComponent
  },
  {
    path: 'evaluation-societe',
    component: SocietyEvaluationComponent
  },
  {
    path: 'semester-evaluation',
    component: SemesterEvaluationComponent
  },
  {
    path: 'pvs-evaluation',
    component: PvsEvaluationAnnualComponent
  },
  {
    path: 'signature-PV',
    component: PvSignedComponent
  },
  {
    path: 'review-company',
    component: ReviewComponent
  },
  {
    path: 'completed-assessment',
    component: CompletedAssessmentComponent
  },
  {
    path: 'all-reviews',
    component: ReviewsCandidatComponent
  },
  {
    path: 'details-evaluation',
    component: AssessmentDetailsComponent
  },
  {
    path:'my-reviews',
    component:MyReviewsComponent
  },
  {
    path:'details-review',
    component:DetailsMyReviewsComponent
  },
  {
    path:'details-pv',
    component:DetailsPvComponent
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EvaluationRoutingModule { }
