import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class ReviewCompanyService extends CrudService<
  ApiResponse<any>,
  number
> {
  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}/api`);
  }

  /* get review company  by id  */
  getReviewCompanyById(id: any) {
    return this._http.get<ApiResponse<any>>(
      this._base + `/ResponseDefaultReview/getById/` + id
    );
  }

  /* update review company */
  updateReviewCompaby(id: number, data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseDefaultReview/update/${id}`,
      data
    );
  }

  /* update review company */

  getAllReviewReviewCompany(
    company_id: number,
    per_page: number,
    page: number,
    sort: number
  ) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseDefaultReview/reviewsbyUser?company_id=${company_id}&per_page=${per_page}&page=${page}&sort=${sort}`
    );
  }

  /* get Candidate Review For One Company */
  getCandidateReviewForOneCompany(id_company: any, id_user: any) {
    return this._http.get<ApiResponse<any>>(
      this._base +
        `/ResponseSpecificReview/getResponsesEvaluateCompany?company_id=` +
        id_company +
        `&id=` +
        id_user
    );
  }

  addEvaluationCompany(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseSpecificReview/update`,
      data
    );
  }

  /* update evaluation */
  updateEvaluationCompany(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/ResponseSpecificReview/update`,
      data
    );
  }
}
