import { Component, ElementRef, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject, takeUntil } from 'rxjs';
import {
  blockSuccess,
  deblockSuccess,
  serverError,
} from '../../../../shared/shared-models/message';
import { environment } from '../../../../../environnements/environment';
import Swal from 'sweetalert2';
import { CompanyService } from '../../services/company.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ICountry } from 'country-state-city';
import { headerColumns } from '../../models/company';
import { PaysService } from '../../../../shared/services/pays.service';

@Component({
  selector: 'app-entreprises',
  templateUrl: './entreprises.component.html',
  styleUrls: ['./entreprises.component.css'],
})
export class EntreprisesComponent {
  /* any */
  file: any;
  selectedImage: any;
  /* modal */
  modalRef?: BsModalRef;
  /* object */
  responsableCompany: any;
  /* arrays */
  listCompany: any = [];
  dataHeader: any = [];
  sortAttr: string = '';
  headerColumns: { checked: boolean; name: string; code: string }[] =
    headerColumns;
  listNames: string[] = [];
  isLoadSpinner: boolean = true;
  isDisabled = true;

  typesCompanies = [
    { id: 1, name: 'Entreprise' },
    { id: 2, name: 'ESN' },
    { id: 3, name: 'Client Final' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ];
  /* number */
  totalItems!: number;
  itemsPerPage: number = 5;
  currentPage: number = 1;
  startIndex: number = 0;
  endIndex: number = 5;
  currentSortDirection: number = 2;
  company_type: number = 2;
  /* string */
  mode: string = 'create';
  url: string = environment.baseUrl + '/api';
  fileError: string = '';
  fileExtention: string = '';
  fileName: string = '';
  /* formGroup */
  companyForm: FormGroup = this.createCompanyForm();
  searchForm: FormGroup = this.createSearchForm();
  /* form data */
  formData = new FormData();
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  pagination: boolean = false;


  // Initial state
  isCollapsed = true;
  toggleCollapsible() {
    this.isCollapsed = !this.isCollapsed;
  }
  pays = [
    { id: 1, name: 'France' },
    { id: 2, name: 'Tunisie' },
    { id: 3, name: 'Turquie' },
    { id: 4, name: 'Allemagne' },
  ];

  salarie = [
    { id: 1, name: '0 - 20' },
    { id: 2, name: '20 - 50' },
    { id: 3, name: '50 - 100' },
    { id: 3, name: '100 - 200' },
    { id: 4, name: '> 200' },
  ];
  typeEntreprise = [
    { id: 2, name: 'ESN' },
    { id: 4, name: 'Cabinet de recrutement' },
    { id: 3, name: 'Client final' },
  ];
  note: number = 4;
  stars: number[] = Array(5).fill(0);
  ispagination: boolean = true;
  translations: { [key: string]: string } = {
    Afghanistan: 'Afghanistan',
    'South Africa': 'Afrique du Sud',
    Albania: 'Albanie',
    Algeria: 'Algérie',
    Germany: 'Allemagne',
    Andorra: 'Andorre',
    Angola: 'Angola',
    Anguilla: 'Anguilla',
    Antarctica: 'Antarctique',
    'Antigua and Barbuda': 'Antigua-et-Barbuda',
    'Saudi Arabia': 'Arabie saoudite',
    Argentina: 'Argentine',
    Armenia: 'Arménie',
    Aruba: 'Aruba',
    Australia: 'Australie',
    Austria: 'Autriche',
    Azerbaijan: 'Azerbaïdjan',
    Bahamas: 'Bahamas',
    Bahrain: 'Bahreïn',
    Bangladesh: 'Bangladesh',
    Barbados: 'Barbade',
    Belgium: 'Belgique',
    Belize: 'Belize',
    Benin: 'Bénin',
    Bermuda: 'Bermudes',
    Bhutan: 'Bhoutan',
    Belarus: 'Biélorussie',
    Bolivia: 'Bolivie',
    'Bosnia and Herzegovina': 'Bosnie-Herzégovine',
    Botswana: 'Botswana',
    Brazil: 'Brésil',
    Brunei: 'Brunei',
    Bulgaria: 'Bulgarie',
    'Burkina Faso': 'Burkina Faso',
    Burundi: 'Burundi',
    'Cape Verde': 'Cabo Verde',
    Cambodia: 'Cambodge',
    Cameroon: 'Cameroun',
    Canada: 'Canada',
    Chile: 'Chili',
    China: 'Chine',
    Cyprus: 'Chypre',
    Colombia: 'Colombie',
    Comoros: 'Comores',
    'Congo - Brazzaville': 'Congo-Brazzaville',
    'Congo - Kinshasa': 'Congo-Kinshasa',
    'North Korea': 'Corée du Nord',
    'South Korea': 'Corée du Sud',
    'Costa Rica': 'Costa Rica',
    'Ivory Coast': 'Côte d’Ivoire',
    Croatia: 'Croatie',
    Cuba: 'Cuba',
    Curaçao: 'Curaçao',
    Denmark: 'Danemark',
    Djibouti: 'Djibouti',
    Dominica: 'Dominique',
    'Dominican Republic': 'République dominicaine',
    Ecuador: 'Équateur',
    Egypt: 'Égypte',
    'United Arab Emirates': 'Émirats arabes unis',
    Eritrea: 'Érythrée',
    Spain: 'Espagne',
    Eswatini: 'Eswatini',
    Estonia: 'Estonie',
    'United States': 'États-Unis',
    Ethiopia: 'Éthiopie',
    Fiji: 'Fidji',
    Finland: 'Finlande',
    France: 'France',
    Gabon: 'Gabon',
    Gambia: 'Gambie',
    Georgia: 'Géorgie',
    Ghana: 'Ghana',
    Gibraltar: 'Gibraltar',
    Greece: 'Grèce',
    Grenada: 'Grenade',
    Greenland: 'Groenland',
    Guadeloupe: 'Guadeloupe',
    Guam: 'Guam',
    Guatemala: 'Guatemala',
    Guernsey: 'Guernesey',
    Guinea: 'Guinée',
    'Guinea-Bissau': 'Guinée-Bissau',
    'Equatorial Guinea': 'Guinée équatoriale',
    Guyana: 'Guyana',
    'French Guiana': 'Guyane française',
    Haiti: 'Haïti',
    Honduras: 'Honduras',
    'Hong Kong': 'Hong Kong',
    Hungary: 'Hongrie',
    Iceland: 'Islande',
    India: 'Inde',
    Indonesia: 'Indonésie',
    Iraq: 'Irak',
    Iran: 'Iran',
    Ireland: 'Irlande',
    'Isle of Man': 'Île de Man',
    Israel: 'Israël',
    Italy: 'Italie',
    Jamaica: 'Jamaïque',
    Japan: 'Japon',
    Jersey: 'Jersey',
    Jordan: 'Jordanie',
    Kazakhstan: 'Kazakhstan',
    Kenya: 'Kenya',
    Kiribati: 'Kiribati',
    Kuwait: 'Koweït',
    Kyrgyzstan: 'Kirghizistan',
    Laos: 'Laos',
    Latvia: 'Lettonie',
    Lebanon: 'Liban',
    Lesotho: 'Lesotho',
    Liberia: 'Libéria',
    Libya: 'Libye',
    Liechtenstein: 'Liechtenstein',
    Lithuania: 'Lituanie',
    Luxembourg: 'Luxembourg',
    Macao: 'Macao',
    'North Macedonia': 'Macédoine du Nord',
    Madagascar: 'Madagascar',
    Malawi: 'Malawi',
    Malaysia: 'Malaisie',
    Maldives: 'Maldives',
    Mali: 'Mali',
    Malta: 'Malte',
    'Marshall Islands': 'Îles Marshall',
    Martinique: 'Martinique',
    Mauritania: 'Mauritanie',
    Mauritius: 'Maurice',
    Mayotte: 'Mayotte',
    Mexico: 'Mexique',
    Micronesia: 'Micronésie',
    Moldova: 'Moldavie',
    Monaco: 'Monaco',
    Mongolia: 'Mongolie',
    Montenegro: 'Monténégro',
    Montserrat: 'Montserrat',
    Morocco: 'Maroc',
    Mozambique: 'Mozambique',
    Myanmar: 'Myanmar',
    Namibia: 'Namibie',
    Nauru: 'Nauru',
    Nepal: 'Népal',
    Netherlands: 'Pays-Bas',
    'New Caledonia': 'Nouvelle-Calédonie',
    'New Zealand': 'Nouvelle-Zélande',
    Nicaragua: 'Nicaragua',
    Niger: 'Niger',
    Nigeria: 'Nigeria',
    Niue: 'Niue',
    'Norfolk Island': 'Île Norfolk',
    'Northern Mariana Islands': 'Îles Mariannes du Nord',
    Norway: 'Norvège',
    Oman: 'Oman',
    Pakistan: 'Pakistan',
    Palau: 'Palaos',
    Palestine: 'Palestine',
    Panama: 'Panama',
    'Papua New Guinea': 'Papouasie-Nouvelle-Guinée',
    Paraguay: 'Paraguay',
    Peru: 'Pérou',
    Philippines: 'Philippines',
    'Pitcairn Islands': 'Îles Pitcairn',
    Poland: 'Pologne',
    Portugal: 'Portugal',
    'Puerto Rico': 'Porto Rico',
    Qatar: 'Qatar',
    Romania: 'Roumanie',
    Russia: 'Russie',
    Rwanda: 'Rwanda',
    Réunion: 'Réunion',
    'Saint Barthélemy': 'Saint-Barthélemy',
    'Saint Helena': 'Sainte-Hélène',
    'Saint Kitts and Nevis': 'Saint-Kitts-et-Nevis',
    'Saint Lucia': 'Sainte-Lucie',
    'Saint Martin': 'Saint-Martin',
    'Saint Pierre and Miquelon': 'Saint-Pierre-et-Miquelon',
    'Saint Vincent and the Grenadines': 'Saint-Vincent-et-les-Grenadines',
    Samoa: 'Samoa',
    'San Marino': 'Saint-Marin',
    'Sao Tome and Principe': 'Sao Tomé-et-Principe',
    Senegal: 'Sénégal',
    Serbia: 'Serbie',
    Seychelles: 'Seychelles',
    'Sierra Leone': 'Sierra Leone',
    Singapore: 'Singapour',
    'Sint Maarten': 'Saint-Martin',
    Slovakia: 'Slovaquie',
    Slovenia: 'Slovénie',
    'Solomon Islands': 'Îles Salomon',
    Somalia: 'Somalie',
    'South Georgia and the South Sandwich Islands':
      'Géorgie du Sud-et-les Îles Sandwich du Sud',
    'South Sudan': 'Soudan du Sud',
    'Sri Lanka': 'Sri Lanka',
    Sudan: 'Soudan',
    Suriname: 'Suriname',
    'Svalbard and Jan Mayen': 'Svalbard et Jan Mayen',
    Swaziland: 'Eswatini',
    Sweden: 'Suède',
    Switzerland: 'Suisse',
    Syria: 'Syrie',
    Taiwan: 'Taïwan',
    Tajikistan: 'Tadjikistan',
    Tanzania: 'Tanzanie',
    Thailand: 'Thaïlande',
    'Timor-Leste': 'Timor-Leste',
    Togo: 'Togo',
    Tokelau: 'Tokelau',
    Tonga: 'Tonga',
    'Trinidad and Tobago': 'Trinité-et-Tobago',
    Tunisia: 'Tunisie',
    Turkey: 'Turquie',
    Turkmenistan: 'Turkménistan',
    Tuvalu: 'Tuvalu',
    Uganda: 'Ouganda',
    Ukraine: 'Ukraine',
    'United Kingdom': 'Royaume-Uni',
    Uruguay: 'Uruguay',
    Uzbekistan: 'Ouzbékistan',
    Vanuatu: 'Vanuatu',
    'Vatican City': 'Vatican',
    Venezuela: 'Venezuela',
    Vietnam: 'Viêt Nam',
    'Western Sahara': 'Sahara occidental',
    Yemen: 'Yémen',
    Zambia: 'Zambie',
    Zimbabwe: 'Zimbabwe',
  };

  paysList: ICountry[] = [];
  typeCompany = [
    // { id: 1, name: 'Entreprise' },
    { id: 2, name: 'ESN' },
    { id: 3, name: 'Client Final' },
    { id: 4, name: 'Cabinet de Recrutement' },
  ]
  Stateesn = [
    { id: 0, name: 'Non Bloqué' },
    { id: 1, name: 'Bloqué' },
  ]
  currentSortColumn: string = 'name'; // Initial column to sort by

  constructor(
    private companyService: CompanyService,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private router: Router,
    private paysService: PaysService,
    private sortDataService: CompanyService,
    private elRef: ElementRef
  ) { }
  ngOnInit() {
    this.paysList = this.paysService.getAllCountries();
    this.getListCompanies();
    this.getListElementHeader();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }

  closeDropdownOnScroll(): void {
    // Find the open dropdown
    const dropdown = this.elRef.nativeElement.querySelector('.dropdown-menu.show');

    // If the dropdown is open, remove the 'show' class to close it without triggering a page scroll
    if (dropdown) {
      dropdown.classList.remove('show');
      console.log('HEYYYYYYYYYYYY');
    }
  }
  /* get indexes for pagination */
  getItems(event?: any) {
    if (event) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
      if (this.searchForm.dirty) {
        this.searchCompnies();
      }
      else if ((this.startIndex != 0 || this.endIndex != 5) || this.pagination == true) {
        this.pagination = true

        this.getListCompanies();
      }
    }
  }
  company = {
    average_note: 1, // or the initial value of your note
  };

  onRatingChange(newRating: number) {
    if (newRating < 1) {
      this.company.average_note = 1;
    } else {
      this.company.average_note = newRating;
    }
  }
  translateCountryName(name: string): string {
    return this.translations[name] || name;
  }
  // sortData(item: string) {
  //   for (const iterator of headerColumns) {
  //     if (item == iterator.name) item = iterator.code;
  //   }
  //   let sort = { sort: this.currentSortDirection, sortAttribute: this.currentSortColumn }

  //   if (this.currentSortColumn === item) {
  //     this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1; // Toggle sort direction
  //   } else {
  //     this.currentSortColumn = item; // Change the sort column
  //     this.currentSortDirection = 1; // Reset sort direction to ascending
  //   }
  //   this.getListCompanies(sort);
  // }
  sortData(name?: string) {
    for (const iterator of this.headerColumns) {
      if (iterator.name == name) this.sortAttr = iterator.code;
    }
    this.currentSortDirection = this.currentSortDirection === 1 ? 2 : 1;
    this.currentPage = 1;
    this.endIndex = 5;
    if (this.searchForm.dirty)
      this.listCompany = this.sortDataService.sortArray(
        this.listCompany,
        name,
        this.currentSortDirection
      );
    else this.getListCompanies();
  }
  isHalfStar(index: number, averageNote: number): boolean {
    return index + 0.5 === averageNote && !Number.isInteger(averageNote);
  }

  getListCompanies(sort?: any) {
    // let dataPayload = !sort ? { per_page: this.itemsPerPage, page: this.currentPage, type: this.company_type, } : { ...{ per_page: this.itemsPerPage, page: this.currentPage, type: this.company_type }, ...sort }
    let dataPyload =
      this.sortAttr == ''
        ? {
          per_page: this.itemsPerPage,
          page: this.currentPage,
          type: this.company_type,
        }
        : {
          ...{
            per_page: this.itemsPerPage,
            page: this.currentPage,
            type: this.company_type,
          },
          ...{
            sort: this.currentSortDirection,
            sortAttribute: this.sortAttr,
          },
        };

    this.spinner.show();
    this.companyService.listCompany(dataPyload).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (response) => {
        this.isLoadSpinner = false;
        this.listCompany = response.data.data
        // .slice(0, this.endIndex);
        this.totalItems = response.data.total;

        this.spinner.hide();
        console.log('%centreprises.component.ts line:114 listCompany+++', 'color: #007acc;', this.listCompany);
      },
      error: () => {
        this.spinner.hide();
      },
    });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { backdrop: 'static' });
  }

  closeModal() {
    this.modalRef?.hide();
    this.companyForm.reset();
    this.formData = new FormData();
    this.file = null;
    this.fileError = '';
    this.fileExtention = '';
  }

  /* create add subCategory form */
  createCompanyForm(data?: any) {
    return this.formBuilder.group({
      name: [data ? data.name : '', [Validators.required]],
      type: [data ? data.type : null, [Validators.required]],
      linkedin_link: [data ? data.linkedin_link : '', [Validators.required]],
      website_link: [data ? data.website_link : '', [Validators.required]],
      logo: [data ? data.logo : ''],
    });
  }

  /* set form data */
  setFormData() {
    this.formData.append('name', this.companyForm.value.name);
    this.formData.append('type', this.companyForm.value.type);
    this.formData.append('linkedin_link', this.companyForm.value.linkedin_link);
    this.formData.append('website_link', this.companyForm.value.website_link);
    if (this.file) this.formData.append('logo', this.file);
  }

  /************************** navigation company ********************* */
  profilCompanyNavigation(id: any, idRole: string) {
    this.router.navigate(['/wegestu/entreprises/details', { id: id, idRole: idRole }])
  }

  /************************** navigation contact responsable ********************* */
  contactResponsableNavigation(id: any) {
    this.router.navigate([
      '/wegestu/contact/responsable',
      { idResponsable: id },
    ]);
  }

  /* ************************* navigation contact responsable ********************* */
  reviewCompanyNavigation(id: any, redirection: any) {
    this.router.navigate(['/wegestu/evaluations/review-company', { idCompany: id, redirection: redirection }])
  }

  /* search form */
  createSearchForm(data?: any) {
    return this.formBuilder.group({
      ID_company: [data ? data.ID_company : ''],
      name: [data ? data.name : ''],
      note: [data ? data.note : 0],
      typeComp: [data ? data.typeComp : null],
      blocked: [data ? data.blocked : null],
      country: [data ? data.country : null],
      search: ['', [Validators.pattern(/"([^"]*"(and|not|,|&&)[^"]*)*"/)]],
    });
  }
  /* search */
  transformSearch(search: string): string {
    search = search.replace(/\|\|/g, 'OR');
    search = search.replace(/&&/g, 'AND');
    search = search.replace(/!/g, 'NOT');

    search = search.replace(/"([^"]+)"|(\S+)/g, (match, p1, p2) => {
      if (p1) {
        return `"${p1}"`;
      } else {
        const escapedTerm = p2.replace(/"/g, '\\"');
        return `"${escapedTerm}"`;
      }
    });

    return search;
  }
  isSimpleWord(value: string): boolean {
    const simpleWordPattern = /^[a-zA-Z0-9]+(\s[a-zA-Z0-9]+)?$/;
    return simpleWordPattern.test(value);
  }
  searchCompnies() {
    let data = {
      name: this.searchForm.value?.name,
      typeComp: !this.searchForm.value.typeComp
        ? ''
        : this.searchForm.value.typeComp.toString(),
      country: !this.searchForm.value.country
        ? []
        : this.searchForm.value.country,
      note: this.searchForm.value.note,
      ID_company: this.searchForm.value.ID_company,
      page: this.currentPage,
      per_page: this.itemsPerPage,
      blocked: this.searchForm.value.blocked,
      search: this.transformSearch(this.searchForm.value?.search)
    }
    this.spinner.show()
    if (data.note === 0) {
      console.log("note")
      data.note = ""
    }
    console.log('data search', data);
    this.companyService
      .searchCompany(data)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.isRecherche = true;
          console.log('response search', res);
          console.log('is pagination', this.ispagination);
          console.log('page', data.page);
          //
          console.log('current page', this.currentPage);
          if (this.currentPage > 1 && !this.ispagination) {
            console.log('ifffff');
            this.currentPage = 1;
            this.searchCompnies();
            this.spinner.hide();
          } else {
            console.log('else');
            this.listCompany = res.data.data.slice(0, this.endIndex);
            this.totalItems = res.data.total;
            this.listCompany.forEach((company: any) =>
              this.convertAverageNoteToNumber(company)
            );

            this.ispagination = true;
            this.spinner.hide();
          }
          this.spinner.hide();
          //  this.search=false;
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  private convertAverageNoteToNumber(company: any): void {
    company.average_note = parseFloat(company.average_note);
  }
  isRecherche: boolean = false;
  reset() {
    this.currentPage = 1;
    this.searchForm.reset();
    this.searchForm = this.createSearchForm();
    if (!this.isRecherche) {
      this.spinner.hide();
    } else {
      this.spinner.hide();
      this.getListCompanies();
    }
    return (this.isRecherche = false);
  }

  getListCompaniesreset(sort?: any) {
    let dataPayload = !sort
      ? {
        per_page: this.itemsPerPage,
        page: this.currentPage,
        type: this.company_type,
      }
      : {
        ...{
          per_page: this.itemsPerPage,
          page: this.currentPage,
          type: this.company_type,
        },
        ...sort,
      };

    this.companyService
      .listCompany(dataPayload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (response) => {
          if (response.data.data.length) {
            this.listCompany = response.data.data.slice(0, this.endIndex);
            this.totalItems = response.data.total;

            console.log(
              '%centreprises.component.ts line:114 listCompany+++',
              'color: #007acc;',
              this.listCompany
            );
          }
        },
        error: () => { },
      });
  }
  bloquer(idCompany: any) {
    Swal.fire({
      title: 'Es-tu sûr?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'oui, bloquer',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('%centreprises.component.ts line:212 idCompany+++', 'color: #007acc;', idCompany);
        this.blockCompany(idCompany)
        this.searchCompnies()
        // window.location.reload()
      }
    });
  }

  debloquer(idCompany: any) {
    Swal.fire({
      title: 'Es-tu sûr?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'oui, débloquer',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deblockCompany(idCompany);
        this.searchCompnies();

      }
    });
  }

  /* block  company */
  blockCompany(idCompany: any) {

    this.spinner.show()
    console.log('%centreprises.component.ts line:221 idCompany', 'color: #007acc;', idCompany);
    this.companyService.blockCompany(idCompany).pipe(takeUntil(this.unsubscribeAll)).subscribe({
      next: (res) => {
        console.log("response blocked", res)
        this.spinner.hide()
        this.toastr.success(blockSuccess)
      },
      error: () => {
        this.spinner.hide()
        this.toastr.error(serverError)
      },
    })
  }
  shouldDisplayDerniereConnexion: boolean = true;
  changeSelection(event: any, data: any, i: number) {
    this.listNames = [];
    data.checkAdmin = event.target.checked;

    for (const iterator of this.dataHeader) {
      if (iterator.checkAdmin) {
        this.listNames.push(iterator.name);
      }
    }
    this.changeSelectionHeadrElements(data);
  }

  changeSelectionHeadrElements(data: any) {
    let payload = {
      sub_page_id: data?.id,
    };
    this.companyService
      .changeDelectedElementHeader(payload)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: () => { },
        error: () => { },
      });
  }
  getListElementHeader() {
    this.companyService
      .getHeaderElements(8)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.dataHeader = res.data;
          for (const iterator of this.dataHeader) {
            if (
              (iterator.checked == 'oui' && !iterator.checked_user) ||
              (iterator.checked == 'non' && iterator.checked_user == 'oui') ||
              (iterator.checked == 'oui' && iterator.checked_user == 'oui')
            )
              this.listNames.push(iterator.name);
            console.log('listNames', this.listNames);
          }
          this.dataHeader.map((el: any) => {
            if (
              (el.checked == 'oui' && !el.checked_user) ||
              (el.checked_user == 'oui' &&
                (el.checked == 'non' || el.checked == 'oui'))
            ) {
              return (el.checkAdmin = true);
            } else {
              return (el.checkAdmin = false);
            }
          });
        },
        error: () => { },
      });
  }
  handleRatingClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (target.classList.contains('bs-rating-star')) {
      // Check if the "active" class is present
      const isActive = target.classList.contains('active');
      // Toggle the "active" class
      target.classList.toggle('active', !isActive);
      this.ispagination = false;
    }
  }
  /* deblock  company */
  deblockCompany(idCompany: any) {
    this.spinner.show();
    this.companyService
      .deblockCompany(idCompany)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.spinner.hide();
          this.toastr.success(deblockSuccess);
        },
        error: () => {
          this.spinner.hide();
          this.toastr.error(serverError);
        },
      });
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
    const scrollableTable = this.elRef.nativeElement.querySelector('#kt_customers_table');
    scrollableTable?.addEventListener('scroll', this.closeDropdownOnScroll.bind(this));
  }
}
