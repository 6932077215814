import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';

import { DossierRoutingModule } from './dossier-routing.module';
import { DossierComptComponent } from './components/dossier-compt/dossier-compt.component';
import { ModifierDossierComponent } from './components/modifier-dossier/modifier-dossier.component';
import { TagInputModule } from 'ngx-chips';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorMessageComponent } from '../../shared/error-message/error-message.component';
import { FieldErrorModule } from '../../shared/shared-field-error/field-error.module';
import { NgxIntlTelephoneInputModule } from 'ngx-intl-telephone-input';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { PaginationModule } from '../../shared/pagination/pagination.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CvCandidatComponent } from './components/cv-candidat/cv-candidat.component';
import { RatingModule } from 'ngx-bootstrap/rating';
import { SearchFilterPipe } from './pipes/SearchFilterPipe';
import { CalendarModule } from 'primeng/calendar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { QuillModule } from 'ngx-quill';
// import { MatFormFieldModule } from '@angular/material/form-field';
// import { MatChipsModule } from '@angular/material/chips';
// import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    // QcmComponent
    DossierComptComponent,
    ModifierDossierComponent,
    CvCandidatComponent,
    SearchFilterPipe,
  ],
  imports: [
    CommonModule,
    TagInputModule,
    DossierRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    FieldErrorModule,
    ErrorMessageComponent,
    NgxIntlTelephoneInputModule,
    NgxIntlTelInputModule,
    PaginationModule,
    NgxSpinnerModule,
    RatingModule.forRoot(),
    MatFormFieldModule, 
    MatChipsModule, 
    MatIconModule,
   CalendarModule,
   QuillModule.forRoot({
    modules:{
      syntax:true,
     
    }
 })
  


  ],
  providers: [DatePipe],  // Make sure DatePipe is provided here
})
export class DossierModule {}
