
export interface IPerson{
  first_name?:string ;
  last_name ?:string ;
  phone_number  ?:string ;
  email?:string ;
  personal_email?:string;
  password?:string ;
  source?:string ;
  terme?:string ;
  confirmedPassword?:string
}
export class CLogin implements IPerson {
  email: string | undefined;
  password: string | undefined;
  confirmedPassword: string | undefined;

  constructor(model?: CLogin) {
      this.email = model && model.email || "";
      this.password = model && model.password || "";
      this.confirmedPassword= model && model.confirmedPassword || "";
  }
}
export class CRegister extends CLogin {
  first_name : string| undefined;
  last_name : string| undefined;
  phone_number  : string| undefined;
  personal_email : string| undefined;
  source : string| undefined;
  terme : string| undefined;
  constructor(data:IPerson) {
    super({email:data.personal_email,password:data.password,confirmedPassword:data.confirmedPassword}); // Call the constructor of the parent class
    this.first_name =data && data.first_name || "";
    this.last_name =data && data.last_name || "";
    this.phone_number  =data && data.phone_number  || "";
    this.source  =data && data.source  || "";
    this.terme  =data && data.terme  || "";


  }
}
export class CPassoword{
  newPassword: string | undefined;
  oldPassword: string | undefined;
  constructor(model?: CPassoword) {
      this.newPassword = model && model.newPassword || "";
      this.oldPassword = model && model.oldPassword || "";
  }
}


export class ChangePassoword{
  newPassword: string | undefined;
  oldPassword: string | undefined;
  confirmNewPassword: string | undefined;
  constructor(model?:ChangePassoword) {
      this.newPassword = model && model.newPassword || "";
      this.oldPassword = model && model.oldPassword || "";
      this.confirmNewPassword = model && model.confirmNewPassword || "";
  }
}


