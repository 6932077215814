<div id="kt_app_toolbar" class="app-toolbar py-3 py-lg-6">
    <!--begin::Toolbar container-->
    <div id="kt_app_toolbar_container" class="app-container container-fluid d-flex flex-stack">
        <!--begin::Page title-->
        <div class="page-title d-flex flex-column justify-content-center flex-wrap me-3">
            <!--begin::Title-->
            <div class="d-flex align-items-center">
                <h1 class="page-heading">
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g filter="url(#filter0_d_458_4123)">
                            <path
                                d="M17.7089 14.3632C19.8695 14.3632 21.621 12.6165 21.621 10.4619C21.621 8.30723 19.8695 6.56055 17.7089 6.56055C15.5484 6.56055 13.7969 8.30723 13.7969 10.4619C13.7969 12.6165 15.5484 14.3632 17.7089 14.3632Z"
                                fill="#265D91" />
                            <path
                                d="M21.5657 15.577H13.8462C12.9039 15.577 12.1406 16.3391 12.1406 17.2788V18.3751C12.2692 18.5271 12.4013 18.6774 12.544 18.8189C13.9233 20.1953 15.7566 20.9521 17.706 20.9521C19.6562 20.9521 21.4895 20.1953 22.868 18.8197C23.0107 18.6774 23.1428 18.5271 23.2722 18.3751V17.2788C23.2722 16.34 22.508 15.577 21.5657 15.577Z"
                                fill="#265D91" />
                            <path
                                d="M30.4491 12.7065H29.146C28.9217 10.011 27.7684 7.50562 25.8288 5.57219C23.89 3.63788 21.3777 2.48861 18.6748 2.26495V0.965387C18.6748 0.432303 18.2413 0 17.7077 0C17.174 0 16.7414 0.432303 16.7414 0.965387V2.26495C14.0385 2.48861 11.5262 3.63877 9.5875 5.57219C7.64788 7.5065 6.49456 10.011 6.27028 12.7065H4.96715C4.4326 12.7065 4 13.1379 4 13.6719C4 14.2041 4.43349 14.6355 4.96715 14.6355H6.27028C6.49456 17.331 7.64788 19.8364 9.5875 21.7698C11.5271 23.7041 14.0385 24.8543 16.7423 25.078V26.3775C16.7423 26.9106 17.1749 27.342 17.7086 27.342C18.2422 27.342 18.6757 26.9106 18.6757 26.3775V25.078C21.3777 24.8543 23.8909 23.7033 25.8296 21.7698C27.7693 19.8355 28.9226 17.331 29.1469 14.6355H30.45C30.9845 14.6355 31.4171 14.2041 31.4171 13.671C31.4171 13.1379 30.9837 12.7065 30.4491 12.7065ZM23.9795 19.9257C22.3041 21.5974 20.0773 22.5177 17.7077 22.5177C15.3381 22.5177 13.1113 21.5974 11.4358 19.9257C9.76037 18.2548 8.83754 16.0341 8.83754 13.6719C8.83754 11.3079 9.76125 9.08719 11.4358 7.41633C13.1113 5.74547 15.3381 4.82517 17.7068 4.82517C20.0764 4.82517 22.3041 5.74547 23.9787 7.41633C25.6541 9.08808 26.5769 11.3079 26.5769 13.6719C26.5778 16.0341 25.655 18.2557 23.9795 19.9257Z"
                                fill="#265D91" />
                        </g>
                        <defs>
                            <filter id="filter0_d_458_4123" x="0" y="0" width="35.418" height="35.342"
                                filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                <feColorMatrix in="SourceAlpha" type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                <feOffset dy="4" />
                                <feGaussianBlur stdDeviation="2" />
                                <feComposite in2="hardAlpha" operator="out" />
                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_458_4123" />
                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_458_4123"
                                    result="shape" />
                            </filter>
                        </defs>
                    </svg>


                    Client Final
                </h1>

                <!--begin::Menu-->

            </div>
            <!--end::Title-->
            <!--begin::Breadcrumb-->
            <ul class="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">
                    <a [routerLink]="['/wegestu/profil-candidat']" class="text-muted" routerLinkActive="router-link-active" >Accueil</a>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item">
                    <span class="bullet bg-gray-400 w-5px h-2px"></span>
                </li>
                <!--end::Item-->
                <!--begin::Item-->
                <li class="breadcrumb-item text-muted">Listes des clients finaux</li>


                <!--end::Item-->
            </ul>
            <!--end::Breadcrumb-->
        </div>
        <!--end::Page title-->
        <!--begin::Actions-->

        <!--end::Actions-->
    </div>
    <!--end::Toolbar container-->
</div>
<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">
            <!--begin::Card header-->

            <div class="card-body tools">
                <div class="title mb-10">
                    <h4>Recherche</h4>
                </div>
                <div class="container">
                    <div class="row mb-3">
                        <div class="col-lg-4">
                            <div class="input-shadow">
                                <input type="text" name="Nom" id="Nom" class="form-control form-control-solid mb-3"
                                    placeholder="Nom">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="text" name="Responsable" id="Responsable"
                                class="form-control form-control-solid mb-3" placeholder="Responsable">
                        </div>
                        <div class="col-lg-4">
                            <select class="form-select" data-control="select2" data-placeholder="In Progress"
                                data-hide-search="true">
                                <option value="">Domaine</option>
                                <option value="1">Pays de résidence</option>
                                <option value="2" selected="selected">Pays de résidence</option>
                                <option value="3">Tunisie</option>
                            </select>

                        </div>

                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-4">
                            <input type="text" name="Nombre de salarié" id="Nombre de salarié"
                                class="form-control form-control-solid mb-3" placeholder="Nombre de salarié">
                        </div>
                        <div class="col-lg-4">
                            <select data-control="select2" data-placeholder="Select a format" data-hide-search="true"
                                name="format" class="form-select mb-3">
                                <option value="excell">Salarié</option>
                                <option value="pdf">1 ans</option>
                                <option value="cvs">2 ans</option>
                                <option value="zip">+ 3 ans</option>
                            </select>
                        </div>
                        <div class="col-lg-4">
                            <input type="text" name="Consultant externe" id="Consultant externe"
                                class="form-control form-control-solid mb-3" placeholder="Consultant externe">

                        </div>

                    </div>

                    <div class="row mb-3">
                        <div class="col-lg-4">
                            <input type="text" name="Nbre consultant externe" id="Nbre consultant externe"
                                class="form-control form-control-solid mb-3" placeholder="Nbre consultant externe">
                        </div>
                        <div class="col-lg-4">
                            <input type="text" name="Note" id="Note" class="form-control form-control-solid mb-3"
                                placeholder="Note">

                        </div>
                        <div class="col-lg-4">

                        </div>

                    </div>
                    <div class="row mb-3 rechercher">
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4"></div>
                        <div class="col-lg-4">
                            <div class="btn_search">
                                <a href="#" class="search"> <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M19.7235 18.263L16.0223 14.5657C18.8541 11.0421 18.5809 5.83122 15.3515 2.63023C13.6625 0.942973 11.4019 0 8.9923 0C6.58271 0 4.34709 0.942973 2.63307 2.63023C0.943993 4.31749 0 6.5756 0 8.98259C0 11.3895 0.943993 13.6228 2.63307 15.3349C4.32216 17.0222 6.58271 17.9652 8.9923 17.9652C11.0293 17.9652 13.0165 17.2704 14.6063 16.005L18.3076 19.7023C18.5064 19.9008 18.7547 20 19.0032 20C19.2764 20 19.5249 19.9008 19.6987 19.7023C20.0962 19.2805 20.0962 18.6601 19.7235 18.2631L19.7235 18.263ZM15.9973 8.95782C15.9973 10.8189 15.2771 12.5807 13.9603 13.8958C12.6438 15.2109 10.88 15.9306 9.01701 15.9306C7.154 15.9306 5.39017 15.2111 4.0737 13.8958C2.75714 12.5806 2.03667 10.8188 2.03667 8.95782C2.03667 7.09682 2.75697 5.3349 4.0737 4.01985C5.39026 2.70472 7.154 1.98502 9.01701 1.98502C10.88 1.98502 12.6438 2.70454 13.9603 4.01985C15.2771 5.33499 15.9973 7.09682 15.9973 8.95782Z"
                                            fill="white" />
                                    </svg>
                                    Rechercher</a>
                            </div>
                        </div>
                    </div>
                </div>


            </div>
        </div>

    </div>
    <!--end::Content container-->
</div>

<div id="kt_app_content" class="app-content flex-column-fluid mb-4">
    <!--begin::Content container-->
    <div id="kt_app_content_container" class="app-container container-fluid">
        <!--begin::Card-->
        <div class="card">

            <!--begin::Card header-->
            <div class="d-flex flex-wrap my-1 header-grid align-items-center">
                <div class="section_title">
                    <h1 class="section-heading my-0">Listes des clients finaux</h1>
                </div>
                <!--begin::Tab nav-->
                <ul class="nav nav-pills me-5">
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary  me-3"
                            data-bs-toggle="tab" href="#kt_project_targets_card_pane">
                            <i class="ki-duotone ki-element-plus fs-1">
                                <span class="path1"></span>
                                <span class="path2"></span>
                                <span class="path3"></span>
                                <span class="path4"></span>
                                <span class="path5"></span>
                            </i>
                        </a>
                    </li>
                    <li class="nav-item m-0">
                        <a class="btn btn-sm btn-icon btn-light btn-color-muted btn-active-primary active"
                            data-bs-toggle="tab" href="#kt_project_targets_table_pane">
                            <i class="ki-duotone ki-row-horizontal fs-2">
                                <span class="path1"></span>
                                <span class="path2"></span>
                            </i>
                        </a>
                    </li>
                </ul>
                <!--end::Tab nav-->
                <!--begin::Wrapper-->

                <!--end::Wrapper-->
            </div>
            <!--end::Card header-->
            <!--begin::Card body-->
            <div class="card-body pt-0">
                <div class="tab-content">
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_card_pane" class="tab-pane fade ">
                        <!--begin::Row-->
                        <div class="row g-6 g-xl-9">
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1 </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap">
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                                <div class="fw-semibold text-gray-400">Année création</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">23</div>
                                                <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                                <div class="fw-semibold text-gray-400">Note</div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Domaine 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1
                                        </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap">
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                                <div class="fw-semibold text-gray-400">Année création</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">23</div>
                                                <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                                <div class="fw-semibold text-gray-400">Note</div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Domaine 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1
                                        </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap">
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                                <div class="fw-semibold text-gray-400">Année création</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">23</div>
                                                <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                                <div class="fw-semibold text-gray-400">Note</div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Domaine 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1
                                        </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap">
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                                <div class="fw-semibold text-gray-400">Année création</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">23</div>
                                                <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                                <div class="fw-semibold text-gray-400">Note</div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Domaine 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">ESN 1
                                        </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap">
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$14,560</div>
                                                <div class="fw-semibold text-gray-400">Année création</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">23</div>
                                                <div class="fw-semibold text-gray-400">Nombre salarié</div>
                                            </div>
                                            <!--end::Stats-->
                                            <!--begin::Stats-->
                                            <div
                                                class="border border-gray-300 border-dashed rounded min-w-80px py-3 px-4 mx-2 mb-3">
                                                <div class="fs-6 fw-bold text-gray-700">$236,400</div>
                                                <div class="fw-semibold text-gray-400">Note</div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Domaine 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia
                                            Meddeb </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap mt-5">
                                            <!--begin::Stats-->
                                            <div class="skills">
                                                <div class="skill">
                                                    <span class="badge style1">Python</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style2">Laravel</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style3">C#</span>
                                                </div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Poste 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia
                                            Meddeb </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap mt-5">
                                            <!--begin::Stats-->
                                            <div class="skills">
                                                <div class="skill">
                                                    <span class="badge style1">Python</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style2">Laravel</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style3">C#</span>
                                                </div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Poste 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia
                                            Meddeb </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap mt-5">
                                            <!--begin::Stats-->
                                            <div class="skills">
                                                <div class="skill">
                                                    <span class="badge style1">Python</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style2">Laravel</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style3">C#</span>
                                                </div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Poste 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                            <!--begin::Col-->
                            <div class="col-md-6 col-xxl-4">
                                <!--begin::Card-->
                                <div class="card pt-5 p-5">
                                    <div class="tools">
                                        <div class="dropdown">
                                            <button
                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                type="button" id="dropdownMenuButton" data-bs-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                                <svg width="19" height="5" viewBox="0 0 19 5" fill="none"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 0 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)" fill="#265D91" />
                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)" fill="#265D91" />
                                                </svg>
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir
                                                    profil</a>
                                                <a class="dropdown-item">Postuler</a>
                                                <a class="dropdown-item">Ne jamais voir cette offre</a>
                                            </div>
                                        </div>
                                    </div>
                                    <!--begin::Card body-->
                                    <div class="card-body d-flex flex-center flex-column">
                                        <!--begin::Avatar-->
                                        <div class="symbol symbol-65px symbol-circle mb-5">
                                            <img src="assets/media//avatars/300-2.jpg" alt="image" />
                                            <div
                                                class="bg-success position-absolute border border-4 border-body h-15px w-15px rounded-circle translate-middle start-100 top-100 ms-n3 mt-n3">
                                            </div>
                                        </div>
                                        <!--end::Avatar-->
                                        <!--begin::Name-->
                                        <a class="fs-4 text-gray-800 text-hover-primary fw-bold mb-0">Samia
                                            Meddeb </a>
                                        <!--end::Name-->
                                        <!--begin::Position-->
                                        <!--end::Position-->
                                        <!--begin::Info-->
                                        <div class="d-flex flex-center flex-wrap mt-5">
                                            <!--begin::Stats-->
                                            <div class="skills">
                                                <div class="skill">
                                                    <span class="badge style1">Python</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style2">Laravel</span>
                                                </div>
                                                <div class="skill">
                                                    <span class="badge style3">C#</span>
                                                </div>
                                            </div>
                                            <!--end::Stats-->
                                        </div>

                                        <!--end::Info-->
                                    </div>
                                    <div class="foot p-5">
                                        <div class="poste">

                                            <span> Poste 1 </span>
                                        </div>
                                        <div class="plus">
                                            <a routerLink="/wegestu/client-final/details-client">Voir plus</a>
                                        </div>
                                    </div>
                                    <!--end::Card body-->
                                </div>
                                <!--end::Card-->
                            </div>
                            <!--end::Col-->
                        </div>
                        <!--end::Row-->
                    </div>
                    <!--end::Tab pane-->
                    <!--begin::Tab pane-->
                    <div id="kt_project_targets_table_pane" class="tab-pane fade  active show">
                        <div class="content">
                            <div class="card-body pt-3">
                                <!--begin::Table-->
                                <div class="table-responsive">
                                    <table class="table align-middle fs-6 gy-5"
                                        id="kt_customers_table">
                                        <thead>
                                            <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
                                                <!-- <th class="w-10px pe-2">
                                                <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                    <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_customers_table .form-check-input" value="1" />
                                                </div>
                                            </th> -->
                                                <th></th>
                                                <th class="min-w-125px">CLIENT</th>
                                                <th class="min-w-125px">DOMAINE</th>
                                                <th class="min-w-125px">NOMBRE DE SALARIé</th>
                                                <th class="min-w-125px">NOMBRE DE CONSULTANT EXTERNE </th>
                                                <th class="min-w-125px">note</th>
                                                <th class="min-w-125px">ANNéé DE CRéATION</th>
                                                <th class="min-w-90px">Actions</th>
                                                <th class="min-w-90px">
                                                    <div class="dropdown" id="table-filter">
                                                        <button
                                                            class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                            type="button" id="dropdownMenuButton"
                                                            data-bs-toggle="dropdown" aria-haspopup="true"
                                                            aria-expanded="false">
                                                            <svg width="28" height="28" viewBox="0 0 28 28" fill="none"
                                                                xmlns="http://www.w3.org/2000/svg">
                                                                <g filter="url(#filter0_d_444_2002)">
                                                                    <rect x="4" width="20" height="20" rx="4"
                                                                        fill="#63C3E8" fill-opacity="0.12"
                                                                        shape-rendering="crispEdges" />
                                                                </g>
                                                                <path
                                                                    d="M17.5 10.6H14.6V13.5C14.6 13.8314 14.3314 14.1 14 14.1C13.6686 14.1 13.4 13.8314 13.4 13.5V10.6H10.5C10.1686 10.6 9.9 10.3314 9.9 10C9.9 9.66863 10.1686 9.4 10.5 9.4H13.4V6.5C13.4 6.16863 13.6686 5.9 14 5.9C14.3314 5.9 14.6 6.16863 14.6 6.5V9.4H17.5C17.8314 9.4 18.1 9.66863 18.1 10C18.1 10.3314 17.8314 10.6 17.5 10.6Z"
                                                                    fill="#265D91" stroke="#265D91"
                                                                    stroke-width="0.2" />
                                                                <defs>
                                                                    <filter id="filter0_d_444_2002" x="0" y="0"
                                                                        width="28" height="28"
                                                                        filterUnits="userSpaceOnUse"
                                                                        color-interpolation-filters="sRGB">
                                                                        <feFlood flood-opacity="0"
                                                                            result="BackgroundImageFix" />
                                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                                            result="hardAlpha" />
                                                                        <feOffset dy="4" />
                                                                        <feGaussianBlur stdDeviation="2" />
                                                                        <feComposite in2="hardAlpha" operator="out" />
                                                                        <feColorMatrix type="matrix"
                                                                            values="0 0 0 0 0.14902 0 0 0 0 0.364706 0 0 0 0 0.568627 0 0 0 0.13 0" />
                                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                                            result="effect1_dropShadow_444_2002" />
                                                                        <feBlend mode="normal" in="SourceGraphic"
                                                                            in2="effect1_dropShadow_444_2002"
                                                                            result="shape" />
                                                                    </filter>
                                                                </defs>
                                                            </svg>


                                                        </button>
                                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    ESN
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Poste
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Compétences
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Année experience
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Salaire
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    TJM
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Ville
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Type de contrat
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Type de poste
                                                                </label>
                                                            </div>
                                                            <div
                                                                class="form-check form-check-custom form-check-solid mb-3">
                                                                <input class="form-check-input" type="checkbox"
                                                                    value="1" id="flexCheckDefault" checked />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    Date
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-semibold text-gray-600">
                                            <tr>

                                                <td>
                                                    <img src="/assets/media/img/avatar.png" alt="" width="75px">
                                                </td>
                                                <td>
                                                    <a routerLink="/wegestu/client-final/details-client"
                                                        class="text-gray-800 text-hover-primary mb-1"> Client 1 </a>
                                                </td>
                                                <td>
                                                    <a class="text-gray-600 text-hover-primary mb-1">Domaine 1</a>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <div class="note">
                                                        <img src="/assets/media/rating/image (18).png" alt=""
                                                            width="100px">
                                                    </div>
                                                </td>
                                                <td> <span>2002</span></td>
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item" routerLink="/wegestu/client-final/details-client">Voir Détails</a>
                                                                <a class="dropdown-item" routerLink="/wegestu/contact/esn">Contacter
                                                                    le responsable</a>
                                                                <a class="dropdown-item">Evaluer</a>
                                                                <a class="dropdown-item">Bloquer</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <img src="/assets/media/img/avatar.png" alt="" width="75px">
                                                </td>
                                                <td>
                                                    <a routerLink="/wegestu/client-final/details-client"
                                                        class="text-gray-800 text-hover-primary mb-1"> Client 1 </a>
                                                </td>
                                                <td>
                                                    <a class="text-gray-600 text-hover-primary mb-1">Domaine 1</a>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <div class="note">
                                                        <img src="/assets/media/rating/image (18).png" alt=""
                                                            width="100px">
                                                    </div>
                                                </td>
                                                <td> <span>2002</span></td>
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item"
                                                                    routerLink="/wegestu/client-final/details-client">Voir Détails</a>
                                                                <a class="dropdown-item" href="/wegestu/wegestu/contact/esn">Contacter
                                                                    le responsable</a>
                                                                <a class="dropdown-item">Evaluer</a>
                                                                <a class="dropdown-item">Bloquer</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <img src="/assets/media/img/avatar.png" alt="" width="75px">
                                                </td>
                                                <td>
                                                    <a routerLink="/wegestu/client-final/details-client"
                                                        class="text-gray-800 text-hover-primary mb-1"> Client 1 </a>
                                                </td>
                                                <td>
                                                    <a class="text-gray-600 text-hover-primary mb-1">Domaine 1</a>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <div class="note">
                                                        <img src="/assets/media/rating/image (18).png" alt=""
                                                            width="100px">
                                                    </div>
                                                </td>
                                                <td> <span>2002</span></td>
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item"
                                                                    routerLink="/wegestu/client-final/details-client">Voir Détails</a>
                                                                <a class="dropdown-item" routerLink="/wegestu/contact/esn">Contacter
                                                                    le responsable</a>
                                                                <a class="dropdown-item">Evaluer</a>
                                                                <a class="dropdown-item">Bloquer</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <img src="/assets/media/img/avatar.png" alt="" width="75px">
                                                </td>
                                                <td>
                                                    <a routerLink="/wegestu/client-final/details-client"
                                                        class="text-gray-800 text-hover-primary mb-1 client"> Client 1
                                                    </a>
                                                </td>
                                                <td>
                                                    <a class="text-gray-600 text-hover-primary mb-1">Domaine 1</a>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <div class="note">
                                                        <img src="/assets/media/rating/image (18).png" alt=""
                                                            width="100px">
                                                    </div>
                                                </td>
                                                <td> <span>2002</span></td>
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item"
                                                                    routerLink="/wegestu/client-final/details-client">Voir Détails</a>
                                                                <a class="dropdown-item" routerLink="/wegestu/contact/esn">Contacter
                                                                    le responsable</a>
                                                                <a class="dropdown-item">Evaluer</a>
                                                                <a class="dropdown-item">Bloquer</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>

                                                <td>
                                                    <img src="/assets/media/img/avatar.png" alt="" width="75px">
                                                </td>
                                                <td>
                                                    <a routerLink="/wegestu/client-final/details-client"
                                                        class="text-gray-800 text-hover-primary mb-1 client"> Client 1
                                                    </a>
                                                </td>
                                                <td>
                                                    <a class="text-gray-600 text-hover-primary mb-1">Domaine 1</a>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <span>452</span>
                                                </td>
                                                <td>
                                                    <div class="note">
                                                        <img src="/assets/media/rating/image (18).png" alt=""
                                                            width="100px">
                                                    </div>
                                                </td>
                                                <td> <span>2002</span></td>
                                                <td class="actions">
                                                    <div class="tools">
                                                        <div class="dropdown">
                                                            <button
                                                                class="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary dropdown-toggle"
                                                                type="button" id="dropdownMenuButton"
                                                                data-bs-toggle="dropdown" aria-haspopup="true"
                                                                aria-expanded="false">
                                                                <svg width="19" height="5" viewBox="0 0 19 5"
                                                                    fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 0 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 6.85254 4.89478)"
                                                                        fill="#265D91" />
                                                                    <circle cx="2.44739" cy="2.44739" r="2.44739"
                                                                        transform="matrix(1 0 0 -1 13.7061 4.89478)"
                                                                        fill="#265D91" />
                                                                </svg>
                                                            </button>
                                                            <div class="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <a class="dropdown-item"
                                                                    routerLink="/wegestu/client-final/details-client">Voir Détails</a>
                                                                <a class="dropdown-item" routerLink="/wegestu/contact/esn">Contacter
                                                                    le responsable</a>
                                                                <a class="dropdown-item">Evaluer</a>
                                                                <a class="dropdown-item">Bloquer</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>




                                        </tbody>
                                    </table>
                                </div>
                                <!--end::Table-->
                                <div class="pagination pt-3">
                                    <svg width="141" height="21" viewBox="0 0 141 21" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M133 4L139 10L133 16" stroke="#ACA7A7" stroke-width="2"
                                            stroke-linecap="round" />
                                        <path d="M8 4L2 10L8 16" stroke="#ACA7A7" stroke-width="2"
                                            stroke-linecap="round" />
                                        <path
                                            d="M80.66 9.049C80.7113 8.37433 80.9717 7.84633 81.441 7.465C81.9103 7.08367 82.519 6.893 83.267 6.893C83.7657 6.893 84.1947 6.98467 84.554 7.168C84.9207 7.344 85.1957 7.586 85.379 7.894C85.5697 8.202 85.665 8.55033 85.665 8.939C85.665 9.39367 85.533 9.786 85.269 10.116C85.0123 10.446 84.675 10.6587 84.257 10.754V10.809C84.7337 10.9263 85.1113 11.1573 85.39 11.502C85.6687 11.8467 85.808 12.2977 85.808 12.855C85.808 13.273 85.7127 13.6507 85.522 13.988C85.3313 14.318 85.0453 14.5783 84.664 14.769C84.2827 14.9597 83.8243 15.055 83.289 15.055C82.5117 15.055 81.8737 14.8533 81.375 14.45C80.8763 14.0393 80.5977 13.46 80.539 12.712H81.507C81.5583 13.152 81.738 13.5113 82.046 13.79C82.354 14.0687 82.7647 14.208 83.278 14.208C83.7913 14.208 84.18 14.076 84.444 13.812C84.7153 13.5407 84.851 13.1923 84.851 12.767C84.851 12.217 84.6677 11.821 84.301 11.579C83.9343 11.337 83.3807 11.216 82.64 11.216H82.387V10.38H82.651C83.3257 10.3727 83.8353 10.2627 84.18 10.05C84.5247 9.83 84.697 9.49267 84.697 9.038C84.697 8.64933 84.5687 8.33767 84.312 8.103C84.0627 7.86833 83.7033 7.751 83.234 7.751C82.7793 7.751 82.4127 7.86833 82.134 8.103C81.8553 8.33767 81.6903 8.653 81.639 9.049H80.66Z"
                                            fill="#7E7C7C" />
                                        <rect x="24" y="1" width="19" height="19" rx="3" fill="#63C3E8" />
                                        <rect x="48.75" y="0.75" width="19.5" height="19.5" rx="3.25"
                                            stroke="#63C3E8" stroke-width="0.5" />
                                        <rect x="73.75" y="0.75" width="19.5" height="19.5" rx="3.25"
                                            stroke="#63C3E8" stroke-width="0.5" />
                                        <path d="M32.54 7.5V6.252H35.036V15H33.656V7.5H32.54Z" fill="white" />
                                        <path
                                            d="M55.561 14.197C56.4923 13.449 57.222 12.8367 57.75 12.36C58.278 11.876 58.7217 11.3737 59.081 10.853C59.4477 10.325 59.631 9.808 59.631 9.302C59.631 8.82533 59.5137 8.45133 59.279 8.18C59.0517 7.90133 58.6813 7.762 58.168 7.762C57.6693 7.762 57.2807 7.91967 57.002 8.235C56.7307 8.543 56.584 8.95733 56.562 9.478H55.594C55.6233 8.65667 55.8727 8.02233 56.342 7.575C56.8113 7.12767 57.4163 6.904 58.157 6.904C58.9123 6.904 59.51 7.113 59.95 7.531C60.3973 7.949 60.621 8.52467 60.621 9.258C60.621 9.86667 60.4377 10.4607 60.071 11.04C59.7117 11.612 59.301 12.118 58.839 12.558C58.377 12.9907 57.7867 13.4967 57.068 14.076H60.852V14.912H55.561V14.197Z"
                                            fill="#7E7C7C" />
                                        <path
                                            d="M106.166 15.066C105.975 15.066 105.814 15 105.682 14.868C105.55 14.736 105.484 14.5747 105.484 14.384C105.484 14.1933 105.55 14.032 105.682 13.9C105.814 13.768 105.975 13.702 106.166 13.702C106.349 13.702 106.503 13.768 106.628 13.9C106.76 14.032 106.826 14.1933 106.826 14.384C106.826 14.5747 106.76 14.736 106.628 14.868C106.503 15 106.349 15.066 106.166 15.066ZM108.476 15.066C108.285 15.066 108.124 15 107.992 14.868C107.86 14.736 107.794 14.5747 107.794 14.384C107.794 14.1933 107.86 14.032 107.992 13.9C108.124 13.768 108.285 13.702 108.476 13.702C108.659 13.702 108.813 13.768 108.938 13.9C109.07 14.032 109.136 14.1933 109.136 14.384C109.136 14.5747 109.07 14.736 108.938 14.868C108.813 15 108.659 15.066 108.476 15.066ZM110.785 15.066C110.594 15.066 110.433 15 110.301 14.868C110.169 14.736 110.103 14.5747 110.103 14.384C110.103 14.1933 110.169 14.032 110.301 13.9C110.433 13.768 110.594 13.702 110.785 13.702C110.968 13.702 111.122 13.768 111.247 13.9C111.379 14.032 111.445 14.1933 111.445 14.384C111.445 14.5747 111.379 14.736 111.247 14.868C111.122 15 110.968 15.066 110.785 15.066Z"
                                            fill="#7E7C7C" />
                                        <rect x="98.75" y="0.75" width="19.5" height="19.5" rx="3.25"
                                            stroke="#63C3E8" stroke-width="0.5" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--end::Tab pane-->
                </div>
                <!--end::Table-->
            </div>
            <!--end::Card body-->
        </div>
        <!--end::Card-->
        <!--begin::Modals-->
        <!--begin::Modal - Customers - Add-->

        <!--end::Modal - Customers - Add-->
        <!--begin::Modal - Adjust Balance-->

        <!--end::Modal - New Card-->
        <!--end::Modals-->
    </div>
    <!--end::Content container-->
</div>