import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../../shared/shared-models/responseApi';
import { CrudService } from '../../../shared/shared-services/crud-service/crud.service';
import { environment } from '../../../../environnements/environment';

@Injectable({
  providedIn: 'root',
})
export class TutoService extends CrudService<ApiResponse<any>, number> {
  public endpoint_tuto = '/api/Tuto/getBytype/1?';

  constructor(_http: HttpClient) {
    super(_http, `${environment.baseUrl}`);
  }

  /* get list tuto  */
  getAllTuto(perpage: number, page: number): Observable<ApiResponse<any>> {
    return this._http.get<ApiResponse<any>>(
      this._base + this.endpoint_tuto + 'per_page=' + perpage + '&page=' + page
    );
  }
  /* search list tuto*/
  searchListTuto(data: any) {
    return this._http.post<ApiResponse<any>>(
      this._base + `/api/Tuto/filterbytype`,
      data
    );
  }
}
